import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IUserCurrencyData,
  IUserCurrency,
} from "../../../../@types/CurrencyUser"

const initialState: IUserCurrencyData = {
  userCurrency: {
    currency_data: {
      code: "",
      currency_id: 0,
      decimal_digits: 0,
      name: "",
      name_plural: "",
      rounding: 0,
      symbol: "",
      symbol_native: "",
    },
    fashion_market_id: 0,
    message: "",
    status: 0,
  },
}

const getUserCurrency = createSlice({
  name: "UserCurrency",
  initialState,
  reducers: {
    setUserCurrency: (state, action: PayloadAction<IUserCurrency>) => {
      state.userCurrency = action.payload
    },
  },
})

export const { setUserCurrency } = getUserCurrency.actions

export default getUserCurrency.reducer
