import { useEffect, useState } from "react"
import { useAppSelector } from "../../utils/hooks"
import { useParams } from "react-router-dom"
import usePopup from "../../api/hooks/usePopup"
import useFollow from "../../api/hooks/useFollow"
import useReferral from "../../api/hooks/useReferral"
import useCaption from "../../api/hooks/useCaption"

export const useProfile = () => {
  const { getReferral } = useReferral()
  const { getCaption } = useCaption()

  useEffect(() => {
    getReferral()
    getCaption()
  }, [])
  const refLinksData = useAppSelector((state) => state.referralLink.RefLinks)
  const imgCaptionData = useAppSelector(
    (state) => state.profileCaption.imgCaption
  )

  return { refLinksData, imgCaptionData }
}
