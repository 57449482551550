import React, { useState } from "react"
import openShop from "../../../assets/open-shop.svg"
import { Modal } from "flowbite-react"
import styled from "styled-components"
import tw from "twin.macro"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import dots from "../../../assets/dots.png"
import { Accordion } from "flowbite-react"
import product from "../../../assets/productDetail.png"
import { useParams } from "react-router-dom"

import {
  FaBookmark,
  FaCircleRight,
  FaGift,
  FaInfo,
  FaLink,
  FaMessage,
  FaShare,
} from "react-icons/fa6"
import Main from "../../layouts/Main"
import { Container } from "../../Components/Globals/Container"
import ProductPreview from "../../Components/ProductPreview"
import { Marginer } from "../../Components/marginer"
import { Hr, Product, ProductList } from "../Home/Styled.Comps"
import { ISizeChart } from "../../../@types/sku"
import { useDiscover } from "../Discover/Discover.hooks"
import { useProductDeatilsData } from "../ProductDetails/ProductDetail.Hooks"
import useTalkToStore from "../../api/hooks/useTalkToStore"
const RadioContainer = styled.div`
  ${tw`flex items-center relative sm:mb-4`}
`

const RadioInput = styled.input`
  opacity: 0;

  &:checked + label {
    ${tw`
      border-blue-600
      text-blue-600
    `}
  }
`

const Label = styled.label`
  ${tw`
md:px-10 
md:py-4 
px-6 
py-4 
font-semibold
rounded-full 
bg-transparent 
border-2 
border-gray-500 
text-gray-400
text-blue-600`}
`
const Heading = styled.div`
  ${tw` 
  md:text-2xl text-lg
  font-bold
`}
`
const Button = styled.button`
  ${tw`
bg-sky-500
w-full
py-4
px-3 
rounded-full
text-white
mt-7 
transition-all
font-bold
sm:text-2xl text-sm
`};
`

const Button2 = styled.div`
  ${tw` 
    rounded-3xl  
    text-white 
    bg-black 
    py-2
    sm:px-8 px-6
    text-center
    flex items-center
`}
`
const GrayBorder = styled.div`
  ${tw`border my-8`}
`

const ProductPreviewer = styled(Container)`
  ${tw`
grid 
lg:grid-cols-2 grid-cols-1
`}
`
const PriceHeading = styled.div`
  ${tw`flex`}
`
const PriceHeadingGray = styled.div`
  ${tw`line-through font-normal mr-2 text-gray-600`}
`
const PriceHeadingRed = styled.div`
  ${tw`font-bold text-[#E41F10] ml-2`}
`
const GridCol = styled.div`
  ${tw`
  px-5
  pt-5
  
`}
  svg.slick-arrow {
    font-size: 18px;
    z-index: 1;
    color: #000;
  }
`
const Flex = styled.div`
  ${tw`
  flex
  flex-row
  justify-center
  gap-4
`}
`

const GiftDetail = () => {
  const { addChannel } = useTalkToStore()
  const [dotOpen, setDotOpen] = useState(false)
  const handleDots = () => {
    setDotOpen(!dotOpen)
  }
  const [openModal, setOpenModal] = useState(false)
  const [sizeName, setSizeName] = useState<string[]>([])
  const [sizeValue, setSizeValue] = useState([])

  const handleSizePopup = () => {
    const sizes = SkuData?.sui_data[0].sku_details.size_chart
    const sizeDt: ISizeChart[] = sizes.map((size) => ({
      Size: size.Size,
      Bust: size.Bust,
      Chest: size.Chest,
      France: size.France,
      Hip: size.Hip,
      Italy: size.Italy,
      Neck: size.Neck,
      UK: size.UK,
      US: size.US,
      Waist: size.Waist,
      standard: size.standard,
    }))
    let names: string[] = []
    let values = []
    sizeDt.map((size, index) => {
      if (index === 0) {
        names = Object.keys(size)
      }
      values.push(Object.values(size))
    })
    setSizeName(names)
    setSizeValue(values)
    console.log(names, values, "sizeName, sizeValue")
  }
  const { sui } = useParams<"sui">()
  const {
    SkuData,
    SkuRelatedData,
    addToGiftList,
    selection,
    setSelection,
    addToFavorite,
    removeFromFavorite,
    AddToBag,
  } = useProductDeatilsData(sui)
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    handleState,
    segment,
    gender,
    priceMin,
    priceMax,
    page,
    receiveData,
    colorsData,
    categoriesData,
    sizeslist,
    color,
    size,
    category,
  } = useDiscover({ searchParams, setSearchParams })
  const navigate = useNavigate()
  return (
    <Main className="profile">
      <Marginer margin={40} direction="vertical" />
      {SkuData.sui_data.length > 0 && (
        <ProductPreviewer>
          <GridCol className="slider-container">
            <ProductPreview
              images={SkuData?.sui_data[0].sku_details.image_data.image_data.map(
                (image) => image.image_url
              )}
              children={undefined}
            />
            <Marginer margin={40} direction="vertical" />
            <Flex>
              <Button2
                onClick={async (e) => {
                  e.preventDefault()
                  let resp = await addChannel({
                    shopper_username_v1: SkuData.store_details.store_username,
                    store_uuid: SkuData.store_details.store_uuid,
                  })
                  if (resp.status == 200) {
                    navigate("/chats/")
                  }
                }}
              >
                <Link to="" className="flex items-center">
                  <FaMessage className="sm:size-5 size-4 mr-2" />
                  Talk to store
                </Link>
              </Button2>
              <Button2
                className="!bg-sky-800 sm:text-sm px-5 cursor-pointer"
                onClick={() =>
                  navigate(
                    `/store/${SkuData.store_details.store_username}?store_uuid=${SkuData.store_details.store_uuid ?? "na"}`
                  )
                }
              >
                <img src={openShop} alt="" className="w-10 h-10" /> Enter Store
              </Button2>
            </Flex>
          </GridCol>
          <GridCol>
            <div className="flex justify-between relative">
              {SkuData.store_details && SkuData.store_details ? (
                <div
                  className="flex items-center cursor-pointer "
                  onClick={() =>
                    navigate(`/store/${SkuData.store_details.store_uuid}`)
                  }
                >
                  <div>
                    <img
                      src={SkuData.store_details.store_logo_thumb}
                      alt=""
                      className="h-20 w-20 rounded-full"
                    />
                  </div>

                  <Heading className="ml-5 hover:underline">
                    {SkuData.store_details.store_name}
                  </Heading>
                </div>
              ) : (
                "No Product Found"
              )}
              <Link to="" onClick={handleDots}>
                <img src={dots} alt="" />
              </Link>
              {dotOpen && (
                <div className="absolute bg-[#eee] shadow-lg rounded-lg right-0 top-8 ">
                  <Link
                    to=""
                    className="flex items-center text-lg py-4 px-6 hover:bg-slate-300"
                  >
                    <FaInfo className="mr-2 size-5 " />
                    Report
                  </Link>
                  <Link
                    to=""
                    className="flex items-center text-lg py-4 px-6 hover:bg-slate-300"
                  >
                    <FaLink className="mr-2 size-5 " />
                    Share Link
                  </Link>
                </div>
              )}
            </div>
            <GrayBorder></GrayBorder>
            <Heading>
              {SkuData?.sui_data?.length &&
                SkuData?.sui_data[0].sku_details?.product_title}
              <span className="block"> Dress</span>
            </Heading>
            <PriceHeading>
              <PriceHeadingGray>
                ₹{SkuData.sui_data[0].sku_details?.base_total}
              </PriceHeadingGray>
              {SkuData.sui_data[0].sku_details?.base_unit_price}{" "}
              <PriceHeadingRed>40% OFF</PriceHeadingRed>
            </PriceHeading>
            <h5 className="flex justify-between my-6 font-semibold">
              Select Size{" "}
              <Link
                to={""}
                className="flex items-center underline "
                onClick={() => {
                  handleSizePopup()
                  setOpenModal(true)
                }}
              >
                <FaCircleRight className="mr-2 size-5 " />
                Size Guide
              </Link>
              {sizeName && sizeValue && (
                <Modal
                  show={openModal}
                  onClose={() => setOpenModal(false)}
                  className="size_modal"
                >
                  <Modal.Header className="text-white bg-sky-900">
                    Size Chart
                  </Modal.Header>
                  <table className=" table-auto p-2">
                    <tr className="space-x-3 row">
                      {sizeName.map((name) => (
                        <th>{name.toUpperCase()}</th>
                      ))}
                    </tr>
                    {sizeValue.map((value, index) => (
                      <tr
                        className={`space-x-3 row ${index % 2 === 0 ? "bg-sky-100" : "bg-sky-200"} text-black`}
                      >
                        {value.map((val, val_index) => (
                          <td
                            className={`${val_index === 0 ? "font-bold" : ""}`}
                          >
                            {val}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </table>
                </Modal>
              )}
            </h5>
            <div className="flex flex-wrap md:gap-4 gap-2">
              {SkuData.sui_data[0]?.sku_sizes.map((item, index) => {
                return (
                  <RadioContainer key={index}>
                    <RadioInput
                      id={"default-radio-" + index}
                      type="radio"
                      value={item.size_id}
                      name={"default-radio"}
                      onClick={(e) => {
                        setSelection({
                          ...selection,
                          size_id: item.size_id.toString(),
                        })
                      }}
                    />
                    <Label htmlFor={"default-radio-" + index}>
                      {" "}
                      {item.name}
                    </Label>
                  </RadioContainer>
                )
              })}
            </div>
            <h5 className="my-6 font-semibold">Color </h5>
            <div className="flex items-center color_radio sm:flex-wrap gap-4">
              {SkuData.sui_data[0]?.sku_colors.map((color, index) => {
                return (
                  <RadioContainer>
                    <RadioInput
                      id={"color-radio-" + index}
                      type="radio"
                      value={color.color_id}
                      name="color"
                      onClick={(e) =>
                        setSelection({
                          ...selection,
                          color_id: color.color_id.toString(),
                        })
                      }
                    />
                    <Label htmlFor={"color-radio-" + index}>{color.name}</Label>
                  </RadioContainer>
                )
              })}
            </div>
            <Button
              className="py-4"
              onClick={() =>
                AddToBag({
                  currency_id: SkuData.sui_data[0].sku_details.currency_id,
                  gift_list_id: "",
                  gift_message: "",
                  color_id: selection.color_id,
                  quantity: 1,
                  receipient_uuid: "",
                  size_id: selection.size_id,
                  sui,
                })
              }
            >
              Add to Shopping Bag
            </Button>
            <div className="flex items-center justify-center my-8">
              <Link to="">
                <FaGift
                  className="mr-8 size-10 text-[#858585] hover:text-gray-800"
                  onClick={(e) => {
                    addToGiftList(e, {
                      sui: SkuData.sui_data[0].sku_details.SUI,
                      color_id: selection.color_id,
                      influencer_user_id: selection.influencer_user_id,
                      size_id: selection.size_id,
                      store_uuid: SkuData.store_details.store_uuid,
                      system_name:
                        SkuData.sui_data[0].sku_details.product_image_name,
                    })
                  }}
                />
              </Link>
              <Link to="">
                <FaShare className="mr-8 size-10 text-[#858585] hover:text-gray-800" />
              </Link>
              <Link to="">
                <FaBookmark
                  className={
                    "mr-8 size-10 " +
                    (SkuData.sui_data[0].sku_details.is_favorite
                      ? "text-[#0ea5e9]"
                      : "text-[#858585]") +
                    " hover:text-gray-800"
                  }
                  onClick={(e) => {
                    if (SkuData.sui_data[0].sku_details.is_favorite) {
                      removeFromFavorite(e, {
                        sui: SkuData.sui_data[0].sku_details.SUI,
                        type: "product",
                      })
                    } else {
                      addToFavorite(e, {
                        sui: SkuData.sui_data[0].sku_details.SUI,
                        influencer_user_id: selection.influencer_user_id,
                        store_uuid: SkuData.store_details.store_uuid,
                        system_name:
                          SkuData.sui_data[0].sku_details.product_image_name,
                        type: "product",
                      })
                    }
                  }}
                />
              </Link>
            </div>
            <GrayBorder></GrayBorder>
            <Accordion className="product_accordion">
              {SkuData.sui_data[0].sku_details?.policy.map((name, index) => {
                return (
                  <Accordion.Panel>
                    <Accordion.Title> {name.Title}</Accordion.Title>
                    <Accordion.Content>
                      <p className="mb-2 text-gray-500 dark:text-gray-400">
                        {name.Description}
                      </p>
                    </Accordion.Content>
                  </Accordion.Panel>
                )
              })}
            </Accordion>
          </GridCol>
        </ProductPreviewer>
      )}
      <Container>
        <Hr />
        <Marginer margin={30} direction="vertical" />
        <ProductList>
          {SkuRelatedData.sku_data.map((item, index) => {
            return (
              <Product
                link={`/product-details/${item.sui}`}
                image={item.image_url}
                name={item.sku_title}
                price={item.base_total}
                salePrice={item.base_unit_price}
                key={index}
                item={item}
                currency_code={item.display_currency_code}
              />
            )
          })}
        </ProductList>
        <Marginer margin={60} direction="vertical" />
      </Container>
    </Main>
  )
}

export default GiftDetail
