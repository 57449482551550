import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IUserCurrencyList,
  ICurrencyListData,
} from "../../../../@types/CurrencyUser"

const initialState: IUserCurrencyList = {
  currencyList: {
    currency_list: [],
  },
}

const getCurrencyList = createSlice({
  name: "CurrencyList",
  initialState,
  reducers: {
    setCurrencyList: (state, action: PayloadAction<ICurrencyListData>) => {
      state.currencyList = action.payload
    },
  },
})

export const { setCurrencyList } = getCurrencyList.actions

export default getCurrencyList.reducer
