import { useEffect, useState } from "react"
import { useAppSelector } from "../../utils/hooks"
import useCart from "../../api/hooks/useCart"
import { useNavigate } from "react-router-dom"
import { useGetCurrency } from "../../Components/Header/Header.Hooks"

export const useShoppingBagData = () => {
  const { cartDetail } = useAppSelector((state) => state)
  const { UserCurrency } = useGetCurrency()
  const [cartState, setCartState] = useState({
    isUpdate: false,
    is_gift: cartDetail.cartdetail.shopping_cart_data.gift_list_id,
    for_self: cartDetail.cartdetail.shopping_cart_data.for_self,
    message: "",
  })

  const { getCartDetails, updateCartMeta } = useCart()

  useEffect(() => {
    //@ts-ignore
    if (
      localStorage.getItem("order_num") &&
      localStorage.getItem("order_num").length > 0
    ) {
      GetCart()
    }
  }, [UserCurrency])

  const GetCart = () => {
    //@ts-ignore
    getCartDetails({ order_num: localStorage.getItem("order_num") })
  }

  const HandleGift = () => {
    let is_gift = cartState.is_gift == 0 ? 1 : 0
    let for_self = is_gift == 0 ? 1 : 0
    setCartState({
      ...cartState,
      is_gift,
      for_self,
      isUpdate: !cartState.isUpdate,
    })
  }

  const updateMeta = async () => {
    await updateCartMeta({
      for_self: cartState.for_self,
      message: cartState.message,
    })
    setCartState({ ...cartState, isUpdate: !cartState.isUpdate })
  }

  return {
    CartDetailsData: cartDetail.cartdetail,
    GetCart,
    cartState,
    HandleGift,
    updateMeta,
    setCartState,
  }
}
