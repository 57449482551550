import React, { useState } from "react"
import axios from "axios"
import { API, getToken } from "../config"
import { useAppDispatch } from "../../utils/hooks"
import { setSearchResulttt } from "../../utils/slices/SearchData/searchResult"
import { setuserSearch } from "../../utils/slices/Chats/getSearchUser"
import { setDictList } from "../../utils/slices/Dict/getMatchDictSlice"

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers.common["Content-Type"] = "application/json"

const useSearch = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()

  function getSearchResult(value: any) {
    const searchResult = axios
      .get(
        `${API}/search/get_search_results?api_token=${API_TOKEN}&dict_pattern=Black&search_pattern=black dress&type=product&page=0&search_string=${value}`
      )
      .then((res) => {
        dispatch(setuserSearch(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getSearchDict() {
    const searchDict = axios
      .get(`${API}/search/get_search_dict?api_token=${API_TOKEN}&type=&value=`)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getSearchMatchDict(inputValue: any) {
    const searchMatchDict = axios
      .get(
        `${API}/search/match_search_dictionary?api_token=${API_TOKEN}&search_string=${inputValue}`
      )
      .then((res) => {
        console.log(res, "inputValue", inputValue)

        dispatch(setDictList(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return { getSearchResult, getSearchDict, getSearchMatchDict }
}
export default useSearch
