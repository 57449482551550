import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IMenuListMain, IGetMenuList } from "../../../../@types/MenuInterface"
const initialState: IGetMenuList = {
  menuList: {
    list: {
      Women: [],
      Men: [],
      Accessories: [],
      Clothing: [],
      Jewellery: [],
      LiveSocial: [],
      Store: [],
    },
  },
}
const getMenuList = createSlice({
  name: "menuList",
  initialState,
  reducers: {
    setMenuLists: (state, action: PayloadAction<IMenuListMain>) => {
      state.menuList = action.payload
    },
  },
})

export const { setMenuLists } = getMenuList.actions

export default getMenuList.reducer
