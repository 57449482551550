import {
  CiHeart,
  CiLogout,
  CiSearch,
  CiShoppingCart,
  CiUser,
} from "react-icons/ci"
import { GiHamburgerMenu } from "react-icons/gi"
import styled from "styled-components"
import tw from "twin.macro"
import { Container } from "../Globals/Container"
import Logo from "../Globals/Logo"
import Menu from "../Menu"
import { SCREENS } from "../responsive"
import { useGetCurrency } from "./Header.Hooks"
import { useAppSelector } from "../../utils/hooks"
import { Link, useNavigate, useParams } from "react-router-dom"
import { CiBellOn } from "react-icons/ci"
import { useEffect, useState } from "react"
import useAuthApi from "../../api/hooks/useAuthApi"
import moment from "moment"
import { FaGift } from "react-icons/fa6"
const MenuBar = styled.div`
  ${tw`
        flex
        flex-row
        justify-between
        my-7
        relative
    `}
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
            flex-col
            px-1
            items-center
        `}
  }
`
const AlertDiv = styled.div`
  ${tw`
 flex flex-row items-center justify-center mt-4 mb-8
    `}
`
const SelectItems = styled.select`
  ${tw`
        bg-sky-100 
        border 
        border-gray-300 
        text-gray-900 
        text-xs 
        rounded-2xl 
        block 
        transition ease-in-out 
        delay-100
        font-light
        cursor-pointer
    `}
  &:focus {
    ${tw`
            ring-[#038DCE]
            border-[#038DCE]
        `}
  }
`
const Button = styled.button`
  ${tw`
bg-sky-500
w-full
py-2
px-3 
rounded-full
text-white
mt-7 
transition-all
font-bold
sm:text-sm text-sm mr-1
`};
`
const ListHolder = styled.div`
  ${tw`
            text-white 
            font-bold 
            text-xl
        `}
  @media screen and (max-width: ${SCREENS.lg}) {
    .icons {
      margin-left: 2px;
    }
    ${tw`
                mr-16
                mt-0
            `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    .icons {
      margin-left: 2px;
    }
    ${tw`
                mt-4
                mr-0
            `}
  }
`
const Header = () => {
  const [position, setPosition] = useState(window.scrollY)
  const [visible, setVisible] = useState(true)
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.scrollY

      setVisible(position > moving)
      setPosition(moving)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const cls = visible ? "visible" : "hidden"

  const { UserCurrency, CurrencyList, cartCount, setUserCurrencyData } =
    useGetCurrency()
  const { logoutApi } = useAuthApi()

  const { getMenulist } = useAppSelector((state) => state)
  const { AlertsInHeader } = useGetCurrency()
  const { uuid, type } = useParams()
  const Currencies = () => {
    return (
      <div className="flex items-center justify-center cursor-pointer">
        <SelectItems
          id="countries"
          value={UserCurrency?.userCurrency?.currency_data?.currency_id}
          onChange={(e) => {
            setUserCurrencyData({ currency_id: e.target.value })
          }}
        >
          {CurrencyList?.currencyList?.currency_list?.map((currency) => {
            return (
              <option
                className=" cursor-pointer"
                key={currency?.currency_id}
                value={currency?.currency_id}
              >
                {currency?.code}
              </option>
            )
          })}
        </SelectItems>
      </div>
    )
  }

  const handleToggler = (event) => {
    event.preventDefault()

    const target = event.currentTarget
    const topMenu = target.parentNode?.querySelector(".top-menu")

    if (topMenu) {
      topMenu.classList.toggle("active")
    } else {
      // Handle the case where .top-menu element is not found
      console.error("Top menu element not found")
    }
  }

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const navigate = useNavigate()
  const handleClick = (refId, type) => {
    switch (type) {
      case "Follow": {
        navigate("/request")
        break
      }
      case "Ship": {
        navigate(`/orderDetails/${refId}`)
        break
      }
      case "Gift": {
        navigate(`/giftOrderDetail/${refId}`)
        break
      }
      case "Popup": {
        navigate(`/deals-details/${refId}`)
        break
      }
    }
  }
  const elements = document.getElementsByTagName("header")
  if (elements.length > 0) {
    elements[0].scrollIntoView()
  }
  const userId = localStorage.getItem("user")
  return (
    <header className={`header ${cls}`}>
      <Container>
        <MenuBar>
          <Logo />
          <div className="mobile-toggler" onClick={handleToggler}>
            <GiHamburgerMenu />
          </div>
          <Menu MenuList={getMenulist.menuList.list} />
          <ListHolder>
            <ul className="flex">
              <li className="mx-5 flex justify-center items-center text-xs icons">
                <Link to="/discover?setFocusSearch=true">
                  <CiSearch className=" text-sky-700 size-6" />{" "}
                </Link>
              </li>
              {localStorage.getItem("user") && (
                <li className="mx-2 flex justify-center items-center text-xs icons">
                  <Link
                    to={
                      "/profile/" + localStorage.getItem("user") + "/gift/#tabs"
                    }
                  >
                    <CiHeart className=" text-sky-700 size-6" />
                  </Link>
                </li>
              )}
              {localStorage.getItem("user") ? (
                <li className="mx-2 flex justify-center items-center text-xs icons">
                  <Link to={`/profile/${localStorage.getItem("user")}`}>
                    <CiUser className=" text-sky-700 size-6" />
                  </Link>
                </li>
              ) : (
                <li className="mx-2 flex justify-center items-center text-xs icons">
                  <Link to="/Auth">
                    <CiUser className=" text-sky-700 size-6" />
                  </Link>
                </li>
              )}
              <li className="mx-2 flex justify-center items-center text-xs icons">
                <Link to={"/shoppingBag"}>
                  <CiShoppingCart className=" text-sky-700 size-6 " />
                </Link>
                {cartCount > 0 && (
                  <span className="itemCount">{cartCount}</span>
                )}
              </li>
              {localStorage.getItem("user") && (
                <li
                  className="mx-2 flex justify-center items-center text-xs icons"
                  onClick={toggleDropdown}
                >
                  <CiBellOn className="text-sky-700 size-6 mt-0 cursor-pointer" />
                </li>
              )}
              {isDropdownOpen && (
                <div className="absolute top-8 right-0 z-10 bg-white  border text-base font-normal ">
                  <div>
                    <div className="px-4 py-2 text-black cursor-pointer  w-[370px] h-[600px] overflow-auto ">
                      <div>
                        {AlertsInHeader.rcvd_notifications.length > 0 ? (
                          AlertsInHeader.rcvd_notifications.map(
                            (item, index) => (
                              <div className="hover:bg-gray-100 pt-1 flex justify-start pb-1 mt-2">
                                <AlertDiv>
                                  <Link
                                    to={`/profile/${AlertsInHeader.user_uuid}`}
                                  >
                                    <div className="">
                                      <img
                                        src={item.sender_image}
                                        alt=""
                                        className="w-14 h-14 mr-2 rounded-full"
                                      />
                                    </div>
                                  </Link>
                                  <div
                                    className="ml-5 "
                                    onClick={() =>
                                      handleClick(
                                        item.reference_id,
                                        item.message_type
                                      )
                                    }
                                  >
                                    <h3 className="font-semibold">
                                      {item.sender_name}
                                    </h3>
                                    <p>{item.message}</p>
                                    <p>
                                      {moment
                                        .utc(item.date)
                                        .local()
                                        .startOf("seconds")
                                        .fromNow()}
                                    </p>
                                    {item.message_type === "Follow" ? (
                                      <div className="flex">
                                        <Button>Accept</Button>
                                        <Button>Reject</Button>{" "}
                                      </div>
                                    ) : null}
                                    {item.message_type === "Gift" ? (
                                      <FaGift className="text-red-500" />
                                    ) : null}
                                  </div>
                                </AlertDiv>
                              </div>
                            )
                          )
                        ) : (
                          <p className="text-center mt-2 border-solid border-2 border-grey-600 pt-1 pb-1 rounded-full hover:bg-gray-200">
                            No Alerts Found
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {localStorage.getItem("user") && (
                <li
                  className="mx-2 flex justify-center items-center text-xs icons"
                  onClick={async (e) => {
                    e.preventDefault()
                    let keysToRemove = [
                      "userData",
                      "token",
                      "user",
                      "cart_id",
                      "order_num",
                    ]
                    let resp = await logoutApi()
                    if (resp.status == 200) {
                      for (let key of keysToRemove) {
                        localStorage.removeItem(key)
                      }

                      navigate("/Auth")
                    }
                  }}
                >
                  <Link to={"#"}>
                    <CiLogout className="text-sky-700 size-6 mt-1 cursor-pointer" />
                  </Link>
                </li>
              )}
              <li className="mx-2 flex justify-center items-center text-xs icons">
                {Currencies()}{" "}
              </li>
            </ul>
          </ListHolder>
        </MenuBar>
      </Container>
    </header>
  )
}

export default Header
