import { useEffect } from "react"
import { useAppSelector } from "../../utils/hooks"
import useMyorders from "../../api/hooks/useMyorders"

export const useOrderdetail = (order_id) => {
  const { getMyorders } = useMyorders()

  useEffect(() => {
    getMyorders(order_id)
  }, [])
  const OrderCards = useAppSelector((state) => state.myOrder.suggestions)
  return { OrderCards }
}
