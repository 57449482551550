import React, { useState } from "react"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6"
import Slider from "react-slick"
import "./style.css"

const initialSettings = {
  dots: false,
  dotsClass: "slick-dots slick-thumb",
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <FaAngleLeft />,
  nextArrow: <FaAngleRight />,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        arrows: false,
      },
    },
  ],
}
const initialSettings2 = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  prevArrow: <FaAngleLeft />,
  nextArrow: <FaAngleRight />,
  centerMode: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        arrows: false,
      },
    },
  ],
}

const ProductPreview = ({ children, images, ...props }) => {
  const [settings, setSettings] = useState(initialSettings)
  const [settings2, setSettings2] = useState(initialSettings2)
  const [nav1, setNav1] = useState<Slider | undefined>(undefined)
  const [nav2, setNav2] = useState<Slider | undefined>(undefined)

  return (
    <div className="w-[80%] max-w-[400px] mx-auto">
      {images.length <= 1 && (
        <>
          <div
            data-index="0"
            className="slick-slide slick-active slick-current max-w-[100%]"
            aria-hidden="false"
            style={{ outline: "none", width: "728px", display: "block" }}
          >
            <div style={{ width: "100%", display: "inline-block" }}>
              <img
                src={images[0].image_url}
                alt="Product 1"
                className="w-full"
              />
            </div>
          </div>
        </>
      )}
      {images.length > 1 && (
        <>
          <Slider
            asNavFor={nav2}
            ref={(slider1) => setNav1(slider1 ?? undefined)}
            {...settings}
            className="productPreview"
            key={"Preview"}
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image.image_url}
                  alt={`Product ${index + 1}`}
                  className="w-full"
                />
              </div>
            ))}
          </Slider>

          <Slider
            asNavFor={nav1}
            ref={(slider1) => setNav2(slider1 ?? undefined)}
            {...settings2}
            className="w-11/12 mx-auto previewTrack"
            key={"track"}
          >
            {images.map((image, index) => (
              <div key={index} className="p-3 ">
                <img
                  src={image.image_url_thumb}
                  alt={`Product ${index + 1}`}
                  className="w-full"
                />
              </div>
            ))}
          </Slider>
        </>
      )}
    </div>
  )
}

export default ProductPreview
