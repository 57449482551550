import styled from "styled-components"
import { Container } from "../Globals/Container"
import tw from "twin.macro"
import { Link, useNavigate } from "react-router-dom"
import { Fragment } from "react"
import Logo from "../Globals/Logo"
import { Marginer } from "../marginer"
import { customerServices, myAccount } from "../../dataJson/footer"
import { FaFacebook } from "react-icons/fa6"
import { RiInstagramFill, RiYoutubeFill } from "react-icons/ri"
import { FaTwitter } from "react-icons/fa"
import playstore from "../../../assets/playstore.png"
import appstore from "../../../assets/appstore.png"
import { SCREENS } from "../../Components/responsive"
const FooterContainer = styled.div`
  background-color: #05090c;
  ${tw`
    flex
    flex-row
    w-full
`}
`
const FooterColumn = styled.div`
  ${tw`
    w-full
    flex
    flex-col
    py-7
    text-slate-200
`}
  @media only screen and (max-width: ${SCREENS.md}) {
    ${tw`
        px-5
    `}
  }
`
const FooterLogo = styled(Logo)`
  img {
    max-width: 160px;
  }
`
const FooterWrapper = styled(Container)`
  ${tw`
    grid 
    grid-cols-4 
    gap-4
    transition-all
`}
  @media only screen and (max-width: ${SCREENS.md}) {
    ${tw`
        grid 
        grid-cols-2 
        gap-4
    `}
  }
  @media only screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        grid 
        grid-cols-1 
        gap-4
    `}
  }
`
const P = styled.p`
  ${tw`
    text-left
    text-slate-200
    font-normal
    text-sm
`}
`
const PBold = styled(P)`
  ${tw`
    text-sm
    font-medium
`}
`
const Heading = styled.h3`
  ${tw`
    text-sm
    font-semibold
    text-white
`}
`
const LinksHolder = styled.div`
  ${tw`
    flex 
    flex-col 
    text-justify  
    gap-4 
    text-slate-200
`}
`

const Image = styled.img`
  max-width: 150px;
  ${tw`

`}
`

const Anchor = styled.a`
  ${tw`

`}
`
const Footer = () => {
  const navigate = useNavigate()
  const userId = localStorage.getItem("user")
  const handleAccount = () => {
    navigate(`/profile/${userId}`)
  }
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterColumn>
          <FooterLogo />
          <Marginer margin={30} direction="vertical" />
          <P>
            The meaning of TEXT is the original words and form of a written or
            printed work. How to use text in a sentence.{" "}
          </P>
          <Marginer margin={30} direction="vertical" />
          <PBold className="text-white w-52 text-justify">
            Address: 100xyz Fork Avenur Cool 58est Mumbai India
          </PBold>
          <Marginer margin={20} direction="vertical" />
          <PBold className="text-white">Email: abd100@gmail.com </PBold>
          <Marginer margin={20} direction="vertical" />
          <PBold className="text-white">Phone: 9834572817</PBold>
        </FooterColumn>

        <FooterColumn>
          <Heading>CUSTOMER SERVICE</Heading>
          <Marginer margin={30} direction="vertical" />
          <LinksHolder>
            <ul>
              {customerServices.length > 0 &&
                customerServices.map((link, index) => {
                  return (
                    <Fragment key={index}>
                      <li className="mb-3">{link.list}</li>
                    </Fragment>
                  )
                })}
            </ul>
          </LinksHolder>
        </FooterColumn>
        {localStorage.getItem("user") && (
          <FooterColumn>
            <Heading> MY ACCOUNT</Heading>
            <Marginer margin={30} direction="vertical" />
            <LinksHolder>
              <ul>
                {myAccount.length > 0 &&
                  myAccount.map((link, index) => {
                    return (
                      <Fragment key={index + "1"}>
                        <li className="mb-3 cursor-pointer">
                          <a href={link.link}>{link.list}</a>
                        </li>
                      </Fragment>
                    )
                  })}
              </ul>
            </LinksHolder>
          </FooterColumn>
        )}

        <FooterColumn>
          <Heading>DOWNLOAD THE APP</Heading>
          <Marginer margin={30} direction="vertical" />
          <Anchor
            href="https://play.google.com/store/apps/details?id=com.seashels.discover&hl=en_IN&gl=US"
            target="_blank"
          >
            <Image src={playstore} />
          </Anchor>
          <Marginer margin={10} direction="vertical" />
          <Anchor
            href="https://apps.apple.com/in/app/seashels/id6463077820"
            target="_blank"
          >
            <Image src={appstore} />
          </Anchor>

          <Marginer margin={20} direction="vertical" />
          <h5>FOLLOW US ON</h5>
          <Marginer margin={20} direction="vertical" />
          <div className="flex gap-6  ">
            <Link to="http://www.facebook.com/sharer/sharer.php?u=https://seashel.shivoas.com/&t=Seashels">
              <FaFacebook className="size-5" />
            </Link>
            <Link to="https://www.instagram.com/">
              <RiInstagramFill className="size-5" />
            </Link>
            <Link to="https://www.youtube.com/">
              <RiYoutubeFill className="size-5" />
            </Link>
            <Link to="https://twitter.com">
              <FaTwitter className="size-5" />
            </Link>
          </div>
          <Marginer margin={20} direction="vertical" />
        </FooterColumn>
      </FooterWrapper>
    </FooterContainer>
  )
}

export default Footer
