import { useEffect, useState } from "react"
import { useAppSelector } from "../../../utils/hooks"

import useUserAccount from "../../../api/hooks/useUserAccount"
import useGift from "../../../api/hooks/useGift"
import countryCode from "../../../utils/countryList.json"
import { PopTost } from "../../../utils/common"
import { useDispatch } from "react-redux"

export const useAccountInfoData = (userId?: string) => {
  const dispatch = useDispatch()
  const { getUserDetails, updateUserInfo, uploadProfile } = useUserAccount()
  const { getGiftList, getUserFavouriteList } = useGift()
  const userDetails = useAppSelector((state) => state.userAccount.UserAccourt)
  const DataOfGiftList = useAppSelector((state) => state.giftLists.ListOfGifts)
  const DataOfFavLists = useAppSelector((state) => state.favList.allUserFavList)

  const [firstName, setFirstName] = useState(userDetails.user_data.profile_name)
  const [email, setEmail] = useState(userDetails.user_data.user_email)
  const [password, setPassword] = useState("")
  const [phoneNumber, setPhoneNumber] = useState(
    userDetails.user_data.user_phone
  )
  const [DateOfM, setDateof] = useState(userDetails.user_data.date_of_birth)
  const [image, setImage] = useState(userDetails.user_data.image_url)
  const [selectedGender, setSelectedGender] = useState(
    userDetails.user_data.gender_id
  )

  useEffect(() => {
    let userIdTemp = userId
    if (!userId) {
      userIdTemp = localStorage.getItem("user")
    }
    getUserDetails(userIdTemp)
    getGiftList()
    getUserFavouriteList()
  }, [])

  useEffect(() => {
    let phone = userDetails.user_data.user_phone.split("-")
    let code = ""
    Object.keys(countryCode).map((key) => {
      if (countryCode[key] == phone[0]) {
        code = key
      }
    })

    phone.splice(0, 1, code)

    setPhoneNumber(phone.join(""))

    setFirstName(userDetails.user_data.profile_name)
    setEmail(userDetails.user_data.user_email)
    setDateof(userDetails.user_data.date_of_birth)
    setSelectedGender(userDetails.user_data.gender_id)
    setImage(userDetails.user_data.image_url)
    setPassword("")
  }, [userDetails.user_data.user_phone])

  const handleGenderChange = (e) => {
    setSelectedGender(e.target.value)
  }

  const updateInfo = async () => {
    let resp = await updateUserInfo({
      email: userDetails.user_data.user_email,
      profile_name: firstName,
      phone: userDetails.user_data.user_phone,
      gender_id: selectedGender,
      date_of_birth: DateOfM,
      password: password,
    })
    if (resp.status == 200) {
      PopTost({ message: "Profile Updated Successfully", type: "success" })
    }
    let userIdTemp = userId
    if (!userId) {
      userIdTemp = localStorage.getItem("user")
    }
    getUserDetails(userIdTemp)
  }

  const UploadImage = async (e) => {
    if (e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]))
      let resp = await uploadProfile({ file: e.target.files[0] })
      if (resp.status == 200) {
        PopTost({
          message: "Profile Image Updated Successfully",
          type: "success",
        })
      }
    }
  }

  return {
    userDetails,
    DataOfGiftList,
    DataOfFavLists,
    firstName,
    email,
    password,
    phoneNumber,
    DateOfM,
    selectedGender,
    handleGenderChange,
    setFirstName,
    setEmail,
    setPassword,
    setPhoneNumber,
    setDateof,
    image,
    setSelectedGender,
    updateInfo,
    UploadImage,
  }
}
