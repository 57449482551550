import { useEffect } from "react"
import UseCurrency from "../../api/hooks/useCurrency"
import { useAppSelector } from "../../utils/hooks"
import { useNavigate } from "react-router-dom"
import useAlerts from "../../api/hooks/useAlerts"

export const useGetCurrency = () => {
  const { getCurrencyList, getUserCurrency, setUserCurrencyData } =
    UseCurrency()
  const { getAlerts } = useAlerts()

  useEffect(() => {
    getUserCurrency()
    getCurrencyList()
    getAlerts()
  }, [])

  const navigate = useNavigate()
  const handleClick = (refId, type) => {
    switch (type) {
      case "Follow": {
        navigate(`/followers`)
        break
      }
      case "Ship": {
        navigate(`/orderDetails/${refId}`)
        break
      }
      case "Gift": {
        navigate(`/giftOrderDetail/${refId}`)
        break
      }
      case "Popup": {
        navigate(`/deals-details/${refId}`)
        break
      }
    }
  }

  const { UserCurrency, CurrencyList } = useAppSelector((state) => state)
  const { cartDetail } = useAppSelector((state) => state)
  const AlertsInHeader = useAppSelector((state) => state.alerts.suggestions)

  //@ts-ignore
  return {
    UserCurrency,
    CurrencyList,
    AlertsInHeader,
    setUserCurrencyData,
    handleClick,
    cartCount: cartDetail?.cartdetail?.cart_items?.length ?? 0,
  }
}
