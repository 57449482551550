import AuthScreen from "../../layouts/auth"
import { Login } from "../../Components/AuthComponents/Login"
import { Signup } from "../../Components/AuthComponents/Signup"
import { ForgotPassword } from "../../Components/AuthComponents/ForgotPassword"
import { VerifyScreen } from "../../Components/AuthComponents/VerifyScreen"
import { useGetGuest } from "./Auth.hooks"

const Auth = () => {
  const { state, handleState, login, signup, verify, checkUser, resendOTP } =
    useGetGuest()

  return (
    <AuthScreen>
      {(state.activePage.length <= 0 || state.activePage == "login") && (
        <Login updateState={handleState} onSubmit={login} state={state} />
      )}

      {state.activePage == "signup" && (
        <Signup
          updateState={handleState}
          onSubmit={signup}
          state={state}
          checkUser={checkUser}
        />
      )}

      {state.activePage == "forgotPassword" && (
        <ForgotPassword
          updateState={handleState}
          onSubmit={verify}
          state={state}
        />
      )}

      {state.activePage == "verify" && (
        <VerifyScreen
          updateState={handleState}
          onSubmit={verify}
          resendOTP={resendOTP}
          state={state}
        />
      )}
    </AuthScreen>
  )
}

export default Auth
