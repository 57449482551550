import { Link } from "react-router-dom"
import styled from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../responsive"
import Skeleton from "react-loading-skeleton"
import { useEffect, useState } from "react"

const ThumbHolder = styled.div<{ image: string }>`
  ${tw`
    w-80
    bg-cover
    bg-center
    relative
`}
  height:500px;
  background-image: url(${(props) => props.image});

  @media screen and (max-width: ${SCREENS["2xl"]}) {
    ${tw`
        w-72
    `}
  }
  /* @media screen and (max-width:${SCREENS.xl}){
    ${tw`
        w-60
    `}
    height:400px;
} */
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        w-72
    `}
    height:450px;
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        w-96
    `}
    height:450px;
  }
`
const TextHolder = styled.div`
  ${tw`
    absolute
    w-full
    h-1/4
    bg-gradient-to-t
    from-neutral-900
    to-transparent
    bottom-0 
    left-0
    justify-end
    p-5
    flex
    flex-col
`}
`
const Links = styled(Link)`
  ${tw`
    flex
    justify-start
`}
  @media screen and (max-width:${SCREENS.lg}) {
    ${tw`
        justify-center
    `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        justify-center
    `}
  }
`
const Title = styled.h2`
  ${tw`
    text-base
    font-medium
    text-white
`}
`
const SubTitle = styled.h3`
  ${tw`
text-sm
font-normal
text-white
`}
`

const ThumbSlide = ({ children, title, subtitle, image, ...props }) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])
  return (
    <Links to={props.link} key={props.key}>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "500px",
            backgroundColor: "#f3f3f3",
            position: "relative",
          }}
        >
          <Skeleton height={100} count={1} />
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              left: "30%",
              top: "25%",
              color: "#000",
              display: "flex",
              alignItems: "center",
            }}
          ></div>
        </div>
      ) : (
        <ThumbHolder image={image}>
          <TextHolder>
            <Title>{title}</Title>
            <SubTitle>{subtitle}</SubTitle>
            {props.expiry && <SubTitle>Expires {props.expiry}</SubTitle>}
          </TextHolder>
        </ThumbHolder>
      )}
    </Links>
  )
}

export default ThumbSlide
