import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ISkuListData, ISkuList } from "../../../../@types/sku"

const initialState: ISkuListData = {
  skuListData: {
    current_index: " ",
    per_page: 0,
    sku_count: 0,
    sku_data: [],
    status: 0,
    store_details: {
      store_logo: "",
      store_name: "",
      store_region: "",
    },
    total_count: 0,
  },
}

const skuListSlice = createSlice({
  name: "skuList",
  initialState,
  reducers: {
    setSkuList: (state, action: PayloadAction<ISkuList>) => {
      state.skuListData = action.payload
    },
  },
})

export const { setSkuList } = skuListSlice.actions

export default skuListSlice.reducer
