import axios from "axios"
import { API } from "../config"
import { useAppDispatch } from "../../utils/hooks"
import { setMenuLists } from "../../utils/slices/Menu/MenuList"

const useMenuList = () => {
  const dispatch = useAppDispatch()

  const getMenu = async () => {
    const menu = await axios.get(`${API}/website/get_nav_menu`)

    if (menu.data.status == 200 || menu.data.status == 201) {
      dispatch(setMenuLists({ list: menu.data.data }))
      return menu.data
    }
    return menu.data
  }

  return { getMenu }
}

export default useMenuList
