import axios from "axios"
import { useAppDispatch } from "../../utils/hooks"
import { API, getToken } from "../config"
import { setPopupDetails } from "../../utils/slices/Popups/getPopupDetailsSlice"
import { setProducts } from "../../utils/slices/Products/ProductsSlice"

interface IProps {
  popup_uuid?: string
}

const usePopup = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  function getPopupDetails(props: IProps) {
    dispatch(
      setPopupDetails({
        data: {
          popup_data: {
            category_id: 0,
            category_name: "",
            description: "",
            discount_max: 0,
            discount_min: 0,
            epoch_time: 0,
            expiry_date: "",
            fashion_market_id: [],
            gender: "",
            gender_id: 0,
            main_image: "",
            main_image_url: "",
            popup_uuid: "",
            segment_id: 0,
            segment_name: "",
            start_date: "",
            status: "200",
            store_id: 0,
            store_name: "",
            sui_1: "",
            sui_10: "",
            sui_2: "",
            sui_3: "",
            sui_4: "",
            sui_5: "",
            sui_6: "",
            sui_7: "",
            sui_8: "",
            sui_9: "",
            system_name: "",
            tag_list: [],
            visible_markets: 0,
          },
          status: 200,
          store_details: {
            base_country: "",
            city: "",
            store_logo: "",
            store_logo_thumb: "",
            store_name: "",
            store_owner: 0,
            store_username: "",
            store_uuid: "",
          },
          sui_list: [],
        },
      })
    )
    const popup = axios
      .get(
        `${API}/popups/get_popup_details?&api_token=${API_TOKEN}&popup_uuid=${props.popup_uuid}`
      )
      .then((res) => {
        const suiData = Array.isArray(res.data.sui_data)
          ? res.data.sui_data
          : []
        dispatch(setPopupDetails(res.data))
        dispatch(
          setProducts({
            data: [suiData],
            count: res.data.count,
            current_page: parseInt(res.data.current_page),
            total_count: res.data.total_count,
            next_page: res.data.next_page,
            prev_page: res.data.prev_page,
          })
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return { getPopupDetails }
}
export default usePopup
