import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IDetailsData, IDetailsSku } from "../../../../@types/sku"

const initialState: IDetailsData = {
  detailData: {
    shopper_details: "",
    status: 0,
    store_details: {
      base_country: "",
      city: "",
      store_logo: "",
      store_logo_thumb: "",
      store_name: "",
      store_owner: 0,
      store_username: "",
      store_uuid: "",
    },
    sui_data: [],
  },
}

const detailsSkuSlice = createSlice({
  name: "detailData",
  initialState,
  reducers: {
    setdetailsSku: (state, action: PayloadAction<IDetailsSku>) => {
      state.detailData = action.payload
    },
  },
})

export const { setdetailsSku } = detailsSkuSlice.actions

export default detailsSkuSlice.reducer
