import { Link } from "react-router-dom"
import styled from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../responsive"

const ThumbHolder = styled.div<{ image: string }>`
  ${tw`
    w-full
    bg-cover
    bg-center
    relative
`}
  height:600px;
  background-image: url(${(props) => props.image});

  @media screen and (max-width: ${SCREENS["2xl"]}) {
    ${tw`
        w-full
    `}
  }
  /* @media screen and (max-width:${SCREENS.xl}){
    ${tw`
        w-60
    `}
    height:400px;
} */
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        w-full
    `}
    height:580px;
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        w-10/12
    `}
    height:500px;
  }
`
const TextHolder = styled.div`
  ${tw`
    absolute
    w-full
    h-1/4
    bg-gradient-to-t
    from-neutral-900
    to-transparent
    bottom-0 
    left-0
    justify-end
    p-5
    flex
    flex-col
`}
`
const Links = styled(Link)`
  ${tw`
    flex
    justify-start 
`}
  @media screen and (max-width:${SCREENS.lg}) {
    ${tw`
        justify-center
    `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        justify-center
    `}
  }
`
const Title = styled.h2`
  ${tw`
    text-base
    font-medium
    text-white
`}
`
const SubTitle = styled.h3`
  ${tw`
text-sm
font-normal
text-white
`}
`

const SocialThumb = ({ children, title, subtitle, image, ...props }) => {
  return (
    <Links to={props.link} key={props.key}>
      <ThumbHolder image={image} className="">
        <TextHolder>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </TextHolder>
      </ThumbHolder>
    </Links>
  )
}

export default SocialThumb
