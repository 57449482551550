import axios from "axios"
import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { API, getToken, BasePayloadPost } from "../config"
import { sethome } from "../../utils/slices/User/homeSlice"

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/json';

const useUser = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  function getHome() {
    console.log(BasePayloadPost())
    const body = BasePayloadPost()
    const userhome = axios
      .post(`${API}/user/user_home`, body)
      .then((res) => {
        console.log(res.data)
        dispatch(sethome(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return { getHome }
}
export default useUser
