import React from "react"
import axios from "axios"
import { API, getToken, objectToUrlParams, tokenPayloadLogin } from "../config"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { setcartDetails } from "../../utils/slices/Carts/cartDetails"
import { PopTost } from "../../utils/common"

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers.common["Content-Type"] = "application/json"

const useCart = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  const { cartDetail } = useAppSelector((state) => state)
  interface CartItem {
    cart_id: string
    color_id: string
    currency_id: string
    gift_list_id: string
    gift_message: string
    order_num: string
    quantity: string
    receipient_uuid: string
    size_id: string
    sui: string
  }

  const getCartDetails = async ({ order_num }) => {
    const payload = tokenPayloadLogin({ order_num })
    console.log(payload)
    const params = objectToUrlParams(payload)
    const cartDetails = await axios.get(
      `${API}/cart/get_cart_details?` + params
    )
    if (cartDetails.data.status == 200 || cartDetails.data.status == 200) {
      let cart = cartDetails.data
      cart.cart_data = cartDetail.cartdetail.cart_data
      dispatch(setcartDetails(cart))
      return cart
    }
    return cartDetails.data
  }

  const createCart = async () => {
    const payload = { api_token: API_TOKEN }
    const newCart = await axios.post(`${API}/cart/create_cart`, payload)
    const cartDetails = { ...cartDetail }
    if (
      newCart.data?.cart_data?.status == 200 ||
      newCart.data?.cart_data?.status == 201
    ) {
      let cart = { ...cartDetails.cartdetail }
      cart.cart_data = newCart.data.cart_data
      //@ts-ignore
      localStorage.setItem("order_num", newCart?.data?.cart_data?.order_num)
      localStorage.setItem("cart_id", newCart.data.cart_data.cart_id)
      dispatch(setcartDetails(cart))
      return newCart.data
    }

    return newCart.data
  }

  const addToCart = async (CartItem) => {
    const payload = { api_token: API_TOKEN, ...CartItem }
    const newCart = await axios.post(`${API}/cart/add_item_to_cart`, payload)

    if (newCart.data.status == 200 || newCart.data.status == 201) {
      return newCart.data
    }
    return newCart.data
  }

  const removeFromCart = async ({ sui }) => {
    const payload = {
      api_token: API_TOKEN,
      sui,
      order_num: localStorage.getItem("order_num"),
      cart_id: localStorage.getItem("cart_id"),
    }
    const newCart = await axios.post(
      `${API}/cart/remove_item_from_cart`,
      payload
    )

    if (newCart.data.status == 200 || newCart.data.status == 201) {
      PopTost({ message: "Removed Successfully", type: "info" })
      return newCart.data
    }
    return newCart.data
  }

  const updateCartItem = async ({
    sui,
    color_id,
    size_id,
    quantity,
    final_price,
  }) => {
    const payload = {
      api_token: getToken(),
      sui,
      color_id,
      size_id,
      quantity,
      final_price,
      order_num: localStorage.getItem("order_num"),
      cart_id: localStorage.getItem("cart_id"),
    }
    const newCart = await axios.post(`${API}/cart/update_item_in_cart`, payload)

    if (newCart.data.status == 200 || newCart.data.status == 201) {
      PopTost({ message: "Item updated successfully", type: "info" })
      return newCart.data
    }
    return newCart.data
  }

  const updateCartMeta = async ({ message, for_self }) => {
    const payload = {
      api_token: getToken(),
      gift_message: message,
      for_self,
      shopping_cart_id: localStorage.getItem("cart_id"),
    }
    const newCart = await axios.post(`${API}/cart/mark_cart_as_gift`, payload)

    if (
      newCart.data.cart_data.status == 200 ||
      newCart.data.cart_data.status == 201
    ) {
      PopTost({ message: "cart updated successfully", type: "info" })
      return newCart.data
    }
    return newCart.data
  }

  return {
    createCart,
    getCartDetails,
    addToCart,
    removeFromCart,
    updateCartItem,
    updateCartMeta,
  }
}
export default useCart
