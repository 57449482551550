import React, { useState } from "react"
import axios from "axios"
import { API } from "../config"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { getToken } from "../config"
import { setuserSearch } from "../../utils/slices/Chats/getSearchUser"

const useChatSearch = () => {
  // const Name = getName()
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  async function getSearchChat({ name }) {
    const searchUser = await axios.get(
      `${API}/search/user_profile?api_token=${API_TOKEN}&name=${name}`
    )
    dispatch(setuserSearch(searchUser.data.data))
    return searchUser.data
  }

  return { getSearchChat }
}
export default useChatSearch
