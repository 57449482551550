import axios from "axios"
import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { API, default_gender, getToken, paramsTypes } from "../config"
import { setAlerts } from "../../utils/slices/Alerts/getAlertsData"
import { setActivity } from "../../utils/slices/Account/activity"

const useAccActivity = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()

  function getActivity() {
    const AccActivity = axios
      .get(`${API}/credits/get_user_activity_data?&api_token=${API_TOKEN}`)
      .then((res) => {
        dispatch(setActivity(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return { getActivity }
}
export default useAccActivity
