import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IUserCaption, ICaption } from "../../../../@types/UserAccountInfo"

const initialState: IUserCaption = {
  UserCaption: {
    caption: "",
    status: 0,
  },
}

const userCaption = createSlice({
  name: "userCaption",
  initialState,
  reducers: {
    setUserCaption: (state, action: PayloadAction<ICaption>) => {
      state.UserCaption = action.payload
    },
  },
})

export const { setUserCaption } = userCaption.actions

export default userCaption.reducer
