import { useEffect, useState, useRef } from "react"
import useFilters from "../../api/hooks/useFliters"
import useSku from "../../api/hooks/useSku"
import { default_gender, paramsTypes } from "../../api/config"
import { useAppSelector } from "../../utils/hooks"
import _ from "lodash"
import useSearch from "../../api/hooks/useSearch"
import { useGetCurrency } from "../../Components/Header/Header.Hooks"

// export interface Click {
//   click:boolean
// }

export const useDiscover = ({ searchParams, setSearchParams }) => {
  const { getSearchMatchDict } = useSearch()
  const { getSuggest } = useSku()
  const { UserCurrency } = useGetCurrency()
  console.log(UserCurrency, "currency checking by shiv")

  const { skuforfilter, get_categories_list, colors_list, getSizeslist } =
    useFilters()

  const [filterApplied, setFilterApplied] = useState<paramsTypes>({
    segment_id: searchParams.get("segment_id") ?? "1",
    gender_id: searchParams.get("gender_id") ?? default_gender,
    page: searchParams.get("page") ?? "0",
    price_min: searchParams.get("price_min") ?? "0",
    price_max: searchParams.get("price_max") ?? "1000000",
    color_list: searchParams.get("color_list")
      ? searchParams.get("color_list").match(/\d+/g)
      : [],
    size_list: searchParams.get("size_list")
      ? searchParams.get("size_list").match(/\d+/g)
      : [],
    currency_code:
      searchParams.get("currency_code") ??
      `${UserCurrency?.userCurrency?.currency_data?.code}`,
    category_list: searchParams.get("category_list") ?? [],
  })

  const [segment, setSegment] = useState(searchParams.get("segment_id") ?? "1")
  const [gender, setGender] = useState(
    searchParams.get("gender_id") ?? default_gender
  )
  const [priceMin, setPriceMin] = useState(searchParams.get("price_min") ?? "0")
  const [priceMax, setPriceMax] = useState(
    searchParams.get("price_max") ?? "10000000"
  )
  const [page, setPage] = useState(searchParams.get("page") ?? "0")
  const [color, setColor] = useState(
    searchParams.get("color_list")
      ? searchParams.get("color_list").match(/\d+/g)
      : []
  )
  const [size, setSize] = useState(
    searchParams.get("size_list")
      ? searchParams.get("size_list").match(/\d+/g)
      : []
  )
  const [category, setCategory] = useState(
    searchParams.get("category_list")
      ? searchParams.get("category_list").match(/\d+/g)
      : []
  )

  useEffect(() => {
    console.log("Initial data fetch")
    // getSearchMatchDict("")
    const time = setTimeout(() => {
      if (Number(filterApplied.price_max) > 0) {
        getFilteredData(filterApplied)
      }
    }, 300)
    console.log(filterApplied)
    return () => clearTimeout(time)
  }, [])
  const timer1 = useRef(null)
  const timer2 = useRef(null)

  useEffect(() => {
    timer1.current = setTimeout(() => {
      if (Number(filterApplied.price_max) > 0) {
        getFilteredData(filterApplied)
      }
    }, 300)

    return () => clearTimeout(timer1.current)
  }, [filterApplied])

  useEffect(() => {
    timer2.current = setTimeout(() => {
      const spram = {
        segment_id: searchParams.get("segment_id") ?? "1",
        gender_id: searchParams.get("gender_id") ?? default_gender,
        page: searchParams.get("page") ?? "0",
        price_min: searchParams.get("price_min") ?? "0",
        price_max: searchParams.get("price_max") ?? "1000000",
        color_list: searchParams.get("color_list")?.split(",") || [],
        size_list: searchParams.get("size_list")?.match(/\d+/g) || [],
        currency_code:
          searchParams.get("currency_code") ??
          `${UserCurrency?.userCurrency?.currency_data?.code}`,
        category_list: searchParams.get("category_list")?.split(",") || [],
      }

      setGender(searchParams.get("gender_id"))

      if (!_.isEqual(filterApplied, spram)) {
        // if(Number(spram.price_max)>0){
        getFilteredData(spram)
        // }
      }
    }, 300)

    return () => clearTimeout(timer2.current)
  }, [searchParams])

  const getFilteredData = (params) => {
    if (params.price_min > 0 && params.price_max <= 0) {
      params.price_max = 1000000
    }
    get_categories_list(params)
    colors_list(params)
    getSizeslist(params)
    skuforfilter(params)
  }

  const searchData = useAppSelector((state) => state.searchDict.search_data)
  console.log(searchData, "checking search list")

  const receiveData = useAppSelector((state) => state.suggestSku.suggestions)
  const { colorsData, categoriesData, sizeslist } = useAppSelector(
    (state) => state
  )

  const handleState = async ({ type, value, click = false }) => {
    console.log(type, value, "handlesate")
    switch (type) {
      case "segment_id": {
        await setSegment(value)
        setFilterApplied({ ...filterApplied, segment_id: value })
        setSearchParams(() => ({ ...filterApplied, segment_id: value }))
        break
      }

      case "gender_id": {
        await setGender(value)
        setFilterApplied({ ...filterApplied, gender_id: value })
        setSearchParams({ ...filterApplied, gender_id: value })
        break
      }
      case "price_min": {
        await setPriceMin(value)
        setFilterApplied({ ...filterApplied, price_min: value })
        setSearchParams({ ...filterApplied, price_min: value })
        break
      }
      case "price_max": {
        const valueMax = value > 0 ? value : 100000
        await setPriceMax(value)
        setFilterApplied({ ...filterApplied, price_max: value })
        setSearchParams({ ...filterApplied, price_max: value })
        break
      }
      case "page": {
        await setPage(value)
        setFilterApplied({ ...filterApplied, page: value })
        setSearchParams({ ...filterApplied, page: value })
        break
      }
      case "category": {
        if (click) {
          setCategory([value])
          setFilterApplied({ ...filterApplied, category_list: [value] })
          setSearchParams({
            ...filterApplied,
            category_list: [value].join(","),
          })
        } else {
          if (category.length > 0 && category.indexOf(value) > -1) {
            category.splice(category.indexOf(value), 1)
            setCategory(category)
            setFilterApplied({
              ...filterApplied,
              category_list: category.splice(category.indexOf(value), 1),
            })
            setSearchParams({
              ...filterApplied,
              category_list: category
                .splice(category.indexOf(value), 1)
                .join(","),
            })
          } else {
            setCategory([...category, value])
            setFilterApplied({ ...filterApplied, category_list: category })
            setSearchParams({
              ...filterApplied,
              category_list: [...category, value].join(","),
            })
          }
          // updateCategory(value, category, setCategory, filterApplied, setFilterApplied, setSearchParams)
        }
        break
      }
      case "color": {
        setColor(value)
        setFilterApplied({ ...filterApplied, color_list: value })
        setSearchParams({
          ...filterApplied,
          // color_list: value.splice().join(","),
          color_list: [...color, value].join(","),
        })

        console.log(`checking setcolor ${value.splice().join(",")}`)
        break
      }
      case "sizes": {
        if (size.length > 0 && size.indexOf(value) > -1) {
          size.splice(size.indexOf(value), 1)
          setSize(size)
          setFilterApplied({
            ...filterApplied,
            size_list: size.splice(size.indexOf(value), 1),
          })
          setSearchParams({
            ...filterApplied,
            size_list: size.splice(size.indexOf(value), 1).join(","),
          })
        } else {
          setSize([...size, value])
          setFilterApplied({ ...filterApplied, size_list: [...size, value] })
          setSearchParams({
            ...filterApplied,
            size_list: [...size, value].join(","),
          })
        }

        break
      }
    }
  }

  const clearAllFilters = () => {
    console.log("click")
    setFilterApplied({
      segment_id: "1",
      gender_id: default_gender,
      page: "0",
      price_min: "",
      price_max: "",
      color_list: [],
      size_list: [],
      currency_code: `${UserCurrency?.userCurrency?.currency_data?.code}`,
      category_list: [],
    })
    setCategory([])
    setSearchParams({}) // Clear all search parameters
  }

  console.log(`checking price max : ${priceMax}`)

  return {
    handleState,
    segment,
    gender,
    priceMin,
    priceMax,
    page,
    receiveData,
    colorsData,
    categoriesData,
    sizeslist,
    color,
    size,
    category,
    clearAllFilters,
    getSearchMatchDict,
    searchData,
    UserCurrency,
  }
}
