import React from "react"
import axios from "axios"
import { API } from "../config"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"

import { getToken } from "../config"
import { setMyOrder } from "../../utils/slices/MyOrders/getMyOrder"
const myOrderInitial = {
  count: 0,
  error: "",
  page: "",
  per_page: 0,
  receipt_data: [],
  status: 0,
  total_count: 0,
}

const useMyorders = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  function getMyorders(order_id) {
    dispatch(setMyOrder(myOrderInitial))
    const MyOrders = axios
      .get(
        `${API}/commerce/get_user_receipts?&api_token=${API_TOKEN}&order_id=${order_id}`
      )
      .then((res) => {
        dispatch(setMyOrder(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return { getMyorders }
}
export default useMyorders
