import { styled } from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../responsive"
import { Marginer } from "../marginer"
import StyledInput from "../input"
import TextHr from "../text-hr"
import StyledLink from "../link"
import { Button } from "../button"
import Logo from "../Globals/Logo"
import { useState } from "react"
import countryList from "../../utils/countryList.json"
let Container = styled.div`
  ${tw`
        flex
        w-full
        items-center
        justify-center
    `}
`

const FormHolder = styled.div`
  ${tw`
        w-6/12
        flex
        flex-col
    `}
  @media (max-width: ${SCREENS.xl}) {
    ${tw`
            w-11/12
        `}
  }
`
const Form = styled.form`
  ${tw`
        w-full
        mt-5
    `}
`
const LogoAuth = styled(Logo)`
  ${tw`
        px-5
    `}
`

export const Signup = ({ state, updateState, onSubmit, checkUser }) => {
  const [selection, setSelection] = useState("Password")
  const [isError, setIsError] = useState(false)
  const [signState, setSignState] = useState({ confirmPass: "" })
  return (
    <FormHolder>
      <LogoAuth />
      <Marginer direction="vertical" margin="3em" />
      <Form>
        <StyledInput
          id="email"
          label="Email"
          type="email"
          value={state.request.email}
          onBlur={(e) => {
            if (e.target.value.length > 0) {
              checkUser()
            }
          }}
          onChange={(e) => {
            updateState({
              type: "request",
              value: { ...state.request, email: e.target.value },
            })
          }}
        />

        <Marginer direction="vertical" margin="3em" />

        <StyledInput
          id="phoneNumber"
          label="Phone Number"
          type="phone"
          onBlur={(e) => {
            checkUser()
          }}
          value={state.request.phone}
          onChange={(e, countryCode) => {
            updateState({ type: "phoneCode", value: countryCode })
            updateState({
              type: "request",
              value: {
                ...state.request,
                phone: e,
                country_code: countryList[countryCode],
                phone_code: countryCode,
              },
            })
          }}
        />

        <Marginer direction="vertical" margin="3em" />

        <StyledInput
          id="profileName"
          label="profileName"
          type="text"
          value={state.request.name}
          onBlur={(e) => {}}
          onChange={(e) => {
            updateState({
              type: "request",
              value: { ...state.request, name: e.target.value },
            })
          }}
        />

        <Marginer direction="vertical" margin="3em" />
        {selection != "Password" && (
          <>
            <StyledInput
              id="password"
              label="password"
              type="password"
              value={state.request.password}
              onChange={(e) => {
                updateState({
                  type: "request",
                  value: { ...state.request, password: e.target.value },
                })
              }}
            />

            <Marginer direction="vertical" margin="3em" />

            <StyledInput
              id="confirmPassword"
              label="confirmPassword"
              type="password"
              error={signState.confirmPass}
              onChange={(e) => {
                if (e.target.value != state.request.password) {
                  setSignState({
                    ...signState,
                    confirmPass: "Confirm Password Not Matching",
                  })
                  setIsError(true)
                } else {
                  setSignState({ ...signState, confirmPass: "" })
                  setIsError(false)
                }
              }}
            />

            <Marginer direction="vertical" margin="3em" />
          </>
        )}
      </Form>
      <Marginer direction="vertical" margin="5px" />

      <Container>
        Don't have an account? <Marginer direction="horizontal" margin="1em" />
        <StyledLink
          theme="plain"
          text={"Already have an account"}
          url={"/auth/login"}
          onClick={(e) => {
            e.preventDefault()
            updateState({ type: "activePage", value: "login" })
          }}
        />
      </Container>

      <Button
        theme="outlined"
        text={"Signup"}
        className="w-full"
        click={onSubmit}
        disable={isError}
      />

      <TextHr text={"or"} className="" />
      <Button
        theme="outlined"
        text={"Signup With " + selection}
        className="w-full"
        click={() => {
          updateState({
            type: "request",
            value: { ...state.request, password: "" },
          })
          setSelection(selection == "Password" ? "OTP" : "Password")
        }}
      />

      <Marginer direction="vertical" margin="4em" />
      <Container>
        <StyledLink theme="plain" text="Continue as Guest" url="/store" />
      </Container>
    </FormHolder>
  )
}
