const userId = localStorage.getItem("user")
console.log(userId, "userId")
export const myAccount = [
  { list: "My Account", link: `/profile/${userId}` },
  { list: "Order History", link: "/orders" },
  { list: "Wishlist", link: `/profile/${userId}/gift/#tabs` },
  { list: "Gift Certifications", link: "" },
  { list: "Returns", link: "" },
]

export const customerServices = [
  { list: "Affiliates" },
  { list: "Delivery information" },
  { list: "Privacy Policy" },
  { list: "Terms & conditions" },
  { list: "About us" },
]
