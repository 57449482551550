import { useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import product from "../../../assets/productDetail.png"
import {
  FaArrowRight,
  FaBars,
  FaComments,
  FaMessage,
  FaPhone,
  FaPlus,
  FaStore,
  FaVideo,
  FaVideoSlash,
} from "react-icons/fa6"
import { Link, useLocation, useParams } from "react-router-dom"
import { Container } from "../../Components/Globals/Container"
import Main from "../../layouts/Main"
import { BsSend } from "react-icons/bs"
import { useSendbird } from "./Chats.Hooks"
import "./style/chat.css"
import CallView from "../../../sendBirdCalls/components/views/CallView"
import {
  DirectCallOption,
  useSbCalls,
} from "../../../sendBirdCalls/lib/sendbird-calls"
import Authenticator from "../../../sendBirdCalls/containers/Authenticator"
import Overlay from "../../../sendBirdCalls/components/atoms/Overlay"
import { queryAllByAltText } from "@testing-library/react"
import useChatSearch from "../../api/hooks/useChatsearch"
import SendBirdCall, {
  DirectCallEndResult,
  DirectCallUserRole,
} from "sendbird-calls"
import useSearch from "../../api/hooks/useSearch"
const Sharebtn = styled.div`
  ${tw`inline-flex 
text-3xl
items-center 
justify-center 
rounded-full 
h-10 
w-20 
transition 
duration-500 
ease-in-out 
text-gray-500 
hover:text-[#038DCE]
focus:outline-none`}
`

const HiddenDiv = styled.div`
  ${tw`md:block md:col-span-1`}
`

const StyledForm = styled.form`
  ${tw`flex items-center py-4`}
`

const StyledInput = styled.input`
  ${tw`block w-full py-2 px-10 bg-[#DEF5FF] text-sm text-gray-900 rounded-md border-0 focus:ring-blue-500 focus:border-0 dark:bg-[#DEF5FF] dark:border-0 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 `}
`

const StyledUl = styled.ul`
  ${tw`bg-white`}
`

const StyledLi = styled.li`
  ${tw`flex justify-between p-3`}
`

const StyledPlusButton = styled(Link)`
  ${tw`text-[#038DCE] text-lg font-semibold ml-6 mr-3`}
`

const SectionLeft = styled.div`
  ${tw`
    flex 
    p-2
    justify-between
`}
`
const SectionRight = styled.div`
  ${tw`
   
    flex  
    justify-between 
    items-center 
    items-center 
    lg:border-l 
    border-gray-300 
    px-4
`}
`

function getCallOption(callOption?: DirectCallOption) {
  return {
    localMediaView: undefined,
    remoteMediaView: undefined,
    videoEnabled: true,
    audioEnabled: true,
    ...callOption,
  }
}

const Chats = () => {
  const [usersChat, setUsersChat] = useState(false)
  const [searchUserList, setSearchUserList] = useState([])
  const [chatList, setChatList] = useState([])
  const [searchKeyword, setSearchKeyword] = useState("")
  const [groupChatList, setGroupChatList] = useState([])
  const channelRef = useRef()
  const callRef = useRef()
  const [activeUser, setactiveUser] = useState({ name: "", id: null })
  const sbCall = useSbCalls()
  const { calls } = useSbCalls()
  const [sideTabActive, setSideTabActive] = useState("chatList")
  let { uuid, name, storeID } = useParams()
  const onCall = useMemo(() => {
    return calls.find((call) => call.isOngoing)
  }, [calls])
  const dial = (isVideoCall: boolean) => {
    sbCall?.dial({
      userId: activeUser.id,
      isVideoCall,
      callOption: getCallOption({}),
    }) ?? console.log("not connectedAuthScreen")
  }
  const getStoreList = useSearch()
  const {
    loadMessages,
    loadChannels,
    setupUser,
    updateState,
    sendMessage,
    onMessageInputChange,
    handleJoinChannel,
    handleMemberInvite,
    onUserNameInputChange,
    onUserIdInputChange,
    onFileInputChange,
    handleEnterPress,
    state,
    Message,
    findUser,
    ProfileImage,
    createNewChannel,
  } = useSendbird(channelRef)

  const handleMenu = () => {
    setUsersChat(!usersChat)
  }

  if (uuid && name) {
    let userIdsToInvite = [uuid, localStorage.getItem("user")]
    if (storeID) {
      userIdsToInvite.push(storeID)
    }
    createNewChannel({ userIdsToInvite, channelName: name })
  }

  const chatInput = {
    display: "flex !important",
    content: "",
    border: "1px solid #aaa",
    background: " #fff",
  }

  const getLogs = async () => {
    const params = {
      myRole: DirectCallUserRole.CALLER,
      endResults: [
        DirectCallEndResult.ACCEPT_FAILED,
        DirectCallEndResult.CANCELED,
        DirectCallEndResult.COMPLETED,
        DirectCallEndResult.DECLINED,
      ],
      limit: 100,
    }

    const listQuery = SendBirdCall.createDirectCallLogListQuery(params)
    let logs = []
    await listQuery.next((directCallLog) => {
      console.log(listQuery.hasNext)
      if (listQuery.hasNext && !listQuery.isLoading) {
        logs = directCallLog
      } else {
        logs = directCallLog
      }
    })

    return logs
  }

  const filterList = () => {
    let newlist = state.channels.filter((people) => {
      let found = false
      people.members.map((usr) => {
        if (usr.nikname == searchKeyword || usr.name) {
          found = true
        }
      })
      return found
    })

    if (sideTabActive == "chatList") {
      setChatList(newlist)
    }
    if (sideTabActive == "channelsList") {
      setGroupChatList(newlist)
    }
  }

  useEffect(() => {
    setChatList(state.channels)
    setGroupChatList(state.channels)
  }, [state.channels])
  const location = useLocation()
  let image = ""
  const [isInputDisabled, setIsInputDisabled] = useState(true)
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const userUuid = searchParams.get("uuid")
    if (localStorage.getItem("userData")) {
      let userData = JSON.parse(localStorage.getItem("userData"))
      image = userData?.image_url
    }

    // Enable input if user_uuid is present
    // setIsInputDisabled(!userUuid)
  }, [location.search])
  return (
    <>
      <Authenticator key={"Authenticator"} />
      <Main>
        <div className="bg-[#038DCE]">
          <Container className="flex-row flex gap-4">
            <SectionLeft>
              <img
                src={image ?? product}
                alt=""
                className="w-12 h-12 rounded-full ml-0"
              />
              <div className="flex justify-between items-center gap-5">
                <Link
                  to=""
                  onClick={() => {
                    setSideTabActive("callLogs")
                  }}
                >
                  <FaPhone className="size-5 text-white" />
                </Link>
                <Link
                  to=""
                  onClick={() => {
                    setSideTabActive("channelsList")
                  }}
                >
                  <FaStore className="text-white size-6" />
                </Link>
                <Link
                  to=""
                  onClick={() => {
                    setSideTabActive("newChat")
                  }}
                >
                  <FaMessage className="text-white size-6" />
                </Link>
                <Link
                  to=""
                  onClick={() => {
                    setSideTabActive("chatList")
                  }}
                >
                  <FaComments className="text-white size-6" />
                </Link>
              </div>
            </SectionLeft>
            <SectionRight>
              <h4 className="text-white font-semibold text-xl">
                {activeUser.name}
              </h4>
              <div className="flex justify-between items-center gap-5">
                {activeUser.id != null && (
                  <>
                    <Link to="">
                      <FaVideo
                        className="text-white size-6"
                        onClick={() => dial(true)}
                      />
                    </Link>
                    {/* <Link to="">
                  <FaVideoSlash className="text-white size-6" />
                </Link> */}
                    <Link to="">
                      <FaPhone
                        className="size-5 text-white"
                        onClick={() => dial(false)}
                      />
                    </Link>
                  </>
                )}
              </div>
            </SectionRight>
          </Container>
        </div>
        <div className="bg-gradient-to-r from-white from-10% via-white via-30% to-[#F7F9FA] to-90%">
          <Container className="">
            <div className="lg:grid lg:grid-cols-3">
              <button className="block absolute top-6" onClick={handleMenu}>
                <FaBars className="size-6 text-white mx-4" />
              </button>
              <SectionLeft className="lg:col-span-1 !block">
                <HiddenDiv
                  className={`${usersChat ? "" : "hidden1"} md:block md:col-span-1`}
                >
                  <StyledForm>
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <StyledInput
                        type="search"
                        id="default-search"
                        placeholder="Search"
                        onChange={async (e) => {
                          if (e.target.value.length > 0) {
                            switch (sideTabActive) {
                              case "callLogs":
                                filterList()
                                break
                              case "channelsList":
                                filterList()
                                break
                              case "newChat":
                                let listUser = await findUser({
                                  name: e.target.value,
                                })
                                setSearchUserList(listUser)
                                break
                              case "chatList":
                                filterList()
                                break

                              default:
                                break
                            }
                          } else {
                            switch (sideTabActive) {
                              case "callLogs":
                                filterList()
                                break
                              case "channelsList":
                                setGroupChatList(state.channels)
                                break
                              case "newChat":
                                let listUser = await findUser({
                                  name: e.target.value,
                                })
                                setSearchUserList(listUser)
                                break
                              case "chatList":
                                setChatList(state.channels)
                                break

                              default:
                                break
                            }
                          }
                        }}
                        required
                      />
                    </div>
                    {/* <StyledPlusButton to="">Edit</StyledPlusButton> */}
                  </StyledForm>
                  {sideTabActive == "newChat" && (
                    <StyledUl className="max-h-[70vh] overflow-auto">
                      {searchUserList.map((searchResult, index) => (
                        <StyledLi
                          key={index}
                          id={searchResult.user_uuid}
                          onClick={async () => {
                            let userIdsToInvite = [
                              searchResult.user_uuid,
                              localStorage.getItem("user"),
                            ]
                            if (storeID) {
                              userIdsToInvite.push(storeID)
                            }
                            // let channelName = localStorage.getItem("user")+"_"+searchResult.name
                            await createNewChannel({
                              userIdsToInvite,
                              channelName: "",
                            })
                            setSideTabActive("chatList")
                            // setSearchUserList([])
                          }}
                        >
                          <div className="">
                            <img
                              src={searchResult.image_url_thumb}
                              alt=""
                              className="w-16 h-16 rounded-lg mr-4"
                            />
                            <h4 className="text-md flex justify-center flex-col">
                              <span className="block text-xs font-semibold">
                                {searchResult.name}
                              </span>
                              <span className="block text-xs font-normal">
                                {searchResult.message ?? ""}
                              </span>
                            </h4>
                          </div>
                        </StyledLi>
                      ))}
                    </StyledUl>
                  )}
                  {sideTabActive == "callLogs" && (
                    <StyledUl ref={callRef}>
                      {
                        /* todo call logs */
                        // getLogs()
                      }
                    </StyledUl>
                  )}
                  {sideTabActive == "chatList" && (
                    <StyledUl>
                      {chatList.map(
                        (people, index) =>
                          people.customType == "" && (
                            <StyledLi
                              key={index}
                              id={people.url}
                              onClick={async () => {
                                await handleJoinChannel(people.url)
                                let user =
                                  people.isDistinct == true
                                    ? (localStorage.getItem("userData") !==
                                        null &&
                                        people?.members[0]?.nickname ==
                                          JSON.parse(
                                            localStorage.getItem("userData")
                                          )?.name) ||
                                      people?.members[0]?.nickname ==
                                        localStorage
                                          ?.getItem("token")
                                          ?.replace("guest_", "")
                                      ? {
                                          name: people?.members[1]?.nickname,
                                          id: people?.members[1]?.userId,
                                        } ?? { name: people.name, id: null }
                                      : {
                                          name: people?.members[0]?.nickname,
                                          id: people?.members[0]?.userId,
                                        }
                                    : { name: people.name, id: null }
                                setactiveUser(user)
                                setIsInputDisabled(false)
                              }}
                            >
                              <div className="flex">
                                <img
                                  src={people.coverUrl}
                                  alt=""
                                  className="md:w-14 md:h-14 w-10 h-10 rounded-lg mr-4"
                                />
                                <h4 className="text-md flex justify-center flex-col">
                                  <span className="block text-xs font-semibold">
                                    {people.isDistinct == true
                                      ? (localStorage.getItem("userData") !==
                                          null &&
                                          people?.members[0]?.nickname ==
                                            JSON.parse(
                                              localStorage.getItem("userData")
                                            )?.name) ||
                                        people?.members[0]?.nickname ==
                                          localStorage
                                            ?.getItem("token")
                                            ?.replace("guest_", "")
                                        ? people?.members[1]?.nickname ??
                                          people.name
                                        : people?.members[0]?.nickname
                                      : people.name}
                                  </span>
                                  <span className="block text-xs font-normal">
                                    {people.message ?? ""}
                                  </span>
                                </h4>
                              </div>
                              <h5 className="text-[10px] text-[#464646] font-light">
                                {people.lastSeen}
                              </h5>
                            </StyledLi>
                          )
                      )}
                    </StyledUl>
                  )}

                  {sideTabActive == "channelsList" && (
                    <StyledUl ref={channelRef}>
                      {groupChatList.map(
                        (people, index) =>
                          people.customType == "L-Chat" && (
                            <StyledLi
                              key={index}
                              id={people.url}
                              onClick={async () => {
                                await handleJoinChannel(people.url)
                                let user =
                                  people.isDistinct == true
                                    ? (localStorage.getItem("userData") !==
                                        null &&
                                        people?.members[0]?.nickname ==
                                          JSON.parse(
                                            localStorage.getItem("userData")
                                          )?.name) ||
                                      people?.members[0]?.nickname ==
                                        localStorage
                                          ?.getItem("token")
                                          ?.replace("guest_", "")
                                      ? {
                                          name: people?.members[1]?.nickname,
                                          id: people?.members[1]?.userId,
                                        } ?? { name: people.name, id: null }
                                      : {
                                          name: people?.members[0]?.nickname,
                                          id: people?.members[0]?.userId,
                                        }
                                    : { name: people.name, id: null }
                                // setactiveUser(user)
                                setIsInputDisabled(true)
                              }}
                            >
                              <div className="flex">
                                <img
                                  src={people.coverUrl}
                                  alt=""
                                  className="w-16 h-16 rounded-lg mr-4"
                                />
                                <h4 className="text-md flex justify-center flex-col">
                                  <span className="block text-xs font-semibold">
                                    {people.isDistinct == true
                                      ? (localStorage.getItem("userData") !==
                                          null &&
                                          people?.members[0]?.nickname ==
                                            JSON.parse(
                                              localStorage.getItem("userData")
                                            )?.name) ||
                                        people?.members[0]?.nickname ==
                                          localStorage
                                            ?.getItem("token")
                                            ?.replace("guest_", "")
                                        ? people?.members[1]?.nickname ??
                                          people.name
                                        : people?.members[0]?.nickname
                                      : people.name}
                                  </span>
                                  <span className="block text-xs font-normal">
                                    {people.message ?? ""}
                                  </span>
                                </h4>
                              </div>
                              <h5 className="text-[10px] text-[#464646] font-light">
                                {people.lastSeen}
                              </h5>
                            </StyledLi>
                          )
                      )}
                    </StyledUl>
                  )}
                </HiddenDiv>
              </SectionLeft>

              <SectionRight className="bg-[#F7F9FA] col-span-2">
                <div className="flex flex-col h-[85vh] w-full">
                  <div
                    id="messages"
                    className="flex flex-col relative space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch flex-1"
                  >
                    {!!calls.length && (
                      <CallView call={calls[calls.length - 1]} />
                    )}

                    {/* {onCall &&
                      <Overlay>
                        <CallView call={onCall} />
                      </Overlay>
                      } */}
                    <div className="message-list chat-list">
                      {state.messages.map((message) => {
                        // console.log(message.sender)
                        if (!message.sender) return null
                        const messageSentByCurrentUser =
                          message.sender.userId === state.currentUser
                        const classValues = messageSentByCurrentUser
                          ? "oc-message-item me"
                          : "oc-message-item other"
                        return (
                          <div key={message.messageId} className={classValues}>
                            <Message message={message} />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0">
                    <div className="relative flex p-2 items-center">
                      <label className="file-upload-label" htmlFor="upload">
                        <FaPlus className="size-5 mx-3" />
                      </label>

                      <input
                        id="upload"
                        className="file-upload-button"
                        type="file"
                        hidden={true}
                        onChange={onFileInputChange}
                        onClick={() => {}}
                        disabled={isInputDisabled}
                      />
                      <textarea
                        placeholder="write a message"
                        className="chat-input h-[54px] w-full resize-none"
                        style={chatInput}
                        value={state.messageInputValue}
                        onChange={onMessageInputChange}
                        onKeyDown={(event) =>
                          handleEnterPress(event, sendMessage)
                        }
                        disabled={!isInputDisabled}
                      />
                      <div className="right-0 items-center inset-y-0 hidden sm:flex">
                        <Sharebtn>
                          <BsSend
                            onClick={(e) => {
                              if (!isInputDisabled) {
                                sendMessage()
                              }
                            }}
                          />
                        </Sharebtn>
                      </div>
                    </div>
                  </div>
                </div>
              </SectionRight>
            </div>{" "}
          </Container>
        </div>
      </Main>
    </>
  )
}

export default Chats
