import { useEffect, useState } from "react"
import { useAppSelector } from "../../../utils/hooks"

import useUserAccount from "../../../api/hooks/useUserAccount"

export const useCaptionData = () => {
  const { getUserCaption, upDateCaption } = useUserAccount()

  useEffect(() => {
    const getCaptionData = async () => {
      await getUserCaption()
    }
    getCaptionData()
  }, [])

  const userCaptions = useAppSelector((state) => state.userCaption.UserCaption)

  const [caption, setCaption] = useState("")

  useEffect(() => {
    setCaption(userCaptions.caption)
  }, [userCaptions])

  return { caption, setCaption, upDateCaption }
}
