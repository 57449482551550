import React, { useEffect } from "react"
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "./App.css"
import AccoountInformation from "./app/Pages/Account"
import Chats from "./app/Pages/Chats"
import Checkout from "./app/Pages/CheckoutAddres"
import DealsDetails from "./app/Pages/DealsDetails"
import Discover from "./app/Pages/Discover"
import Followers from "./app/Pages/Followers"
import Following from "./app/Pages/Following"
import GitfCards from "./app/Pages/GiftCards"
import Home from "./app/Pages/Home"
import LiveSocialPage from "./app/Pages/LiveSocial"
import OrderDetails from "./app/Pages/OrderDetails"
import OrderScreen from "./app/Pages/Orders"
import Popup from "./app/Pages/Popup"
import ProductDetail from "./app/Pages/ProductDetails"
import Profile from "./app/Pages/Profile"
import Request from "./app/Pages/Request"
import ShoppingBag from "./app/Pages/ShoppingBag"
import SocialVideo from "./app/Pages/SocialVideo"
import StorePage from "./app/Pages/StorePage"
import Stores from "./app/Pages/Stores"
import AuthScreen from "./app/layouts/auth"
import OtpVerification from "./app/layouts/auth/OtpVerification"
import PasswordVerification from "./app/layouts/auth/PasswordVerification"
import { useAppAuth } from "./App.hook"
import Auth from "./app/Pages/Auth"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"
import { Bounce, ToastContainer, toast } from "react-toastify"
import Loader from "react-js-loader"
import MyGiftsLists from "./app/Pages/MyGiftsLists"
import GiftOrderDetailScreen from "./app/Pages/GiftOrderDetailScreen"
import SeashelEditPage from "./app/Pages/Seashel Edit"
import GiftDetail from "./app/Pages/GiftDetail"
import ExtProfile from "./app/Pages/ExtProfile"
import StoreVideo from "./app/Pages/StoreVideo"
import ScrollToTop from "./ScrollToTop"
import "@shoelace-style/shoelace/dist/themes/light.css"
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path"
import SlFormatNumber from "@shoelace-style/shoelace/dist/react/format-number"

function App() {
  const website_version = "0.1.3"
  console.log(`Website Version: ${website_version}`)

  axios.interceptors.request.use((config) => {
    if (config.url?.includes("/upload_profile_image")) {
      config.headers["Content-Type"] = "multipart/form-data"
    } else {
      config.headers["Content-Type"] = "application/json"
    }

    config.headers["Access-Control-Allow-Origin"] = "*"
    return config
  })

  axios.interceptors.response.use(
    (response) => {
      if (
        response.data.msg &&
        response.data.msg.split(" ").join("").toLowerCase() ==
          "usersessionexpired"
      ) {
        let keysToRemove = ["userData", "token", "user", "cart_id", "order_num"]
        for (let key of keysToRemove) {
          localStorage.removeItem(key)
        }
        window.location.href = "/Auth"
      }
      let overlayMain = document.querySelector(".overlayMain")
      if (overlayMain != null) {
        overlayMain.classList.remove("active")
      }

      if (
        response.data.status &&
        response.data.status != 200 &&
        response.data.status != 201 &&
        !response.config.url.includes("user/check_user")
      ) {
        let message = response.data.message ?? "Something Went Wrong"
        toast.error(message, {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
      }
      return response
    },
    (error) => {
      document.querySelector(".overlayMain").classList.remove("active")
      // console.log(error)
      if (error.response && error.response.status === 401) {
        // Redirect the user to the login page.
        let message = error.response.message ?? "Something Went Wrong"
        toast(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
      }
      return Promise.reject(error)
    }
  )

  useAppAuth()

  return (
    <div className="App">
      <>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/stores" element={<Stores />} />
            <Route path="/store/:uuid" element={<StorePage />} />
            <Route path="/storeVideos/:uuid" element={<StoreVideo />} />
            <Route path="/discover" element={<Discover />} />

            <Route path="/women" element={<Discover />} />
            <Route path="/men" element={<Discover />} />
            <Route path="/accessories" element={<Discover />} />
            <Route path="/clothing" element={<Discover />} />
            <Route path="/jewellery" element={<Discover />} />

            <Route path="/live/:uuid" element={<LiveSocialPage />} />
            <Route path="/live" element={<LiveSocialPage />} />
            <Route
              path="/giftOrderDetail/:order_id"
              element={<GiftOrderDetailScreen />}
            />
            <Route
              path="/giftOrderDetail"
              element={<GiftOrderDetailScreen />}
            />
            <Route path="/social/:reference" element={<SocialVideo />}></Route>
            <Route path="/deals/:uuid" element={<Popup />}></Route>
            <Route path="/deals" element={<Popup />}></Route>
            <Route
              path="/deals-details/:popup_uuid"
              element={<DealsDetails />}
            ></Route>
            <Route path="/profile" element={<Profile />}></Route>
            <Route path="/externalProfile" element={<ExtProfile />}></Route>
            <Route
              path="/product-details/:sui"
              element={<ProductDetail />}
            ></Route>
            <Route path="/gift-details/:sui" element={<GiftDetail />}></Route>
            <Route path="/profile/:uuid/:type?" element={<Profile />}></Route>
            <Route
              path="/externalProfile/:uuid/:type?"
              element={<ExtProfile />}
            ></Route>
            <Route path="/myGifts" element={<MyGiftsLists />}></Route>
            <Route path="/giftCards" element={<GitfCards />}></Route>
            <Route path="/orders" element={<OrderScreen />}></Route>
            <Route
              path="/chats/:uuid?/:name?/:storeID?"
              element={<Chats />}
            ></Route>
            <Route
              path="/orderDetails/:order_id"
              element={<OrderDetails />}
            ></Route>
            <Route path="/shoppingBag" element={<ShoppingBag />}></Route>
            <Route path="/followers" element={<Followers />}></Route>
            <Route path="/following" element={<Following />}></Route>
            <Route path="/request" element={<Request />}></Route>
            <Route path="/checkout" element={<Checkout />}></Route>
            <Route path="/seashel-edits" element={<SeashelEditPage />}></Route>
            <Route
              path="/account/:subpage"
              Component={AccoountInformation}
            ></Route>
            <Route path="/Auth/:action?" element={<Auth />}></Route>
          </Routes>
        </Router>
        <div className="overlayMain active">
          <Loader type="bubble-scale" bgColor={"#fff"} size={100} />
        </div>
        <ToastContainer />
        <div className="fixed inset-x-0 bottom-2 z-20 left-2 flex justify-start font-normal italic text-gray-800">
          {website_version}
        </div>
      </>
    </div>
  )
  setBasePath(
    "https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.0/cdn/"
  )
}

export default App
