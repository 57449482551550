import { useEffect, useRef, useState } from "react"
import { useAppSelector } from "../../utils/hooks"
import { UseLiveSocial } from "../../api/hooks/useLiveSocial"
import useTags from "../../api/hooks/useTags"

export const useSocialVideo = (reference) => {
  const { listTags } = useAppSelector((state) => state)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentVideo, setCurrentVideo] = useState({
    sui_details: [],
    store_uuid: "",
    store_logo: "",
    streaming_url: "",
    store_name: "",
    tags_list: [],
  })
  const { getListTags } = useTags()
  const { getVideo } = UseLiveSocial()
  var videoRef = useRef(null)

  useEffect(() => {
    setVideo(reference)
  }, [])

  // useEffect(()=>{
  //     getOurVideoStream()
  // },[currentVideo])

  const setVideo = async (reference_id) => {
    listTags.listTags.tags_data.map((item, index) => {
      if (item.reference_id == reference_id) {
        setCurrentIndex(index)
        return true
      }
    })
    console.log({ video_uuid: reference_id })
    let video = await getVideo({ video_uuid: reference_id })

    if (video.status == 200 || video.status == 201) {
      let videoData = { ...video.video_details }
      setCurrentVideo(videoData)

      if (listTags.listTags.tags_data.length <= 0) {
        getListTags({
          store_uuid: videoData.store_uuid,
          tag_type: "video",
          tag_id: "",
        })
      }
    }
  }

  const nextVideo = async () => {
    if (currentIndex >= listTags?.listTags?.tags_data.length) {
      return
    }
    //@ts-ignore
    let reference_id =
      listTags?.listTags?.tags_data[currentIndex + 1]?.reference_id
    setCurrentIndex(currentIndex + 1)
    let video = await getVideo({ video_uuid: reference_id })

    if (video.status == 200 || video.status == 201) {
      let videoData = { ...video.video_details }
      setCurrentVideo(videoData)
    }
  }

  const prevVideo = async () => {
    if (currentIndex <= 0) {
      return
    }
    //@ts-ignore
    let reference_id =
      listTags?.listTags?.tags_data[currentIndex - 1]?.reference_id
    setCurrentIndex(currentIndex - 1)

    let video = await getVideo({ video_uuid: reference_id })

    if (video.status == 200 || video.status == 201) {
      setCurrentVideo(video.video_details)
    }
  }

  return { setVideo, currentVideo, prevVideo, nextVideo, videoRef }
}
