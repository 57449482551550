import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  setGuestToken,
  setGuestLoading,
  setGuestError,
} from "./../../utils/slices/GuestLogin/guestSlice"
import axios from "axios"
import { RootState } from "../../utils/store"
import { useAppDispatch } from "../../utils/hooks"
import { API, BasePayloadPost } from "../config"

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
const GUEST_LOGIN_API = API + "/guest/guest_login"

const useGuestLogin = () => {
  const generateGuest = async () => {
    const request = await axios.post(GUEST_LOGIN_API, BasePayloadPost())
    localStorage.setItem("token", request.data.api_token)
  }

  return { generateGuest }
}

export default useGuestLogin
