import React, { useEffect, useRef, useState } from "react"
import openShop from "../../../assets/open-shop.svg"
import { Modal } from "flowbite-react"
import styled from "styled-components"
import tw from "twin.macro"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import dots from "../../../assets/dots.png"
import { Accordion } from "flowbite-react"
import { useParams } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import {
  FaBookmark,
  FaCircleRight,
  FaGift,
  FaImage,
  FaInfo,
  FaLink,
  FaMessage,
  FaShare,
} from "react-icons/fa6"
import Main from "../../layouts/Main"
import { Container } from "../../Components/Globals/Container"
import ProductPreview from "../../Components/ProductPreview"
import { Marginer } from "../../Components/marginer"
import { Hr, Product, ProductList } from "../Home/Styled.Comps"
import { useProductDeatilsData } from "./ProductDetail.Hooks"
import { useDiscover } from "../Discover/Discover.hooks"
import useGenerateDynamicLink from "../../api/hooks/useGenerateDynamicLink"
import facebook from "../../../assets/facebook.png"
import whatsapp from "../../../assets/whatsapp.png"
import twitter from "../../../assets/twitter.png"
import { API, API_TOKEN, ShortMainLink } from "../../api/config"
import { BsCopy } from "react-icons/bs"
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch"
import { BiZoomIn, BiZoomOut } from "react-icons/bi"
import { SlFormatNumber } from "@shoelace-style/shoelace"
import { useGetCurrency } from "../../Components/Header/Header.Hooks"
import useTalkToStore from "../../api/hooks/useTalkToStore"
import axios from "axios"
import { Bounce, toast } from "react-toastify"
const RadioContainer = styled.div`
  ${tw`flex items-center relative sm:mb-4`}
`

const RadioInput = styled.input`
  opacity: 0;

  &:checked + label,
  &.active + label {
    ${tw`
      border-blue-600
      text-blue-600
     
    `}
  }
`

const Label = styled.label`
  ${tw`
md:px-10 
md:py-4 
px-6 
py-4 
font-semibold
rounded-full 
bg-transparent 
border-2 
border-gray-500 
cursor-pointer
text-gray-400
text-blue-600`}
`
const Heading = styled.div`
  ${tw` 
  md:text-2xl text-lg
  font-bold
`}
`
const Button = styled.button`
  ${tw`
bg-sky-500
w-full
py-4
px-3 
rounded-full
text-white
mt-7 
transition-all
font-bold
sm:text-sm text-sm
`};
`

const Button2 = styled.div`
  ${tw` 
    rounded-3xl  
    text-white 
    bg-black 
    py-3
    px-6
    text-center
    flex items-center
    2xl:text-lg text-[13px]
    flex xl:mt-0 mt-3
`}
`
const GrayBorder = styled.div`
  ${tw`border my-8`}
`

const ProductPreviewer = styled(Container)`
  ${tw`
grid 
lg:grid-cols-2 grid-cols-1 lg:pt-10 pt-12
`}
`
const PriceHeading = styled.div`
  ${tw`flex`}
`
const PriceHeadingGray = styled.div`
  ${tw`line-through font-normal mr-2 text-gray-600`}
`
const PriceHeadingRed = styled.div`
  ${tw`font-bold text-[#E41F10] ml-2`}
`
const GridCol = styled.div`
  ${tw`
  px-5
  pt-5
  
`}
  svg.slick-arrow {
    font-size: 18px;
    z-index: 1;
    color: #000;
  }
`
const Flex = styled.div`
  ${tw`
  inline-flex
  mb-3
  gap-4
  items-center justify-end
`}
`
const Paragraph = styled.div`
  ${tw`
 mb-2 text-gray-500 dark:text-gray-400
`}
`
const Heading5 = styled.p`
  ${tw`
 my-6 font-semibold
`}
`
const SkuDataList = styled.p`
  ${tw`
  flex flex-wrap items-center xl:justify-between lg:justify-center sm:justify-between 
  justify-center  my-8
`}
`
const GridColDiv = styled.p`
  ${tw`
  flex justify-between relative
`}
`
const DotOpen = styled.p`
  ${tw`
 absolute bg-[#eee] shadow-lg rounded-lg right-0 top-12 
`}
`
const StoreImg = styled.img`
  ${tw`
   h-full max-w-full object-cover
  `}
`
const ResponsiveTableContainer = styled.div`
  overflow: auto;
`
const ProductDetail = () => {
  const { UserCurrency } = useGetCurrency()
  const [dotOpen, setDotOpen] = useState(false)
  const handleDots = () => {
    setDotOpen(!dotOpen)
  }
  const handleReport = async (sui) => {
    let reportProduct = await axios.post(`${API}/user/report_content`, {
      api_token: API_TOKEN,
      reference_id: sui,
      comment: "",
    })
    console.log(reportProduct.data, "removeUser")
    if (reportProduct.data.status === 200) {
      toast.success("Report sent")
    }
  }
  const [loading, setLoading] = useState(true)
  const { sui } = useParams<"sui">()
  const {
    SkuData,
    SkuRelatedData,
    addToGiftList,
    selection,
    setSelection,
    addToFavorite,
    removeFromFavorite,
    AddToBag,
  } = useProductDeatilsData(sui)

  // const [hasVideo, setHasVideo] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams()
  const { addChannel } = useTalkToStore()
  const {
    handleState,
    segment,
    gender,
    priceMin,
    priceMax,
    page,
    receiveData,
    colorsData,
    categoriesData,
    sizeslist,
    color,
    size,
    category,
  } = useDiscover({ searchParams, setSearchParams })
  const [sizeKey, setSizeKey] = useState([""])
  const navigate = useNavigate()
  const [userData, setUserData] = useState([])
  const [currentPage, setCurrentPage] = useState(
    Number(SkuRelatedData?.current_index)
  )
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setUserData(SkuRelatedData?.sku_data)
    setTotalPages(
      Math.ceil((SkuRelatedData?.sku_data?.length || 0) / itemsPerPage)
    )
  }, [SkuRelatedData?.sku_data])

  const handleNextClick = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages))
  }
  const handlePrevClick = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
  }

  const itemsPerPage = SkuRelatedData?.per_page
  console.log(itemsPerPage, "itemsPerPage")
  const startIndex = currentPage
  const endIndex = itemsPerPage
  const itemsToDisplay = userData.slice(startIndex, endIndex)
  console.log(itemsToDisplay, "itemsToDisplay")
  const preDisable = currentPage === 1
  const nextDisable = currentPage === totalPages
  useEffect(() => {
    if (SkuData?.sui_data[0]?.sku_details?.size_chart.length > 0) {
      setSizeKey(
        Object.keys(SkuData?.sui_data[0].sku_details.size_chart[0]).filter(
          (val) => val != "Size" && val != "size"
        )
      )
    }
    let color = SkuData.sui_data[0]?.sku_colors[0].color_id.toString()
    if (SkuData && SkuData.sui_data && SkuData.sui_data[0]?.sku_sizes) {
      const sizes = SkuData.sui_data[0]?.sku_sizes
      const mSize = sizes?.find((size) => size.name === "M")

      // If "M" size is available, select it; otherwise, select the first available size
      const selectedSize = mSize
        ? mSize.size_id.toString()
        : sizes[0]?.size_id.toString()
      console.log(mSize, "size")

      setSelection({ ...selection, color_id: color, size_id: selectedSize })
    }
  }, [SkuData])
  console.log(SkuData, "SkuData")
  function handleStoreClick(store_uuid: string) {
    localStorage.setItem("store_uuid", store_uuid)
    navigate(`/store/${store_uuid}`)
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])
  const generateLink = useGenerateDynamicLink()
  const [openModal, setOpenModal] = useState(false)
  const [openModal2, setOpenModal2] = useState(false)
  const [shortLink, setShortLink] = useState<string | null>(null)
  const handleClick = async () => {
    try {
      let userDataDetail = JSON.parse(localStorage.getItem("userData"))

      const longLink = `${ShortMainLink}/product?lui=${SkuData.sui_data}&imgSystemName=${SkuData.store_details.store_name}&store_id=${SkuData.store_details.store_uuid}&uploadType="product"&currentType="shopper"&sender_id=${userDataDetail?.user_uid}&region="IN"&mediaUrl=${SkuData?.sui_data[0].sku_details.image_data.image_data}&description=${SkuData.sui_data[0]?.sku_details.description}`
      const responseShortLink = await generateLink(longLink)

      setShortLink(responseShortLink)
      setOpenModal2(true)
    } catch (error) {
      console.error("Error in handleClick:", error)
    }
  }
  const profileUrlRef = useRef(null)
  const copyProfileUrl = () => {
    if (profileUrlRef.current) {
      profileUrlRef.current.select()
      navigator.clipboard.writeText(profileUrlRef.current.value)
    }
  }
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls()
    return (
      <>
        <div
          className="absolute lg:top-40 top-36 left-30 translate-y-96 translate-x-36 z-10 text-white flex items-center
         "
        >
          <button onClick={() => zoomIn()}>
            <BiZoomIn className="mx-2 size-5 hover:text-slate-400 hover:rounded-full " />
          </button>
          <button onClick={() => zoomOut()}>
            <BiZoomOut className="mx-2 size-5 hover:text-slate-400 hover:rounded-full " />
          </button>
        </div>
      </>
    )
  }

  const policyArray = SkuData?.sui_data?.[0]?.sku_details?.policy
  let lastPolicyItem
  if (policyArray && policyArray.length > 0) {
    lastPolicyItem = policyArray[policyArray.length - 1]
    console.log(lastPolicyItem)
  } else {
    console.log("Policy array is empty or undefined.")
  }

  const price = SkuData.sui_data[0]?.sku_price?.price

  function formatPriceCurrency(
    price,
    currencyCode = SkuData.sui_data[0]?.sku_price?.currency_code
  ) {
    if (!currencyCode) {
      console.warn("Currency code not found, using default format.")
      return price?.toFixed(2)
    }
    const numberFormatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currencyCode,
      maximumSignificantDigits: 3,
    })

    return numberFormatter.format(price)
  }
  const newPrice = formatPriceCurrency(price)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 2200)
    return () => clearTimeout(timer)
  }, [])
  return (
    <Main className="profile ">
      {SkuData.sui_data.length > 0 && (
        <ProductPreviewer>
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "500px",
                backgroundColor: "#f3f3f3",
                position: "relative",
              }}
            >
              <Skeleton height={500} count={1} />
              <div
                style={{
                  position: "absolute",
                  bottom: "0px",
                  left: "50%",
                  top: "25%",
                  color: "#000",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaImage className="mr-2 size-4" />
              </div>
            </div>
          ) : (
            <GridCol className="slider-container px-10 lg:m-0 m-auto">
              <TransformWrapper>
                <Controls />
                <TransformComponent>
                  <ProductPreview
                    images={
                      SkuData?.sui_data[0].sku_details.image_data.image_data
                    }
                    children={undefined}
                  />
                </TransformComponent>
              </TransformWrapper>
              <Marginer margin={40} direction="vertical" />
            </GridCol>
          )}
          <GridCol>
            <GridColDiv>
              {SkuData.store_details && SkuData.store_details ? (
                <div
                  className="flex items-center cursor-pointer "
                  onClick={() =>
                    handleStoreClick(SkuData.store_details.store_uuid)
                  }
                >
                  <div className="w-20 h-20 rounded-full overflow-hidden flex items-center justify-center">
                    <StoreImg src={SkuData.store_details.store_logo} alt="" />
                  </div>

                  <Heading className="ml-5 hover:underline">
                    {SkuData.store_details.store_name}
                  </Heading>
                </div>
              ) : (
                "No Product Found"
              )}
              <Link
                to=""
                onClick={handleDots}
                className="flex justify-center items-center"
              >
                <img src={dots} alt="" />
              </Link>
              {dotOpen && (
                <DotOpen>
                  <Link
                    to=""
                    onClick={() => handleReport(sui)}
                    className="flex items-center text-lg py-4 px-6 hover:bg-slate-300"
                  >
                    <FaInfo className="mr-2 size-5 " />
                    Report
                  </Link>
                  <Link
                    to=""
                    className="flex items-center text-lg py-4 px-6 hover:bg-slate-300"
                    onClick={handleClick}
                  >
                    <FaLink className="mr-2 size-5 " />
                    Share Link
                  </Link>
                </DotOpen>
              )}
            </GridColDiv>
            <GrayBorder></GrayBorder>

            <Heading>
              {SkuData?.sui_data?.length &&
                SkuData?.sui_data[0].sku_details?.product_title}
              <span className="block"> Dress</span>
            </Heading>
            <Skeleton
              height={30}
              width="100%"
              duration={2}
              count={loading ? 1 : 0}
            />
            {!loading && (
              <PriceHeading>
                {SkuData.sui_data[0]?.sku_price?.symbol +
                  " " +
                  SkuData.sui_data[0]?.sku_price?.price_post_discount !=
                  SkuData.sui_data[0]?.sku_price?.symbol + " " + newPrice && (
                  <PriceHeadingGray>{newPrice}</PriceHeadingGray>
                )}
                {SkuData.sui_data[0].sku_price?.symbol +
                  " " +
                  SkuData.sui_data[0].sku_price?.price_post_discount}{" "}
                {" " + SkuData.sui_data[0].sku_price?.price_post_discount !=
                  " " + newPrice && (
                  <PriceHeadingRed>
                    {100 -
                      Math.round(
                        (SkuData.sui_data[0].sku_price?.price_post_discount /
                          SkuData.sui_data[0]?.sku_price?.price) *
                          100
                      )}
                    % OFF
                  </PriceHeadingRed>
                )}
              </PriceHeading>
            )}
            <h5 className="flex justify-between my-6 font-semibold ">
              Select Size{" "}
              {SkuData?.sui_data[0].sku_details.size_chart &&
                SkuData?.sui_data[0].sku_details.size_chart.length > 0 && (
                  <Link
                    to={""}
                    className="flex items-center underline "
                    onClick={() => {
                      setOpenModal(true)
                    }}
                  >
                    <FaCircleRight className="mr-2 size-5 " />
                    Size Guide
                  </Link>
                )}
              {SkuData?.sui_data[0].sku_details.size_chart &&
                SkuData?.sui_data[0].sku_details.size_chart.length > 0 && (
                  <Modal
                    show={openModal}
                    onClose={() => setOpenModal(false)}
                    className="size_modal !mt-20"
                  >
                    <Modal.Header className="text-black bg-gray-500 ">
                      Size Chart
                    </Modal.Header>
                    <ResponsiveTableContainer>
                      <table className="w-full p-2">
                        <tr className="space-x-3 row">
                          <th>{"SIZE"}</th>
                          <th>{"BUST"}</th>
                          <th>{"HIP"}</th>
                          <th>{"WAIST"}</th>
                        </tr>
                        {SkuData?.sui_data[0].sku_details.size_chart.map(
                          (value, index) => (
                            <tr
                              key={index}
                              className={`space-x-3 row ${index % 2 === 0 ? "bg-white" : "bg-gray-200"} text-black`}
                            >
                              <td className={"font-bold"}>
                                {value["Size"] ?? value["Size"]}
                              </td>
                              <td className={"font-bold"}>
                                {value["BUST"] ?? value["Bust"]}
                              </td>
                              <td className={"font-bold"}>
                                {value["HIP"] ?? value["Hip"]}
                              </td>
                              <td className={"font-bold"}>
                                {value["WAIST"] ?? value["Waist"]}
                              </td>
                            </tr>
                          )
                        )}
                      </table>
                    </ResponsiveTableContainer>
                  </Modal>
                )}
            </h5>
            <div className="flex flex-wrap md:gap-4 gap-2 ">
              {SkuData.sui_data[0]?.sku_sizes.map((item, index) => {
                return (
                  <RadioContainer key={index}>
                    <RadioInput
                      id={"default-radio-" + index}
                      type="radio"
                      value={item.size_id}
                      name={"default-radio"}
                      className={
                        selection.size_id === "M" ||
                        selection.size_id === item.size_id.toString()
                          ? "active"
                          : ""
                      }
                      onClick={(e) => {
                        setSelection({
                          ...selection,
                          size_id: item.size_id.toString(),
                        })
                      }}
                    />
                    <Label htmlFor={"default-radio-" + index}>
                      {" "}
                      {item.name}
                    </Label>
                  </RadioContainer>
                )
              })}
            </div>
            <Heading5>Color </Heading5>
            <div className="flex items-center color_radio sm:flex-wrap gap-4">
              {SkuData.sui_data[0]?.sku_colors.map((color, index) => {
                return (
                  <RadioContainer>
                    <RadioInput
                      id={"color-radio-" + index}
                      type="radio"
                      value={color.color_id}
                      name="color"
                      className={
                        selection.color_id == color.color_id.toString()
                          ? "active"
                          : ""
                      }
                      onClick={(e) =>
                        setSelection({
                          ...selection,
                          color_id: color.color_id.toString(),
                        })
                      }
                    />
                    <Label htmlFor={"color-radio-" + index}>{color.name}</Label>
                  </RadioContainer>
                )
              })}
            </div>

            <Button
              className="py-4"
              onClick={() => {
                AddToBag({
                  currency_id: SkuData.sui_data[0].sku_details.currency_id,
                  gift_list_id: "",
                  gift_message: "",
                  color_id: selection.color_id,
                  quantity: 1,
                  receipient_uuid: "",
                  size_id: selection.size_id,
                  sui,
                })
                navigate("/shoppingBag")
              }}
            >
              Add to Shopping Bag
            </Button>

            <SkuDataList>
              <div className="flex items-center">
                <Link to="">
                  <FaGift
                    className={
                      "mr-8 size-10 " +
                      (SkuData.sui_data[0].sku_details.is_wishlist
                        ? "text-[#0ea5e9]"
                        : "text-[#858585]") +
                      " hover:text-gray-800"
                    }
                    onClick={(e) => {
                      addToGiftList(e, {
                        sui: SkuData.sui_data[0].sku_details.SUI,
                        color_id: selection.color_id,
                        influencer_user_id: selection.influencer_user_id,
                        size_id: selection.size_id,
                        store_uuid: SkuData.store_details.store_uuid,
                        system_name:
                          SkuData.sui_data[0].sku_details.product_image_name,
                      })
                    }}
                  />
                </Link>
                <Link to="" onClick={handleClick}>
                  <FaShare className="mr-8 size-10 text-[#858585] hover:text-gray-800" />
                </Link>
                <Link to="">
                  <FaBookmark
                    className={
                      "mr-8 size-10 " +
                      (SkuData.sui_data[0].sku_details.is_favorite
                        ? "text-[#0ea5e9]"
                        : "text-[#858585]") +
                      " hover:text-gray-800"
                    }
                    onClick={(e) => {
                      if (SkuData.sui_data[0].sku_details.is_favorite) {
                        removeFromFavorite(e, {
                          sui: SkuData.sui_data[0].sku_details.SUI,
                          type: "product",
                        })
                      } else {
                        addToFavorite(e, {
                          sui: SkuData.sui_data[0].sku_details.SUI,
                          influencer_user_id: selection.influencer_user_id,
                          store_uuid: SkuData.store_details.store_uuid,
                          system_name:
                            SkuData.sui_data[0].sku_details.product_image_name,
                          type: "product",
                        })
                      }
                    }}
                  />
                </Link>
              </div>
              <Flex>
                <Button2
                  onClick={async (e) => {
                    e.preventDefault()
                    let resp = await addChannel({
                      shopper_username_v1: SkuData.store_details.store_username,
                      store_uuid: SkuData.store_details.store_uuid,
                    })
                    if (resp.status == 200) {
                      navigate("/chats/")
                    }
                  }}
                >
                  <FaMessage className="size-5 2xl:mr-2 lg:mr-1 mr-2" />
                  Talk to store
                </Button2>
                <Button2
                  className="!bg-sky-800 px-5 py-2 cursor-pointer "
                  onClick={() =>
                    navigate(
                      `/store/${SkuData.store_details.store_uuid}?store_uuid=${SkuData.store_details.store_uuid ?? "na"}`
                    )
                  }
                >
                  <img src={openShop} alt="" className="size-6 mr-2" /> Enter
                  Store
                </Button2>
              </Flex>
            </SkuDataList>
            <GrayBorder></GrayBorder>
            <Accordion className="product_accordion" collapseAll>
              <Accordion.Panel>
                <Accordion.Title>Description</Accordion.Title>
                <Accordion.Content>
                  {SkuData.sui_data[0]?.sku_details.description ? (
                    <Paragraph
                      dangerouslySetInnerHTML={{
                        __html: SkuData.sui_data[0]?.sku_details.description,
                      }}
                    />
                  ) : (
                    <Skeleton count={5} />
                  )}
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            {SkuData.sui_data[0]?.sku_details.product_details ? (
              <Accordion className="product_accordion" collapseAll>
                <Accordion.Panel>
                  <Accordion.Title>Product Detail</Accordion.Title>
                  <Accordion.Content>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      {SkuData.sui_data[0]?.sku_details.product_details}
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            ) : null}

            <Accordion className="product_accordion" collapseAll>
              <Accordion.Panel>
                <Accordion.Title>{lastPolicyItem.Title}</Accordion.Title>
                <Accordion.Content>
                  {lastPolicyItem.Description ? (
                    <Paragraph
                      dangerouslySetInnerHTML={{
                        __html: lastPolicyItem.Description,
                      }}
                    />
                  ) : (
                    <Skeleton count={5} />
                  )}
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion className="product_accordion" collapseAll>
              {SkuData.sui_data[0]?.sku_details?.policy
                .slice(0, -1)
                .map((name, index, array) => {
                  return (
                    <Accordion.Panel>
                      <Accordion.Title> {name.Title}</Accordion.Title>

                      {!loading && (
                        <Accordion.Content>
                          {name.Description ? (
                            <Paragraph
                              dangerouslySetInnerHTML={{
                                __html: name.Description,
                              }}
                            />
                          ) : (
                            <Skeleton count={2} />
                          )}
                        </Accordion.Content>
                      )}
                    </Accordion.Panel>
                  )
                })}
            </Accordion>
          </GridCol>
        </ProductPreviewer>
      )}
      <Container>
        <Hr />
        <Marginer margin={30} direction="vertical" />
        {itemsToDisplay.length > 0 ? (
          <ProductList>
            {SkuRelatedData.sku_data.map((item, index) => {
              return (
                <Product
                  link={`/product-details/${item.sui}`}
                  image={item.image_url}
                  name={item.sku_title}
                  price={item.display_total_price}
                  salePrice={item.display_final_price}
                  key={index}
                  item={item}
                  symbol={item.display_currency_symbol}
                  currency_code={item.display_currency_code}
                />
              )
            })}
          </ProductList>
        ) : (
          <Skeleton />
        )}
        <Modal
          show={openModal2}
          onClose={() => setOpenModal2(false)}
          className="max-w-[700px] m-auto mt-28 z-10"
        >
          <div className="modal-content py-8 px-10">
            <Modal.Header className="text-xl font-bold my-6 p-0 flex items-center">
              Share Profile
            </Modal.Header>
            <div className="flex">
              <input
                type="text"
                value={shortLink}
                readOnly
                className="w-full relative"
              />
              <button onClick={copyProfileUrl}>
                <BsCopy className="size-5 ml-3" />
              </button>
            </div>
            <div className="flex items-center justify-center mt-10">
              <Link
                to={`http://www.facebook.com/sharer/sharer.php?u=${shortLink}&t=${SkuData.store_details.store_name}`}
                target="_blank"
              >
                <img src={facebook} className="w-10 " alt="" />
              </Link>
              <Link to={`https://wa.me/?text=${shortLink}`} target="_blank">
                <img src={whatsapp} className="w-10 mx-8" alt="" />
              </Link>
              <Link
                to={`http://www.twitter.com/intent/tweet?url=${shortLink}&text=${SkuData.store_details.store_name}`}
                target="_blank"
              >
                <img src={twitter} className="w-10" alt="" />
              </Link>
            </div>
          </div>
        </Modal>
        <div className="pagination-wrapper">
          <div className=" mb-16 flex mt-4 justify-center gap-2  ml-16">
            <button
              onClick={handlePrevClick}
              disabled={preDisable}
              className=" font-bold text-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            {Array.from({ length: totalPages }, (_, i) => {
              const page = i + 1
              const isHidden = Math.abs(page - currentPage) > 2
              return (
                <div className="pagination block w-8 h-8 text-center border border-gray-100 rounded leading-8  dark:active:bg-sky-400 focus:bg-sky-400">
                  <button
                    key={i}
                    onClick={() => handleState({ type: "page", value: i })}
                    disabled={i + 1 === currentPage}
                    className={`${isHidden ? "hidden" : ""} ${page === currentPage ? "active" : ""}`}
                  >
                    {i + 1}
                  </button>
                </div>
              )
            })}
            <button
              onClick={handleNextClick}
              disabled={nextDisable}
              className="font-bold text-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <Marginer margin={60} direction="vertical" />
      </Container>
    </Main>
  )
}

export default ProductDetail
