import { useEffect } from "react"
import useUser from "../../api/hooks/useUser"
import { useAppSelector } from "../../utils/hooks"
import { useGetCurrency } from "../../Components/Header/Header.Hooks"

export const useHomeData = () => {
  const { getHome } = useUser()
  const { UserCurrency } = useGetCurrency()

  const HomeData = useAppSelector((state) => state.home.homeData)

  useEffect(() => {
    getHome()
  }, [])
  useEffect(() => {
    getHome()
  }, [UserCurrency])
  return { HomeData }
}

export const HandleClick = () => {
  return
}
