import React from "react"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetAllGiftListData, getGiftListData } from "../../../../@types/gifts"

const initialState: GetAllGiftListData = {
  ListOfGifts: {
    count: 0,
    gift_list: [],
    page: "",
    page_limit: 0,
    status: 0,
    total_count: 0,
  },
}

const getGiftList = createSlice({
  name: "giftLists",
  initialState,
  reducers: {
    setGiftLists: (state, action: PayloadAction<getGiftListData>) => {
      state.ListOfGifts = action.payload
    },
  },
})

export const { setGiftLists } = getGiftList.actions

export default getGiftList.reducer
