import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import image from "../../../assets/image4.png"
import { SCREENS } from "../../Components/responsive"

let Container = styled.div`
  ${tw`
        flex
        w-full
        items-center
        justify-center
    `}
`
let ColumnAuto = styled.div`
  ${tw`    
    flex
    flex-1
    flex-row
    h-screen
`}
`
let LeftColumn = styled(ColumnAuto)`
  ${tw`
        justify-center
        items-center
    `}
  @media (max-width: ${SCREENS.lg}) {
    background-image: url(${image});
    background-position: top center;
    background-color: rgba(255, 255, 255, 0.842);
    ${tw`
            bg-no-repeat
            bg-cover
            bg-top
            bg-blend-lighten
        `}
  }
`
let RightColumn = styled(ColumnAuto)`
  background-image: url(${image});
  background-position: top center;
  ${tw`
        bg-no-repeat
        bg-cover
        bg-top
    `}
  @media (max-width: ${SCREENS.lg}) {
    ${tw`
            hidden
        `}
  }
`

const AuthScreen = ({ children }) => {
  return (
    <>
      <Container>
        <LeftColumn>{children}</LeftColumn>
        <RightColumn></RightColumn>
      </Container>
    </>
  )
}
export default AuthScreen
