import styled from "styled-components"
import tw from "twin.macro"
export const BaseContainer = styled.div`
  ${tw`
        flex
        w-full
        flex-col
    `}
  overflow:auto;
`
