import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ISuggestion, ISuggestSku } from "../../../../@types/sku"

const initialState: ISuggestion = {
  suggestions: {
    count: 0,
    current_page: "0",
    price_band: [],
    segment_data: [],
    status: 0,
    sui_data: [],
    total_count: 0,
  },
}

const suggestSkuSlice = createSlice({
  name: "suggestSku",
  initialState,
  reducers: {
    setSuggestions: (state, action: PayloadAction<ISuggestSku>) => {
      state.suggestions = action.payload
    },
  },
})

export const { setSuggestions } = suggestSkuSlice.actions

export default suggestSkuSlice.reducer
