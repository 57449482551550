import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import tw from "twin.macro"
import Main from "../../layouts/Main"
import { Container } from "../../Components/Globals/Container"
import Search from "../../Components/Search"
import { BlockHeading } from "../../Components/Globals/BlockHeading"
import { Marginer } from "../../Components/marginer"
import { Grid3 } from "../../Components/Grid"
import { useSearchCredits, useSearchUser } from "./Gift.Hooks"
import useChatSearch from "../../api/hooks/useChatsearch"

const SecondDivContents = styled.div`
  ${tw` 
w-full 
h-80 
rounded-xl 
bg-gray-500 
flex 
flex-col 
justify-center 
text-white 
items-center 
gap-6 
text-xl
font-bold 
`}
`
const DivButton = styled.div`
  ${tw` 
flex 
justify-center 
items-center 
`}
`
const Button = styled.button`
  ${tw` 
m-8 
mt-10 
rounded-3xl 
font-semibold 
text-lg 
bg-sky-500 
w-1/2 
pt-2 
pb-2
`}
`
export default function GitfCards() {
  const [results, setResults] = useState([])
  const [filteredResults, setFilteredResults] = useState([])
  const [searchQuery, setSearchQuery] = useState("")

  const { SearchUser } = useSearchUser("Ray")

  const { skuList } = useSearchCredits(localStorage.getItem("store_uuid"))
  console.log(skuList, "skulistsss")
  const [selected, setSelected] = useState(false)
  const navigate = useNavigate()
  const handleAddToCart = () => {
    navigate("/cart")
  }

  const Grid = styled(Grid3)`
    ${tw`
        gap-16
    `}
  `

  useEffect(() => {
    setResults(SearchUser)
  }, [SearchUser])

  useEffect(() => {
    const filtered = results.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setFilteredResults(filtered)
  }, [searchQuery])

  const handleSearch = (e) => {
    const userSearch = e.target.value
    setSearchQuery(userSearch)
  }

  return (
    <Main>
      <Marginer margin={40} direction="vertical" />
      <Container className="relative">
        <Search placeholder="Search" onChange={handleSearch} />
        {searchQuery && (
          <ul className="w-80 absolute z-10 top-16 right-0  bg-gray-200  max-h-60 overflow-y-auto rounded-lg">
            {filteredResults.map((item) => (
              <li
                key={item.id}
                className="cursor-pointer py-4 px-6 hover:bg-white"
              >
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </Container>
      <Marginer margin={60} direction="vertical" />
      <Container>
        <BlockHeading>{"Gift Cards"}</BlockHeading>
        <Marginer margin={20} direction="vertical" />
      </Container>
      <Container>
        <Marginer margin={40} direction="vertical" />
        <Grid>
          {skuList.sku_data.map((data: any, index) => (
            <SecondDivContents
              key={index}
              className={`card hover:bg-sky-500 focus:bg-sky-700 cursor-pointer ${
                selected ? "selected" : ""
              }`}
              onClick={() => {}}
            >
              <div className="flex items-center">
                <div className="">
                  {skuList.sku_data[0].display_currency_symbol}
                </div>
                <div key={"data" + index}>
                  {skuList.sku_data[0].base_final_price}
                </div>
              </div>
              <div>{skuList.sku_data[0].category}</div>
              <div>{skuList.sku_data[0].segment}</div>
            </SecondDivContents>
          ))}
        </Grid>
        <Marginer margin={40} direction="vertical" />
        <DivButton>
          <Button className="text-white" onClick={() => {}}>
            Add to Shoping Bags
          </Button>
        </DivButton>
      </Container>
    </Main>
  )
}
