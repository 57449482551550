import { useEffect } from "react"
import { useAppSelector } from "../../utils/hooks"
import useFollow from "../../api/hooks/useFollow"

export const useFollowingData = () => {
  const follow = useAppSelector((state) => state.follow.follow)
  const { getFollowFollower } = useFollow()
  useEffect(() => {
    getFollowFollower()
  }, [])
  return { follow }
}
