import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IStoreResult, IGetStore } from "../../../../@types/store"

const initialState: IStoreResult = {
  suggestions: {
    created_on: "",
    debug: 0,
    gender_list: {
      Men: [],
      Unisex: [],
      Women: [],
    },
    is_following: false,
    is_store_connected: false,
    market_app_version: {
      android: "",
      ios: "",
    },
    num_followers: 0,
    num_following: 0,
    price_range: [],
    status: 0,
    store_address: {
      address_1: "",
      address_2: "",
      address_id: 0,
      city: "",
      country: "",
      country_code: "",
      name: "",
      phone: "",
      pincode: "",
      state: "",
      type: "",
      warehouse_id: "",
    },
    store_alert_notice: 0,
    store_chat_url: "",
    store_city: "",
    store_fashion_market_id: 0,
    store_logo: "",
    store_logo_thumb: "",
    store_name: "",
    store_owner: 0,
    store_sellers: [],
    store_status: "",
    store_title: "",
    store_user_uuid: "",
    store_username: "",
    store_uuid: "",
    tags_list: [],
    video_data: [],
  },
}

const getstore = createSlice({
  name: "storelistMain",
  initialState,
  reducers: {
    setStorelistMain: (state, action: PayloadAction<IGetStore>) => {
      state.suggestions = action.payload
    },
  },
})

export const { setStorelistMain } = getstore.actions

export default getstore.reducer
