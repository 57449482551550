import React, { useRef, useEffect, useContext } from "react"
import Banner from "../../Components/BannerSmall"
import Main from "../../layouts/Main"
import styled from "styled-components"
import tw from "twin.macro"
import LiveSocial from "../../Components/LiveSocial"
import ThumbSlide from "../../Components/LiveSocial/ThumbSlides"
import { Container } from "../../Components/Globals/Container"
import { Marginer } from "../../Components/marginer"
import { BlockHeading } from "../../Components/Globals/BlockHeading"
import { Hr, Product, ProductList } from "../Home/Styled.Comps"
import { useState } from "react"
import useStore from "../../api/hooks/useStore"
import { useAppDispatch } from "../../utils/hooks"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useStoreData } from "./StoreVideo.Hooks"
import { Accordion } from "flowbite-react"
import { SCREENS } from "../../Components/responsive"
import StyledInput from "../../Components/input"
import { Link } from "react-router-dom"
import { useSendbird } from "../Chats/Chats.Hooks"
import { setStorelistMain } from "../../utils/slices/Stores/getStore"
import FilterTrack from "../../Components/FilterTrack"
import useTalkToStore from "../../api/hooks/useTalkToStore"
import { FaListCheck } from "react-icons/fa6"
import { NumericFormat } from "react-number-format"
const Wrapper = styled.div`
  ${tw`
            flex
            flex-row
        `}
`
const Filter = styled.div`
  ${tw` w-1/4`}
  h2 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`ml-6 `}
  }
`
const ProductsSection = styled.div`
  ${tw`
            flex
            w-3/4
        `}
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
                w-full
            `}
  }
`
const FilterButton = styled.button`
  ${tw`
            py-2
            px-6
            rounded-full
            mx-2
            mt-2 
            transition-all
            font-bold
            border-gray-400
            text-gray-400
            bg-transparent
            border
        `}
  font-size:14px;
  font-weight: 400;
  &:hover {
    ${tw`
                border-sky-400
                text-sky-400
            `}
  }
  &.active {
    ${tw`
                border-sky-400
                text-sky-400
            `}
  }
`
const NewHr = styled(Hr)`
  ${tw`
        my-4
        `}
`
const Apanel = styled(Accordion.Panel)`
  ${tw`
            border-0
			`}
`

const PageBanner = styled(Banner)``
const SectionHeading = styled(BlockHeading)`
  ${tw`
        text-black
        text-xl
    `}
`
const CardContent = styled.div`
  ${tw` 
    flex
    justify-between 
    flex-wrap
    mt-3
    
    `}
`
const CardContentDetails = styled.div`
  ${tw` 
    flex
    `}
`
const Button = styled.button`
  ${tw` 
    mt-2
    p-1
    pr-7
    pl-7
    rounded-lg
    border-solid 
    border-2 
    bg-gray-200
    text-[#038DCE] 
    font-semibold
    ml-8
    `}
`
const ButtonMain = styled.div`
  ${tw` 
    gap-6 
    inline-block
    self-end  
    `}
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
      mt-8
                `}
  }
`

export interface LinkData {
  link: any
}
const StoreVideo = ({ ...props }) => {
  const { uuid } = useParams<"uuid">()
  const channelRef = useRef()
  const { joinChannel, leaveChannel } = useSendbird(channelRef)
  const [searchParams, setSearchParams] = useSearchParams()
  const { followStore, unFollowStore, getStoreSeller } = useStore()
  const [filter, setOpenFilter] = useState(true)
  const openFilter = () => {
    setOpenFilter(!filter)
  }
  const dispatch = useAppDispatch()
  const {
    storeData1,
    handleState,
    gender,
    priceMin,
    priceMax,
    colorsData,
    categoriesData,
    sizeslist,
    color,
    size,
    category,
    handleTags,
    ProductsList,
  } = useStoreData({ searchParams, setSearchParams, uuid })
  const [tagid, setTagId] = useState("")
  const [selectTag, setSelectTag] = useState("")

  const navigate = useNavigate()
  const { addChannel } = useTalkToStore()

  const handleFollow = async (e) => {
    e.preventDefault()
    if (!storeData1.suggestions.is_following) {
      const resp = await followStore({
        uuid: storeData1.suggestions.store_user_uuid,
      })
      if (resp.status == 200 && storeData1.suggestions.store_chat_url) {
        storeData1.suggestions.store_chat_url &&
          joinChannel(storeData1.suggestions.store_chat_url)
      }
      dispatch(
        setStorelistMain({ ...storeData1.suggestions, is_following: true })
      )
    } else {
      const resp = await unFollowStore({
        uuid: storeData1.suggestions.store_user_uuid,
      })
      if (resp.status == 200 && storeData1.suggestions.store_chat_url) {
        storeData1.suggestions.store_chat_url &&
          leaveChannel(storeData1.suggestions.store_chat_url)
      }
      dispatch(
        setStorelistMain({ ...storeData1.suggestions, is_following: false })
      )
    }
  }
  const changeUrl = () => {
    console.log(storeData1.suggestions.tags_list, "storedata")
    console.log(selectTag, "selectTag")
  }

  const [userData, setUserData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const url = ProductsList.list.data

  useEffect(() => {
    setUserData(ProductsList.list.data)
    setTotalPages(Math.ceil(ProductsList.list.data.length / 54))
  }, [ProductsList.list.data])

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  const handlePrevClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage - 1)
    }
  }

  const preDisable = currentPage === 1
  const nextDisable = currentPage === totalPages

  const itemsPerPage = 9
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const itemsToDisplay = userData.slice(startIndex, endIndex)

  const [numericValue, setNumericValue] = useState(0)

  const handleValueChange = (newValue) => {
    if (!isNaN(newValue)) {
      setNumericValue(newValue)
    }
  }

  // Function to format numeric value with Indian numbering system
  const formatValue = (value) => {
    return value.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",")
  }

  return (
    <Main>
      <Marginer margin={60} direction="vertical" />
      <Container>
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 text-center gap-y-4 mt-4">
          {storeData1?.suggestions?.video_data?.map((item, index) => {
            return (
              <ThumbSlide
                key={index}
                title={item.store_username}
                subtitle={item.description}
                image={item.thumbnail}
                link={"/social/" + item.video_uuid}
                children={undefined}
                className=""
              />
            )
          })}
        </div>

        <Marginer margin={30} direction="vertical" />
      </Container>
      <Hr />
    </Main>
  )
}

export default StoreVideo
