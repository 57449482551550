import React from "react"
import { styled } from "styled-components"
import tw from "twin.macro"
import { Link } from "react-router-dom"

interface LinkProps {
  theme: "filled" | "outlined" | "plain"
  text: String
  url: String
  className?: string
  onClick?: any
}

const BaseLink = styled(Link)`
  ${tw`
        inline-flex
        self-end
        text-[#038DCE]
        underline
        cursor-pointer
    `}
`
const Outlined = styled(BaseLink)`
  ${tw`
        bg-[#038DCE]
        border-2
        border-transparent
    `}
  &:hover {
    ${tw`
            bg-transparent
            text-[#038DCE]
            border-[#038DCE]
        `}
  }
`

const Filled = styled(BaseLink)`
  ${tw`
        border-[#038DCE]
        text-[#038DCE]
        bg-transparent
        border-2
        
    `}
  &:hover {
    ${tw`
            bg-[#038DCE]
            text-white
            border-transparent
        `}
  }
`

const StyledLink = (props: LinkProps) => {
  const { theme, text, url, className, onClick } = props
  if (theme === "filled") {
    return (
      <Filled
        to={url + ""}
        className={className}
        onClick={(e) => {
          onClick(e)
        }}
      >
        {" "}
        {text}
      </Filled>
    )
  }
  if (theme === "outlined") {
    return (
      <Outlined
        to={url + ""}
        className={className}
        onClick={(e) => {
          onClick(e)
        }}
      >
        {text}
      </Outlined>
    )
  } else {
    return (
      <BaseLink
        to={url + ""}
        className={className}
        onClick={(e) => {
          onClick(e)
        }}
      >
        {text}
      </BaseLink>
    )
  }
}
export default StyledLink
