import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../responsive"

interface IButtonProps {
  theme: "filled" | "normal" | "outlined"
  text: String
  color?: "sky" | "white"
  className?: string
  click?: () => void
  disable?: boolean
}

const BaseButton = styled.button`
  ${tw`
        bg-sky-500
        py-5
        px-3
        rounded-full
        text-white
        mt-7 
        transition-all
        font-bold
    `}
  &:disabled {
    ${tw`
      bg-gray-400
    `}
  }
  @media screen and (max-width: ${SCREENS["2xl"]}) {
    ${tw`
        py-5
        px-3
      `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    ${tw`
        py-4
        px-2
      `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        py-3
        px-2
      `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        py-1
        px-1
      `}
  }
`

const OutlinedButton = styled(BaseButton)`
  ${tw`
        bg-[#038DCE]
        border-2
        border-transparent
    `}
  &:disabled&:hover {
    ${tw`
      text-gray-400
      border-gray-400
    `}
  }
  &:hover {
    ${tw`
            bg-transparent
            text-[#038DCE]
            border-[#038DCE]
        `}
  }
  .active {
    ${tw`
    bg-transparent
    text-[#038DCE]
    border-[#038DCE]
`}
  }
`

const OutlinedWhiteButton = styled(BaseButton)`
  ${tw`
        bg-white
        border-2
        border-transparent
        text-black
    `}
  &:disabled&:hover {
    ${tw`
      text-gray-400
      border-gray-400
    `}
  }
  &:hover {
    ${tw`
            bg-transparent
            text-white
            border-white
        `}
  }
  .active {
    ${tw`
    bg-transparent
    text-white
    border-white
`}
  }
`

const FilledButton = styled(BaseButton)`
  ${tw`
        border-[#038DCE]
        text-[#038DCE]
        bg-transparent
        border-2
        
    `}
  &:disabled&:hover {
    ${tw`
      bg-gray-400
    `}
  }
  &:hover {
    ${tw`
            bg-[#038DCE]
            text-white
            border-transparent
        `}
  }
  .active {
    ${tw`
    bg-[#038DCE]
    text-white
    border-transparent
`}
  }
`

const FollowerButton = styled.button`
  ${tw`
        bg-sky-500
        w-3/4
        py-2
        px-3
        rounded-md
        text-white
        mt-7 
        transition-all
        font-bold
    `}
  &:disabled {
    ${tw`
      bg-gray-400
    `}
  }
`

const FollowButton = styled(FollowerButton)`
  ${tw`
      border-[#038DCE]
      text-[#038DCE]
      bg-transparent
      border-2
      
  `}
  &:disabled&:hover {
    ${tw`
      bg-gray-400
    `}
  }
  &:hover {
    ${tw`
          bg-[#038DCE]
          text-white
          border-transparent
      `}
  }
  .active {
    ${tw`
  bg-[#038DCE]
  text-white
  border-transparent
`}
  }
`

export function Button(props: IButtonProps) {
  const { theme, text, className, click, color, disable } = props

  if (theme === "filled") {
    return (
      <FilledButton className={className} onClick={click} disabled={disable}>
        {text}
      </FilledButton>
    )
  } else if (theme === "normal") {
    return (
      <FollowButton className={className} onClick={click} disabled={disable}>
        {text}
      </FollowButton>
    )
  } else {
    if (color === "white") {
      return (
        <OutlinedWhiteButton
          className={className}
          onClick={click}
          disabled={disable}
        >
          {text}
        </OutlinedWhiteButton>
      )
    } else {
      return (
        <OutlinedButton
          className={className}
          onClick={click}
          disabled={disable}
        >
          {text}
        </OutlinedButton>
      )
    }
  }
}
