import { useEffect } from "react"
import { useAppSelector } from "../../utils/hooks"
import useTags from "../../api/hooks/useTags"
import { useParams } from "react-router-dom"

export const useStoreData = (tag_id: string) => {
  const { uuid } = useParams<"uuid">()
  const tag_type = "video"
  const listTags = useAppSelector((state) => state.listTags.listTags)
  const listTagsLive = useAppSelector((state) => state.listTags)
  const { ProductsList } = useAppSelector((state) => state)
  const { getListTags } = useTags()

  useEffect(() => {
    getListTags({ store_uuid: uuid ?? "", tag_type: tag_type, tag_id: tag_id })
  }, [tag_id, uuid])

  const handleTags = async (e) => {
    if (e.target.classList.contains("active")) {
      e.target.classList.remove("active")
    } else {
      let elements = e.target
        .closest(".slick-list")
        .querySelectorAll("button.active")
      elements.forEach((element) => element.classList.remove("active"))
      setTimeout(() => {
        e.target.classList.add("active")
      }, 800)
    }
  }

  return { listTags, ProductsList, handleTags, listTagsLive }
}
