import React, { useEffect, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { DynamicCard } from "../../Components/Card"
import { Link } from "react-router-dom"
import { SCREENS } from "../../Components/responsive"
import Main from "../../layouts/Main"
import { Container } from "../../Components/Globals/Container"
import { useFollowingData } from "./Following.hooks"
import axios from "axios"
import { API, API_TOKEN } from "../../api/config"
const MainContainer = styled.div`
  ${tw`
  lg:flex
  `}
  @media screen and (max-width: ${SCREENS.md}) {
    ${tw`
   flex-wrap 
    `}
  }
`
const FollowText = styled.div`
  ${tw`
  text-lg py-5 px-14 rounded-xl bg-white font-semibold w-full
  `}
`
const StyledLink = styled(Link)`
  ${tw`
   hover:bg-[#038DCE] hover:text-white mb-4 flex w-full
  `}
`
const ButtonContainer = styled.div`
  ${tw`

  left-0
  top-0 
  h-screen 
  px-4 
  pt-10
    text-center
  m-1
  mt-6
  lg:block flex lg:justify-start justify-center
  `}
  @media screen and (max-width: ${SCREENS.md}) {
    ${tw`
    w-full
    flex
    gap-2
    ml-0
    `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
    h-auto
    pl-0
    pr-0
    `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
    flex-wrap
    w-auto
    ml-12
    mr-12
    mt-4
    `}
  }
`

const CardDetails = styled.div`
  ${tw`
  grid 
  sm:grid-cols-1
  sm:grid-cols-2
  xl:grid-cols-3 
  gap-6
  lg:p-10 p-4 mt-4
  `}
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  @media screen and (max-width: ${SCREENS.md}) {
    ${tw`
      grid-cols-1
    pr-10
    pl-10
    pt-4
    `}
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`

const Following = () => {
  const [isRemoved, setIsRemoved] = useState(false)
  const { follow } = useFollowingData()
  const handleUnfollow = async (user_following_uuid) => {
    let response = await axios.post(`${API}/follow/unfollow_user`, {
      api_token: API_TOKEN,
      user_following_uuid: user_following_uuid,
    })
    console.log("response", response.data)
    setIsRemoved(true)
  }
  const [userData, setUserData] = useState([])
  const [currentPage, setCurrentPage] = useState(Number(follow?.page) || 1)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setUserData(follow?.follower_data)
    setTotalPages(
      Math.ceil(follow?.total_following_data_count / follow?.per_page)
    )
  }, [follow?.follower_data])

  const handleNextClick = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages))
  }
  const handlePrevClick = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
  }

  const preDisable = currentPage === 1
  const nextDisable = currentPage === totalPages

  const itemsPerPage = follow.per_page
  console.log(itemsPerPage, "itemsPerPage")
  const startIndex = currentPage
  const endIndex = itemsPerPage
  const itemsToDisplay = userData.slice(startIndex, endIndex)
  const handlePageChange = () => {
    setCurrentPage(itemsPerPage)
  }
  return (
    <>
      <Main>
        <Container>
          <MainContainer>
            <ButtonContainer>
              <StyledLink to="/followers">
                <FollowText>Followers</FollowText>
              </StyledLink>
              <StyledLink to="/following">
                <FollowText className="!bg-sky-500 text-white">
                  Following
                </FollowText>
              </StyledLink>
              <StyledLink to="/request">
                <FollowText>Request</FollowText>
              </StyledLink>
            </ButtonContainer>
            <CardDetails>
              {follow.following_data.map((card) => {
                return (
                  <DynamicCard
                    imageSrc={card.image_url}
                    userName={card.following_name}
                    brandLevel={card.following_role}
                    onUnFollow={() => handleUnfollow(card.user_following_uuid)}
                    user_uid={card.user_following_uuid}
                    link={`/externalProfile/${card.user_following_uuid}`}
                  />
                )
              })}
            </CardDetails>
          </MainContainer>
          <div className="pagination-wrapper">
            <div className=" mb-16 flex mt-4 justify-center gap-2  ml-16">
              <button
                onClick={handlePrevClick}
                disabled={preDisable}
                className=" font-bold text-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              {Array.from({ length: totalPages }, (_, i) => {
                const page = i + 1
                const isHidden = Math.abs(page - currentPage) > 2
                return (
                  <div className="pagination block w-8 h-8 text-center border border-gray-100 rounded leading-8  dark:active:bg-sky-400 focus:bg-sky-400">
                    <button
                      key={i}
                      onClick={() => handlePageChange}
                      disabled={i + 1 === currentPage}
                      className={`${isHidden ? "hidden" : ""} ${page === currentPage ? "active" : ""}`}
                    >
                      {i + 1}
                    </button>
                  </div>
                )
              })}
              <button
                onClick={handleNextClick}
                disabled={nextDisable}
                className="font-bold text-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </Container>
      </Main>
    </>
  )
}

export default Following
