import React from "react"
import axios from "axios"
import { API, getToken } from "../config"
import { useAppDispatch } from "../../utils/hooks"
import { setUserAccount } from "../../utils/slices/UserAccount/userAccount"
import { setUserCaption } from "../../utils/slices/UserAccount/userCaption"
import { PopTost } from "../../utils/common"

const useUserAccount = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()

  const getUserDetails = async (user_uuid) => {
    const userDetails = await axios.get(
      `${API}/user/get_user_profile?api_token=${API_TOKEN}&user_uuid=${user_uuid}`
    )
    if (userDetails.data.status == 200) {
      dispatch(setUserAccount(userDetails.data))
    }
  }
  const getUserCaption = async () => {
    const captionResponse = await axios.get(
      `${API}/user/get_user_caption?api_token=${API_TOKEN}`
    )
    if (
      captionResponse.data.status == 201 ||
      captionResponse.data.status == 200
    ) {
      console.log(captionResponse.data)
      dispatch(setUserCaption(captionResponse.data))
    }
  }

  async function upDateCaption({ caption }) {
    let response = await axios.post(`${API}/user/update_user_caption`, {
      api_token: API_TOKEN,
      caption: caption,
    })
    if (response.data.status == 200) {
      PopTost({ message: "updated successfully.", type: "success" })
    }
  }

  const updateUserInfo = async ({
    email,
    profile_name,
    phone,
    gender_id,
    password,
    date_of_birth,
  }) => {
    let response = await axios.post(`${API}/user/update_user_data`, {
      api_token: API_TOKEN,
      email,
      phone,
      date_of_birth,
      gender_id,
      profile_name,
      password,
    })
    return response.data
  }

  const uploadProfile = async ({ file }) => {
    const formData = new FormData()
    formData.append("api_token", API_TOKEN)
    formData.append("file_to_upload", file)
    let response = await axios.post(
      `${API}/content/images/upload_profile_image`,
      formData
    )
    return response.data
  }

  return {
    getUserDetails,
    getUserCaption,
    upDateCaption,
    updateUserInfo,
    uploadProfile,
  }
}
export default useUserAccount
