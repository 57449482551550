import { useEffect } from "react"
import { useAppSelector } from "../../utils/hooks"
import useGiftsReceipts from "../../api/hooks/useGiftsReceipts"

export const useGiftReceipts = (order_id) => {
  const { getGiftReceipts } = useGiftsReceipts()

  useEffect(() => {
    getGiftReceipts(order_id)
  }, [])
  const giftReceiptsList = useAppSelector(
    (state) => state.GiftsDetails.ReceiptsOfGifts.gift_receipt
  )
  return { giftReceiptsList }
}
