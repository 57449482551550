import axios from "axios"
import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { API, default_gender, getToken, paramsTypes } from "../config"
import { setAlerts } from "../../utils/slices/Alerts/getAlertsData"

const useAlerts = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()

  function getAlerts() {
    const Alerts = axios
      .get(
        `${API}/notifications/get_user_notifications?api_token=${API_TOKEN}&state=rcvd&msg_type=`
      )
      .then((res) => {
        dispatch(setAlerts(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return { getAlerts }
}
export default useAlerts
