import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import StyledInput from "../../Components/input/index"
import Logo from "../../Components/Globals/Logo"
import image from "../../../assets/image4.png"
import { Marginer } from "../../Components/marginer/index"
import { Button } from "../../Components/button/index"
import StyledLink from "../../Components/link"
import { SCREENS } from "../../Components/responsive"

let Container = styled.div`
  ${tw`
        flex
        w-full
        items-center
        justify-center
    `}
`
let ColumnAuto = styled.div`
  ${tw`    
    flex
    flex-1
    flex-row
    h-screen
`}
`
let LeftColumn = styled(ColumnAuto)`
  ${tw`
        justify-center
        items-center
    `}
  @media (max-width: ${SCREENS.lg}) {
    background-image: url(${image});
    background-position: top center;
    background-color: rgba(255, 255, 255, 0.842);
    ${tw`
            bg-no-repeat
            bg-cover
            bg-top
            bg-blend-lighten
        `}
  }
`
let RightColumn = styled(ColumnAuto)`
  background-image: url(${image});
  background-position: top center;
  ${tw`
        bg-no-repeat
        bg-cover
        bg-top
    `}
  @media (max-width: ${SCREENS.lg}) {
    ${tw`
            hidden
        `}
  }
`

const FormHolder = styled.div`
  ${tw`
        w-6/12
        flex
        flex-col
    `}
  @media (max-width: ${SCREENS.xl}) {
    ${tw`
            w-11/12
        `}
  }
`
const Form = styled.form`
  ${tw`
        w-full
        mt-5
    `}
`
const LogoAuth = styled(Logo)`
  ${tw`
        px-5
    `}
`
const Heading2 = styled.div`
  ${tw`
        text-2xl font-bold text-center mt-8
    `}
`
const Heading3 = styled.div`
  ${tw`
        text-lg font-bold text-center mt-16
    `}
`
const Heading4 = styled.div`
  ${tw`
        text-sm text-center mt-4
    `}
`

export const VerifyScreen = ({ state, updateState, onSubmit, resendOTP }) => {
  return (
    <FormHolder>
      <LogoAuth />

      <Marginer direction="vertical" margin="2em" />

      <Form>
        {state.request.phone.length > 10 && state.request.phone.length < 15 && (
          <>
            <StyledInput
              id={"phoneNumber"}
              label="Phone Number"
              type="phone"
              value={state.request.phone}
              onChange={() => {}}
              disabled={true}
            />
            <Marginer direction="vertical" margin="2em" />
          </>
        )}
        {state.request.email.length > 0 && (
          <>
            <StyledInput
              id="email"
              label="Email"
              type="email"
              value={state.request.email}
              disabled={true}
              onChange={(e) => {}}
            />
            <Marginer direction="vertical" margin="2em" />
          </>
        )}
        <StyledInput
          id={"otphere"}
          label="Enter OTP here"
          type="number"
          value={state.request.otp}
          onChange={(e) => {
            updateState({
              type: "request",
              value: { ...state.request, otp: e.target.value },
            })
          }}
        />
      </Form>
      <Marginer direction="vertical" margin="5px" />
      <Button
        theme="outlined"
        text={"Verify"}
        click={onSubmit}
        className="py-4"
      />
      <Container>
        <Heading4>
          {" "}
          Didn't receive the verification OTP?{" "}
          <Marginer direction="horizontal" margin="10px" />
          <StyledLink
            className="font-semibold"
            theme="plain"
            text={" Resend OTP"}
            url={""}
            onClick={(e) => {
              e.preventDefault()
              resendOTP()
            }}
          />
        </Heading4>
      </Container>
      <Heading4>
        <StyledLink
          className="font-normal text-black"
          theme="plain"
          text={"Continue as Guest"}
          url={"/"}
        />
      </Heading4>
    </FormHolder>
  )
}
