/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { BiSolidEdit } from "react-icons/bi"
import imageblank from "../../../../assets/imageblank.png"
import StyledInput from "../../../Components/input"
import { Marginer } from "../../../Components/marginer"
import { useAccountInfoData } from "../hooks/accountInfoData.Hooks"
import { Link } from "react-router-dom"
import { FaEdit, FaRegCalendarAlt } from "react-icons/fa"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { FaCalendarAlt } from "react-icons/fa"

interface StyledInputProps {
  [x: string]: any
  label: string
  id: string
  type: string
  value: string
  onChange: any
  defaultValue?: string
}
const ContainerRight = styled.div`
  ${tw` 
    justify-start lg:w-[800px] p-4   ml-10  w-full
`}
`
const ContainerRightContent = styled.div`
  ${tw` 
   flex gap-3 relative 
`}
`

const DivTop = styled.div`
  ${tw` 
  flex flex-col mt-16 
`}
`
const ImageContent = styled.div`
  ${tw` 
  text-3xl font-bold
`}
`
const AbsoluteDiv = styled.div`
  ${tw` 
  absolute top-40 left-16
`}
`
const DivBottom = styled.div`
  ${tw` 
   mt-[200px] text-center bg-sky-500 p-2 rounded-full text-white text-xl cursor-pointer`}
`
const Label = styled.label`
  left: 0px !important;
`
export default function AccInfo(props: StyledInputProps) {
  const [editable, setEditable] = useState(false)
  const toggleEditMode = () => {
    setEditable(!editable)
  }

  const {
    userDetails,
    DataOfGiftList,
    DataOfFavLists,
    firstName,
    email,
    phoneNumber,
    DateOfM,
    image,
    password,
    UploadImage,
    selectedGender,
    handleGenderChange,
    setFirstName,
    setEmail,
    setPassword,
    setPhoneNumber,
    setDateof,
    updateInfo,
  } = useAccountInfoData()

  const currentDate = new Date()
  const day = currentDate.getDate()
  const month = currentDate.toLocaleString("default", { month: "long" })
  const formattedDate = `${day}${getOrdinalSuffix(day)} ${month}`
  const [date, setDate] = useState(new Date())

  const handleChange = (newDate) => {
    setDate(newDate)
  }
  function getOrdinalSuffix(day: number) {
    if (day >= 11 && day <= 13) {
      return "th"
    }
    switch (day % 10) {
      case 1:
        return "st"
      case 2:
        return "nd"
      case 3:
        return "rd"
      default:
        return "th"
    }
  }

  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  }

  return (
    <ContainerRight className="text-sm  ml-5">
      <Marginer margin={40} direction="vertical" />
      <ContainerRightContent>
        <img className="size-48" alt="img" src={image ?? imageblank} />
        <DivTop>
          <ImageContent>{userDetails.user_data.profile_name}</ImageContent>
          <div>{userDetails.user_data.profile_title}</div>
        </DivTop>
        <AbsoluteDiv>
          <div>
            <input
              type="file"
              name="userImage"
              id="userImage"
              className="hidden"
              onChange={(e) => {
                e.preventDefault()
                UploadImage(e)
              }}
            />
            <label htmlFor="userImage">
              <BiSolidEdit className=" bg-white size-12  rounded-full p-1" />
            </label>
          </div>
        </AbsoluteDiv>
      </ContainerRightContent>
      <form>
        <div className="mt-10">
          <StyledInput
            id="FirstName"
            type={"firstNumber"}
            label="Profile Name*"
            value={firstName}
            stateLess={true}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="mt-10 flex justify-between items-center  relative">
          <StyledInput
            id={"Phone Number"}
            type={"phone"}
            label="New Phone Number*"
            disabled={!editable}
            value={phoneNumber}
            stateLess={false}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="phone-number-label"
          />
          {!editable && (
            <Link
              to=""
              className="text-gray-400 font-semibold absolute right-0"
              onClick={() => setEditable(true)}
            >
              <FaEdit className="size-5" />
            </Link>
          )}
        </div>
        <div className="mt-10 flex justify-between items-center  relative">
          <StyledInput
            id={"email"}
            type={"email"}
            label="New Email*"
            value={email}
            disabled={!editable}
            onChange={(e) => setEmail(e.target.value)}
          />
          {!editable && (
            <Link
              to=""
              className="text-gray-400 font-semibold absolute right-0"
              onClick={() => setEditable(true)}
            >
              <FaEdit className="size-5" />
            </Link>
          )}
        </div>
        <div className="mt-10 flex justify-between items-center  relative">
          <StyledInput
            id={"password"}
            type={"text"}
            label="New Password"
            value={"*******"}
            disabled={true}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Link
            to="/Auth"
            className="text-gray-400 font-semibold absolute right-0"
          >
            <FaEdit className="size-5" />
          </Link>
        </div>

        <div className="relative">
          {/* <StyledInput
          className="date-pick relative"
          id={"DateOf"}
          type={"text"}
          label={`Birthday-e.g. ${formattedDate}*`}
          
        /> */}
          <label className="text-[#4b5563] text-[12px]">{`Birthday-e.g. ${formattedDate}*`}</label>
          <DatePicker
            dateFormat="dd MMMM"
            selected={date}
            onChange={handleChange}
            icon={FaRegCalendarAlt}
            ariaLabelledBy="date"
            className="!outline-none  border-l-0 border-r-0 !ring-0 !border-b-1 border-b-[#9ca3af] focus:border-b-[#000] border-t-0 !focus:none bg-transparent"
          />
        </div>

        <div className="flex gap-8 mt-5">
          Gender :
          <label>
            <input
              type="radio"
              value="1"
              checked={selectedGender.toString() === "1"}
              onChange={handleGenderChange}
              className="mr-2"
            />
            Male
          </label>
          <label>
            <input
              type="radio"
              value="2"
              checked={selectedGender.toString() === "2"}
              onChange={handleGenderChange}
              className="mr-2"
            />
            Female
          </label>
          <label>
            <input
              type="radio"
              value="3"
              checked={selectedGender.toString() === "3"}
              onChange={handleGenderChange}
              className="mr-2"
            />
            Other
          </label>
        </div>

        <DivBottom onClick={updateInfo}>Update</DivBottom>
      </form>
      <Marginer margin={80} direction="vertical" />
    </ContainerRight>
  )
}
