import userCaption from "./slices/UserAccount/userCaption"
import getPopupDetailsSlice from "./slices/Popups/getPopupDetailsSlice"
import userAccount from "./slices/UserAccount/userAccount"
import userFavList from "./slices/Gifts/userFavList"
import getGiftList from "./slices/Gifts/getGiftList"
import { configureStore, combineReducers } from "@reduxjs/toolkit"
import getCurrencyList from "./slices/Currency/getCurrencyList"
import getUserCurrency from "./slices/Currency/getUserCurrency"
import getStoreGiftList from "./slices/Gifts/getStoreGiftList"
import detailsSkuSlice from "./slices/Sku/detailsSkuSlice"
import storeSkuSlice from "./slices/Sku/storeSkuSlice"
import listTagsSlice from "./slices/Tags/listTagsSlice"
import suggestSkuSlice from "./slices/Sku/suggestSkuSlice"
import storeListMarketSkuSlice from "./slices/Sku/storeListMarketSkuSlice"
import relatedSkuSlice from "./slices/Sku/relatedSkuSlice"
import multipleSkuSlice from "./slices/Sku/multipleSkuSlice"
import skuListSlice from "./slices/Sku/skuListSlice"
import homeSlice from "./slices/User/homeSlice"
import categoriesListSlice from "./slices/Filters/categoriesListSlice"
import colorsListSlice from "./slices/Filters/colorsListSlice"
import sizesListSlice from "./slices/Filters/sizesListSlice"
import skuForFiltersSlice from "./slices/Filters/skuForFiltersSlice"
import storeCategoriesSlice from "./slices/Filters/storeCategoriesSlice"
import cartDetails from "./slices/Carts/cartDetails"
import getStoreList from "./slices/Stores/getStoreList"
import getStore from "./slices/Stores/getStore"
import getMenuList from "./slices/Menu/MenuList"
import getCredits from "./slices/Credits/getCredits"
import calculateCreadits from "./slices/Credits/calculateCreadits"
import getFollowFollowersSlice from "./slices/Follow/getFollowFollowersSlice"
import getMyOrder from "./slices/MyOrders/getMyOrder"
import getAlertsData from "./slices/Alerts/getAlertsData"
import getGifts from "./slices/giftsReceipts/getGifts"
import getSearchUser from "./slices/Chats/getSearchUser"
import getProductsListSlice from "./slices/Products/ProductsSlice"
import getAddressList from "./slices/Credits/getAddressList"
import MatchDictSlice from "./slices/Dict/MatchDictSlice"
import GetReferral from "./slices/Referral/GetReferral"
import getProfileCaption from "./slices/Caption/getProfileCaption"
import activity from "./slices/Account/activity"
import getMatchDictSlice from "./slices/Dict/getMatchDictSlice"

const rootReducer = combineReducers({
  suggestSku: suggestSkuSlice,
  storeListMarketSku: storeListMarketSkuSlice,
  relatedSku: relatedSkuSlice,
  multipleSku: multipleSkuSlice,
  detailData: detailsSkuSlice,
  storeSku: storeSkuSlice,
  skuList: skuListSlice,
  listTags: listTagsSlice,
  home: homeSlice,
  categoriesData: categoriesListSlice,
  colorsData: colorsListSlice,
  sizeslist: sizesListSlice,
  skuforfilter: skuForFiltersSlice,
  storeCategories: storeCategoriesSlice,
  getMenulist: getMenuList,
  CurrencyList: getCurrencyList,
  UserCurrency: getUserCurrency,
  storeGiftList: getStoreGiftList,
  storeList: getStoreList,
  storelistMain: getStore,
  userCaption: userCaption,
  popup: getPopupDetailsSlice,
  userAccount: userAccount,
  favList: userFavList,
  giftLists: getGiftList,
  cartDetail: cartDetails,
  CreditsGet: getCredits,
  calCulateCreadits: calculateCreadits,
  follow: getFollowFollowersSlice,
  myOrder: getMyOrder,
  alerts: getAlertsData,
  GiftsDetails: getGifts,
  userGifts: getSearchUser,
  ProductsList: getProductsListSlice,
  AddressList: getAddressList,
  matchDict: MatchDictSlice,
  searchDict: getMatchDictSlice,
  referralLink: GetReferral,
  profileCaption: getProfileCaption,
  userActivity: activity,
})

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
