import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IRelatedData, IRelatedSku } from "../../../../@types/sku"

const initialState: IRelatedData = {
  related: {
    current_index: 0,
    per_page: 0,
    sku_count: 0,
    sku_data: [],
    status: 0,
    store_details: {
      store_logo: "",
      store_name: "",
      store_region: "",
    },
    total_count: 0,
  },
}

const relatedSlice = createSlice({
  name: "relatedSku",
  initialState,
  reducers: {
    setRelated: (state, action: PayloadAction<IRelatedSku>) => {
      state.related = action.payload
    },
  },
})

export const { setRelated } = relatedSlice.actions

export default relatedSlice.reducer
