import { Bounce, toast } from "react-toastify"
import useCart from "../api/hooks/useCart"
import { useAppSelector } from "./hooks"
import { useNavigate } from "react-router-dom"

export const IfLoggedIn = () => {
  const navigate = useNavigate()
  if (
    !localStorage.getItem("user") &&
    localStorage.getItem("token").includes("guest_")
  ) {
    navigate("/Auth")
  }
}

export const PopTost = ({ message, type }) => {
  switch (type) {
    case "error": {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
      break
    }
    case "success": {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
      break
    }
    case "info": {
      toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
      break
    }
    case "warning": {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
      break
    }
  }
}

export const useCommonCart = () => {
  const { createCart, getCartDetails, addToCart } = useCart()
  const { cartDetail } = useAppSelector((state) => state)
  const navigate = useNavigate()
  const create_cart = async () => {
    //@ts-ignore
    console.log(cartDetail.cartdetail?.cart_data?.cart_id)
    //@ts-ignore
    if (
      !localStorage.getItem("cart_id") ||
      parseInt(localStorage.getItem("cart_id") ?? "0") <= 0
    ) {
      return await createCart()
    }
    return false
  }

  const addItemToCart = async ({
    color_id,
    currency_id,
    gift_list_id,
    gift_message,
    quantity,
    receipient_uuid,
    size_id,
    sui,
  }) => {
    let cart = await create_cart()
    //@ts-ignore
    if (
      cart != false ||
      parseInt(localStorage.getItem("cart_id") ?? "0") > 0 ||
      localStorage.getItem("order_num").length > 0
    ) {
      //@ts-ignore
      let item = await addToCart({
        cart_id: localStorage.getItem("cart_id") ?? cart.cart_data.cart_id,
        color_id,
        currency_id,
        gift_list_id,
        gift_message,
        order_num: localStorage.getItem("order_num"),
        quantity,
        receipient_uuid,
        size_id,
        sui,
      })

      if (item?.cart_data?.status == 200 || item?.cart_data?.status == 201) {
        //@ts-ignore
        getCartDetails({ order_num: localStorage.getItem("order_num") })
      }
      PopTost({ message: "Product added to cart.", type: "success" })
    }
  }

  return { addItemToCart }
}
