import { useEffect, useState } from "react"
import { FaImage } from "react-icons/fa6"
import Skeleton from "react-loading-skeleton"
import { Link } from "react-router-dom"
import { styled } from "styled-components"
import tw from "twin.macro"
const Wrapper = styled.div`
  ${tw` 
  cursor-pointer
  overflow-hidden 
  m-7
  `}
`

const ContainerInfo = styled.div`
  ${tw` 
    flex  
    justify-center 
    mt-2
    `}
`

const ParaPriceMain = styled.div`
  ${tw` 
  flex 
  justify-center 
  items-center
  `}
`

const ParaPrice = styled.div`
  ${tw` 
text-sky-500 
text-sm
`}
`
const DealsThumb = ({ image, heading, subhead, link, date, ...props }) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 2200)
    return () => clearTimeout(timer)
  }, [])
  return (
    <Wrapper>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "500px",
            backgroundColor: "#f3f3f3",
            position: "relative",
          }}
        >
          <Skeleton height={500} count={1} />
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              left: "50%",
              top: "25%",
              color: "#000",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaImage className="mr-2 size-4" />
          </div>
        </div>
      ) : (
        <Link to={link}>
          <img src={image} className="w-full" alt="/" />
          <ContainerInfo>
            <div className=" font-semibold">
              <h3 className="text-center text-base font-semibold">{heading}</h3>
              <div className="flex text-center flex-col font-normal text-sm">
                <ParaPriceMain>{date}</ParaPriceMain>
                <ParaPrice className="text-xs">{subhead}</ParaPrice>
              </div>
            </div>
          </ContainerInfo>
        </Link>
      )}
    </Wrapper>
  )
}
export default DealsThumb
