import { Link } from "react-router-dom"
import styled from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../responsive"
const ThumbHolder = styled.div<{ image: string }>`
  ${tw`
    w-96
    bg-cover
    bg-center
    relative
    rounded-2xl
`}
  height:400px;
  background-image: url(${(props) => props.image});

  @media screen and (max-width: ${SCREENS["2xl"]}) {
    ${tw`
        w-72
    `}
  }
  /* @media screen and (max-width:${SCREENS.xl}){
    ${tw`
        w-60
    `}
    height:400px;
} */
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        w-72
    `}
    height:450px;
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        w-96
    `}
    height:450px;
  }
`
const Links = styled(Link)`
  ${tw`
    flex
    justify-start
`}
  @media screen and (max-width:${SCREENS.lg}) {
    ${tw`
        justify-center
    `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        justify-center
    `}
  }
`

const EditsThumb = ({ children, image, alt, ...props }) => {
  return (
    <Links to={props.link} key={props.key}>
      <ThumbHolder image={image}></ThumbHolder>
    </Links>
  )
}

export default EditsThumb
