import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IUserDetails,
  IUserAccountDetails,
} from "../../../../@types/UserAccountInfo"

const initialState: IUserDetails = {
  UserAccourt: {
    currency_data: {
      code: "",
      symbol: "",
    },
    status: 0,
    user_data: {
      base_country_code: "",
      currency_data: [],
      date_of_birth: "",
      fashion_market_pref: "",
      fashion_market_pref_id: 0,
      gender: "",
      gender_id: 0,
      gift_list: [],
      image_url: "",
      image_url_thumb: "",
      influencer_coupon: "",
      influencer_credits: {
        earnings: 0,
        orders: 0,
        sales: 0,
      },
      influencer_discount: 0,
      influencer_discount_expiry: 0,
      initial_signup_credits: 0,
      is_following: false,
      is_following_status: "",
      is_influencer: false,
      is_user_private: 0,
      num_followers: 0,
      num_following: 0,
      num_referrals: 0,
      profile_name: "",
      profile_title: "",
      role: "",
      total_credits: 0,
      user_creation_date: "",
      user_email: "",
      user_favorites: [],
      user_phone: "",
      user_referral_code: "",
      user_status: 0,
      user_uuid: "",
      username_v1: "",
    },
  },
}

const userAccount = createSlice({
  name: "userAccount",
  initialState,
  reducers: {
    setUserAccount: (state, action: PayloadAction<IUserAccountDetails>) => {
      state.UserAccourt = action.payload
    },
  },
})

export const { setUserAccount } = userAccount.actions

export default userAccount.reducer
