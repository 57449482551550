import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IGetReferralLinks, IGetRefferal } from "../../../../@types/referral"

const initialState: IGetReferralLinks = {
  RefLinks: {
    data: "",
    status: 0,
  },
}

const GetReferral = createSlice({
  name: "referralLink",
  initialState,
  reducers: {
    setReferralLink: (state, action: PayloadAction<IGetRefferal>) => {
      state.RefLinks = action.payload
    },
  },
})

export const { setReferralLink } = GetReferral.actions

export default GetReferral.reducer
