import { useEffect } from "react"
import { useAppSelector } from "../../utils/hooks"
import { useParams } from "react-router-dom"
import usePopup from "../../api/hooks/usePopup"
import { useGetCurrency } from "../../Components/Header/Header.Hooks"

export const useDealDetailsData = () => {
  const { popup_uuid } = useParams<"popup_uuid">()
  const popup = useAppSelector((state) => state.popup.popup.data)
  const { UserCurrency } = useGetCurrency()
  const { getPopupDetails } = usePopup()
  const popups_uuid = popup_uuid ?? ""
  useEffect(() => {
    getPopupDetails({ popup_uuid: popups_uuid })
  }, [UserCurrency])
  return { popup }
}
