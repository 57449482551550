import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import tw from "twin.macro"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const BannerHolder = styled.div`
  ${tw`
    w-full
    relative
    overflow-hidden
`}
`
const Dots = styled.div`
  ${tw`
    absolute
    right-0
    m-auto
    w-12
    flex
    h-full
`}
  ul {
    ${tw`
        flex
        flex-col
        justify-center
        items-center
    `}
  }
  .slick-dots li {
    margin: 5px 0;
  }
  li button:before {
    font-size: 16px;
  }
  li.slick-active button:before {
    color: #fff;
    opacity: 1;
  }
  li button:before {
    color: #fff;
  }
`

const BannerSlider = ({ children, ...props }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <Dots className="dotsHolder ">
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </Dots>
    ),
    autoplay: true,
  }
  return (
    <BannerHolder>
      <Slider {...settings}>{children}</Slider>
    </BannerHolder>
  )
}

export default BannerSlider
