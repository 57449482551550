import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IStoreData, IStoreSku } from "../../../../@types/sku"

const initialState: IStoreData = {
  storeData: {
    shopper_details: "",
    status: 0,
    store_details: {
      base_country: "",
      city: "",
      store_logo: "",
      store_logo_thumb: "",
      store_name: "",
      store_owner: 0,
      store_username: "",
      store_uuid: "",
    },
    sui_data: [],
  },
}

const storeSkuSlice = createSlice({
  name: "storeSku",
  initialState,
  reducers: {
    setStoreSku: (state, action: PayloadAction<IStoreSku>) => {
      state.storeData = action.payload
    },
  },
})

export const { setStoreSku } = storeSkuSlice.actions

export default storeSkuSlice.reducer
