import { useEffect, useRef, useState } from "react"
import SendbirdChat from "@sendbird/chat"
import {
  GroupChannelModule,
  GroupChannelFilter,
  GroupChannelListOrder,
  MessageFilter,
  MessageCollectionInitPolicy,
} from "@sendbird/chat/groupChannel"
import { v4 as uuid } from "uuid"
import parse from "html-react-parser"
import { SENDBIRD_INFO } from "./constants/constants"
import { timestampToTime, handleEnterPress } from "./utils/messageUtils"
import useChatSearch from "../../api/hooks/useChatsearch"

let sb
export const useSendbird = (channelRef) => {
  const [state, updateState] = useState({
    applicationUsers: [],
    groupChannelMembers: [],
    currentlyJoinedChannel: null,
    messages: [],
    channels: [],
    messageInputValue: "",
    userNameInputValue: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("userData"))?.name
      : "guest",
    userIdInputValue:
      localStorage.getItem("user") ?? localStorage.getItem("token"),
    // .replace("guest_", ""),
    channelNameUpdateValue: "",
    settingUpUser: true,
    file: null,
    messageToUpdate: null,
    messageCollection: null,
    loading: false,
    error: false,
    currentUser: "",
  })

  //need to access state in message received callback
  const stateRef = useRef(state)
  stateRef.current = state

  useEffect(() => {
    console.log("connect")
    setupUser()
  }, [])

  const channelHandlers = {
    onChannelsAdded: (context, channels) => {
      const updatedChannels = [...channels, ...stateRef.current.channels]
      updateState({
        ...stateRef.current,
        channels: updatedChannels,
        applicationUsers: [],
      })
    },
    onChannelsDeleted: (context, channels) => {
      const updatedChannels = stateRef.current.channels.filter((channel) => {
        return !channels.includes(channel.url)
      })
      updateState({ ...stateRef.current, channels: updatedChannels })
    },
    onChannelsUpdated: (context, channels) => {
      const updatedChannels = stateRef.current.channels.map((channel) => {
        const updatedChannel = channels.find(
          (incomingChannel) => incomingChannel.url === channel.url
        )
        if (updatedChannel) {
          return updatedChannel
        } else {
          return channel
        }
      })

      updateState({ ...stateRef.current, channels: updatedChannels })
    },
  }

  const messageHandlers = {
    onMessagesAdded: (context, channel, messages) => {
      console.log("stateRef")
      const updatedMessages = [...stateRef.current.messages, ...messages]

      updateState({ ...stateRef.current, messages: updatedMessages })
    },
    onMessagesUpdated: (context, channel, messages) => {
      const updatedMessages = [...stateRef.current.messages]
      for (let i in messages) {
        const incomingMessage = messages[i]
        const indexOfExisting = stateRef.current.messages.findIndex(
          (message) => {
            return incomingMessage.reqId === message.reqId
          }
        )

        if (indexOfExisting !== -1) {
          updatedMessages[indexOfExisting] = incomingMessage
        }
        if (!incomingMessage.reqId) {
          updatedMessages.push(incomingMessage)
        }
      }

      updateState({ ...stateRef.current, messages: updatedMessages })
    },
    onMessagesDeleted: (context, channel, messageIds) => {
      const updateMessages = stateRef.current.messages.filter((message) => {
        return !messageIds.includes(message.messageId)
      })
      updateState({ ...stateRef.current, messages: updateMessages })
    },
    onChannelUpdated: (context, channel) => {},
    onChannelDeleted: (context, channelUrl) => {},
    onHugeGapDetected: () => {},
  }

  const scrollToBottom = (item, smooth = "smooth") => {
    item?.scrollTo({
      top: item.scrollHeight,
      behavior: smooth,
    })
  }

  useEffect(() => {
    scrollToBottom(channelRef.current)
  }, [state.currentlyJoinedChannel])

  useEffect(() => {
    scrollToBottom(channelRef.current, "smooth")
  }, [state.messages])

  const onError = (error) => {
    updateState({ ...state, error: error.message })
    console.log(error)
  }

  const { getSearchChat } = useChatSearch()

  const handleJoinChannel = async (channelUrl) => {
    if (state.messageCollection && state.messageCollection.dispose) {
      state.messageCollection?.dispose()
    }

    if (state.currentlyJoinedChannel?.url === channelUrl) {
      return null
    }
    const { channels } = state
    updateState({ ...state, loading: true })
    const channel = channels.find((channel) => channel.url === channelUrl)
    const onCacheResult = (err, messages) => {
      updateState({
        ...stateRef.current,
        currentlyJoinedChannel: channel,
        messages: messages,
        loading: false,
      })
    }

    const onApiResult = (err, messages) => {
      updateState({
        ...stateRef.current,
        currentlyJoinedChannel: channel,
        messages: messages,
        loading: false,
      })
    }

    const collection = loadMessages(
      channel,
      messageHandlers,
      onCacheResult,
      onApiResult
    )

    updateState({ ...state, messageCollection: collection })
  }

  const handleLeaveChannel = async () => {
    const { currentlyJoinedChannel } = state
    await currentlyJoinedChannel.leave()

    updateState({ ...state, currentlyJoinedChannel: null })
  }

  const handleCreateChannel = async (channelName = "testChannel") => {
    const [groupChannel, error] = await sb.createChannel(
      channelName,
      state.groupChannelMembers
    )
    if (error) {
      return onError(error)
    }
  }

  const handleUpdateChannelMembersList = async () => {
    const { currentlyJoinedChannel, groupChannelMembers } = state
    await inviteUsersToChannel(currentlyJoinedChannel, groupChannelMembers)
    updateState({ ...state, applicationUsers: [] })
  }

  const handleDeleteChannel = async (channelUrl) => {
    const [channel, error] = await deleteChannel(channelUrl)
    if (error) {
      return onError(error)
    }
  }

  const handleMemberInvite = async () => {
    // @ts-ignore
    const [users, error] = await getAllApplicationUsers()
    if (error) {
      return onError(error)
    }
    updateState({ ...state, applicationUsers: users })
  }

  const onUserNameInputChange = (e) => {
    const userNameInputValue = e.currentTarget.value
    updateState({ ...state, userNameInputValue })
  }

  const onUserIdInputChange = (e) => {
    const userIdInputValue = e.currentTarget.value
    updateState({ ...state, userIdInputValue })
  }

  const onMessageInputChange = (e) => {
    const messageInputValue = e.currentTarget.value
    updateState({ ...state, messageInputValue })
  }

  const sendMessage = async () => {
    const { messageToUpdate, currentlyJoinedChannel, messages } = state
    if (messageToUpdate) {
      const userMessageUpdateParams = {} // @ts-ignore
      userMessageUpdateParams.message = state.messageInputValue
      const updatedMessage = await currentlyJoinedChannel.updateUserMessage(
        messageToUpdate.messageId,
        userMessageUpdateParams
      )
      const messageIndex = messages.findIndex(
        (item) => item.messageId == messageToUpdate.messageId
      )
      messages[messageIndex] = updatedMessage
      updateState({
        ...state,
        messages: messages,
        messageInputValue: "",
        messageToUpdate: null,
      })
    } else {
      console.log(state.messageInputValue)
      const userMessageParams = {} // @ts-ignore
      userMessageParams.message = state.messageInputValue
      currentlyJoinedChannel
        .sendUserMessage(userMessageParams)
        .onSucceeded((message) => {
          console.log(stateRef.current.messages)
          let messagesList = [message, ...messages]
          updateState({
            ...stateRef.current,
            messageInputValue: "",
            messages: messagesList,
          })
        })
        .onFailed((error) => {
          console.log(error)
          console.log("failed")
        })
    }
  }

  const onFileInputChange = async (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const { currentlyJoinedChannel, messages } = state
      const fileMessageParams = {}
      // @ts-ignore
      fileMessageParams.file = e.currentTarget.files[0]
      currentlyJoinedChannel
        .sendFileMessage(fileMessageParams)
        .onSucceeded((message) => {
          updateState({
            ...stateRef.current,
            messageInputValue: "",
            file: null,
          })
        })
        .onFailed((error) => {
          console.log(error)
          console.log("failed")
        })
    }
  }

  const handleDeleteMessage = async (messageToDelete) => {
    const { currentlyJoinedChannel } = state
    await deleteMessage(currentlyJoinedChannel, messageToDelete) // Delete
  }

  const updateMessage = async (message) => {
    updateState({
      ...state,
      messageToUpdate: message,
      messageInputValue: message.message,
    })
  }

  const handleLoadMemberSelectionList = async () => {
    updateState({ ...state, currentlyJoinedChannel: null })
    // @ts-ignore
    const [users, error] = await getAllApplicationUsers()
    if (error) {
      return onError(error)
    }
    updateState({
      ...state,
      currentlyJoinedChannel: null,
      applicationUsers: users,
      groupChannelMembers: [sb.currentUser.userId],
    })
  }

  const addToChannelMembersList = (userId) => {
    const groupChannelMembers = [...state.groupChannelMembers, userId]
    updateState({ ...state, groupChannelMembers: groupChannelMembers })
  }

  const setupUser = async () => {
    const { userNameInputValue, userIdInputValue } = state
    const sendbirdChat = await SendbirdChat.init({
      appId: SENDBIRD_INFO.appId,
      localCacheEnabled: true,
      modules: [new GroupChannelModule()],
    })

    await sendbirdChat.connect(userIdInputValue)
    await sendbirdChat.setChannelInvitationPreference(true)
    const userUpdateParams = {}
    // @ts-ignore
    userUpdateParams.nickname = userNameInputValue
    // @ts-ignore
    userUpdateParams.userId = userIdInputValue
    await sendbirdChat.updateCurrentUserInfo(userUpdateParams)

    sb = sendbirdChat
    updateState({ ...state, loading: true })
    const [channels, error] = await loadChannels(channelHandlers)
    if (error) {
      return onError(error)
    }
    // console.log(channels)
    updateState({
      ...state,
      channels: channels,
      loading: false,
      settingUpUser: false,
      currentUser: sb.currentUser.userId,
    })
  }

  // Helpful functions that call Sendbird
  const loadChannels = async (channelHandlers) => {
    const groupChannelFilter = new GroupChannelFilter()
    groupChannelFilter.includeEmpty = true

    const collection = sb.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    })

    collection.setGroupChannelCollectionHandler(channelHandlers)

    const channels = await collection.loadMore()
    return [channels, null]
  }

  const loadMessages = (
    channel,
    messageHandlers,
    onCacheResult,
    onApiResult
  ) => {
    const messageFilter = new MessageFilter()

    const collection = channel.createMessageCollection({
      filter: messageFilter,
      startingPoint: Date.now(),
      limit: 100,
    })

    collection.setMessageCollectionHandler(messageHandlers)
    collection
      .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
      .onCacheResult(onCacheResult)
      .onApiResult(onApiResult)
    return collection
  }

  const inviteUsersToChannel = async (channel, userIds) => {
    await channel.inviteWithUserIds(userIds)
  }

  const createNewChannel = async ({ userIdsToInvite, channelName }) => {
    try {
      const groupChannelParams = {
        invitedUserIds: userIdsToInvite,
        isDistinct: true,
      }

      if (channelName.length > 0) {
        // @ts-ignore
        groupChannelParams.name = channelName
      }

      // @ts-ignore
      groupChannelParams.operatorUserIds = userIdsToInvite
      const groupChannel =
        await sb.groupChannel.createChannel(groupChannelParams)

      return groupChannel
    } catch (error) {
      return [null, error]
    }
  }

  const joinChannel = async (CHANNEL_URL) => {
    const channel = await sb.groupChannel.getChannel(CHANNEL_URL)
    if (channel.isPublic) {
      await channel.join()
    }
  }
  const leaveChannel = async (CHANNEL_URL) => {
    const channel = await sb.groupChannel.getChannel(CHANNEL_URL)
    await channel.leave()
  }

  const deleteChannel = async (channelUrl) => {
    try {
      const channel = await sb.groupChannel.getChannel(channelUrl)
      await channel.delete()
      return [channel, null]
    } catch (error) {
      return [null, error]
    }
  }

  const deleteMessage = async (currentlyJoinedChannel, messageToDelete) => {
    await currentlyJoinedChannel.deleteMessage(messageToDelete)
  }

  const getAllApplicationUsers = async () => {
    try {
      const userQuery = sb.createApplicationUserListQuery({ limit: 100 })
      const users = await userQuery.next()
      return [users, null]
    } catch (error) {
      return [null, error]
    }

    if (state.loading) {
      return <div>Loading...</div>
    }

    if (state.error) {
      return (
        <div className="error">
          {state.error} check console for more information.
        </div>
      )
    }

    return { stateRef }
  }
  const Message = ({ message }) => {
    const messageSentByCurrentUser = message.sender.userId === state.currentUser
    const classValues = messageSentByCurrentUser
      ? "oc-message by"
      : "oc-message from"

    if (!message.sender) return null
    if (message.url) {
      return (
        <div className={classValues}>
          <div className="sent-time">{timestampToTime(message.createdAt)}</div>
          <div className="oc-message-sender-name">
            {message.sender.nickname}{" "}
          </div>
          <img src={message.url} />
        </div>
      )
    }

    return (
      <>
        <div className={classValues}>
          <div className="sent-time">{timestampToTime(message.createdAt)}</div>
          <div className="oc-message-sender-name">
            {messageSentByCurrentUser
              ? "by you"
              : "from " + message.sender.nickname}
          </div>
          <div className="message">{parse(message.message)}</div>
        </div>
        <ProfileImage user={message.sender} />
      </>
    )
  }
  const ProfileImage = ({ user }) => {
    if (user.plainProfileUrl) {
      return (
        <div className="userImg">
          <img className="profile-image" src={user.plainProfileUrl} />
        </div>
      )
    } else {
      return (
        <div className="profile-image-fallback">{user.nickname.charAt(0)}</div>
      )
    }
  }

  const findUser = async ({ name }) => {
    let resp = await getSearchChat({ name })
    return resp.data ?? []
  }

  return {
    loadMessages,
    loadChannels,
    setupUser,
    updateState,
    sendMessage,
    onMessageInputChange,
    handleJoinChannel,
    handleMemberInvite,
    onUserNameInputChange,
    onUserIdInputChange,
    onFileInputChange,
    handleEnterPress,
    state,
    Message,
    ProfileImage,
    joinChannel,
    leaveChannel,
    createNewChannel,
    findUser,
  }
}
