import axios from "axios"
import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { API, getToken } from "../config"
import { setListTags } from "../../utils/slices/Tags/listTagsSlice"
import { setProducts } from "../../utils/slices/Products/ProductsSlice"

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers.common["Content-Type"] = "application/json"

interface IProps {
  store_uuid?: string
  tag_type?: string
  tag_id?: string
  tag_name?: string
  count?: string
  page?: string
  category_id?: string
}

const useTags = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(true)

  function getListTags(props: IProps) {
    // dispatch(setListTags({categories:"",gender:"",next_page:-1,prev_page:0,status:200,tag_list:[],tags_data:[],tags_item_count:0,tags_items_total_count:0,tags_page_count:0}))
    dispatch(
      setProducts({
        data: [],
        count: 0,
        current_page: 0,
        total_count: 0,
        next_page: 0,
        prev_page: 0,
      })
    )

    const listTags = axios
      .get(
        `${API}/tags/get_list_for_tag?api_token=${API_TOKEN}&store_uuid=${props.store_uuid}&tag_type=${props.tag_type}&tag_id=${props.tag_id}&count=${props.count ?? ""}&page=${props.page ?? "0"}&category_id=${props.category_id ?? ""}`
      )
      .then((res) => {
        dispatch(setListTags(res.data))
        dispatch(
          setProducts({
            data: [...res.data.tags_data],
            count: res.data.tags_item_count,
            current_page: res.data.tags_page_count,
            total_count: res.data.tags_items_total_count,
            next_page: res.data.next_page,
            prev_page: res.data.prev_page,
          })
        )
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  }
  return { getListTags }
}
export default useTags
