import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IProductList, IProducts } from "../../../../@types/productsData"

const initialState: IProductList = {
  list: {
    data: [],
    count: 0,
    total_count: 0,
    current_page: 0,
    next_page: 1,
    prev_page: -1,
  },
}

const getProductsListSlice = createSlice({
  name: "productsData",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<IProducts>) => {
      state.list = action.payload
    },
  },
})

export const { setProducts } = getProductsListSlice.actions

export default getProductsListSlice.reducer
