import { BlockHeading } from "../../Components/Globals/BlockHeading"
import { Container } from "../../Components/Globals/Container"
import Search from "../../Components/Search"
import Main from "../../layouts/Main"

import styled from "styled-components"
import tw from "twin.macro"
import StoresThumb from "../../Components/StoresThumb"
import { Marginer } from "../../Components/marginer"
import { SCREENS } from "../../Components/responsive"
import { Hr } from "../Home/Styled.Comps"
import { useStoreData } from "./Stores.Hooks"
import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"

const StoresWrapper = styled.div`
  ${tw`
        grid
        grid-cols-3
        gap-4
        mb-4
    `}
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
            grid-cols-2
        `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
            grid-cols-1
        `}
  }
`
const StoresHolder = styled.div`
  ${tw`
        flex
        flex-col
        w-full
    `}
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
            px-4
        `}
  }
`
const StoreBlock = styled(StoresThumb)`
  ${tw`
        flex-auto
    `}
`
const chunkArray = (arr, chunkSize) => {
  const chunks = Array()
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize))
  }
  return chunks
}

const Stores = () => {
  const { PopulatStores } = useStoreData()
  const [filter, setFilter] = useState([])
  const [slicedData, setSlicedData] = useState([])

  useEffect(() => {
    if (filter.length > 0) {
      setSlicedData(chunkArray(filter, 4))
    } else {
      setSlicedData(chunkArray(PopulatStores, 4))
    }
  }, [filter, PopulatStores])

  const filterProducts = (e: { target: { value: any } }) => {
    let filteredData = PopulatStores.filter((val) =>
      val.store_name.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setFilter(filteredData)
  }

  return (
    <Main>
      <Marginer margin={40} direction="vertical" />
      <Container>
        <Search placeholder="Search" onChange={filterProducts} />
      </Container>
      <Container>
        <Marginer margin={10} direction="vertical" />
        <BlockHeading>{"Popular stores"}</BlockHeading>
        <Marginer margin={20} direction="vertical" />
        <StoresHolder>
          {slicedData.map((item, index) => {
            return (
              <>
                <Hr />

                <StoresWrapper
                  key={index}
                  dir={index % 2 === 0 ? "ltr" : "rtl"}
                >
                  {item.map((subitem, subindex) => {
                    return (
                      <StoreBlock
                        link={`/store/${subitem.store_uuid ?? ""}`}
                        title={subitem.store_name}
                        image={subitem.store_logo}
                        key={"sub_" + subindex}
                        children={undefined}
                      />
                    )
                  })}
                </StoresWrapper>
              </>
            )
          })}
        </StoresHolder>
      </Container>
      <Marginer margin={100} direction="vertical" />
    </Main>
  )
}

export default Stores
