import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IGetDataOfAlerts, IGetAlerts } from "../../../../@types/alerts"
import { IGetActivity, IUserActivity } from "../../../../@types/activity"

const initialState: IGetActivity = {
  activity: {
    activity_data: [],
    debug: {},
    status: 0,
    txn_debug: [],
  },
}

const getAccountActivity = createSlice({
  name: "userActivity",
  initialState,
  reducers: {
    setActivity: (state, action: PayloadAction<IUserActivity>) => {
      state.activity = action.payload
    },
    setActivityStatus: (
      state,
      action: PayloadAction<IUserActivity["status"]>
    ) => {
      state.activity.status = action.payload
    },
  },
})

export const { setActivity } = getAccountActivity.actions

export default getAccountActivity.reducer
