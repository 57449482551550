import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { ICategories, ISegment } from "../../../../@types/search"
import { ISearchResult } from "../../../../@types/search"

const initialState: ISegment = {
  categories: [],
  gender: "",
  gender_id: 0,
  segment_id: 0,
  segment_name: "",
}

const MatchDictslice = createSlice({
  name: "matchDict",
  initialState,
  reducers: {
    setMatchdict: (state, action: PayloadAction<ICategories[]>) => {
      state.categories = action.payload
    },
  },
})

export const { setMatchdict } = MatchDictslice.actions

export default MatchDictslice.reducer
