import { CiHeart } from "react-icons/ci"
import { CiShoppingCart } from "react-icons/ci"
import { FaRegShareFromSquare, FaImage } from "react-icons/fa6"
import styled from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../responsive"
import { Link, useNavigate } from "react-router-dom"
import { useProductThumb } from "./ProductThumb.hook"
import { useEffect, useRef, useState } from "react"
import useGenerateDynamicLink from "../../api/hooks/useGenerateDynamicLink"
import { Modal } from "flowbite-react"
import facebook from "../../../assets/facebook.png"
import whatsapp from "../../../assets/whatsapp.png"
import twitter from "../../../assets/twitter.png"
import { BsCopy } from "react-icons/bs"
import { ShortMainLink } from "../../api/config"
import Skeleton from "react-loading-skeleton"
import useSku from "../../api/hooks/useSku"
//import * as CurrencyFormat from 'react-currency-format';

const ThumbContainer = styled.div`
  ${tw`
    overflow-hidden 
    relative
    items-center
    max-h-[750px]
    `}
  &:hover {
    cursor: pointer;
  }
  &:hover .hover-actions {
    ${tw`
        opacity-100
        bg-transparent
      `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        px-4
        py-2
      `}
  }
`
const ContainerInfo = styled.div`
  ${tw`
    flex 
    justify-center 
    mt-2 
    items-center`}
`
const ParaPriceMain = styled.div`
  ${tw`
    flex 
    justify-center 
    items-center`}
`
const ParaPrice = styled.div`
  ${tw`
    flex 
    justify-start 
    items-center 
    text-gray-500 
    line-through`}
`
const HoverActions = styled.div`
  ${tw`
    flex 
    absolute  
    opacity-0 
    transition-all 
    duration-500
    bg-white
    h-full
    w-full
    top-0
    left-0
    justify-center
    items-end
    py-28
    gap-4
    `}
`

const ProductImage = styled.img`
  ${tw`
        w-full
    `}
  height:auto;
`
const Button = styled.button`
  ${`
        block
        p-4
    `}
`
const ProductHeading = styled.h3`
  ${tw`
        text-center
        font-semibold
    `}
`

const Pricings = styled.div`
  ${tw`
            flex
            justify-center
            gap-5
        `}
`
const ProductThumb = ({
  link,
  image,
  name,
  salePrice,
  price,
  currency_code,
  ...props
}) => {
  const navigate = useNavigate()
  const { addToBag, addToFavorite, removeFromFavorite, SkuRelatedData } =
    useProductThumb()
  const generateLink = useGenerateDynamicLink()
  const [openModal, setOpenModal] = useState(false)
  const [shortLink, setShortLink] = useState<string | null>(null)
  function formatPriceCurrency(price, currencyCode = currency_code) {
    const formattedPrice = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currencyCode,
      maximumSignificantDigits: 3,
    }).format(price)

    return formattedPrice
  }
  const newPrice = formatPriceCurrency(price)
  function formatSaleCurrency(salePrice, currencyCode = currency_code) {
    const formattedPrice = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currencyCode,
      maximumSignificantDigits: 3,
    }).format(salePrice)

    return formattedPrice
  }
  const newSalePrice = formatSaleCurrency(salePrice)
  const handleClick = async () => {
    try {
      let userDataDetail = JSON.parse(localStorage.getItem("userData"))

      const longLink = `${ShortMainLink}/product?lui=${props.item.sui}&imgSystemName=${props.item.sku_title}&store_id=""&uploadType="product"&currentType="shopper"&sender_id=${userDataDetail?.user_uid}&region="IN"&mediaUrl=${props.item.image_url}&description={}`
      const responseShortLink = await generateLink(longLink)

      setShortLink(responseShortLink)
      setOpenModal(true)
    } catch (error) {
      console.error("Error in handleClick:", error)
    }
  }
  const profileUrlRef = useRef(null)

  const copyProfileUrl = () => {
    if (profileUrlRef.current) {
      profileUrlRef.current.select()
      profileUrlRef.current.setSelectionRange(0, 99999) // For mobile devices
      navigator.clipboard.writeText(profileUrlRef.current.value)
    }
  }
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 2200)
    return () => clearTimeout(timer)
  }, [])
  return (
    <ThumbContainer key={props.key}>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "500px",
            backgroundColor: "#f3f3f3",
            position: "relative",
          }}
        >
          <Skeleton height={500} count={1} />
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              left: "30%",
              top: "25%",
              color: "#000",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaImage className="mr-2 size-4" />
            {name?.length > 20 ? name.substring(0, 12) : name + +" Image"}
          </div>
        </div>
      ) : (
        <Link to={link}>
          <ProductImage src={image} alt={name + " Image"} />
        </Link>
      )}
      <ContainerInfo>
        <div>
          <ProductHeading className="text-center">{name}</ProductHeading>
          <Pricings>
            <>
              <ParaPrice>{newPrice}</ParaPrice>

              <ParaPriceMain>{newSalePrice}</ParaPriceMain>
              <ParaPriceMain className="text-red-700 font-bold">
                {Math.ceil(((price - salePrice) / price) * 100)}%
              </ParaPriceMain>
            </>
          </Pricings>
        </div>
      </ContainerInfo>

      <HoverActions
        className="hover-actions"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          navigate(link)
        }}
      >
        {localStorage.getItem("user") && (
          <Button
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              let system = ""
              if (
                !props.item.system_name &&
                props.item.product_image_name.length > 0
              ) {
                system = props.item.product_image_name
              } else if (!props.item.system_name) {
                let breaked = props.item.image_url.split("/")
                system = breaked[breaked.length - 1]
              } else {
                system = props.item.system_name
              }
              if (props.item?.is_favorite) {
                removeFromFavorite(e, {
                  sui: props.item.sui ?? props.item.SUI,
                  type: "product",
                })
              } else {
                addToFavorite(e, {
                  sui: props.item.sui ?? props.item.SUI,
                  influencer_user_id: "0",
                  store_uuid: props.item.store_uuid ?? props.uuid,
                  system_name: system,
                  type: "product",
                })
              }
            }}
          >
            <CiHeart className="text-sky-500 bg-white size-6 rounded-full border-solid border-2 w-8 h-8 z-10" />
          </Button>
        )}
        <Button
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            navigate("/shoppingBag")
            addToBag({
              currency_id:
                props.item.currency_id ?? props.item.display_currency_id,
              sui: props.item.sui ?? props.item.SUI ?? props.item.reference_id,
            })
          }}
        >
          <CiShoppingCart className="text-sky-500 bg-white size-6 rounded-full border-solid border-2 w-8 h-8 z-10" />
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            handleClick()
          }}
        >
          <FaRegShareFromSquare className="text-sky-500 bg-white size-6 p-1 rounded-full border-solid border-2 w-8 h-8 z-10" />
        </Button>
      </HoverActions>
      <Modal
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="max-w-[700px] m-auto mt-28 z-10"
      >
        <div className="modal-content py-8 px-10">
          <Modal.Header className="text-xl font-bold my-6 p-0 flex items-center">
            Share Profile
          </Modal.Header>
          <div className="flex">
            <input
              type="text"
              value={shortLink}
              readOnly
              ref={profileUrlRef}
              className="w-full relative"
            />
            <button onClick={copyProfileUrl}>
              <BsCopy className="size-5 ml-3" />
            </button>
          </div>
          <div className="flex items-center justify-center mt-10">
            <Link
              to={`http://www.facebook.com/sharer/sharer.php?u=${shortLink}&t=${props.item.sku_title}`}
              target="_blank"
            >
              <img src={facebook} className="w-10 " alt="" />
            </Link>
            <Link to={`https://wa.me/?text=${shortLink}`} target="_blank">
              <img src={whatsapp} className="w-10 mx-8" alt="" />
            </Link>
            <Link
              to={`http://www.twitter.com/intent/tweet?url=${shortLink}&text=${props.item.sku_title}`}
              target="_blank"
            >
              <img src={twitter} className="w-10" alt="" />
            </Link>
          </div>
        </div>
      </Modal>
    </ThumbContainer>
  )
}
export default ProductThumb
