import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IGetAddressListData,
  IGetAddressList,
} from "../../../../@types/credits"

const initialState: IGetAddressListData = {
  suggestions: {
    status: 0,
    user_address: [],
  },
}

const getAddressList = createSlice({
  name: "AddressList",
  initialState,
  reducers: {
    setAddressList: (state, action: PayloadAction<IGetAddressList>) => {
      state.suggestions = action.payload
    },
  },
})

export const { setAddressList } = getAddressList.actions

export default getAddressList.reducer
