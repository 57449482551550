import React from "react"
import axios from "axios"
import { API, BasePayloadLogin, getToken } from "../config"

const useAuthApi = () => {
  const API_TOKEN = getToken()
  const signupApi = async (AuthData) => {
    const {
      phone,
      email,
      password,
      gender,
      name,
      country_code,
      referral_code,
      role,
      phone_code,
    } = AuthData.request
    const payload = {
      phone:
        country_code + "-" + phone.substring(phone_code.substring(1).length),
      email,
      password,
      gender,
      name,
      country_code,
      referral_code,
      role,
      api_token: localStorage.token,
    }

    const request = await axios.post(API + "/user/signup", payload)
    if (request.data.status == 200 || request.data.status == 201) {
      //@ts-ignore
      localStorage.setItem("user", request.data.user_uuid)
      //@ts-ignore
      localStorage.setItem("token", request.data.api_token)

      return request.data
    } else {
      return request.data
    }
  }

  const check = async ({ username }) => {
    const request = await axios.post(API + "/user/check_user", { username })
    return request.data
  }

  const loginApi = async (AuthData) => {
    const { otp, password, email } = AuthData.request
    const params = { otp, password, username: email }
    const payload = BasePayloadLogin(params)

    const request = await axios.post(API + "/user/login", payload)

    if (request.data.status == 200 || request.data.status == 201) {
      //@ts-ignore
      localStorage.setItem("user", request.data.user_uid)
      //@ts-ignore
      localStorage.setItem("token", request.data.api_token)
      //@ts-ignore
      localStorage.setItem("userData", JSON.stringify(request.data))

      return request.data
    }
    return request.data
  }

  const logoutApi = async () => {
    const request = await axios.post(API + "/user/logout", {
      api_token: getToken(),
    })
    return request.data
  }

  const sendOTP = async ({ user_uuid }) => {
    const request = await axios.post(API + "/user/send_otp", { user_uuid })
    if (request.data.status == 200 || request.data.status == 201) {
      return request.data
    } else {
      return request.data
    }
  }

  const confirmOTP = async ({ user_uuid, otp }) => {
    const request = await axios.post(API + "/user/confirm_otp", {
      user_uuid,
      otp,
    })
    if (request.data.status == 200 || request.data.status == 201) {
      return request.data
    } else {
      return request.data
    }
  }

  const forgotPassword = async ({ email, phone }) => {
    const request = await axios.post(API + "/user/confirm_otp", {
      email,
      phone,
    })
    if (request.data.status == 200 || request.data.status == 201) {
      return request.data
    } else {
      return request.data
    }
  }

  const forgotSetPassword = async ({ uuid, new_phone }) => {
    const request = await axios.post(API + "/user/change_password_confirm", {
      uuid,
      new_phone,
    })
    if (request.data.status == 200 || request.data.status == 201) {
      return request.data
    } else {
      return request.data
    }
  }

  const loginOTP = async ({ email, phone }) => {
    const payload = {
      email: email || "",
      phone: phone || "",
    }
    const request = await axios.post(API + "/user/otp_login", payload)
    if (request.data.status == 200 || request.data.status == 201) {
      console.log(request.data, "checking loginOTP")
      return request.data
    } else {
      return request.data
    }
  }

  return {
    signupApi,
    loginApi,
    logoutApi,
    sendOTP,
    confirmOTP,
    forgotPassword,
    check,
    loginOTP,
    forgotSetPassword,
  }
}

export default useAuthApi
