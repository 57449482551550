import React from "react"
import { styled } from "styled-components"
import tw from "twin.macro"

interface TextProp {
  text: String
  className?: string
}

const Container = styled.div`
  ${tw`
        relative 
        flex 
        py-1
        items-center
        m-10
    `}
`
const Hr = styled.div`
  ${tw`
        flex-grow 
        border-t 
        border-gray-500
        my-8
    `}
`
const Text = styled.div`
  ${tw`
        flex-shrink 
        mx-4 
        text-gray-500
        text-xl
    `}
`

const TextHr = (props: TextProp) => {
  const { text, className } = props
  return (
    <Container>
      <Hr className={className} />
      <Text className={className}>{text}</Text>
      <Hr className={className} />
    </Container>
  )
}
export default TextHr
