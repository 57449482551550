import React, { useDebugValue } from "react"
import axios from "axios"
import { API, getToken } from "../config"
import { useAppDispatch } from "../../utils/hooks"
import { setReferralLink } from "../../utils/slices/Referral/GetReferral"

const useReferral = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  function getReferral() {
    const referral = axios
      .get(`${API}/utilities/create_referral_url?api_token=${API_TOKEN}`)
      .then((res) => {
        console.log(res)
        dispatch(setReferralLink(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return { getReferral }
}
export default useReferral
