import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IColorsListData, IColorsList } from "../../../../@types/filters"

const initialState: IColorsListData = {
  colorsListData: {
    colors_data: [],
    status: 0,
  },
}

const colorsListListSlice = createSlice({
  name: "colorsData",
  initialState,
  reducers: {
    setcolorsList: (state, action: PayloadAction<IColorsList>) => {
      state.colorsListData = action.payload
    },
  },
})

export const { setcolorsList } = colorsListListSlice.actions

export default colorsListListSlice.reducer
