import React, { useRef } from "react"
import reelimage2 from "../../../assets/reelimage2.png"
import reelimage1 from "../../../assets/reelimage1.png"
import video1 from "../../../assets/video1.mp4"
import { GoUnmute } from "react-icons/go"
import { FaArrowCircleDown, FaArrowCircleUp, FaShareAlt } from "react-icons/fa"
import { SlClose } from "react-icons/sl"
import { CiVolumeMute } from "react-icons/ci"
import styled from "styled-components"
import tw from "twin.macro"
import { useState } from "react"
import Main from "../../layouts/Main"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useSocialVideo } from "./SocialVideo.hook"
import VideoPlayer from "react-player"
import useTalkToStore from "../../api/hooks/useTalkToStore"
import { Modal } from "flowbite-react"
import useGenerateDynamicLink from "../../api/hooks/useGenerateDynamicLink"
import { BsCopy } from "react-icons/bs"
import facebook from "../../../assets/facebook.png"
import whatsapp from "../../../assets/whatsapp.png"
import twitter from "../../../assets/twitter.png"
import { ShortMainLink } from "../../api/config"
const Container = styled.div`
  ${tw` 
  flex 
  justify-center  
  items-center 

`}
`
const TopImage = styled.div`
  ${tw` 
  absolute 
  top-11
  left-8 
  clear-right 
  flex
`}
`

const ImageContent = styled.div`
  ${tw` 
  text-white 
  text-lg 
  text-center 
  mt-1

`}
`
const ContainerBottom = styled.div`
  ${tw` 
  relative
`}
`
const BottomImage = styled.div`
  ${tw` 
  // absolute 
  // bottom-24 
  // left-48
 w-[70px] h-[70px] rounded-full overflow-hidden bg-cover bg-top mr-2 shadow-md shadow-gray-700 border-2 border-white
`}
`
const Button = styled.div`
  ${tw` 
  rounded-xl 
  pt-1 
  pb-1
  bg-white
  text-center
  w-full
  shadow-md shadow-gray-700 border-2 border-white
`}
`
const BottomContent = styled.div`
  ${tw` 
  absolute 
  bottom-12
  ml-4
  flex 
  gap-5
`}
`
const DivStyle = styled.div`
  ${tw` 
  w-[60px] h-[60px] rounded-full overflow-hidden bg-cover bg-center mr-2 
`}
`
const InsideDiv = styled.div`
  ${tw` 
  flex flex-col 
  relative 
  min-h-2.5 
  
`}
`
const DivSide = styled.div`
  ${tw` 
  absolute left-0 right-0 h-[100px] bg-gradient-to-t from-black to-transparent bottom-0
`}
`
const DivContent = styled.button`
  ${tw` 
  absolute left-0 right-0 h-[100px] bg-gradient-to-b from-black to-transparent top-0
`}
`
const DivContent2 = styled.button`
  ${tw` 
 mt-8 mb-8 relative bg-black
`}
`
const Button1 = styled.button`
  ${tw` 
  bg-none text-white text-2xl
`}
`
const Button2 = styled.button`
  ${tw` 
bg-none text-white text-xl
`}
`
const AnotherDiv = styled.div`
  ${tw` 
  absolute max-w-[80px] justify-center items-center bottom-[15%] right-[10px] flex flex-col
`}
`
const VideoChangeBtn = styled.div`
  ${tw`absolute bottom-0 md:left-0 left-24 right-0 transform -translate-y-40 -translate-x-20 text-center cursor-pointer`}
`
const VideoChangeBtn2 = styled.div`
  ${tw`absolute bottom-0 md:left-0 left-24 right-0 transform -translate-y-20 -translate-x-20 text-center cursor-pointer`}
`
const SocialVideo = () => {
  let { reference } = useParams<"reference">()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }
  const { setVideo, currentVideo, prevVideo, nextVideo, videoRef } =
    useSocialVideo(reference)
  console.log(currentVideo, "currentVideo")
  const generateLink = useGenerateDynamicLink()
  const [openModal, setOpenModal] = useState(false)
  const [shortLink, setShortLink] = useState<string | null>(null)
  const handleClick = async () => {
    let userDataDetail = JSON.parse(localStorage.getItem("userData"))
    const longLink = `${ShortMainLink}/brodcast?id=${reference}&sender_id=${userDataDetail?.user_uid}&region="IN"&brodcasttype="video`

    const responseShortLink = await generateLink(longLink)
    setShortLink(responseShortLink)
    setOpenModal(true)
  }
  const profileUrlRef = useRef(null)
  const copyProfileUrl = () => {
    if (profileUrlRef.current) {
      profileUrlRef.current.select()
      navigator.clipboard.writeText(profileUrlRef.current.value)
    }
  }
  const { addChannel } = useTalkToStore()
  const navigate = useNavigate()
  return (
    <Main>
      <Container>
        <InsideDiv>
          <TopImage>
            <Link
              to={
                //@ts-ignore
                "/store/" + currentVideo.store_uuid
              }
              className="z-10 w-full  flex flex-row"
            >
              <DivStyle
                style={
                  //@ts-ignore
                  { backgroundImage: "url(" + currentVideo?.store_logo + ")" }
                }
              ></DivStyle>

              <ImageContent>
                {
                  //@ts-ignore
                  currentVideo?.store_name
                }{" "}
                <br />
                <span className=" text-lg ">
                  {
                    //@ts-ignore
                    currentVideo?.city
                  }
                </span>
              </ImageContent>
            </Link>
          </TopImage>

          <DivContent2>
            <DivContent></DivContent>
            <VideoPlayer
              url={
                //@ts-ignore
                currentVideo.streaming_url
              }
              muted={isDropdownOpen}
              height={"700px"}
              width={"350px"}
              playing={true}
              loop={true}
            />
            <DivSide></DivSide>
          </DivContent2>

          <ContainerBottom>
            <BottomContent>
              <Button2 onClick={() => handleClick()}>
                <FaShareAlt />
              </Button2>
              <Modal
                show={openModal}
                onClose={() => setOpenModal(false)}
                className="max-w-[700px] m-auto mt-44 z-10 "
              >
                <div className="modal-content py-8 px-10">
                  <Modal.Header className="text-xl font-bold my-6 p-0 flex items-center">
                    Share Video
                  </Modal.Header>
                  <div className="flex">
                    <input
                      type="text"
                      value={shortLink}
                      readOnly
                      className="w-full relative"
                    />
                    <button onClick={copyProfileUrl}>
                      <BsCopy className="size-5 ml-3" />
                    </button>
                  </div>
                  <div className="flex items-center justify-center mt-10">
                    <Link
                      to={`http://www.facebook.com/sharer/sharer.php?u=${shortLink}&t=${currentVideo?.store_name}`}
                      target="_blank"
                    >
                      <img src={facebook} className="w-10 " alt="" />
                    </Link>
                    <Link
                      to={`https://wa.me/?text=${shortLink}`}
                      target="_blank"
                    >
                      <img src={whatsapp} className="w-10 mx-8" alt="" />
                    </Link>
                    <Link
                      to={`http://www.twitter.com/intent/tweet?url=${shortLink}&text=${currentVideo?.store_name}`}
                      target="_blank"
                    >
                      <img src={twitter} className="w-10" alt="" />
                    </Link>
                  </div>
                </div>
              </Modal>
              <button onClick={toggleDropdown}>
                {isDropdownOpen ? (
                  <span>
                    <CiVolumeMute className="bg-none text-white size-7" />
                  </span>
                ) : (
                  <span>
                    <GoUnmute className="bg-none text-white size-7" />
                  </span>
                )}
              </button>

              <Link
                to="/chats"
                className="absolute bottom-[5px] w-[150px] "
                style={{
                  right: "-200px",
                }}
              >
                {" "}
                <Button>Talk to Store</Button>
              </Link>
            </BottomContent>
          </ContainerBottom>
          <AnotherDiv>
            {//@ts-ignore
            currentVideo?.sui_details.map((item, index) => {
              return (
                <Link
                  to={"/product-details/" + item.SUI}
                  key={"prod-" + index}
                  className="m-2 "
                >
                  <BottomImage
                    style={
                      //@ts-ignore
                      { backgroundImage: "url(" + item?.product_url + ")" }
                    }
                  ></BottomImage>
                </Link>
              )
            })}
          </AnotherDiv>
          {currentVideo?.sui_details[0] && (
            <VideoChangeBtn onClick={prevVideo}>
              <FaArrowCircleUp className="size-10" />
            </VideoChangeBtn>
          )}

          <VideoChangeBtn2 onClick={nextVideo}>
            <FaArrowCircleDown className="size-10" />
          </VideoChangeBtn2>
        </InsideDiv>
      </Container>
    </Main>
  )
}
export default SocialVideo
