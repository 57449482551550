import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IStoreCategoriesData,
  IStoreCategories,
} from "../../../../@types/filters"

const initialState: IStoreCategoriesData = {
  storeCategoriesData: {
    sizes_data: [],
    status: 0,
  },
}

const storeCategoriesSlice = createSlice({
  name: "storeCategories",
  initialState,
  reducers: {
    setsizeList: (state, action: PayloadAction<IStoreCategories>) => {
      state.storeCategoriesData = action.payload
    },
  },
})

export const { setsizeList } = storeCategoriesSlice.actions

export default storeCategoriesSlice.reducer
