import React from "react"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IGetPopupDetailsData,
  IGetPopupDetails,
} from "../../../../@types/popup"

const initialState: IGetPopupDetailsData = {
  popup: {
    data: {
      popup_data: {
        category_id: 0,
        category_name: "",
        description: "",
        discount_max: 0,
        discount_min: 0,
        epoch_time: 0,
        expiry_date: "",
        fashion_market_id: [],
        gender: "",
        gender_id: 0,
        main_image: "",
        main_image_url: "",
        popup_uuid: "",
        segment_id: 0,
        segment_name: "",
        start_date: "",
        status: "",
        store_id: 0,
        store_name: "",
        sui_1: "",
        sui_10: "",
        sui_2: "",
        sui_3: "",
        sui_4: "",
        sui_5: "",
        sui_6: "",
        sui_7: "",
        sui_8: "",
        sui_9: "",
        system_name: "",
        tag_list: [],
        visible_markets: 0,
      },
      status: 0,
      store_details: {
        base_country: "",
        city: "",
        store_logo: "",
        store_logo_thumb: "",
        store_name: "",
        store_owner: 0,
        store_username: "",
        store_uuid: "",
      },
      sui_list: [],
    },
  },
}

const getPopupDetailsSlice = createSlice({
  name: "popupDetails",
  initialState,
  reducers: {
    setPopupDetails: (state, action: PayloadAction<IGetPopupDetails>) => {
      state.popup = action.payload
    },
  },
})

export const { setPopupDetails } = getPopupDetailsSlice.actions

export default getPopupDetailsSlice.reducer
