import { Link } from "react-router-dom"
import styled from "styled-components"
import tw from "twin.macro"
import { FaAngleRight } from "react-icons/fa6"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { BlockHeading as Heading } from "../Globals/BlockHeading"

const Header = styled.div`
  ${tw`            
    w-full 
    block
    float-left
`}
`
const ViewAll = styled(Link)`
  ${tw`
    float-right
    flex
    justify-center
`}
`
const NextIcon = styled(FaAngleRight)`
  ${tw`
    inline
    w-16
`}
  margin-top:3px;
`

const BlockHeader = ({ children, heading, linkText, link, ...props }) => {
  return (
    <Header>
      <Heading>{heading}</Heading>
      <ViewAll to={link}>
        {linkText} <NextIcon />
      </ViewAll>
    </Header>
  )
}

export default BlockHeader
