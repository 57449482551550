import axios from "axios"
import { API, getToken } from "../config"

const useTalkToStore = () => {
  const addChannel = async ({ store_uuid, shopper_username_v1 }) => {
    let payload = {
      api_token: getToken(),
      store_uuid,
      shopper_username_v1,
    }
    const response = await axios.post(
      `${API}/chat/create_talk_to_store_channel`,
      payload
    )
    return response.data
  }

  return { addChannel }
}
export default useTalkToStore
