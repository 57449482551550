import React from "react"
import axios from "axios"
import { API, getToken } from "../config"
import { useAppDispatch } from "../../utils/hooks"
import { setStorelistMain } from "../../utils/slices/Stores/getStore"
import { setStoreList } from "../../utils/slices/Stores/getStoreList"
import { setStoreSellerMain } from "../../utils/slices/Stores/getStoreSeller"

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers.common["Content-Type"] = "application/json"

const useStore = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  function getStore(store_uuid: string) {
    dispatch(
      setStorelistMain({
        created_on: "",
        debug: 0,
        gender_list: { Men: [], Unisex: [], Women: [] },
        is_following: false,
        is_store_connected: false,
        market_app_version: { android: "", ios: "" },
        num_followers: 0,
        num_following: 0,
        price_range: [],
        status: 0,
        store_address: {
          address_1: "",
          address_2: "",
          address_id: 0,
          city: "",
          country: "",
          country_code: "",
          name: "",
          phone: "",
          pincode: "",
          state: "",
          type: "",
          warehouse_id: "",
        },
        store_alert_notice: 0,
        store_chat_url: "",
        store_city: "",
        store_fashion_market_id: 0,
        store_logo: "",
        store_logo_thumb: "",
        store_name: "",
        store_owner: 0,
        store_sellers: [],
        store_status: "",
        store_title: "",
        store_user_uuid: "",
        store_username: "",
        store_uuid: "",
        tags_list: [],
        video_data: [],
      })
    )
    const store = axios
      .get(
        `${API}/store/get_store?api_token=${API_TOKEN}&store_uuid=${store_uuid}`
      )
      .then((res) => {
        console.log(res)
        dispatch(setStorelistMain(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getStoreSeller(store_uuid) {
    const storeSeller = axios
      .get(
        `${API}/store/get_store_sellers?api_token=${API_TOKEN}&store_uuid=${store_uuid}`
      )
      .then((res) => {
        console.log(res)
        dispatch(setStoreSellerMain(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getStoreList() {
    const storeList = axios
      .get(`${API}/store/get_store_list?api_token=${API_TOKEN}`)
      .then((res) => {
        console.log(res)
        dispatch(setStoreList(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async function followStore({ uuid }) {
    const resp = await axios.post(`${API}/follow/follow_user`, {
      api_token: localStorage.getItem("token"),
      following_uuid: uuid,
    })
    return resp.data
  }

  async function unFollowStore({ uuid }) {
    const resp = await axios.post(`${API}/follow/unfollow_user`, {
      api_token: localStorage.getItem("token"),
      user_following_uuid: uuid,
    })
    return resp.data
  }

  return { getStore, getStoreList, getStoreSeller, followStore, unFollowStore }
}
export default useStore
