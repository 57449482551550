import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IGetCaption, IGetProductCaption } from "../../../../@types/caption"

// Assuming IData represents an array of user data
const initialState: IGetCaption = {
  imgCaption: {
    caption: "",
    status: 0, // Adjust the initial status value as needed
  },
}

const getProfileCaption = createSlice({
  name: "profileCaption",
  initialState,
  reducers: {
    setProfileCaption: (state, action: PayloadAction<IGetProductCaption>) => {
      state.imgCaption = action.payload
    },
  },
})

export const { setProfileCaption } = getProfileCaption.actions

export default getProfileCaption.reducer
