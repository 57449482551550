import React from "react"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IGetStoreGiftListData,
  IGetStoreGiftList,
} from "../../../../@types/gifts"

const initialState: IGetStoreGiftListData = {
  suggestions: {
    count: 0,
    gift_list: [],
    page: "",
    page_limit: 0,
    status: 0,
    total_count: 0,
  },
}

const getStoreGiftList = createSlice({
  name: "storeGiftList",
  initialState,
  reducers: {
    setStoreGiftList: (state, action: PayloadAction<IGetStoreGiftList>) => {
      state.suggestions = action.payload
    },
  },
})

export const { setStoreGiftList } = getStoreGiftList.actions

export default getStoreGiftList.reducer
