import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICartDetailsData, ICartDetails } from "../../../../@types/cart"

const initialState: ICartDetailsData = {
  cartdetail: {
    cart_data: {},
    cart_items: [],
    express_checkout_banner: "",
    shopping_cart_data: {
      cart_id: 0,
      coupon_code: "",
      coupon_discount: 0,
      coupon_expired_error: 0,
      coupon_id: 0,
      currency_code: "",
      currency_id: 0,
      currency_symbol: "",
      for_self: 0,
      gift_list_id: 0,
      gift_message: "",
      gift_recipient_user_id: 0,
      gift_recipient_uuid: "",
      order_num: "",
      total_cart_discounted_value: 0,
      total_cart_value: 0,
    },
    status: 0,
  },
}

const cartDetailsSlice = createSlice({
  name: "cartDetail",
  initialState,
  reducers: {
    setcartDetails: (state, action: PayloadAction<ICartDetails>) => {
      state.cartdetail = action.payload
    },
  },
})

export const { setcartDetails } = cartDetailsSlice.actions

export default cartDetailsSlice.reducer
