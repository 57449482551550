import styled from "styled-components"
import tw from "twin.macro"
import { Container } from "../Globals/Container"
import { SCREENS } from "../responsive"

const Slide = styled.div<{ img: string }>`
  ${tw`
    bg-cover 
    bg-top
    w-full
    bg-no-repeat
`}
  height:90vh;
  background-image: url(${({ img }) => img});
  @media screen and (max-width: ${SCREENS["2xl"]}) {
    height: 90vh;
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    height: 70vh;
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        bg-cover 
    `}
    height:40vh;
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    height: 30vh;
    ${tw`
        bg-cover 
    `}
  }
`
const TextColum = styled.div<{ textAlign: string }>`
  ${tw`
    col-span-1
    justify-center
    items-end
    flex
    flex-col
`}
  ${({ textAlign }) =>
    textAlign !== "left" &&
    tw`
    col-start-1
    `}
`
const SlideContain = styled.div`
  ${tw`
    grid
    grid-cols-2
    gap-4
    h-full
`}
`

const BannerSlide = ({ children, textAlign, img, alt, ...rest }) => {
  return (
    <Slide key={rest.key ?? 1} img={img}>
      <Container className="h-full">
        <SlideContain>
          <TextColum textAlign={textAlign}>{children}</TextColum>
        </SlideContain>
      </Container>
    </Slide>
  )
}

export default BannerSlide
