import Banner from "../../Components/Banner"
import BannerSlider from "../../Components/BannerSlider"
import BannerSlide from "../../Components/BannerSlider/BannerSlides"
import BlockHeader from "../../Components/Globals/BlockHeader"
import { BlockHeading } from "../../Components/Globals/BlockHeading"
import { Container } from "../../Components/Globals/Container"
import LiveSocial from "../../Components/LiveSocial"
import ThumbSlide from "../../Components/LiveSocial/ThumbSlides"
import SeashelEdits from "../../Components/SeashelEdits"
import EditsThumb from "../../Components/SeashelEdits/ThumbSlides"
import { Marginer } from "../../Components/marginer"
import Main from "../../layouts/Main"
import { useHomeData } from "./Home.hooks"
import { Link, useNavigate } from "react-router-dom"
import { FaAngleRight } from "react-icons/fa6"
import styled from "styled-components"
import tw from "twin.macro"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import {
  Category,
  Heading,
  HeadingBold,
  Hr,
  Product,
  ProductList,
  ShopButton,
  SmallHead,
} from "./Styled.Comps"
import { useEffect, useState } from "react"
import { useGetCurrency } from "../../Components/Header/Header.Hooks"

const Home = () => {
  const { HomeData } = useHomeData()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])
  return (
    <Main>
      <Skeleton
        height={100}
        width="100%"
        duration={2}
        count={loading ? 1 : 0}
      />
      {!loading && (
        <BannerSlider>
          {HomeData.banner?.map((banner, i) => {
            return (
              <BannerSlide
                key={i}
                textAlign={"left"}
                img={banner.banner_url}
                alt={banner.banner_title}
              >
                <Category>{banner.tag_name}</Category>
                <Marginer margin={30} direction="vertical" />
                <HeadingBold>{banner.banner_title}</HeadingBold>
                <Marginer margin={30} direction="vertical" />
                <Heading>{banner.banner_description}</Heading>
                <Marginer margin={30} direction="vertical" />

                <ShopButton
                  text={"Shop Now"}
                  theme={"outlined"}
                  color="white"
                  click={() => {
                    navigate("/seashel-edits?tagId=" + banner.tag_id)
                  }}
                />
              </BannerSlide>
            )
          })}
        </BannerSlider>
      )}
      <Marginer margin={60} direction="vertical" />

      <Container>
        <LiveSocial
          link={"/live/"}
          linkText={"View All"}
          heading={"Live- Social"}
        >
          {HomeData &&
            HomeData.videos?.map((video, index) => {
              return (
                <ThumbSlide
                  key={index}
                  title={video.store_name}
                  subtitle={video.video_description}
                  image={video.video_thumbnail}
                  link={`/social/${video.reference_id}`}
                  children={undefined}
                />
              )
            })}
        </LiveSocial>

        <Marginer margin={100} direction="vertical" />
      </Container>
      <Hr />
      <Container>
        <BlockHeading>{"The Wedding Store"}</BlockHeading>
      </Container>
      <Marginer margin={60} direction="vertical" />

      <Link to="/store/0cb52d907ae740e3bc478c0efbdc5059">
        {" "}
        <Banner img={HomeData.wedding_store_banner} children={undefined} />
      </Link>
      <Marginer margin={40} direction="vertical" />
      <Hr />
      <Container>
        <LiveSocial
          link={"/deals"}
          linkText={"View All"}
          heading={"Latest Deals"}
        >
          {HomeData &&
            HomeData.popup_data?.map((item, index) => {
              return (
                <ThumbSlide
                  key={index}
                  title={item.store_name}
                  subtitle={item.description}
                  image={item.image_url_thumbnail}
                  expiry={item.expiry_date}
                  link={"/deals-details/" + item.popup_uuid}
                  children={undefined}
                />
              )
            })}
        </LiveSocial>

        <Marginer margin={100} direction="vertical" />
      </Container>
      <Hr />
      <Container>
        {HomeData && HomeData.sku_data && HomeData.sku_data.length > 0 && (
          <BlockHeader
            link={`/seashel-edits?tagId=${HomeData.sku_data[0]?.tag_id}`}
            linkText={"View All"}
            heading={"Latest Arrivals"}
            children={undefined}
          />
        )}

        <Marginer margin={40} direction="vertical" />
        <Skeleton
          height={30}
          width="100%"
          duration={2}
          count={loading ? 1 : 0}
        />
        {!loading && (
          <ProductList>
            {HomeData &&
              HomeData.sku_data?.map((data, index) => {
                return (
                  <Product
                    link={`/product-details/${data.sui}`}
                    image={data.image_url}
                    name={data.product_title}
                    price={data.display_total_price}
                    salePrice={data.display_final_price}
                    key={index}
                    item={data}
                    currency_code={data.display_currency_code}
                  />
                )
              })}
          </ProductList>
        )}
      </Container>
      <Marginer margin={40} direction="vertical" />
      <Hr />
      <Container>
        <BlockHeader
          onClick={() => window.scrollTo(0, 0)}
          link={`/live/${HomeData?.billboard[0]?.store_uuid}}`}
          linkText={"View All"}
          heading={"Visit Store Digitally"}
          children={undefined}
        />
      </Container>
      <Marginer margin={60} direction="vertical" />

      <Container>
        <SeashelEdits link={""} linkText={""} heading={""}>
          {HomeData?.billboard?.map((item, index) => {
            return (
              <EditsThumb
                key={index}
                image={item.banner_url}
                link={"/store/" + item.store_uuid}
                children={undefined}
                alt={item.banner_title}
              />
            )
          })}
        </SeashelEdits>
        <Marginer margin={100} direction="vertical" />
      </Container>

      <Marginer margin={60} direction="vertical" />
      <Container>
        <ProductList>
          {HomeData.stores?.map((data, index) => {
            return (
              <ThumbSlide
                key={index}
                title={data.store_name}
                subtitle={data.caption}
                image={data.profile_image}
                link={"/store/" + data.store_uuid}
                children={undefined}
              />
            )
          })}
        </ProductList>
      </Container>
      <Marginer margin={40} direction="vertical" />
      <Hr />

      <Marginer margin={60} direction="vertical" />
    </Main>
  )
}

export default Home
