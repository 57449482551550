import React, { useEffect, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import StyledInput from "../../../Components/input"
import { Marginer } from "../../../Components/marginer"
import { useCaptionData } from "../hooks/caption.Hooks"

const Container = styled.div`
  ${tw` 
flex bg-gray-100 h-[800px] 
`}
`
const ContainerRight = styled.div`
  ${tw` 
justify-start  mt-8 ml-10 w-[700px] flex flex-col gap-3 
`}
`
const Input = styled.input`
  ${tw` 
w-auto border-none shadow-xl rounded-md
`}
`
const BottomDiv = styled.div`
  ${tw` 
mt-[550px] flex justify-center bg-sky-500 rounded-full text-white py-3
`}
`
const Button = styled.button`
  ${tw` 
p-2 text-white text-xl
`}
`
export default function Caption() {
  const { caption, setCaption, upDateCaption } = useCaptionData()

  return (
    <>
      <ContainerRight>
        <div className="">
          <StyledInput
            id={"text"}
            type="text"
            label="Write text here"
            value={caption}
            stateLess={true}
            onChange={(e) => setCaption(e.target.value)}
          />
        </div>

        <BottomDiv
          onClick={(e) => {
            upDateCaption({ caption })
          }}
        >
          Update
        </BottomDiv>
        <Marginer margin={80} direction="vertical" />
      </ContainerRight>
    </>
  )
}
