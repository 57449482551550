import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ISkuForFiltersData, ISkuForFilters } from "../../../../@types/filters"

const initialState: ISkuForFiltersData = {
  skuFilterData: {
    count: 0,
    current_page: "0",
    price_band: [],
    segment_data: [],
    status: 0,
    sui_data: [],
    total_count: 0,
  },
}

const skuForFilterSlice = createSlice({
  name: "skuforfilter",
  initialState,
  reducers: {
    setskuForFilter: (state, action: PayloadAction<ISkuForFilters>) => {
      state.skuFilterData = action.payload
    },
  },
})

export const { setskuForFilter } = skuForFilterSlice.actions

export default skuForFilterSlice.reducer
