import axios from "axios"
import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { API, default_gender, getToken, paramsTypes } from "../config"
import { setSuggestions } from "../../utils/slices/Sku/suggestSkuSlice"
import { setFollowFollowers } from "../../utils/slices/Follow/getFollowFollowersSlice"

const useFollow = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  function getFollowFollower() {
    const follow = axios
      .get(
        `${API}/follow/get_follow_followers?api_token=${API_TOKEN}&get_type=all&page=0`
      )
      .then((res) => {
        dispatch(setFollowFollowers(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return { getFollowFollower }
}
export default useFollow
