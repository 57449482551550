import React, { useEffect, useState } from "react"
import truckImage from "../../../assets/truckImage.png"
import { IoIosArrowForward } from "react-icons/io"
import { Link } from "react-router-dom"
import Main from "../../layouts/Main"
import { Container } from "../../Components/Globals/Container"
import { Grid3 } from "../../Components/Grid"
import { Marginer } from "../../Components/marginer"
import { useOrderdetail } from "./Orders.Hooks"
import Skeleton from "react-loading-skeleton"

export default function OrderScreen() {
  const { OrderCards } = useOrderdetail("")

  const truncateTitle = (title, maxLength) => {
    if (title.length <= maxLength) {
      return title
    }
    return `${title.slice(0, maxLength)}...`
  }
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])
  return (
    <Main>
      <Marginer margin={40} direction="vertical" />
      <Container>
        <div className="flex justify-center ">
          <div className=" w-full mt-4 mb-7 ">
            <div className="flex justify-between">
              <Link to="/orders">
                <div className="text-2xl font-semibold border-2  focus:bg-sky-400 active:text-white active:bg-sky-300 p-1 pl-3 pr-3 rounded-xl">
                  My Orders
                </div>
              </Link>
              <Link to="/myGifts">
                <button className="text-2xl font-semibold border-2 p-1 pl-3 pr-3 focus:bg-sky-400 active:text-white rounded-xl">
                  My Gifts
                </button>
              </Link>
            </div>
            <Marginer margin={40} direction="vertical" />
            <Grid3 className="overflow-scroll h-[800px]">
              {OrderCards.receipt_data.map((Detail) => {
                const formattedDate = new Date(
                  Detail.order_date
                ).toLocaleDateString("en-GB", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })
                return (
                  <Link to={`/orderDetails/${Detail.order_id}`}>
                    {loading ? (
                      <div
                        style={{
                          width: "100%",
                          height: "500px",
                          backgroundColor: "#f3f3f3",
                          position: "relative",
                        }}
                      >
                        <Skeleton height={100} count={1} />
                        <div
                          style={{
                            position: "absolute",
                            bottom: "0px",
                            left: "30%",
                            top: "25%",
                            color: "#000",
                            display: "flex",
                            alignItems: "center",
                          }}
                        ></div>
                      </div>
                    ) : (
                      <div className=" w-full bg-white border-solid border-2 border-grey-300 rounded-xl flex ">
                        <div className="flex flex-col justify-center p-6 sm:w-full ">
                          <div className="flex justify-start items-center gap-4">
                            <div className="bg-gray-100 w-10 flex justify-center items-center h-10 rounded-full">
                              <img src={truckImage} alt="" className="inline" />
                            </div>

                            <div className="flex flex-col text-xs">
                              <div className="font-semibold">
                                {Detail.order_status}
                              </div>
                              <div>{formattedDate}</div>
                            </div>
                          </div>

                          <div className="flex border-solid border-2 border-grey-500 lg:justify-between md:justify-evenly items-center   rounded-md  p-5 gap-5 mt-4 flex-wrap">
                            <div>
                              <img
                                src={Detail.item_list[0].product_url}
                                className="md:mr-5 h-[50px] rounded-md"
                                alt=""
                              />
                            </div>

                            <div className="flex-grow text-xs w-1/2">
                              <h2 className="font-semibold">
                                {Detail.item_list[0].store_username}
                              </h2>
                              <h2 className=" font-medium ">
                                {truncateTitle(
                                  Detail.item_list[0].product_title,
                                  22
                                )}
                              </h2>
                              <h2 className="text-gray-500">
                                Size:
                                <span className="ml-1">
                                  {Detail.item_list[0].size_name}{" "}
                                </span>
                              </h2>
                            </div>
                            <div className="md:flex md:flex-wrap inset-y-0 right-0 ">
                              <IoIosArrowForward className="text-gray-400 size-6 " />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Link>
                )
              })}
            </Grid3>
          </div>
        </div>
        <Marginer margin={40} direction="vertical" />
      </Container>
    </Main>
  )
}
