import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../../Components/responsive"
import { useFollowingData } from "../../Pages/Following/Following.hooks"

interface DynamicCardProps {
  imageSrc: string
  userName: string
  brandLevel: string
  user_uid?: string
  link?: string
  onRemove?: () => void
  onBlock?: () => void
  onAccept?: () => void
  onDecline?: () => void
  onUnFollow?: () => void
}

const Container = styled.div`
  ${tw`
   flex
   justify-center 
   items-center
   `}
`
const MainContainer = styled.div`
  ${tw`
  rounded-2xl 
  p-5
  w-[450px] 
  text-black 
  h-[200px] 
  bg-white 
  border-2
  
  `}
`
const DetailsContainer = styled.div`
  ${tw`
  flex 
  justify-start 
  items-center
  cursor-pointer
  `}
`
const ImageHolder = styled.div`
  ${tw`
    w-20 
    h-20 
    rounded-full
    overflow-hidden
  `}
`
const UserPhoto = styled.img`
  ${tw`
w-full h-full object-cover
`}
`
const UserDetails = styled.div`
  ${tw`
  flex 
  flex-col 
  relative 
  mx-5
  `}
`
const UserName = styled.span`
  ${tw`
  font-bold
  text-base
 `}
`
const BrandLevel = styled.span`
  ${tw` 
 text-sm 
 text-[#9f9fa0]`}
`

const ButtonDetails = styled.div`
  ${tw`
  flex 
  gap-2
  flex
  justify-center
  `}
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
    gap-1
    `}
  }
`

const Button = styled.button`
  ${tw`
 bg-sky-500
 w-[110px]
 py-2
 px-3
 rounded-full
 text-white
 mt-6
 transition-all
 font-bold
 `}
`

const RemoveButton = styled(Button)`
  ${tw`
        bg-[#208DCE]
        border-2
        border-transparent
    `}
  &:hover {
    ${tw`
            bg-transparent
            text-[#208DCE]
            border-[#208DCE]
        `}
  }
`

export const DynamicCard = (props: DynamicCardProps) => {
  const { follow } = useFollowingData()
  const {
    imageSrc,
    userName,
    brandLevel,
    user_uid,
    onRemove,
    onBlock,
    onAccept,
    onDecline,
    onUnFollow,
    link,
  } = props

  return (
    <Container className="!items-start">
      <MainContainer>
        <Link to={`${link}`}>
          <DetailsContainer>
            <ImageHolder>
              <UserPhoto src={imageSrc} alt={`${userName}'s Photo`} />
            </ImageHolder>
            <UserDetails>
              <UserName>{userName}</UserName>
              <BrandLevel>{brandLevel}</BrandLevel>
            </UserDetails>
          </DetailsContainer>
        </Link>
        <ButtonDetails>
          {onRemove && <RemoveButton onClick={onRemove}>Remove </RemoveButton>}
          {onUnFollow && (
            <RemoveButton onClick={onUnFollow}> UnFollow </RemoveButton>
          )}
          {onBlock && <RemoveButton onClick={onBlock}>Block</RemoveButton>}
          {onAccept && <RemoveButton onClick={onAccept}>Accept</RemoveButton>}
          {onDecline && (
            <RemoveButton onClick={onDecline}>Decline</RemoveButton>
          )}
        </ButtonDetails>
      </MainContainer>
    </Container>
  )
}
