import axios from "axios"
import { API, getToken, objectToUrlParams } from "../config"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers.common["Content-Type"] = "application/json"

export const UseLiveSocial = () => {
  const API_TOKEN = getToken()

  const getVideo = async ({ video_uuid }) => {
    const payload = { api_token: API_TOKEN, video_uuid }
    const params = objectToUrlParams(payload)
    const newCart = await axios.get(
      `${API}/content/videos/get_video_details?${params}`
    )

    if (newCart.data.status == 200 || newCart.data.status == 201) {
      return newCart.data
    }
    return newCart.data
  }

  return { getVideo }
}
