import { useEffect, useState } from "react"
import { useAppSelector } from "../../utils/hooks"
import { useParams } from "react-router-dom"
import usePopup from "../../api/hooks/usePopup"
import useFollow from "../../api/hooks/useFollow"

export const useRequestData = () => {
  const follow = useAppSelector((state) => state.follow.follow)
  const { getFollowFollower } = useFollow()
  useEffect(() => {
    getFollowFollower()
  }, [])
  return { follow }
}
