export const API: string = "https://development.seashels.com:7777/api"
export const ShortMainLink: string =
  "https://connect.seashels.com/api/v1.0/Link"
export const API_TOKEN: string = localStorage.getItem("token")
export const default_gender: string = "2"

export interface paramsTypes {
  segment_id?: string
  gender_id?: string
  page?: string
  price_min?: string
  price_max?: string
  category_list?: any
  color_list?: any
  size_list?: any
  currency_code?: any
  store_uuid?: string
}
export const Name = () => {
  return localStorage.getItem("name")
}
export const Token = () => {
  return localStorage.getItem("token")
}

export const BasePayloadLogin = (params) => {
  let load = {
    app_version: "6.0",
    country_code: "IN",
    device_token:
      "duXf97eTS6uHgKM3ml44nV:APA91bE4JiLFXws-3y76qfSJ-I6RkTDUBq4lzp2ITkJTMZFFiIpUR8xpvn3stOcChPhNUufm0iqi6IsqPwhsX18F06V76hrpwTtF-O4jxuxItPWSQnhtqhX1NhxrCG9BHM6WhQUM2ZYI",
    device_type: "Android",
    app_type: "Android",
    ...params,
  }
  if (
    localStorage.getItem("token") &&
    localStorage.getItem("token") != "undefined" &&
    localStorage.getItem("token") != ""
  ) {
    load["guest_api_token"] = localStorage.getItem("token")
  }
  return load
}

export const tokenPayloadLogin = (params) => {
  let load = {
    ...params,
  }
  if (
    localStorage.getItem("token") &&
    localStorage.getItem("token") != "undefined" &&
    localStorage.getItem("token") != ""
  ) {
    load["api_token"] = localStorage.getItem("token")
  }
  return load
}

export const BasePayloadPost = () => {
  let load = {
    app_version: "6.0",
    country_code: "IN",
    device_token:
      "duXf97eTS6uHgKM3ml44nV:APA91bE4JiLFXws-3y76qfSJ-I6RkTDUBq4lzp2ITkJTMZFFiIpUR8xpvn3stOcChPhNUufm0iqi6IsqPwhsX18F06V76hrpwTtF-O4jxuxItPWSQnhtqhX1NhxrCG9BHM6WhQUM2ZYI",
    device_type: "Android",
    app_type: "Android",
  }
  if (
    localStorage.getItem("token") &&
    localStorage.getItem("token") != "undefined" &&
    localStorage.getItem("token") != ""
  ) {
    load["api_token"] = localStorage.getItem("token")
  }
  return load
}

export const getToken = () => {
  return localStorage.getItem("token")
}

export const removeBlankAttributes = (obj) => {
  const result = {}
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      result[key] = obj[key]
    }
  }
  return result
}

export const objectToUrlParams = (object) => {
  const params = new URLSearchParams()
  for (const [key, value] of Object.entries(object)) {
    params.append(key, value?.toString())
  }
  return params.toString()
}
