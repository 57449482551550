import styled from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../responsive"
export const Container = styled.div`
  ${tw`
        container
        mx-auto
    `}
  @media screen and (max-width: ${SCREENS["2xl"]}) {
    ${tw`
            px-14
            max-w-[1366px]
        `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    ${tw`
            px-8
        `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
            px-6
        `}
  }
`
