import { useEffect, useState } from "react"
import { useAppSelector } from "../../utils/hooks"
import useTags from "../../api/hooks/useTags"
import { useParams } from "react-router-dom"
import UseCurrency from "../../api/hooks/useCurrency"
import { useGetCurrency } from "../../Components/Header/Header.Hooks"

export const useSeasheEditData = (tag_id: string) => {
  const tag_type = "product"
  const listTags = useAppSelector((state) => state.listTags.listTags)
  const { getListTags } = useTags()
  const { UserCurrency } = useGetCurrency()
  const store_uuid = ""
  useEffect(() => {
    getListTags({ store_uuid: store_uuid, tag_type: tag_type, tag_id: tag_id })
  }, [tag_id, UserCurrency])

  return { listTags }
}
