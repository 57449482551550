import React, { useEffect, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import StyledInput from "../../../Components/input"
import { Marginer } from "../../../Components/marginer"
import { useCaptionData } from "../hooks/caption.Hooks"
import useAccActivity from "../../../api/hooks/useAccActivity"
import { useActivityData } from "../hooks/accActivity.Hooks"

const Container = styled.div`
  ${tw` 
flex bg-gray-100 h-[800px] 
`}
`
const ContainerRight = styled.div`
  ${tw` 
justify-start mt-20 ml-10 w-full flex flex-col gap-3 overflow-x-auto
`}
`
const Input = styled.input`
  ${tw` 
w-auto border-none shadow-xl rounded-md
`}
`
const BottomDiv = styled.div`
  ${tw` 
mt-96 flex justify-center bg-sky-500 rounded-full
`}
`
const Button = styled.button`
  ${tw` 
p-2 text-white text-xl
`}
`
export default function AccActivity() {
  const { caption, setCaption, upDateCaption } = useCaptionData()
  const { getActivity, activityData, activityDebug } = useActivityData()
  console.log("getActivity", activityDebug)

  return (
    <>
      <ContainerRight>
        <div className="overflow-x-auto">
          <table className="table-auto p-2 AccActivity">
            <tr className="space-x-4 row text-left">
              <th>{"Event"}</th>
              <th>{"Transaction"}</th>
              <th>{"Current Credits"}</th>
              <th>{"Details"}</th>
            </tr>
            {activityData?.map((value, index) => (
              <tr
                key={index}
                className={`p-3 row ${index % 2 === 0 ? "bg-white" : "bg-gray-200"} text-black`}
              >
                <td className="font-semibold py-4 pl-1">{value.event}</td>
                <td className="font-semibold py-4 pl-1">
                  {value.available_points}
                </td>
                <td className="font-semibold py-4 pl-1">{value.points}</td>

                <td className="font-semibold py-4 pl-1">{value.description}</td>
              </tr>
            ))}
          </table>
        </div>
        <Marginer margin={80} direction="vertical" />
      </ContainerRight>
    </>
  )
}
