import axios from "axios"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import {
  API,
  getToken,
  objectToUrlParams,
  removeBlankAttributes,
} from "../config"
import { setcatogoryList } from "../../utils/slices/Filters/categoriesListSlice"
import { setskuForFilter } from "../../utils/slices/Filters/skuForFiltersSlice"
import { setcolorsList } from "../../utils/slices/Filters/colorsListSlice"
import { setsizeList } from "../../utils/slices/Filters/sizesListSlice"
import { setSuggestions } from "../../utils/slices/Sku/suggestSkuSlice"
import { setProducts } from "../../utils/slices/Products/ProductsSlice"

const useFilters = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  interface IFilter {
    gender_id?: string
    segment_id?: string
    categories_list?: any
    size_list?: any
    color_list?: any
    price_min?: string
    price_max?: string
    store_uuid?: string
    page?: any
    sort_order?: string
    currency_code?: string
  }

  // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  // axios.defaults.headers.common["Content-Type"] = "application/json";

  function get_categories_list(filter: IFilter) {
    // const filters = removeBlankAttributes(filter)
    const params = objectToUrlParams(filter)
    const category = axios
      .get(
        `${API}/filters/get_categories_list?api_token=${API_TOKEN}&${params}`
      )
      .then((res) => {
        // console.log(res);
        dispatch(setcatogoryList(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getMenu = async (filter: IFilter) => {
    let params = objectToUrlParams(filter)
    return axios
      .get(
        `${API}/filters/get_categories_list?api_token=${API_TOKEN}&${params}`
      )
      .then((res) => {
        return res.data
      })
  }
  function colors_list(filter: IFilter) {
    // const filters = removeBlankAttributes(filter)
    const params = objectToUrlParams(filter)
    const color = axios
      .get(`${API}/filters/get_colors_list?api_token=${API_TOKEN}&${params}`)
      .then((res) => {
        // console.log(res);
        dispatch(setcolorsList(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  function getSizeslist(filter: IFilter) {
    // const filters = removeBlankAttributes(filter)
    const params = objectToUrlParams(filter)
    const size = axios
      .get(`${API}/filters/get_sizes_list?api_token=${API_TOKEN}&${params}`)
      .then((res) => {
        // console.log(res);
        dispatch(setsizeList(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  function skuforfilter(filter: IFilter) {
    dispatch(
      setSuggestions({
        count: 0,
        current_page: "0",
        price_band: [],
        segment_data: [],
        status: 200,
        sui_data: [],
        total_count: 0,
      })
    )
    dispatch(
      setProducts({
        data: [],
        count: 0,
        current_page: 0,
        total_count: 0,
        next_page: -1,
        prev_page: 0,
      })
    )

    const params = objectToUrlParams(filter)
    const skufilter = axios
      .get(
        `${API}/filters/get_skus_for_filter?api_token=${API_TOKEN}&${params}`
      )
      .then((res) => {
        // console.log(res);
        dispatch(setSuggestions(res.data))
        dispatch(
          setProducts({
            data: [...res.data.sui_data],
            count: res.data.count,
            current_page: parseInt(res.data.current_page),
            total_count: res.data.total_count,
            next_page: res.data.next_page,
            prev_page: res.data.prev_page,
          })
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return {
    get_categories_list,
    skuforfilter,
    colors_list,
    getSizeslist,
    getMenu,
  }
}
export default useFilters
