import { useEffect, useState } from "react"
import { FaUser } from "react-icons/fa"
import styled from "styled-components"
import tw from "twin.macro"
import axios from "axios"
import { API } from "../../../api/config"
import { PopTost } from "../../../utils/common"
import { API_TOKEN } from "../../../api/config"
import useUserAccount from "../../../api/hooks/useUserAccount"

const Container = styled.div`
  ${tw` 
flex gap-4  bg-transparent w-full  h-[800px] px-10
`}
`
const ContainerRight = styled.div`
  ${tw` 
justify-start  mt-24 ml-5 w-full
`}
`
const DivContainer = styled.div`
  ${tw` 
flex flex-col  gap-4
`}
`
const Input = styled.input`
  ${tw` 
mr-2 
ring-2
ring-gray-400
bg-transparent
border-0
`}
  &:checked {
    ${tw`
border-transparent
ring-2
ring-gray-400
bg-transparent
`}
  }
  &:checked:before {
    ${tw`
bg-[#008FC4]
`}
  }
  &:focus {
    ${tw`
ring-2
ring-gray-400
`}
  }
  &:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    margin: 3px;
    border-radius: 50%;
  }
`
const Bottomdiv = styled.div`
  ${tw` 
mt-[540px] bg-sky-500 flex justify-center  p-2 rounded-full text-[16px]  text-white w-full
`}
`

export default function PrivacyFeatures() {
  const [privacy, setPrivacy] = useState()
  const [isChecked, setIsChecked] = useState(!!localStorage.getItem("user"))
  const [selectedOption, setSelectedOption] = useState(
    isChecked ? "option1" : "option2"
  )
  const handleCheckChange = (event) => {
    setIsChecked(event.target.checked)
  }
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value)
  }
  async function updatePrivacyData() {
    try {
      let result = await axios.post(`${API}/user/set_user_privacy`, {
        api_token: API_TOKEN,
        status: selectedOption,
      })
      console.log(result)
      if (result.data.status === 200) {
        PopTost({ message: "Update successfully", type: "success" })
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  return (
    <Container>
      <ContainerRight>
        <DivContainer>
          <label>
            <Input
              type="radio"
              name="privacy"
              id="option1"
              value="1"
              checked={selectedOption === "option1"}
              onChange={handleRadioChange}
            />
            Private
          </label>
          <label>
            <Input
              type="radio"
              id="option2"
              name="privacy"
              value="0"
              checked={selectedOption === "option2"}
              onChange={handleRadioChange}
            />
            Public
          </label>
        </DivContainer>

        <Bottomdiv>
          <button
            className="w-full justify-center items-center flex "
            onClick={updatePrivacyData}
          >
            <FaUser className="" />{" "}
            <span className="ml-2  text-xl">Update User Privacy </span>
          </button>
        </Bottomdiv>
      </ContainerRight>
    </Container>
  )
}
