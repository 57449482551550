import useGift from "../../api/hooks/useGift"
import { PopTost, useCommonCart } from "../../utils/common"
import { useAppSelector } from "../../utils/hooks"

export const useProductThumb = () => {
  const { addItemToCart } = useCommonCart()
  const { addFavorite, removeFavorite } = useGift()
  const SkuRelatedData = useAppSelector((state) => state.relatedSku.related)
  const addToBag = async ({ sui, currency_id }) => {
    await addItemToCart({
      color_id: "0",
      currency_id,
      gift_list_id: "",
      gift_message: "",
      quantity: 1,
      receipient_uuid: "",
      size_id: "0",
      sui,
    })
  }

  const addToFavorite = async (
    e,
    { sui, influencer_user_id, store_uuid, system_name, type }
  ) => {
    e.target.classList.add("text-[#0ea5e9]")
    e.target.classList.remove("text-[#858585]")
    console.log({ sui, influencer_user_id, store_uuid, system_name, type })
    const add = await addFavorite({
      sui,
      influencer_user_id,
      store_uuid,
      system_name,
      type,
    })
    if (add.status == 200) {
      PopTost({ message: "Added To Favorite's.", type: "success" })
    }
    if (add.status == 201) {
      PopTost({ message: add.message, type: "info" })
    }
  }

  const removeFromFavorite = async (e, { sui, type }) => {
    e.target.classList.remove("text-[#0ea5e9]")
    e.target.classList.add("text-[#858585]")
    const add = await removeFavorite({ sui, type })
    if (add.status == 200) {
      PopTost({ message: "Removed from Favorite's.", type: "success" })
    }
    if (add.status == 201) {
      PopTost({ message: add.message, type: "info" })
    }
  }

  return { addToBag, addToFavorite, removeFromFavorite, SkuRelatedData }
}
