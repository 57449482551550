import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ISizeListData, ISizeList } from "../../../../@types/filters"

const initialState: ISizeListData = {
  sizeListData: {
    sizes_data: [],
    status: 0,
  },
}

const colorsListListSlice = createSlice({
  name: "sizeslist",
  initialState,
  reducers: {
    setsizeList: (state, action: PayloadAction<ISizeList>) => {
      state.sizeListData = action.payload
    },
  },
})

export const { setsizeList } = colorsListListSlice.actions

export default colorsListListSlice.reducer
