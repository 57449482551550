import React, { useState } from "react"
import AccoountInformation from ".."
import styled from "styled-components"
import tw from "twin.macro"
import directbox from "../../../../assets/directbox.png"
import { FaArrowRight, FaBars, FaPlus } from "react-icons/fa6"
import { Marginer } from "../../../Components/marginer"
const Container = styled.div`
  ${tw` 
flex  bg-[#F7F9FA] w-full h-[800px]

`}
`
const ContainerRight = styled.div`
  ${tw` 
justify-start  
 max-w-[1200px]
 w-full
 flex-1
`}
`
const ContainerRightMain = styled.div`
  ${tw` 
flex  justify-center items-center h-[150px] bg-[#F1F1F1]   text-sm
`}
`
const DivContent = styled.div`
  ${tw` 
text-2xl font-semibold
`}
`
const ContentDiv = styled.div`
  ${tw` 
w-full flex justify-center mt-10 sm:w-full
`}
`
const ContentDiv1 = styled.div`
  ${tw` 
flex flex-col justify-center items-start gap-3 w-7/12
`}
`
const ContentDiv2 = styled.div`
  ${tw` 
flex flex-col justify-center items-start gap-3 text-sm font-medium font-bold 
`}
`
const Input = styled.input`
  ${tw` 
w-full
bg-transparent
text-xs
font-normal
h-[59px]
border
border-[#EBEBEB]
text-black
`}
`
const BottomDiv = styled.div`
  ${tw` 
flex flex-col  gap-2 w-10/12 mx-auto
`}
`
const Button1 = styled.button`
  ${tw` 
underline text-[#038DCE]
`}
`
const Button2 = styled.button`
  ${tw` 
text-lg font-semibold bg-[#038DCE] p-2 rounded-full text-white
`}
`
export default function CreditsAndRefferal() {
  const [usersChat, setUsersChat] = useState(false)

  const handleMenu = () => {
    setUsersChat(!usersChat)
  }

  return (
    <ContainerRight>
      <Marginer margin={40} direction="vertical" />
      <ContainerRightMain>
        <div>
          <img src={directbox} alt="" />
        </div>
        <div>
          <DivContent>Get Lounging Credits</DivContent>
          <div>
            Introduction your friends to Lounging <br />
            <span>and earnrewarding</span>
          </div>
        </div>
      </ContainerRightMain>
      <Marginer margin={40} direction="vertical" />
      <ContentDiv>
        <ContentDiv1>
          <ContentDiv2>Your referral link</ContentDiv2>
          <Input type="text" value="2sdidewfuigi53jrjsd7sddfj23" disabled />
        </ContentDiv1>
      </ContentDiv>
      <Marginer margin={250} direction="vertical" />
      <BottomDiv>
        <Button1>Copy Link</Button1>
        <Marginer margin={10} direction="vertical" />
        <Button2>Share with Friends</Button2>
      </BottomDiv>
      <Marginer margin={150} direction="vertical" />
    </ContainerRight>
  )
}
