import React from "react"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetAllUserFavList, getUserFavList } from "../../../../@types/gifts"

const initialState: GetAllUserFavList = {
  allUserFavList: {
    count: 0,
    page: 0,
    per_page: 0,
    status: 0,
    total_count: 0,
    user_favorite: [],
  },
}

const userFavList = createSlice({
  name: "favList",
  initialState,
  reducers: {
    setFavList: (state, action: PayloadAction<getUserFavList>) => {
      state.allUserFavList = action.payload
    },
  },
})

export const { setFavList } = userFavList.actions

export default userFavList.reducer
