import { useEffect } from "react"
import { useAppSelector } from "../../utils/hooks"
import useCredtis from "../../api/hooks/useCredits"
import useCart from "../../api/hooks/useCart"

export const useCheckout = () => {
  const { getCartDetails } = useCart()
  const { getCreditsDetails, getCalculateCredits, getAddAddressList } =
    useCredtis()

  useEffect(() => {
    getCreditsDetails()
    getCalculateCredits()
    getAddAddressList()
    getCartDetails({ order_num: localStorage.getItem("order_num") })
  }, [])

  const CalculateCredits = useAppSelector(
    (state) => state.calCulateCreadits.calCulateinfo
  )
  const AddressListData = useAppSelector(
    (state) => state.AddressList.suggestions.user_address
  )
  return { CalculateCredits, AddressListData }
}
