import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IListTagsData,
  IListTags,
  IListTagsVideo,
} from "../../../../../src/@types/tags"

const initialState: IListTagsVideo | IListTagsData = {
  listTags: {
    categories: "",
    gender: "",
    next_page: 0,
    prev_page: 0,
    status: 0,
    tag_list: [],
    tags_data: [],
    tags_item_count: 0,
    tags_items_total_count: 0,
    tags_page_count: 0,
  },
}

const listTagsSlice = createSlice({
  name: "listTags",
  initialState,
  reducers: {
    setListTags: (state, action: PayloadAction<IListTags>) => {
      state.listTags = action.payload
    },
  },
})

export const { setListTags } = listTagsSlice.actions

export default listTagsSlice.reducer
