import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Link, useParams } from "react-router-dom"
import { IoIosArrowForward } from "react-icons/io"
import { HiOutlineUser } from "react-icons/hi2"
import { BiCoinStack } from "react-icons/bi"
import { CiReceipt } from "react-icons/ci"
import { BiLogInCircle } from "react-icons/bi"
import { BsChatSquareQuote } from "react-icons/bs"
import { MdOutlinePrivacyTip } from "react-icons/md"
import { FaListUl } from "react-icons/fa6"
import Main from "../../layouts/Main"
import { Container } from "../../Components/Globals/Container"
import AccInfo from "./SubPages/AccountInfo"
import CreditsAndRefferal from "./SubPages/CreditsAndRefferals"
import Caption from "./SubPages/Caption"
import ChangeLogin from "./SubPages/ChangeLogin"
import PrivacyFeatures from "./SubPages/PrivacyFeature"
import DeleteAccount from "./SubPages/DeleteAccount"
import AccActivity from "./SubPages/AccActivity"

const ContainerLeft = styled.div`
  ${tw` 
    justify-start 
    md:w-[350px]
   md:static absolute
   
    lg:w-[300px] w-[400px] md:bg-white bg-sky-200 md:left-3 left-12 top-4
   
`}
`
const ContainerWrap = styled.div`
  ${tw` 
   md:p-2 p-4
    flex-col 
    gap-4
    flex 
    md:ml-10
    sm:ml-4
    
    relative
    justify-between
   
`}
`
const LeftSmallContainer = styled.div`
  ${tw` 
   
  mb-0
  flex items-center
 
`}
`

const ContainerMain = styled.div`
  ${tw` 
    flex bg-[#F7F9FA] w-full lg:justify-start justify-between relative
`}
`
export default function AccoountInformation() {
  let { subpage } = useParams<"subpage">()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [AccSidebar, setAccSidebar] = useState(true)

  const handleList = () => {
    setAccSidebar(!AccSidebar)
  }

  return (
    <Main>
      <Container>
        <ContainerMain>
          <div className="md:hidden">
            <button
              className="block absolute lg:top-6 top-4 mr-3"
              onClick={handleList}
            >
              <FaListUl className="size-6 text-sky-500 mx-4" />
            </button>
          </div>
          <ContainerLeft
            className={`${AccSidebar ? "hidden" : ""} md:block md:col-span-1 z-10`}
          >
            <ContainerWrap className="mt-5">
              <LeftSmallContainer className="accInfo border-b-2 py-3 border-gray-200">
                <HiOutlineUser className="mt-1 size-5 text-gray-400" />
                <Link to="/account/info" className="w-48 ml-2">
                  Account information
                </Link>
                <IoIosArrowForward className="mt-1 size-5 ml-auto text-gray-400" />
              </LeftSmallContainer>

              <LeftSmallContainer className="accInfo border-b-2 py-3 border-gray-200">
                <BiCoinStack className="mt-1 size-5 text-gray-400" />
                <Link to="/account/activity" className="w-48 ml-2">
                  Account Activity
                </Link>

                <IoIosArrowForward className="mt-1 size-5 ml-auto text-gray-400" />
              </LeftSmallContainer>

              <LeftSmallContainer className="accInfo border-b-2 py-3 border-gray-200">
                <BsChatSquareQuote className="mt-1 size-5 text-gray-400" />
                <Link to="/account/caption" className="w-48 ml-2">
                  Caption
                </Link>

                <IoIosArrowForward className="mt-1 size-5 ml-auto text-gray-400" />
              </LeftSmallContainer>

              <LeftSmallContainer className="accInfo border-b-2 py-3 border-gray-200">
                <BiLogInCircle className="mt-1 size-5 text-gray-400" />
                <Link to="/account/changeLogin" className="w-48 ml-2">
                  Change login details
                </Link>

                <IoIosArrowForward className="mt-1 size-5 ml-auto text-gray-400" />
              </LeftSmallContainer>

              <LeftSmallContainer className="accInfo border-b-2 py-3 border-gray-200">
                <MdOutlinePrivacyTip className="mt-1 size-5 text-gray-400 " />
                <Link to="/account/privacy" className="w-48 ml-2">
                  Privacy Feature
                </Link>

                <IoIosArrowForward className="mt-1 size-5 ml-auto text-gray-400" />
              </LeftSmallContainer>

              <LeftSmallContainer className="accInfo border-b-2 py-3 border-gray-200">
                <CiReceipt className="mt-1 size-5 text-gray-400" />
                <Link to="/account/delete" className="w-48 ml-2">
                  Account Deletion
                </Link>

                <IoIosArrowForward className="mt-1 size-5 ml-auto text-gray-400" />
              </LeftSmallContainer>
            </ContainerWrap>
          </ContainerLeft>
          {subpage === "info" && (
            <AccInfo
              label={""}
              id={""}
              type={""}
              value={""}
              onChange={undefined}
            />
          )}
          {subpage === "credits" && <CreditsAndRefferal />}
          {subpage === "caption" && <Caption />}
          {subpage === "changeLogin" && <ChangeLogin />}
          {subpage === "privacy" && <PrivacyFeatures />}
          {subpage === "delete" && <DeleteAccount />}
          {subpage === "activity" && <AccActivity />}
        </ContainerMain>
      </Container>
    </Main>
  )
}
