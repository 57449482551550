import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IGetDataOfAlerts, IGetAlerts } from "../../../../@types/alerts"

const initialState: IGetDataOfAlerts = {
  suggestions: {
    rcvd_notifications: [],
    send_notifications: [],
    status: 0,
    user_uuid: "",
  },
}

const getAlertsData = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setAlerts: (state, action: PayloadAction<IGetAlerts>) => {
      state.suggestions = action.payload
    },
  },
})

export const { setAlerts } = getAlertsData.actions

export default getAlertsData.reducer
