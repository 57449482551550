import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IgetCreditsInfo,
  IgetCreditsDetailsInterface,
} from "../../../../@types/credits"

const initialState: IgetCreditsInfo = {
  Creditsinfo: {
    credits_exp: "",
    download_credits: 0,
    limit_exp: "",
    limit_percentage: 0,
    limited_credits: 0,
    num_download_referral: 0,
    referral_exp: "",
    shopping_credits: {
      gift: 0,
      giveway: 0,
      loyalty: 0,
      marketing: 0,
      other: 0,
      referral: 0,
      refund: 0,
      signup: 0,
      store: 0,
    },
    status: 0,
    store_credits: 0,
    store_name: "",
    store_uuid: "",
    unlimited_credits: 0,
    unlimited_exp: "",
  },
}

const getCredits = createSlice({
  name: "CreditsGet",
  initialState,
  reducers: {
    setCreditsGet: (
      state,
      action: PayloadAction<IgetCreditsDetailsInterface>
    ) => {
      state.Creditsinfo = action.payload
    },
  },
})

export const { setCreditsGet } = getCredits.actions

export default getCredits.reducer
