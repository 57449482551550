import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import popdetailmain from "../../../assets/popdetailmain.png"
import { Container } from "../../Components/Globals/Container"
import Main from "../../layouts/Main"
import { Marginer } from "../../Components/marginer"
import { Product, ProductList } from "../Home/Styled.Comps"
import { photos } from "../Home/Products"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useDealDetailsData } from "./DealsDetails.Hooks"
import { useHomeData } from "../Home/Home.hooks"
import usePopup from "../../api/hooks/usePopup"
import useTalkToStore from "../../api/hooks/useTalkToStore"
import openShop from "../../../assets/open-shop.svg"
import { Modal } from "flowbite-react"
import useGenerateDynamicLink from "../../api/hooks/useGenerateDynamicLink"
import { BsCopy } from "react-icons/bs"
import facebook from "../../../assets/facebook.png"
import whatsapp from "../../../assets/whatsapp.png"
import twitter from "../../../assets/twitter.png"
import { ShortMainLink } from "../../api/config"
import { useProductDeatilsData } from "../ProductDetails/ProductDetail.Hooks"
import { SCREENS } from "../../Components/responsive"
import { FaImage } from "react-icons/fa6"
import Skeleton from "react-loading-skeleton"
const Container1 = styled.div`
  ${tw` 
        flex 
        justify-center
        items-center 
        gap-32
        flex-row
    `}
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
          flex-wrap
          gap-2
          mt-4
          w-full
          justify-center
      `}
  }
`
const Button2 = styled.div`
  ${tw` 
    rounded-full  
    text-white 
    bg-black 
    py-2
    sm:px-8 px-6
    text-center
    flex items-center
`}
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
           px-5
           py-2
          `}
  }
`
const Heading = styled.div`
  ${tw` 
        font-bold
    `}
  font-size:26px;
`
const ContainerLeft = styled.div`
  ${tw` 
        text-left
        w-1/2
        order-2
    `}
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
          w-full
          text-center
      `}
  }
`
const Button = styled.div`
  ${tw` 
        rounded-full  
        text-white 
        bg-black 
        py-4
        px-10
        cursor-pointer
        text-center
        text-base
    `}
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
               px-9
               py-4
              `}
  }
`

export default function Index() {
  const { popup } = useDealDetailsData()

  const navigate = useNavigate()
  const { addChannel } = useTalkToStore()
  const generateLink = useGenerateDynamicLink()
  const [openModal, setOpenModal] = useState(false)
  const [shortLink, setShortLink] = useState<string | null>(null)
  const handleClick = async () => {
    const longLink = `${ShortMainLink}/brodcast?id=${popup.popup_data.popup_uuid}&sender_id=${popup.popup_data.main_image_url}="IN"&brodcasttype="video"`
    const responseShortLink = await generateLink(longLink)
    setShortLink(responseShortLink)
    setOpenModal(true)
  }

  const profileUrlRef = useRef(null)
  const copyProfileUrl = () => {
    if (profileUrlRef.current) {
      profileUrlRef.current.select()
      navigator.clipboard.writeText(profileUrlRef.current.value)
    }
  }
  const { sui } = useParams<"sui">()
  const {
    SkuData,
    SkuRelatedData,
    addToGiftList,
    selection,
    setSelection,
    addToFavorite,
    removeFromFavorite,
    AddToBag,
  } = useProductDeatilsData(sui)
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  }
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 2300)
    return () => clearTimeout(timer)
  }, [])
  const formattedDate = new Date(
    popup?.popup_data?.expiry_date
  ).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  })
  return (
    <Main>
      <Container>
        <Container1>
          <ContainerLeft>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  height: "500px",
                  backgroundColor: "#f3f3f3",
                  position: "relative",
                }}
              >
                <Skeleton height={500} count={1} />
                <div
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    left: "50%",
                    top: "25%",
                    color: "#000",
                    display: "flex",
                    alignItems: "center",
                  }}
                ></div>
              </div>
            ) : (
              <>
                <Heading>{popup?.popup_data?.description}</Heading>
                <br />
                <p className="text-lg">{"Ends " + formattedDate}</p>
                <br />
                <p className="text-sky-400 text-2xl font-bold">
                  <Link to={`/store/${popup.store_details?.store_uuid}`}>
                    {popup?.popup_data?.store_name}
                  </Link>
                </p>
                <br />
                <div className=" flex gap-4 flex-wrap  sm:justify-start justify-center  sm:flex-wrap sm:w-full sm:gap-2 md:flex-wrap">
                  <Link to="/chats">
                    {" "}
                    <Button
                      onClick={async (e) => {
                        e.preventDefault()
                        let resp = await addChannel({
                          shopper_username_v1:
                            popup.store_details.store_username,
                          store_uuid: popup.store_details.store_uuid,
                        })
                        if (resp.status == 200) {
                          navigate("/chats/")
                        }
                      }}
                    >
                      Talk to store
                    </Button>
                  </Link>

                  <Button2
                    className="!bg-sky-800 sm:text-sm px-5 cursor-pointer sm:px-2 !lg:px-2"
                    onClick={() =>
                      navigate(
                        `/store/${popup.store_details?.store_uuid}?store_uuid=${SkuData.store_details?.store_uuid ?? "na"}`
                      )
                    }
                  >
                    <img src={openShop} alt="" className="w-10 h-10" /> Enter
                    Store
                  </Button2>
                  <div onClick={() => handleClick()}>
                    {" "}
                    <Button className="!pl-16 !pr-16">Share</Button>
                  </div>
                  <Modal
                    show={openModal}
                    onClose={() => setOpenModal(false)}
                    className="max-w-[700px] m-auto mt-28 "
                  >
                    <div className="modal-content py-8 px-10">
                      <Modal.Header className="text-xl font-bold my-6 p-0 flex items-center">
                        Share Deal
                      </Modal.Header>
                      <div className="flex">
                        <input
                          type="text"
                          value={shortLink}
                          ref={profileUrlRef}
                          readOnly
                          className="w-full relative"
                        />
                        <button onClick={copyProfileUrl}>
                          <BsCopy className="size-5 ml-3" />
                        </button>
                      </div>
                      <div className="flex items-center justify-center mt-10">
                        <Link
                          to={`http://www.facebook.com/sharer/sharer.php?u=${shortLink}&t=${popup?.popup_data?.store_name}`}
                          target="_blank"
                        >
                          <img src={facebook} className="w-10 " />
                        </Link>
                        <Link
                          to={`https://wa.me/?text=${shortLink}`}
                          target="_blank"
                        >
                          <img src={whatsapp} className="w-10 mx-8" />
                        </Link>
                        <Link
                          to={`http://www.twitter.com/intent/tweet?url=${shortLink}&text=${popup?.popup_data?.store_name}`}
                          target="_blank"
                        >
                          <img src={twitter} className="w-10" />
                        </Link>
                      </div>
                    </div>
                  </Modal>
                </div>
              </>
            )}
          </ContainerLeft>
          <div className="w-1/2  order-1 sm:order-2 sm:mb-2">
            <img
              className="w-auto h-120 ml-auto mr-24"
              src={popup.popup_data.main_image_url}
              alt=""
            />
          </div>
        </Container1>
        <Marginer margin={60} direction="vertical" />
        <ProductList>
          {popup.sui_list.map((item, index) => {
            return (
              <Product
                image={item.sku_details.image_data?.image_data[0]?.image_url}
                name={item.sku_details.product_title}
                price={item.sku_price.price_post_discount}
                salePrice={item.sku_price.price}
                link={`/product-details/${item.sku_details.SUI}`}
                key={index}
                item={item.sku_details}
                symbol={item.sku_price.symbol}
                currency_code={item.sku_price.currency_code}
              />
            )
          })}
        </ProductList>
        <Marginer margin={40} direction="vertical" />
      </Container>
    </Main>
  )
}
