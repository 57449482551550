import { useEffect } from "react"
import { useAppSelector } from "../../utils/hooks"
import useStore from "../../api/hooks/useStore"

export const useStoreData = () => {
  const { getStoreList } = useStore()

  useEffect(() => {
    getStoreList()
  }, [])
  const PopulatStores = useAppSelector(
    (state) => state.storeList.suggestions.store_list
  )
  return { PopulatStores }
}
