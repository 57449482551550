import React, { useEffect, useState } from "react"
import { styled } from "styled-components"
import tw from "twin.macro"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
interface StyledInputProps {
  label: string
  id: string
  type: string
  value?: string
  onChange?: any
  maxLength?: any
  className?: string
  disabled?: boolean
  ref?: any
  error?: string
  onBlur?: any
  stateLess?: boolean
}

const InputContainer = styled.div`
  ${tw`
        relative w-full
    `}
  input:focus ~ label, input.active ~ label, .inputActive ~ label {
    ${tw`
            text-xs
            -top-4
            text-gray-600
        `}
  }
`
const Phone = styled(PhoneInput)`
  input {
    border: 0 !important;
    box-shadow: none;
    width: 100% !important;
    ${tw`
        border-transparent
        py-1
        transition-colors
        w-full
    `}
    &.active {
      ${tw`
          border-t-transparent
          border-x-transparent
          outline-none
          border-gray-400     
          [--tw-ring-color: none]
          [box-shadow: transparent]
          `}
    }
    &:focus {
      ${tw`
          border-t-transparent
          border-x-transparent
          outline-none
          border-gray-400     
          [--tw-ring-color: none]
          [box-shadow: transparent]
          `}
    }
    border-bottom: 0;
  }
  background: none;
  ${tw`
        border-t-transparent
        border-x-transparent
        border-b
        py-1
        transition-colors
        border-[#c6c7c8]
        w-full
    `}
  &.active {
    ${tw`
        border-t-transparent
        border-x-transparent
        outline-none
        border-gray-400     
        [--tw-ring-color: none]
        [box-shadow: transparent]
        `}
  }
  &:focus {
    ${tw`
        border-t-transparent
        border-x-transparent
        outline-none
        border-gray-400     
        [--tw-ring-color: none]
        [box-shadow: transparent]
        `}
  }
`
const Input = styled.input`
  background: none;
  ${tw`
        border-t-transparent
        border-x-transparent
        border-b
        py-1
        transition-colors
        border-[#c6c7c8]
        w-full
    `}
  &.active {
    ${tw`
        border-t-transparent
        border-x-transparent
        outline-none
        border-gray-400     
        [--tw-ring-color: none]
        [box-shadow: transparent]
        `}
  }
  &:focus {
    ${tw`
        border-b-[#000]
        border-t-transparent
        border-x-transparent
        outline-none
        border-gray-800     
        [--tw-ring-color: none]
        [box-shadow: transparent]
        `}
  }
`
const Label = styled.label`
  ${tw`
        absolute
        cursor-text
        transition-all
        text-[#525252]
    `}
`

const StyledInput = (props: StyledInputProps) => {
  const {
    id,
    label,
    type,
    value,
    onChange,
    disabled,
    ref,
    error,
    onBlur,
    stateLess,
  } = props
  const [state, setState] = useState(value)
  useEffect(() => {
    setState(value)
  }, [value])
  const handleFocus = (e) => {
    let value = e.target.value
    if (e.target?.getAttribute("type") == "tel") {
      e.target.parentElement.classList.add("inputActive")
    }
    if (value.length > 0) {
      e.target.classList.add("active")
    } else {
      e.target.classList.remove("active")
    }
  }

  const handleBlur = (e) => {
    let value = e.target.value
    if (e.target?.getAttribute("type") == "tel") {
      // console.log(value)
      let countryCode = document
        .querySelector(".selected-flag")
        ?.getAttribute("title")
        .split(" + ")
      // console.log(countryCode)
      if (value.length > 0 && value != "+" + countryCode[1]) {
        e.target.parentElement.classList.add("inputActive")
      } else {
        e.target.parentElement.classList.remove("inputActive")
      }
    }
    if (value.length > 0) {
      e.target.classList.add("active")
    } else {
      e.target.classList.remove("active")
    }
    onBlur && onBlur(e)
  }

  const handleState = (e) => {
    if (type == "phone") {
      setState(e)
      let countryCode = document
        .querySelector(".selected-flag")
        ?.getAttribute("title")
        .split(" + ")

      onChange && onChange(e, "+" + countryCode[1])
    } else {
      setState(e.target.value)
      onChange && onChange(e)
    }
  }
  const phone = type == "phone"
  const email = type == "phone"
  const password = type == "phone"
  const name = type == "phone"

  return (
    <InputContainer className={props.className}>
      {phone && stateLess != true ? (
        <Phone
          value={state}
          country={"in"}
          inputProps={{
            name: id,
            id: id,
            disabled: disabled ?? false,
            ref: ref,
          }}
          placeholder=""
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleState}
          disabled={disabled ?? false}
          countryCodeEditable={false}
          enableSearch={true}
          containerClass={state && "inputActive"}
        />
      ) : stateLess != true ? (
        <Input
          id={id}
          type={type}
          value={state}
          onFocus={handleFocus}
          onBlur={handleBlur}
          name={props.id}
          onChange={handleState}
          ref={ref}
          disabled={disabled ?? false}
          className={state && "active"}
        />
      ) : (
        <Input
          id={id}
          type={type}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          name={props.id}
          ref={ref}
          onChange={onChange}
          disabled={disabled ?? false}
          className={state && "active"}
        />
      )}
      <Label
        htmlFor={id}
        className={phone ? "left-[90px] top-[10px]" : "left-0 top-1 "}
      >
        {label}
      </Label>
      <p className="text-xs text-red-500 flex items-center mt-2">{error}</p>
    </InputContainer>
  )
}
export default StyledInput
