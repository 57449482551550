import React from "react"
import styled from "styled-components"
import { SCREENS } from "../responsive"

export interface IMarginerProps {
  margin: number | string
  direction?: "horizontal" | "vertical"
}

const HorizontalMargin = styled.span<IMarginerProps>`
  display: flex-inline;
  width: ${({ margin }) =>
    typeof margin === "string" ? margin : `${margin}px`};
`

const VerticalMargin = styled.span<IMarginerProps>`
  display: flex;
  width: 100%;
  min-height: ${({ margin }) =>
    typeof margin === "string" ? margin : `${margin}px`};
  @media screen and (max-width: ${SCREENS["2xl"]}) {
    min-height: ${({ margin }) => {
      return typeof margin === "string" ? margin : `${margin}px`
    }};
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    min-height: ${({ margin }) => {
      return typeof margin === "string"
        ? Math.round(parseInt(margin) * 0.75) > 0
          ? Math.round(parseInt(margin) * 0.75)
          : 0
        : `${Math.round(margin * 0.75) > 0 ? Math.round(margin * 0.75) : 0}px`
    }};
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    min-height: ${({ margin }) => {
      return typeof margin === "string"
        ? Math.round(parseInt(margin) * 0.5) > 0
          ? Math.round(parseInt(margin) * 0.5)
          : 0
        : `${Math.round(margin * 0.5) > 0 ? Math.round(margin * 0.5) : 0}px`
    }};
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    min-height: ${({ margin }) => {
      return typeof margin === "string"
        ? Math.round(parseInt(margin) * 0.3) > 0
          ? Math.round(parseInt(margin) * 0.3)
          : 0
        : `${Math.round(margin * 0.3) > 0 ? Math.round(margin * 0.3) : 0}px`
    }};
  }
`

function Marginer(props: IMarginerProps) {
  const { direction } = props

  if (direction === "horizontal") return <HorizontalMargin {...props} />
  else {
    return <VerticalMargin {...props} />
  }
}

Marginer.defaultProps = {
  direction: "horizontal",
}

export { Marginer }
