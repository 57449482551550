import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IGiftReceiptsData,
  IGetGiftReceipts,
} from "../../../../@types/giftsReceipts"

const initialState: IGiftReceiptsData = {
  ReceiptsOfGifts: {
    data: "",
    gift_receipt: [],
    status: 0,
  },
}

const getGifts = createSlice({
  name: "GiftsDetails",
  initialState,
  reducers: {
    setGiftsDetails: (state, action: PayloadAction<IGetGiftReceipts>) => {
      state.ReceiptsOfGifts = action.payload
    },
  },
})

export const { setGiftsDetails } = getGifts.actions

export default getGifts.reducer
