import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import {
  FaBookmark,
  FaGift,
  FaImage,
  FaLink,
  FaLock,
  FaShareNodes,
  FaTableList,
  FaUser,
} from "react-icons/fa6"
import { BsCopy } from "react-icons/bs"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import Main from "../../layouts/Main"
import "./style.css"
import { Product, ProductList } from "../Home/Styled.Comps"
import { Marginer } from "../../Components/marginer"
import { useAccountInfoData } from "../Account/hooks/accountInfoData.Hooks"
import { useParams } from "react-router-dom"
import { Modal } from "flowbite-react"
import facebook from "../../../assets/facebook.png"
import whatsapp from "../../../assets/whatsapp.png"
import twitter from "../../../assets/twitter.png"
import { useProfile } from "./Profile.hooks"
import useGenerateDynamicLink from "../../api/hooks/useGenerateDynamicLink"
import { ShortMainLink } from "../../api/config"
import { CiHeart, CiSaveDown1 } from "react-icons/ci"
import { PopTost } from "../../utils/common"
import { FaUserLock } from "react-icons/fa"
import useStore from "../../api/hooks/useStore"
import { useStoreData } from "../StorePage/Store.Hooks"
import { useAppDispatch } from "../../utils/hooks"
import { useSendbird } from "../Chats/Chats.Hooks"
import { setStorelistMain } from "../../utils/slices/Stores/getStore"
import ScrollToTop from "../../../ScrollToTop"
import Skeleton from "react-loading-skeleton"
const Button = styled.button`
  ${tw`
border-sky-500
border
border-2
lg:py-4 py-2
lg:px-8 px-4
rounded-full
text-sky-500
mt-7 
transition-all
font-bold
sm:text-lg text-sm
`}
`
const ButtonFill = styled.button`
  ${tw`
    bg-sky-500
    w-full
    py-4
    px-6
    rounded-full
    text-white
    mt-7 
    transition-all
    text-center
    lg:text-2xl text-sm
`}
`

const Heading5 = styled.button`
  ${tw`lg:text-xl md:text-lg text-sm`}
`

const ExtProfile = () => {
  const { uuid, type } = useParams()
  const channelRef = useRef()
  const { joinChannel, leaveChannel } = useSendbird(channelRef)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { userDetails, DataOfGiftList, DataOfFavLists } =
    useAccountInfoData(uuid)
  const generateLink = useGenerateDynamicLink()
  const [defaultTab, setDefaultTab] = useState(0)
  let userId = localStorage.getItem("user")
  const [openModal, setOpenModal] = useState(false)
  const { refLinksData, imgCaptionData } = useProfile()

  const [referralLinkInfo, setReferralLinkInfo] = useState("")
  const [shortLink, setShortLink] = useState<string | null>(null)

  const handleClick = async () => {
    const longLink = `${ShortMainLink}/profile?user_id=${uuid}&type="store"&region="IN"&mediaUrl=${userDetails?.user_data.image_url_thumb}&description="Profile of"${userDetails.user_data.profile_name}`

    const responseShortLink = await generateLink(longLink)
    setShortLink(responseShortLink)
    setOpenModal(true)
  }

  function refLinksDatainfo() {
    setReferralLinkInfo(refLinksData.data)
    localStorage.setItem("referral_link", refLinksData.data)
  }

  const profileUrlRef = useRef(null)

  const copyProfileUrl = () => {
    if (profileUrlRef.current) {
      profileUrlRef.current.select()
      navigator.clipboard.writeText(profileUrlRef.current.value)
      PopTost({ message: "Link copied", type: "success" })
    }
  }
  const [tabIndex, setTabIndex] = useState(0)
  useEffect(() => {
    setDefaultTab(type == "gift" ? 1 : 0)
    console.log(defaultTab)
  }, [type])

  const handleMessage = () => {
    navigate("/chats")
  }
  // const handleOrders = () => {
  //     navigate("/orders")
  // }
  // const handleGift = () => {
  //     navigate("/giftCards")
  // }
  const [searchParams, setSearchParams] = useSearchParams()
  const { followStore, unFollowStore, getStoreSeller } = useStore()
  const {
    storeData1,
    handleState,
    gender,
    priceMin,
    priceMax,
    colorsData,
    categoriesData,
    sizeslist,
    color,
    size,
    category,
    handleTags,
    ProductsList,
  } = useStoreData({ searchParams, setSearchParams, uuid })
  const handleFollow = async (e) => {
    e.preventDefault()
    if (!storeData1.suggestions.is_following) {
      const resp = await followStore({
        uuid: storeData1.suggestions.store_user_uuid,
      })
      if (resp.status == 200 && storeData1.suggestions.store_chat_url) {
        storeData1.suggestions.store_chat_url &&
          joinChannel(storeData1.suggestions.store_chat_url)
      }
      dispatch(
        setStorelistMain({ ...storeData1.suggestions, is_following: true })
      )
    } else {
      const resp = await unFollowStore({
        uuid: storeData1.suggestions.store_user_uuid,
      })
      if (resp.status == 200 && storeData1.suggestions.store_chat_url) {
        storeData1.suggestions.store_chat_url &&
          leaveChannel(storeData1.suggestions.store_chat_url)
      }
      dispatch(
        setStorelistMain({ ...storeData1.suggestions, is_following: false })
      )
    }
  }
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])
  return (
    <Main className="profile">
      <ScrollToTop />
      <div className="bg-slate-100 flex py-6">
        <div className="container mx-auto ">
          {userId != uuid && (
            <div className=" flex justify-end mb-10">
              <div className="h-auto">
                <Button
                  className="ml-auto mr-5 flex items-center !border-1 !font-medium"
                  onClick={() => handleClick()}
                >
                  {" "}
                  <Link to="/profile/${uuid}">
                    <FaShareNodes className="size-5 mr-3" />
                  </Link>
                  Share Profile
                </Button>

                <Modal
                  show={openModal}
                  onClose={() => setOpenModal(false)}
                  className="max-w-[700px] m-auto mt-28 "
                >
                  <div className="modal-content py-8 px-10">
                    <Modal.Header className="text-xl font-bold my-6 p-0 flex items-center">
                      Share Profile
                    </Modal.Header>
                    <div className="flex">
                      <input
                        type="text"
                        value={shortLink}
                        ref={profileUrlRef}
                        readOnly
                        className="w-full relative"
                      />
                      <button onClick={copyProfileUrl}>
                        <BsCopy className="size-5 ml-3" />
                      </button>
                    </div>
                    <div className="flex items-center justify-center mt-10">
                      <Link
                        to={`http://www.facebook.com/sharer/sharer.php?u=${shortLink}&t=${userDetails.user_data.profile_name}`}
                        target="_blank"
                      >
                        <img src={facebook} className="w-10 " />
                      </Link>
                      <Link
                        to={`https://wa.me/?text=${shortLink}`}
                        target="_blank"
                      >
                        <img src={whatsapp} className="w-10 mx-8" />
                      </Link>
                      <Link
                        to={`http://www.twitter.com/intent/tweet?url=${shortLink}&text=${userDetails.user_data.profile_name}`}
                        target="_blank"
                      >
                        <img src={twitter} className="w-10" />
                      </Link>
                    </div>
                  </div>
                </Modal>
              </div>
              <div>
                <Button
                  className="ml-auto mr-5 flex items-center !font-medium"
                  onClick={() => {
                    const ref_link = localStorage.getItem("referral_link")
                    if (
                      ref_link == null ||
                      ref_link == undefined ||
                      ref_link == ""
                    ) {
                      refLinksDatainfo()
                    }
                    navigator.clipboard.writeText(
                      localStorage.getItem("referral_link")
                    )
                    PopTost({ message: "Link Copied", type: "info" })
                  }}
                >
                  <FaLink className="size-5 mr-3" />
                  Referral Link
                </Button>
              </div>
            </div>
          )}
          <div className="lg:mt-8 mt-4">
            {userDetails.user_data && (
              <div className="lg:grid lg:grid-cols-3  lg:gap-5 gap-3">
                {loading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "300px",
                      backgroundColor: "#f3f3f3",
                      position: "relative",
                      borderRadius: "100%",
                    }}
                  >
                    <Skeleton height={500} count={1} />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        left: "50%",
                        top: "25%",
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FaImage className="mr-2 size-4" />
                    </div>
                  </div>
                ) : (
                  <img
                    src={userDetails?.user_data.image_url}
                    alt=""
                    className="rounded-full h-64 w-64 m-auto"
                  />
                )}
                <div className="col-span-2 lg:p-0 p-4">
                  {loading ? (
                    <Skeleton height={30} count={1} />
                  ) : (
                    <h4 className="font-bold lg:text-2xl text-xl">
                      {userDetails.user_data.profile_name}
                      <span className="block font-normal lg:text-2xl text-xl">
                        {userDetails.user_data.profile_title}
                      </span>
                    </h4>
                  )}
                  {userId == uuid && (
                    <div className="flex text-center mt-10 justify-between ">
                      <Link to="/followers">
                        <Heading5>
                          <span className="block font-bold lg:text-3xl text-xl">
                            {userDetails.user_data.num_followers}
                          </span>
                          Followers
                        </Heading5>
                      </Link>
                      <Link to="/following">
                        <Heading5>
                          <span className="block font-bold lg:text-3xl text-xl">
                            {userDetails.user_data.num_following}
                          </span>
                          Followings
                        </Heading5>
                      </Link>
                      <Heading5>
                        <span className="block font-bold lg:text-3xl text-xl">
                          {userDetails.user_data.num_referrals}
                        </span>
                        Referrals
                      </Heading5>
                      <Heading5>
                        <span className="block font-bold lg:text-3xl text-xl">
                          {`${userDetails.currency_data.symbol}  ${userDetails.user_data.total_credits}`}
                        </span>
                        Credits
                      </Heading5>
                    </div>
                  )}
                  {userId != uuid && (
                    <div className="flex justify-center sm:flex-nowrap flex-wrap items-center lg:mt-20 mt-4">
                      <ButtonFill
                        className="ml-auto mr-5 flex items-center justify-center"
                        onClick={handleFollow}
                      >
                        {" "}
                        {localStorage.getItem("user") && (
                          <>
                            {storeData1.suggestions.is_following && (
                              <button
                                onClick={handleFollow}
                                className="flex items-center"
                              >
                                {" "}
                                <FaUser className="lg:size-5 size-4 mr-3" />{" "}
                                Follow
                              </button>
                            )}
                            {!storeData1.suggestions.is_following && (
                              <button
                                onClick={handleFollow}
                                className="flex items-center"
                              >
                                {" "}
                                <FaUser className="lg:size-5 size-4 mr-3" />{" "}
                                UnFollow
                              </button>
                            )}
                          </>
                        )}
                      </ButtonFill>
                      <ButtonFill
                        className="ml-auto mr-5 flex items-center justify-center"
                        onClick={handleMessage}
                      >
                        {" "}
                        <FaTableList className="lg:size-5 size-4 mr-3" />
                        <Link to="">Message</Link>
                      </ButtonFill>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="border lg:my-20 my-8"></div>

          <Tabs
            id="tabs"
            selectedIndex={defaultTab}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList className="  border-b-2 ">
              <div className="grid grid-cols-2 gap-8 ">
                <Tab
                  className="flex items-center justify-center tab-title text-xl py-5 text-gray-900 active:text-[#038DCE] focus:text-[#038DCE]"
                  onClick={() => {
                    setDefaultTab(0)
                  }}
                >
                  <FaBookmark className=" rounded-full w-6 h-6 mr-2 active:text-[#038DCE] focus:text-[#038DCE]" />
                  Favorites
                </Tab>

                <Tab
                  className="flex items-center justify-center tab-title text-xl py-5 active:text-[#038DCE] focus:text-[#038DCE]"
                  onClick={() => {
                    setDefaultTab(1)
                  }}
                >
                  <FaGift className="size-5 mr-3 active:text-[#038DCE] focus:text-[#038DCE]" />
                  Gift List
                </Tab>
              </div>
            </TabList>

            <TabPanel>
              {uuid === userId ? (
                DataOfFavLists &&
                DataOfFavLists.user_favorite &&
                DataOfFavLists.user_favorite.length > 0 ? (
                  <>
                    <Marginer margin={60} direction="vertical" />
                    <ProductList>
                      {DataOfFavLists.user_favorite.map((item, index) => (
                        <Product
                          link={`/product-details/${item.reference_id}`}
                          image={item.image_url}
                          name={item.product_title}
                          price={item.display_total_price}
                          salePrice={item.display_final_price}
                          key={index}
                          item={item}
                          symbol={item.display_currency_symbol}
                          currency_code={item.display_currency_code}
                        />
                      ))}
                    </ProductList>
                  </>
                ) : (
                  <div className="no-gift-list max-w-36 text-center m-auto py-20">
                    No Favorites
                  </div>
                )
              ) : (
                <div className="no-gift-list max-w-36 text-center m-auto py-20">
                  <FaUserLock className="size-20 m-auto" />
                  Private Account
                </div>
              )}
            </TabPanel>

            <TabPanel>
              {uuid === userId ? (
                DataOfFavLists &&
                DataOfGiftList.gift_list &&
                DataOfGiftList.gift_list.length > 0 ? (
                  <>
                    <Marginer margin={60} direction="vertical" />
                    <ProductList>
                      {DataOfGiftList.gift_list.map((item, index) => {
                        return (
                          <Product
                            link={`/product-details/${item.sui}`}
                            image={item.image_url}
                            name={item.product_title}
                            price={item.display_total_price}
                            salePrice={item.display_final_price}
                            key={index}
                            item={item}
                            symbol={item.display_currency_symbol}
                            currency_code={item.display_currency_code}
                          />
                        )
                      })}
                    </ProductList>
                  </>
                ) : (
                  <div className="no-gift-list">No Gift List</div>
                )
              ) : (
                <div className="no-gift-list max-w-36 text-center m-auto py-20">
                  <FaUserLock className="size-20 m-auto" />
                  Private Account
                </div>
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </Main>
  )
}

export default ExtProfile
