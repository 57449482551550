import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  IStoreListMarketData,
  IStoreListMarketSku,
} from "../../../../@types/sku"

const initialState: IStoreListMarketData = {
  storelistmarketdata: {
    current_index: "",
    per_page: 0,
    sku_count: 0,
    sku_data: [],
    status: 0,
    store_details: {
      store_logo: "",
      store_name: "",
      store_region: "",
    },
    total_count: 0,
  },
}

const storeListMarketSlice = createSlice({
  name: "storeListMarketSku",
  initialState,
  reducers: {
    setStoreListMarket: (state, action: PayloadAction<IStoreListMarketSku>) => {
      state.storelistmarketdata = action.payload
    },
  },
})

export const { setStoreListMarket } = storeListMarketSlice.actions
export default storeListMarketSlice.reducer
