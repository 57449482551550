import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import StyledInput from "../../../Components/input"
import { Marginer } from "../../../Components/marginer"
import axios from "axios"
import { API } from "../../../api/config"
import { toast } from "react-toastify"
import { useGetGuest } from "../../Auth/Auth.hooks"
const Container = styled.div`
  ${tw` 
flex gap-4  bg-gray-100 w-full h-[800px]
`}
`
const ContainerRight = styled.div`
  ${tw` 
justify-start  w-full mt-2 ml-5
`}
`
const Input = styled.input`
  ${tw` 
mr-2 
ring-2
ring-gray-400
bg-transparent
border-0
`}
  &:checked {
    ${tw`
  border-transparent
  ring-2
  ring-gray-400
  bg-transparent
`}
  }
  &:checked:before {
    ${tw`
  bg-[#008FC4]
`}
  }
  &:focus {
    ${tw`
  ring-2
  ring-gray-400
`}
  }
  &:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    margin: 3px;
    border-radius: 50%;
  }
`
const DivContainer = styled.div`
  ${tw` 
flex flex-col  gap-4
`}
`
const SendOtp = styled.div`
  ${tw` 

`}
`
const PressOTP = styled.div`
  ${tw` 
font-bold
w-full
flex
justify-between
items-center
font-bold
text-[16px]
`}
`
const Button = styled.button`
  ${tw` 
rounded-full border-2 border-[#008FC4] p-1 pl-3 pr-3 pb-2  text-[#008FC4] font-semibold text-sm
`}
`
const InputFields = styled.button`
  ${tw` 
flex flex-col gap-2 w-full
`}
`
const DivBottom = styled.div`
  ${tw` 
mt-[300px] text-center bg-sky-500 p-2 rounded-full text-white
`}
`
export default function ChangeLogin() {
  const [change, setChange] = useState("")
  // const { state, handleState, login, signup, verify, checkUser, resendOTP } =
  // useGetGuest()
  const handleChange = (e) => {
    setChange(e.target.value)
  }

  const userDataList = JSON.parse(localStorage.getItem("userData"))
  const RequestSend = async () => {
    let response = await axios.post(`${API}/user/forgot_password`, {
      email: userDataList.email,
      phone: userDataList.phone,
    })
    console.log(response.data)
    if (response.status === 200) {
      toast.success(" OTP  Sent")
    }
  }

  const SaveData = async (user_uuid) => {
    let response = await axios.post(`${API}/user/confirm_otp`, {
      otp: "1946",
      user_uuid: user_uuid,
    })
    console.log(response)
    if (response.status === 200) {
      toast.success(" OTP  Sent")
    }
  }

  return (
    <>
      <Container>
        <ContainerRight>
          <DivContainer>
            <label>
              <Input
                type="radio"
                name="change"
                value={"password"}
                checked={change === "password"}
                onChange={handleChange}
              />
              New Password
            </label>
            <label>
              <Input
                type="radio"
                name="change"
                value={"phone"}
                checked={change === "phone"}
                onChange={handleChange}
              />
              New Phone Number
            </label>
            <label>
              <Input
                type="radio"
                name="change"
                value={"email"}
                checked={change === "email"}
                onChange={handleChange}
              />
              New Email
            </label>
          </DivContainer>
          <SendOtp className=" flex justify-evenly mt-4 mb-12">
            <PressOTP>
              Press to send OTP <Button onClick={RequestSend}>Request</Button>
            </PressOTP>
          </SendOtp>
          <InputFields>
            <StyledInput
              className="w-full"
              id={"otp"}
              type={"text"}
              label="OTP"
            />
            <Marginer margin={20} direction="vertical" />

            {change === "password" && (
              <StyledInput
                className="w-full"
                id={"password"}
                type={"password"}
                label="Password"
              />
            )}

            {change === "email" && (
              <StyledInput
                className="w-full"
                id={"email"}
                type={"email"}
                label="Email"
              />
            )}

            {change === "phone" && (
              <StyledInput
                className="w-full"
                id={"phone"}
                type={"phone"}
                label="Phone"
                value={userDataList.phone}
              />
            )}
          </InputFields>
          <DivBottom className=" w-auto">
            <button className="text-xl" onClick={SaveData}>
              Save
            </button>
          </DivBottom>
        </ContainerRight>
      </Container>
    </>
  )
}
