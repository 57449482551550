import React, { useState } from "react"
import axios from "axios"
import { API } from "../config"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { setCreditsGet } from "../../utils/slices/Credits/getCredits"
import { setCalCulateCreadits } from "../../utils/slices/Credits/calculateCreadits"
import { getToken } from "../config"
import { setAddressList } from "../../utils/slices/Credits/getAddressList"

const useCredtis = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  function getCreditsDetails() {
    const creditsDetails = axios
      .get(`${API}/credits/get_credits?api_token=${API_TOKEN}`)
      .then((res) => {
        dispatch(setCreditsGet(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getCalculateCredits() {
    const orderNo = localStorage.getItem("order_num")
    const calculateCredits = axios
      .get(
        `${API}/credits/calculate_credits?api_token=${API_TOKEN}&order_num=${orderNo}&coupon_id=0`
      )
      .then((res) => {
        dispatch(setCalCulateCreadits(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getAddAddressList() {
    const addressList = axios
      .get(`${API}/user/get_user_address_list?&api_token=${API_TOKEN}`)
      .then((res) => {
        dispatch(setAddressList(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return { getCreditsDetails, getCalculateCredits, getAddAddressList }
}
export default useCredtis
