import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import StyledInput from "../../Components/input/index"
import Logo from "../../Components/Globals/Logo"
import image from "../../../assets/image4.png"
import { Marginer } from "../../Components/marginer/index"
import { Button } from "../../Components/button/index"
import StyledLink from "../../Components/link"
import { SCREENS } from "../../Components/responsive"
import countryList from "../../utils/countryList.json"

let Container = styled.div`
  ${tw`
        flex
        w-full
        items-center
        justify-center
    `}
`
let ColumnAuto = styled.div`
  ${tw`    
    flex
    flex-1
    flex-row
    h-screen
`}
`
let LeftColumn = styled(ColumnAuto)`
  ${tw`
        justify-center
        items-center
    `}
  @media (max-width: ${SCREENS.lg}) {
    background-image: url(${image});
    background-position: top center;
    background-color: rgba(255, 255, 255, 0.842);
    ${tw`
            bg-no-repeat
            bg-cover
            bg-top
            bg-blend-lighten
        `}
  }
`
let RightColumn = styled(ColumnAuto)`
  background-image: url(${image});
  background-position: top center;
  ${tw`
        bg-no-repeat
        bg-cover
        bg-top
    `}
  @media (max-width: ${SCREENS.lg}) {
    ${tw`
            hidden
        `}
  }
`

const FormHolder = styled.div`
  ${tw`
        w-6/12
        flex
        flex-col
    `}
  @media (max-width: ${SCREENS.xl}) {
    ${tw`
            w-11/12
        `}
  }
`
const Form = styled.form`
  ${tw`
        w-full
        mt-5
    `}
`
const LogoAuth = styled(Logo)`
  ${tw`
        px-5
    `}
`
const Heading2 = styled.div`
  ${tw`
        text-2xl font-bold text-center mt-8
    `}
`
const Heading3 = styled.div`
  ${tw`
        text-lg font-bold text-center mt-16
    `}
`
const Heading4 = styled.div`
  ${tw`
        text-sm text-center mt-4
    `}
`

export const ForgotPassword = ({ state, updateState, onSubmit }) => {
  const [resetWith, setResetWith] = useState("phone")
  return (
    <FormHolder>
      <LogoAuth />
      <Heading2>Account Signup</Heading2>
      <Heading3>Reset your Password</Heading3>
      <Heading4>
        Enter the email address associated with your account and we'll send you
        a link to reset your password
      </Heading4>
      <Marginer direction="vertical" margin="4em" />

      <Form>
        {resetWith == "email" && (
          <StyledInput
            id={"email"}
            label="Email"
            type="string"
            value={state.request.email}
            disabled={state.passOtpConfirm}
            onChange={(e) => {
              updateState({
                type: "request",
                value: { ...state.request, email: e.target.value },
              })
            }}
          />
        )}

        {resetWith == "phone" && (
          <StyledInput
            id="phoneNumber"
            label="Phone Number"
            type="phone"
            value={state.request.phone}
            disabled={state.passOtpConfirm}
            onChange={(e, countryCode) => {
              updateState({ type: "phoneCode", value: countryCode })
              updateState({
                type: "request",
                value: {
                  ...state.request,
                  phone: e,
                  country_code: countryList[countryCode],
                },
              })
            }}
          />
        )}

        {state.passOtpConfirm && (
          <>
            <Marginer direction="vertical" margin="20px" />
            <StyledInput
              id="password"
              label="password"
              type="password"
              value={state.request.password}
              onChange={(e) => {
                updateState({
                  type: "request",
                  value: { ...state.request, password: e.target.value },
                })
              }}
            />
          </>
        )}
      </Form>
      <Marginer direction="vertical" margin="5px" />
      <Button
        theme="outlined"
        text={"Reset Password"}
        className="py-4"
        click={onSubmit}
      />
      <Container>
        <Heading4>
          {" "}
          Didn't have access to{" "}
          {resetWith == "phone" ? "Phone Number" : "E-mail"}?{" "}
          <Marginer direction="horizontal" margin="10px" />
          <StyledLink
            className="font-semibold"
            theme="plain"
            text={resetWith == "phone" ? "Use Email" : "Use Phone Number"}
            url={"/"}
            onClick={(e) => {
              e.preventDefault()
              setResetWith(resetWith == "phone" ? "email" : "phone")
            }}
          />
        </Heading4>
      </Container>
      <Heading4>
        <StyledLink
          className="font-normal text-black"
          theme="plain"
          text={"Back"}
          url={""}
          onClick={(e) => {
            e.preventDefault()
            updateState({ type: "activePage", value: "verify" })
          }}
        />
      </Heading4>
    </FormHolder>
  )
}
