import { Link } from "react-router-dom"
import styled from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../responsive"
import Skeleton from "react-loading-skeleton"
import { useEffect, useState } from "react"

const ThumbHolder = styled.div<{ image: string }>`
  ${tw`
            w-full
            bg-cover
            bg-center
            relative
            h-48
            rounded-lg
            overflow-hidden
        `}
  background-image:url(${(props) => props.image});
  &:nth-child(2) {
    ${tw`
                row-span-2
                h-full
            `}
  }
  &:nth-child(3) {
    ${tw`
                row-span-2
                h-full
            `}
  }

  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
                w-full
                row-span-2
            `}
    height:450px;
    &:nth-child(2) {
      height: 450px;
    }
    &:nth-child(3) {
      height: 450px;
    }
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
                w-full
                row-span-2
            `}
    height:450px;
    &:nth-child(2) {
      height: 450px;
    }
    &:nth-child(3) {
      height: 450px;
    }
  }
`
const TextHolder = styled.div`
  ${tw`
            absolute
            w-full
            h-1/4
            bg-gradient-to-t
            from-neutral-900
            to-transparent
            bottom-0 
            left-0
            justify-end
            p-5
            flex
            flex-col
            text-left
        `}
`
const Links = styled(Link)`
  ${tw`
            flex
            justify-start
            absolute
            h-full
            w-full
            left-0
            top-0
        `}
  @media screen and (max-width:${SCREENS.lg}) {
    ${tw`
                justify-center
            `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
                justify-center
            `}
  }
`
const Title = styled.h2`
  ${tw`
            text-base
            font-medium
            text-white
        `}
`
const StoreThumb = ({ children, title, image, ...props }) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])
  return (
    <ThumbHolder key={props.key} image={image}>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "500px",
            backgroundColor: "#f3f3f3",
            position: "relative",
          }}
        >
          <Skeleton height={100} count={1} />
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              left: "30%",
              top: "25%",
              color: "#000",
              display: "flex",
              alignItems: "center",
            }}
          ></div>
        </div>
      ) : (
        <Links to={props.link}>
          <TextHolder>
            <Title>{title}</Title>
          </TextHolder>
        </Links>
      )}
    </ThumbHolder>
  )
}

export default StoreThumb
