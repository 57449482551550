import React from "react"
import axios from "axios"
import { API, getToken } from "../config"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import setgetUserCurrency, {
  setUserCurrency,
} from "../../utils/slices/Currency/getUserCurrency"
import { setCurrencyList } from "../../utils/slices/Currency/getCurrencyList"
import { PopTost } from "../../utils/common"

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers.common["Content-Type"] = "application/json"

const UseCurrency = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  function getCurrencyList() {
    const currencyList = axios
      .get(`${API}/currency/get_currency_list?api_token=${API_TOKEN}`)
      .then((res) => {
        dispatch(setCurrencyList(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getUserCurrency() {
    const getCurrency = axios
      .get(`${API}/currency/get_user_currency_pref?api_token=${API_TOKEN}`)
      .then((res) => {
        dispatch(setUserCurrency(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const setUserCurrencyData = async ({ currency_id }) => {
    // console.log("event", event)
    const setCurrency = await axios.post(
      `${API}/currency/set_user_currency_preference`,
      {
        api_token: API_TOKEN,
        currency_id: currency_id,
      }
    )

    // console.log(setCurrency, "hellog")
    if (setCurrency.data.status === 200) {
      PopTost({ message: "Currency updated successfully.", type: "success" })
      getUserCurrency()
    }
  }

  return { getCurrencyList, getUserCurrency, setUserCurrencyData }
}

export default UseCurrency
