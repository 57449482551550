import React, { useState } from "react"
import { Button } from "../../Components/button"
import styled from "styled-components"
import tw from "twin.macro"
import { Link, useNavigate } from "react-router-dom"
import Main from "../../layouts/Main"
import { Grid2 } from "../../Components/Grid"
import { Container } from "../../Components/Globals/Container"
import { Marginer } from "../../Components/marginer"
import CartProduct from "../../Components/CartProduct"
import { SCREENS } from "../../Components/responsive"
import { useShoppingBagData } from "./shoppingBag.Hooks"
import { API } from "../../api/config"
import { API_TOKEN } from "../../api/config"
import axios from "axios"

const Heading = styled.div`
  ${tw` 
  text-2xl 
  font-bold
`}
`
const CreditDetail = styled.div`
  ${tw`
  my-4 sm:flex block  items-center lg:px-10 px-2
  `}
`
const ApplyCreditButton = styled.button`
  ${tw`
  bg-[#038DCE]
  h-[51px]
  p-2 
  text-white
  text-[16px]
  font-semibold
  border-2 
  border-[#038DCE] 
  hover:bg-blue-400 sm:mt-0 mt-2 sm:w-auto w-full
  `}
`
const EmptyBagPage = styled.div`
  ${tw`text-center flex items-center justify-center h-screen`}
`
const Heading4 = styled.div`
  ${tw`text-center font-bold md:text-[26px] text-xl py-4 bg-white`}
  position: relative;
  width: 150%;
  left: -50%;
  padding-left: 50%;
`
const InputContainer = styled.div`
  ${tw`flex items-center mb-4 `}
`
const GrayBorder = styled.div`
  ${tw`border`}
`
const Grid = styled(Grid2)`
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        grid-cols-1
    `}
  }
`
const CheckoutButton = styled.button`
  ${tw`
bg-sky-500
w-full
md:py-5 py-3
md:px-3
rounded-full
text-white
mt-7 
transition-all
font-bold
`}
`
const ShoppingBag = () => {
  const [currencies, setCurrencies] = useState()
  const [amount, setAmount] = useState(1)
  const {
    CartDetailsData,
    GetCart,
    cartState,
    HandleGift,
    updateMeta,
    setCartState,
  } = useShoppingBagData()
  console.log(CartDetailsData, "CartDetailsData")
  const [coupon, setCoupon] = useState("")
  function handleApplycoupon(e) {
    setCoupon(e.target.value)
  }

  let orDer_Number = localStorage.getItem("order_num")
  const ApplyCouponApi = async () => {
    let couponApply = await axios.post(`${API}/cart/apply_coupon_discount`, {
      api_token: API_TOKEN,
      coupon_code: coupon,
      order_num: orDer_Number,
    })

    console.log(couponApply.data, "creatNewCart")
  }

  const RemoveCouponApi = async () => {
    let couponApply = await axios.post(`${API}/cart/apply_coupon_discount`, {
      api_token: API_TOKEN,
      coupon_code: coupon,
      order_num: orDer_Number,
    })

    console.log(couponApply.data, "creatNewCart")
  }

  const Navigate = useNavigate()
  function goToCheckoutPage() {
    Navigate("/checkout")
  }

  const CartDiscValue = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
  }).format(CartDetailsData.shopping_cart_data.total_cart_discounted_value)

  return (
    <Main>
      {CartDetailsData.cart_items.length > 0 ? (
        <>
          <div className="bg-slate-100 flex flex-col">
            <Marginer margin={60} direction="vertical" />
            <Container>
              <Grid>
                <div>
                  {CartDetailsData.cart_items.map((item, index) => {
                    return (
                      <div className="cart-product" key={index + "_cartProd"}>
                        <Heading4>
                          Store:{" "}
                          <span className="font-normal md:text-[20px] text-lg">
                            <Link to={"/stores/"}>{item.store_name}</Link>
                          </span>{" "}
                        </Heading4>
                        <CartProduct
                          title={item.product_title}
                          subhead={`Item ID: ${item.SUI}`}
                          image={item.product_image}
                          key={1}
                          item={item}
                          remove={GetCart}
                        />
                        <Marginer margin={30} direction="vertical" />
                      </div>
                    )
                  })}
                </div>

                <div className="mx-3">
                  <GrayBorder>
                    <CreditDetail>
                      <input
                        type="text"
                        placeholder="Coupon to apply"
                        className="h-[50px] sm:w-[300px] w-full"
                        value={coupon}
                        onChange={handleApplycoupon}
                      />
                      {
                        <ApplyCreditButton onClick={ApplyCouponApi}>
                          {" "}
                          Apply coupon
                        </ApplyCreditButton>
                      }
                    </CreditDetail>
                    <div className="flex lg:p-10 p-2 items-start justify-between">
                      <div>
                        <h4 className="font-semiBold">
                          Subtotal:{" "}
                          <span>
                            {CartDetailsData.shopping_cart_data.currency_symbol}
                          </span>
                          <span>{CartDiscValue}</span>
                        </h4>
                        <h4 className="font-semiBold">
                          Shipping: <span>Free</span>
                        </h4>
                      </div>
                      <h3 className="font-bold text-xl">
                        <span>
                          {CartDetailsData.shopping_cart_data.currency_symbol}
                        </span>
                        {CartDiscValue}
                      </h3>
                    </div>
                    <GrayBorder></GrayBorder>
                    <div className="lg:px-10 px-2 p-4">
                      <InputContainer>
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 text-blue-600 cursor-pointer"
                          checked={cartState.is_gift == 1}
                          onChange={HandleGift}
                        />
                        <label
                          htmlFor="default-checkbox"
                          className="ms-2 text-md font-medium "
                        >
                          Is it a gift?
                        </label>
                        {/* <h5 className="text-[16px]">Order Date: {item.order_date}</h5> */}
                      </InputContainer>
                      {cartState.is_gift == 1 && (
                        <div className="">
                          <h6>Write here</h6>
                          <textarea
                            className="w-full rounded-md"
                            onChange={(e) => {
                              setCartState({
                                ...cartState,
                                message: e.target.value,
                                isUpdate: true,
                              })
                            }}
                          ></textarea>
                        </div>
                      )}
                      {cartState.isUpdate && (
                        <Button
                          className=""
                          text={"Update"}
                          theme="normal"
                          click={() => {
                            updateMeta()
                          }}
                        />
                      )}
                    </div>

                    <div></div>
                  </GrayBorder>
                  <CheckoutButton onClick={goToCheckoutPage}>
                    Proceed to Checkout
                  </CheckoutButton>
                </div>
              </Grid>
            </Container>
            <Marginer margin={60} direction="vertical" />
          </div>
        </>
      ) : (
        <EmptyBagPage>
          <Heading>
            Your bag is empty{" "}
            <span className="block text-lg font-normal">
              {" "}
              Item added to your bag will show up here
            </span>{" "}
          </Heading>
        </EmptyBagPage>
      )}
    </Main>
  )
}

export default ShoppingBag
