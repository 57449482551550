import { styled } from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../responsive"

export const Grid6 = styled.div`
  ${tw` 
    grid 
    grid-cols-6
    gap-4
    `}
  @media screen and (max-width:${SCREENS["2xl"]}) {
    ${tw`
        grid-cols-4
      `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    ${tw`
        grid-cols-3
      `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        grid-cols-1
      `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        grid-cols-1
        px-5
      `}
  }
`

export const Grid4 = styled.div`
  ${tw` 
    grid 
    grid-cols-4
    gap-10
    `}
  @media screen and (max-width:${SCREENS["2xl"]}) {
    ${tw`
        grid-cols-4
        gap-6
      `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    ${tw`
        grid-cols-3
        gap-6
      `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        grid-cols-2
        gap-6
      `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        grid-cols-1
        px-5
        
      `}
  }
`
export const Grid3 = styled.div`
  ${tw` 
        grid 
        grid-cols-3
        gap-4
        `}
  @media screen and (max-width:${SCREENS["2xl"]}) {
    ${tw`
        grid-cols-3
      `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    ${tw`
        grid-cols-3
      `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        grid-cols-2
      `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        grid-cols-1
        px-5
      `}
  }
`

export const Grid2 = styled.div`
  ${tw` 
    grid 
    grid-cols-2
    gap-4
    `}
  @media screen and (max-width:${SCREENS["2xl"]}) {
    ${tw`
        grid-cols-2
      `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    ${tw`
        grid-cols-2
      `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        grid-cols-2
      `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        grid-cols-1
        px-5
      `}
  }
`
