import { Link, NavLink } from "react-router-dom"
import styled from "styled-components"
import tw from "twin.macro"
import { Grid6 } from "../Grid"
import { TfiAngleDown } from "react-icons/tfi"
import { SCREENS } from "../responsive"
import { RxCross1 } from "react-icons/rx"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { objectToUrlParams } from "../../api/config"
import useMenuList from "../../api/hooks/useMenuList"
import { useEffect } from "react"
const MenuHolder = styled.div`
  ${tw`
        flex-grow 
        flex 
        justify-center
    `}
  @media screen and (max-width: ${SCREENS.lg}) {
    display: none;
  }
  @media screen and (max-width: ${SCREENS.sm}) {
  }
`
const MenuUl = styled.ul`
  ${tw`
      flex
      justify-between
      items-center
    `}
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        relative
      `}
  }
`
const Sublink = styled(Link)`
  ${tw`
    flex
    items-center
    text-lg
  `}
  @media screen and (max-width: ${SCREENS["2xl"]}) {
    ${tw`
      text-sm
    `}
  }
`
const Links = styled(NavLink)`
  ${tw`
      text-gray-400
      transition ease-in-out 
      delay-100
      font-normal
      flex
      justify-center
      items-center
      text-lg
    `}

  @media screen and (max-width: ${SCREENS["2xl"]}) {
    ${tw`
      text-sm
    `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    font-size: 12px;
  }
  @media screen and (max-width: ${SCREENS.lg}) {
  }
  @media screen and (max-width: ${SCREENS.sm}) {
  }
`
const MenuLi = styled.li`
  ${tw`
      px-2
    `}
`

const Menu = ({ MenuList, ...props }: any) => {
  const { getMenulist } = useAppSelector((state) => state)
  const { getMenu } = useMenuList()
  const MainList = [
    { list: "Women", key: "Women", link: "/women" },
    { list: "Men", key: "Men", link: "/men" },
    { list: "Accessories", key: "Accessories", link: "/accessories" },
    { list: "Clothing", key: "Clothing", link: "/clothing" },
    { list: "Jewellery", key: "Jewellery", link: "/jewellery" },
    { list: "Stores", key: "", link: "/stores" },
    { list: "Live-Social", key: "", link: "/live" },
    { list: "Deals", key: "", link: "/deals" },
    { list: "Chats", key: "", link: "/chats" },
  ]
  const menus = []
  MainList.map((item) => {
    let sub = []
    let filterApplied = {
      segment_id: "",
      gender_id: "1",
      page: "0",
      price_min: "",
      price_max: "",
      color_list: [],
      size_list: [],
      currency_code: "INR",
      categories_list: [],
    }

    if (item.key.length > 0) {
      getMenulist.menuList.list[item.key].map((chItem) => {
        filterApplied.gender_id = chItem.gender_id
        filterApplied.segment_id = chItem.segment_id
        filterApplied.categories_list = [chItem.category_id]

        sub.push({
          list: chItem.name,
          link:
            "/" +
            item.list.toLowerCase() +
            "/?" +
            objectToUrlParams(filterApplied),
        })
      })

      menus.push({
        list: item.list,
        link: getMenulist.menuList.list[item.key].length <= 0 ? "#" : item.link,
        child: sub,
      })
    } else {
      menus.push({
        list: item.list,
        link: item.link,
        child: sub,
      })
    }
  })
  const buildChild = (list) => {
    let filterApplied = {
      segment_id: "",
      gender_id: "1",
      page: "0",
      price_min: "",
      price_max: "",
      color_list: [],
      size_list: [],
      currency_code: "INR",
      categories_list: [],
    }

    let childList = Array()
    list.map((item) => {
      filterApplied.gender_id = item.gender_id
      filterApplied.segment_id = item.segment_id
      childList.push({
        link: "/discover/?" + objectToUrlParams(filterApplied),
        list: item.name,
      })
    })
    return childList
  }

  useEffect(() => {
    if (getMenulist.menuList.list.Women.length <= 0) {
      getMenu()
    }
  }, [])

  const swithHeader = (targ) => {
    if (!targ.closest(".header").classList.contains("fixed")) {
      targ
        .closest(".baseContainer")
        .querySelector(".bodyContainer").style.marginTop = ""
    } else {
      targ
        .closest(".baseContainer")
        .querySelector(".bodyContainer").style.marginTop = "0"
    }
    targ.closest(".header").classList.toggle("fixed")
    targ.closest(".header").classList.toggle("z-30")
    targ.closest(".header").classList.toggle("w-full")
    targ.closest(".header").classList.toggle("top-0")
    targ.closest(".header").classList.toggle("left-0")
    targ.closest(".header").classList.toggle("bg-white")
  }
  const handleClick = (event, index, item) => {
    event.preventDefault()
    event.stopPropagation()
    // eslint-disable-next-line no-restricted-globals
    if (screen.width < 1024) {
      if (
        !event.currentTarget.parentNode
          .querySelector(".submenu")
          .hasChildNodes()
      ) {
        return
      }
      const target = event.currentTarget

      if (
        !target.parentNode
          .querySelector(".submenu")
          .classList.contains("active")
      ) {
        let elem = document.getElementsByClassName("submenu")
        for (var i = 0; i < elem.length; i++) {
          elem[i].classList.remove("active")
        }
        setTimeout(() => {
          target.parentNode.querySelector(".submenu").classList.add("active")
        }, 200)
      } else {
        target.parentNode.querySelector(".submenu").classList.toggle("active")
      }
    } else {
      event.target.nextElementSibling.classList.toggle("active")
    }
  }
  const handleHover = (event, index, item) => {
    let elem = document.getElementsByClassName("submenu")
    for (var i = 0; i < elem.length; i++) {
      elem[i].classList.remove("active")
    }

    event.preventDefault()
    // eslint-disable-next-line no-restricted-globals
    if (screen.width > 1024) {
      const target = event.currentTarget
      target.parentNode.querySelector(".submenu").classList.toggle("active")
      swithHeader(target)
    }
  }
  const handleToggler = (event) => {
    event.preventDefault()
    const target = event.currentTarget
    target.parentNode.classList.toggle("active")
  }

  const closeMenu = (event) => {
    // eslint-disable-next-line no-restricted-globals
    if (screen.width > 1024) {
      event.preventDefault()
      const target = event.currentTarget
      target.classList.remove("active")
      if (target.closest(".header").classList.contains("fixed")) {
        swithHeader(target)
      }
    }
  }

  return (
    <MenuHolder className="top-menu">
      <div className="mobile-toggler" onClick={handleToggler}>
        <RxCross1 />
      </div>
      <MenuUl>
        {menus.length > 0 &&
          menus.map((item, index) => {
            return (
              <MenuLi key={index} className="menuItem whitespace-nowrap">
                <Links
                  className="hover:text-black hover:font-semibold "
                  to={item?.link}
                  onMouseEnter={(e) => handleHover(e, index, item)}
                  onClick={(e) => {
                    item.child &&
                      item.child.length > 0 &&
                      handleClick(e, index, item)
                  }}
                  key={item.key}
                >
                  {item.list}{" "}
                  {item.child && item.child.length > 0 && (
                    <TfiAngleDown className="text-xs ml-1" />
                  )}
                </Links>
                <Grid6 className="submenu" onMouseLeave={closeMenu}>
                  {item.child &&
                    item.child.length > 0 &&
                    item.child.map((subMenu, subIndex) => {
                      return (
                        <Sublink
                          to={subMenu?.link}
                          key={"sub-" + subIndex}
                          className="flex w-full"
                        >
                          <div className="link-icon-menu"></div>
                          {subMenu.list}
                        </Sublink>
                      )
                    })}
                </Grid6>
              </MenuLi>
            )
          })}
      </MenuUl>
    </MenuHolder>
  )
}

export default Menu
