import { styled } from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../responsive"
import { Marginer } from "../marginer"
import StyledInput from "../input"
import TextHr from "../text-hr"
import StyledLink from "../link"
import { Button } from "../button"
import Logo from "../Globals/Logo"
import { useState } from "react"
import countryList from "../../utils/countryList.json"

let Container = styled.div`
  ${tw`
        flex
        w-full
        items-center
        justify-center
    `}
`

const FormHolder = styled.div`
  ${tw`
        w-6/12
        flex
        flex-col
    `}
  @media (max-width: ${SCREENS.xl}) {
    ${tw`
            w-11/12
        `}
  }
`
const Form = styled.form`
  ${tw`
        w-full
        mt-5
    `}
`
const LogoAuth = styled(Logo)`
  ${tw`
        px-5
    `}
`

export const Login = ({ state, updateState, onSubmit }) => {
  const [selection, setSelection] = useState("Password")
  return (
    <FormHolder>
      <LogoAuth />
      <Marginer direction="vertical" margin="4em" />
      <Form>
        <StyledInput
          id="phoneNumber"
          label="Phone Number"
          type="phone"
          className="!cursor-default"
          value={state.request.phone}
          disabled={state.request.email !== ""}
          onChange={(e, countryCode) => {
            updateState({ type: "phoneCode", value: countryCode })
            updateState({
              type: "request",
              value: {
                ...state.request,
                phone: e,
                country_code: countryList[countryCode],
                phone_code: countryCode,
              },
            })
          }}
        />
        <TextHr text={"or"} className="Hr:hidden" />
        <StyledInput
          id="email"
          label="Email"
          type="email"
          value={state.request.email}
          disabled={state.request.phone !== ""}
          onChange={(e) => {
            updateState({
              type: "request",
              value: { ...state.request, email: e.target.value },
            })
          }}
        />
        {selection != "Password" && (
          <>
            <Marginer direction="vertical" margin="3em" />
            <StyledInput
              id="password"
              label="password"
              type="password"
              value={state.request.password}
              onChange={(e) => {
                updateState({
                  type: "request",
                  value: { ...state.request, password: e.target.value },
                })
              }}
            />
          </>
        )}
      </Form>
      <Marginer direction="vertical" margin="5px" />
      <Container>
        Don't have an account? <Marginer direction="horizontal" margin="1em" />
        <StyledLink
          theme="plain"
          text={"Sign up for free"}
          url={""}
          onClick={(e) => {
            e.preventDefault()
            updateState({ type: "activePage", value: "signup" })
          }}
        />
      </Container>
      <Marginer direction="vertical" margin="5px" />
      <Container>
        <StyledLink
          theme="plain"
          text={"Forgot Password"}
          url={""}
          onClick={(e) => {
            e.preventDefault()
            updateState({ type: "activePage", value: "forgotPassword" })
          }}
        />
      </Container>
      <Button
        theme="outlined"
        text={"Login"}
        className="w-full"
        click={onSubmit}
      />

      <TextHr text={"or"} className="" />
      <Button
        theme="outlined"
        text={"Login With " + selection}
        className="w-full"
        click={() => {
          if (selection == "Password") {
            updateState({ type: "withPassword", value: true })
          } else {
            updateState({ type: "withPassword", value: false })
          }
          setSelection(selection == "Password" ? "OTP" : "Password")
        }}
      />
      <Marginer direction="vertical" margin="4em" />
      <Container>
        <StyledLink theme="plain" text="Continue as Guest" url="/" />
      </Container>
    </FormHolder>
  )
}
