import { useEffect } from "react"
import { useAppSelector } from "../../utils/hooks"
import useChatSearch from "../../api/hooks/useChatsearch"
import useSku from "../../api/hooks/useSku"

export const useSearchCredits = (store_uuid: string) => {
  const { getSkuList } = useSku()
  const skuList = useAppSelector((state) => state.skuList.skuListData)
  console.log(skuList, "skuList")
  useEffect(() => {
    getSkuList(store_uuid)
  }, [store_uuid])

  return { skuList }
}
export const useSearchUser = (name: string) => {
  const { getSearchChat } = useChatSearch()
  const SearchUser = useAppSelector((state) => state.userGifts.data)

  useEffect(() => {
    getSearchChat({ name })
  }, [name])

  return { SearchUser }
}
