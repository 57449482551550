import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  getCalculateInfo,
  IgetCalculateCreditsInfo,
} from "../../../../@types/credits"

const initialState: getCalculateInfo = {
  calCulateinfo: {
    balance_limited_credits: 0,
    balance_unlimited_credits: 0,
    coupon_type: "",
    credits: 0,
    currency_code: "",
    currency_id: 0,
    currency_symbol: "",
    debug: {
      cart_data: {
        cart_total: null,
        currency_id: null,
        order_id: null,
        status: 0,
        store_id: null,
        user_address_id: null,
      },
      status: 0,
    },
    shipping_address_id: 0,
    status: 0,
    total_cart_value: 0,
  },
}

const getCaluCulateCredits = createSlice({
  name: "calCulateCreadits",
  initialState,
  reducers: {
    setCalCulateCreadits: (
      state,
      action: PayloadAction<IgetCalculateCreditsInfo>
    ) => {
      state.calCulateinfo = action.payload
    },
  },
})

export const { setCalCulateCreadits } = getCaluCulateCredits.actions

export default getCaluCulateCredits.reducer
