import styled from "styled-components"
import tw from "twin.macro"
import { CiSearch } from "react-icons/ci"
const SearchInput = styled.input`
  ${tw`
    rounded-md
    h-9
    pl-7
    w-full
`}
  background-color:#f1eded;
`
const SearchHolder = styled.div`
  ${tw`
    mt-5
    relative
    float-right
    w-80
    focus:border focus:border-black
    mt-5
`}
`
const SearchIcon = styled(CiSearch)`
  ${tw`
    absolute
    left-1.5
    top-2
    text-lg
    text-gray-700
    
`}
`
const Search = ({ ...props }) => {
  return (
    <SearchHolder>
      <SearchInput {...props} />
      <SearchIcon />
    </SearchHolder>
  )
}

export default Search
