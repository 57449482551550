import { useEffect, useState } from "react"
import useSku from "../../api/hooks/useSku"
import useStore from "../../api/hooks/useStore"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { default_gender, paramsTypes } from "../../api/config"
import useFilters from "../../api/hooks/useFliters"
import useTags from "../../api/hooks/useTags"

export const useStoreData = ({ searchParams, setSearchParams, uuid }) => {
  const { getSuggest } = useSku()
  const { skuforfilter, get_categories_list, colors_list, getSizeslist } =
    useFilters()
  const { getListTags } = useTags()
  const storeData1 = useAppSelector((state) => state.storelistMain)
  const storeData2 = useAppSelector((state) => state.skuList)
  const { ProductsList } = useAppSelector((state) => state)
  const [showTagData, setShowTagData] = useState(false)

  useEffect(() => {
    get_categories_list(filterApplied)
    colors_list(filterApplied)
    getSizeslist(filterApplied)
  }, [])

  const [filterApplied, setFilterApplied] = useState<paramsTypes>({
    segment_id: searchParams.get("segment_id") ?? "",
    gender_id: searchParams.get("gender_id") ?? default_gender,
    page: searchParams.get("page") ?? "0",
    price_min: searchParams.get("price_min") ?? "",
    price_max: searchParams.get("price_max") ?? "",
    color_list: searchParams.get("color_list")
      ? searchParams.get("color_list").match(/\d+/g)
      : [],
    size_list: searchParams.get("size_list")
      ? searchParams.get("size_list").match(/\d+/g)
      : [],
    currency_code: searchParams.get("currency_code") ?? "INR",
    category_list: searchParams.get("category_list") ?? [],
    store_uuid: uuid ?? "",
  })

  const [segment, setSegment] = useState(searchParams.get("segment_id") ?? "")
  const [gender, setGender] = useState(
    searchParams.get("gender_id") ?? default_gender
  )
  const [priceMin, setPriceMin] = useState(searchParams.get("price_min") ?? "")
  const [priceMax, setPriceMax] = useState(searchParams.get("price_max") ?? "")
  const [page, setPage] = useState(searchParams.get("page") ?? "0")
  const [color, setColor] = useState(
    searchParams.get("color_list")
      ? searchParams.get("color_list").match(/\d+/g)
      : []
  )
  const [size, setSize] = useState(
    searchParams.get("size_list")
      ? searchParams.get("size_list").match(/\d+/g)
      : []
  )
  const [category, setCategory] = useState(
    searchParams.get("category_list")
      ? searchParams.get("category_list").match(/\d+/g)
      : []
  )
  const [storeUuid, setStoreUuid] = useState(uuid ?? "")
  useEffect(() => {
    skuforfilter(filterApplied)
  }, [filterApplied])

  const receiveData = useAppSelector((state) => state.suggestSku.suggestions)
  const { colorsData, categoriesData, sizeslist } = useAppSelector(
    (state) => state
  )

  const handleState = async ({ type, value }) => {
    console.log(filterApplied)
    switch (type) {
      case "segment_id": {
        await setSegment(value)
        setFilterApplied({ ...filterApplied, segment_id: value })
        setSearchParams(() => ({ ...filterApplied, segment_id: value }))
        break
      }
      case "gender_id": {
        await setGender(value)
        setFilterApplied({ ...filterApplied, gender_id: value })
        setSearchParams({ ...filterApplied, gender_id: value })
        break
      }
      case "price_min": {
        await setPriceMin(value)
        setFilterApplied({ ...filterApplied, price_min: value })
        setSearchParams({ ...filterApplied, price_min: value })
        break
      }
      case "price_max": {
        await setPriceMax(value)
        setFilterApplied({ ...filterApplied, price_max: value })
        setSearchParams({ ...filterApplied, price_max: value })
        break
      }
      case "page": {
        await setPage(value)
        setFilterApplied({ ...filterApplied, page: value })
        setSearchParams({ ...filterApplied, page: value })
        break
      }
      case "category": {
        if (category.size > 0 && category.indexOf(value) > -1) {
          category.splice(category.indexOf(value), 1)
          setCategory(category)
          setFilterApplied({
            ...filterApplied,
            category_list: category.splice(category.indexOf(value), 1),
          })
          setSearchParams({
            ...filterApplied,
            category_list: category
              .splice(category.indexOf(value), 1)
              .join(","),
          })
        } else {
          setCategory([...category, value])
          setFilterApplied({ ...filterApplied, category_list: category })
          setSearchParams({
            ...filterApplied,
            category_list: [...category, value].join(","),
          })
        }

        break
      }
      case "colors": {
        if (color.size > 0 && color.indexOf(value) > -1) {
          color.splice(color.indexOf(value), 1)
          setColor(color)
          setFilterApplied({
            ...filterApplied,
            color_list: color.splice(color.indexOf(value), 1),
          })
          setSearchParams({
            ...filterApplied,
            color_list: color.splice(color.indexOf(value), 1).join(","),
          })
        } else {
          setColor([...color, value])
          setFilterApplied({ ...filterApplied, color_list: color })
          setSearchParams({
            ...filterApplied,
            color_list: [...color, value].join(","),
          })
        }

        break
      }
      case "sizes": {
        if (size.size > 0 && size.indexOf(value) > -1) {
          size.splice(size.indexOf(value), 1)
          setSize(size)
          setFilterApplied({
            ...filterApplied,
            size_list: size.splice(size.indexOf(value), 1),
          })
          setSearchParams({
            ...filterApplied,
            size_list: size.splice(size.indexOf(value), 1).join(","),
          })
        } else {
          setSize([...size, value])
          setFilterApplied({ ...filterApplied, size_list: [...size, value] })
          setSearchParams({
            ...filterApplied,
            size_list: [...size, value].join(","),
          })
        }

        break
      }
      case "uuid": {
        await setStoreUuid(value)
        setFilterApplied({ ...filterApplied, store_uuid: value })
        setSearchParams({ ...filterApplied, store_uuid: value })
        break
      }
    }
  }
  const { getStore, getStoreList } = useStore()
  const { getSkuList } = useSku()

  useEffect(() => {
    getStore(uuid)
    getSkuList(uuid)
    getStoreList()
  }, [])

  const handleTags = async (e, { tag_id }) => {
    if (e.target.classList.contains("active")) {
      skuforfilter(filterApplied)
      setShowTagData(false)
      e.target.classList.remove("active")
    } else {
      let elements = e.target
        .closest(".slick-list")
        .querySelectorAll("button.active")
      elements.forEach((element) => element.classList.remove("active"))

      const tagList = await getListTags({
        store_uuid: uuid,
        tag_id,
        tag_type: "product",
      })
      setShowTagData(true)
      e.target.classList.add("active")
    }

    // setSuggestions
  }

  return {
    storeData1,
    storeData2,
    handleState,
    segment,
    gender,
    priceMin,
    priceMax,
    page,
    receiveData,
    colorsData,
    categoriesData,
    sizeslist,
    color,
    size,
    category,
    showTagData,
    ProductsList,
    handleTags,
  }
}
