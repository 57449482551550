import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IHomeData, IHome } from "../../../../@types/user"

const initialState: IHomeData = {
  homeData: {
    app_versions: {
      android: "",
      ios: "",
    },
    banner: [],
    banner_timer: {
      banner_description: "",
      banner_for_share: "",
      banner_id: "",
      banner_title: "",
      expiry: "",
      image_url: "",
      is_enable: "",
      tag_id: "",
      tag_type: "",
    },
    billboard: [],
    categories: [],
    fashion_markets: [],
    initial_signup_credits: 0,
    message: "",
    popup: {
      enable: "",
      line_1: "",
      line_2: "",
      line_3: "",
      line_4: "",
      line_5: "",
    },
    popup_data: [],
    popup_tag_list: [],
    price_range: [],
    section: [],
    sku_data: [],
    status: 0,
    stores: [],
    user_data: {
      alert_notice: "",
      base_country_code: 0,
      currency_data: {
        code: "",
        currency_id: 0,
        decimal_digits: 0,
        name: "",
        name_plural: "",
        rounding: 0,
        symbol: "",
        symbol_native: "",
      },
      fashion_market_pref: "",
      fashion_market_pref_id: 0,
      gender: "",
      gender_id: 0,
      image_url: "",
      image_url_thumb: "",
      is_password_set: "",
      is_user_private: 0,
      phone: "",
      profile_name: "",
      profile_title: "",
      role: "",
      user_creation_date: "",
      user_dob: "",
      user_email: "",
      user_status: 0,
      user_uuid: "",
      username_v1: "",
    },
    video_tags: [],
    videos: [],
    wedding_store_banner: "",
  },
}

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    sethome: (state, action: PayloadAction<IHome>) => {
      state.homeData = action.payload
    },
  },
})

export const { sethome } = homeSlice.actions

export default homeSlice.reducer
