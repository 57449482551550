import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IMyOrderdetails, IMyOrders } from "../../../../@types/myOrders"

const initialState: IMyOrderdetails = {
  suggestions: {
    count: 0,
    error: "",
    page: "",
    per_page: 0,
    receipt_data: [],
    status: 0,
    total_count: 0,
  },
}

const getMyOrder = createSlice({
  name: "myOrder",
  initialState,
  reducers: {
    setMyOrder: (state, action: PayloadAction<IMyOrders>) => {
      state.suggestions = action.payload
    },
  },
})

export const { setMyOrder } = getMyOrder.actions

export default getMyOrder.reducer
