import React from "react"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  GetFollowFollowerData,
  getFollowFollower,
} from "../../../../@types/follow"

const initialState: GetFollowFollowerData = {
  follow: {
    count: 0,
    follower_data: [],
    follower_request_pending: [],
    following_data: [],
    following_request_pending: [],
    page: "",
    per_page: 0,
    status: 0,
    total_follower_data: 0,
    total_following_data_count: 0,
  },
}

const getFollowFollowers = createSlice({
  name: "giftLists",
  initialState,
  reducers: {
    setFollowFollowers: (state, action: PayloadAction<getFollowFollower>) => {
      state.follow = action.payload
    },
  },
})

export const { setFollowFollowers } = getFollowFollowers.actions

export default getFollowFollowers.reducer
