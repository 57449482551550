import { useEffect, useState } from "react"
import useSku from "../../api/hooks/useSku"
import { useAppSelector } from "../../utils/hooks"
import useGift from "../../api/hooks/useGift"
import { PopTost, useCommonCart } from "../../utils/common"
import { useGetCurrency } from "../../Components/Header/Header.Hooks"

export const useProductDeatilsData = (sui: string) => {
  const { getSkuDetails } = useSku()
  const { getRelatedSku } = useSku()
  const { UserCurrency } = useGetCurrency()
  const { addItemGiftList, addFavorite, removeFavorite } = useGift()
  const { addItemToCart } = useCommonCart()
  const [selection, setSelection] = useState({
    color_id: "M",
    size_id: "",
    influencer_user_id: "0",
  })

  useEffect(() => {
    getSkuDetails(sui)
  }, [sui, UserCurrency.userCurrency.currency_data.currency_id])

  const SkuData = useAppSelector((state) => state.storeSku.storeData)
  const SkuRelatedData = useAppSelector((state) => state.relatedSku.related)

  useEffect(() => {
    if (SkuData.store_details.store_uuid) {
      getRelatedSku(sui, SkuData.store_details.store_uuid)
    }
  }, [SkuData.store_details.store_uuid])

  const addToGiftList = async (
    e,
    { sui, color_id, influencer_user_id, size_id, store_uuid, system_name }
  ) => {
    if (size_id.length <= 0) {
      PopTost({ message: "Please select Size", type: "error" })
      return
    }
    if (color_id.length <= 0) {
      PopTost({ message: "Please select Color", type: "error" })
      return
    }

    const add = await addItemGiftList({
      sui,
      color_id,
      influencer_user_id,
      size_id,
      store_uuid,
      system_name,
    })
    if (add.status == 200) {
      PopTost({ message: "Added To Gift List", type: "success" })
    }
    if (add.status == 201) {
      PopTost({ message: add.message, type: "info" })
    }
  }

  const addToFavorite = async (
    e,
    { sui, influencer_user_id, store_uuid, system_name, type }
  ) => {
    e.target.classList.add("text-[#0ea5e9]")
    e.target.classList.remove("text-[#858585]")
    const add = await addFavorite({
      sui,
      influencer_user_id,
      store_uuid,
      system_name,
      type,
    })
    if (add.status == 200) {
      PopTost({ message: "Added To Favorite's.", type: "success" })
    }
    if (add.status == 201) {
      PopTost({ message: add.message, type: "info" })
    }
  }

  const removeFromFavorite = async (e, { sui, type }) => {
    e.target.classList.remove("text-[#0ea5e9]")
    e.target.classList.add("text-[#858585]")
    const add = await removeFavorite({ sui, type })
    if (add.status == 200) {
      PopTost({ message: "Removed from Favorite's.", type: "success" })
    }
    if (add.status == 201) {
      PopTost({ message: add.message, type: "info" })
    }
  }

  const AddToBag = async ({
    currency_id,
    gift_list_id,
    gift_message,
    color_id,
    quantity,
    receipient_uuid,
    size_id,
    sui,
  }) => {
    if (size_id.length <= 0) {
      PopTost({ message: "Please select Size", type: "error" })
      return
    }
    if (color_id.length <= 0) {
      PopTost({ message: "Please select Color", type: "error" })
      return
    }
    addItemToCart({
      currency_id,
      gift_list_id,
      gift_message,
      color_id,
      quantity,
      receipient_uuid,
      size_id,
      sui,
    })
    // currency_id:SkuData.sui_data[0].sku_details.currency_id,gift_list_id:"",gift_message:"",color_id:selection.color_id,quantity:1,receipient_uuid:"",size_id:selection.size_id,sui
  }

  return {
    SkuData,
    SkuRelatedData,
    addToGiftList,
    selection,
    setSelection,
    addToFavorite,
    removeFromFavorite,
    AddToBag,
  }
}
