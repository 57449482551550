import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ISearchUserInfo, IData } from "../../../../@types/userSearch"

// Assuming IData represents an array of user data
const initialState: ISearchUserInfo = {
  data: [],
  status: 0,
}

const getSearchUser = createSlice({
  name: "SearchUser",
  initialState,
  reducers: {
    setuserSearch: (state, action: PayloadAction<IData[]>) => {
      state.data = action.payload
    },
  },
})

export const { setuserSearch } = getSearchUser.actions

export default getSearchUser.reducer
