import { useEffect, useState } from "react"
import { useAppSelector } from "../../../utils/hooks"
import useAccActivity from "../../../api/hooks/useAccActivity"
import { IActivityData, IDebug } from "../../../../@types/activity"

export const useActivityData = () => {
  const { getActivity } = useAccActivity()

  useEffect(() => {
    const getActivityData = async () => {
      await getActivity()
    }
    getActivityData()
  }, [])

  const userActivity = useAppSelector((state) => state.userActivity.activity)

  const [activityData, setActivityData] = useState<IActivityData[]>([])
  const [activityDebug, setActivityDebug] = useState<IDebug[]>([])

  useEffect(() => {
    setActivityData(userActivity.activity_data)
    setActivityDebug([userActivity.debug])
  }, [userActivity])

  return { getActivity, activityData, activityDebug }
}
