import { useEffect, useState } from "react"
import styled from "styled-components"
import { Container } from "../../Components/Globals/Container"
import Main from "../../layouts/Main"
import tw from "twin.macro"
import { Hr, Product, ProductList } from "../Home/Styled.Comps"
import { Accordion, List } from "flowbite-react"
import { SCREENS } from "../../Components/responsive"
import { Marginer } from "../../Components/marginer"
import StyledInput from "../../Components/input"
import Search from "../../Components/Search"
import { useLocation, useSearchParams } from "react-router-dom"
import { useDiscover } from "./Discover.hooks"

import useSearch from "../../api/hooks/useSearch"
import { FaListCheck, FaRegWindowMinimize } from "react-icons/fa6"
import { Pagination } from "flowbite-react"
import Skeleton from "react-loading-skeleton"
import useFilters from "../../api/hooks/useFliters"
import getSearchUser from "../../utils/slices/Chats/getSearchUser"
const Wrapper = styled.div`
  ${tw`
            flex
            flex-row
        `}
`
const Filter = styled.div`
  ${tw`lg:w-1/4 w-[300px]`}
  h2 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`ml-6 `}
  }
`
const ProductsSection = styled.div`
  ${tw`
            flex
            
        `}
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
                w-full
            `}
  }
`
const FilterButton = styled.button`
  ${tw`
            py-2
            px-6
            rounded-full
            mx-2
            mt-2 
            transition-all
            font-bold
            border-gray-400
            text-gray-400
            bg-transparent
            border
        `}
  font-size:14px;
  font-weight: 400;
  &:hover {
    ${tw`
                border-sky-400
                text-sky-400
            `}
  }
  &.active {
    ${tw`
                border-sky-400
                text-sky-400
            `}
  }
`
const NewHr = styled(Hr)`
  ${tw`
        my-4
        `}
`
const Apanel = styled(Accordion.Panel)`
  ${tw`
            border-0
			`}
`

const Discover = () => {
  const location = useLocation()
  const pathLocation = location.pathname

  // console.log("checking path gender", pathLocation)

  const isWomen = pathLocation?.split("/")[1] === "women"
  const isNotmen = pathLocation?.split("/")[1] !== "men"
  const condition = isWomen && isNotmen
  // console.log(`checking condition`, condition);
  // console.log(`checking women ${isWomen}`);

  const [filter, setOpenFilter] = useState(true)
  const openFilter = () => {
    setOpenFilter(!filter)
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const [onFocussearch, _] = useState(
    new URLSearchParams(location.search).get("setFocusSearch") || "false"
  )

  const {
    handleState,
    segment,
    gender,
    priceMin,
    priceMax,
    page,
    receiveData,
    colorsData,
    categoriesData,
    sizeslist,
    color,
    size,
    category,
    clearAllFilters,
    getSearchMatchDict,
    searchData,
    // genderList,
  } = useDiscover({ searchParams, setSearchParams })
  // console.log(color)
  const [query, setQuery] = useState("")
  const [isFilterList, setIsFilterList] = useState(false)
  const [filteredResults, setFilteredResults] = useState(receiveData?.sui_data)
  const [displayData, setDisplayData] = useState(receiveData?.sui_data)

  // Check if there's at least one selected category
  const hasCategorySelected = category && category.length > 0

  const [sortingType, setSortingtype] = useState("")

  const handleSortChange = (event: any) => {
    const value = event.target.value
    setSortingtype(value)
  }

  const getSorted = (dataArray, sorting) => {
    if (dataArray.length) {
      if (sorting === "High to low") {
        return dataArray.sort((a, b) => {
          return b.display_final_price - a.display_final_price
        })
      } else if (sorting === "Low to high") {
        return dataArray.sort((a, b) => {
          return a.display_final_price - b.display_final_price
        })
      } else {
        return dataArray
      }
    }
  }
  const throttle = (func: Function, delay: number) => {
    let lastCall = 0
    return (...args: any[]) => {
      const now = new Date().getTime()
      if (now - lastCall >= delay) {
        lastCall = now
        return func(...args)
      }
    }
  }

  const handleInputChange = async (event: any) => {
    setIsFilterList(true)
    setQuery(event.target.value)
    handleFilterData(event.target.value)
    if (event.target.value.length > 0 && event.target.value !== " ") {
      throttledGetSearchMatchDict(event.target.value)
    }
  }
  const throttledGetSearchMatchDict = throttle(getSearchMatchDict, 300)
  const handleFilterData = (value: string, isListValue: boolean = false) => {
    // console.log(value.length)
    if (value.length) {
      if (!isListValue) {
        const filtered = receiveData?.sui_data?.filter((item) => {
          return item.product_title
            .toLowerCase()
            .trim()
            .includes(value.toLowerCase().trim())
        })
        setFilteredResults(filtered)
      } else {
        const filtered = receiveData?.sui_data?.filter(
          (item) => item.product_title === value
        )

        setDisplayData(filtered)
        setIsFilterList(false)
      }
    } else {
      setDisplayData(receiveData?.sui_data)
      setIsFilterList(false)
    }
  }

  useEffect(() => {
    setQuery(" ")
  }, [category])

  const [userData, setUserData] = useState([])
  const [currentPage, setCurrentPage] = useState(
    Number(receiveData?.current_page) || 1
  )
  const [totalPages, setTotalPages] = useState(0)
  const url = receiveData?.sui_data

  useEffect(() => {
    setUserData(receiveData?.sui_data)
    setTotalPages(Math.ceil(receiveData?.total_count / receiveData?.count))
  }, [receiveData?.sui_data])

  const handleNextClick = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages))
  }
  const handlePrevClick = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
  }

  const preDisable = currentPage === 1
  const nextDisable = currentPage === totalPages

  const itemsPerPage = receiveData?.count
  // console.log(itemsPerPage, "itemsPerPage")
  const startIndex = currentPage
  const endIndex = itemsPerPage
  const itemsToDisplay = userData.slice(startIndex, endIndex)
  const handlePageChange = () => {
    setCurrentPage(itemsPerPage)
  }
  return (
    <Main>
      <Container>
        <div className="relative mt-20">
          <Search
            placeholder="Search"
            onChange={handleInputChange}
            value={query}
            autoFocus={onFocussearch}
            onClick={() => {
              clearAllFilters()
            }}
          />
          {isFilterList && (
            <ul className="w-80 p-2 absolute z-10 top-14 right-0  bg-[#f1eded] max-h-60 overflow-y-auto  ">
              {searchData.map((item) => (
                <List
                  key={item?.id}
                  className="cursor-pointer flex text-left border-b-orange-50 font-medium hover:bg-sky-200  hover:text-black hover:rounded-md"
                >
                  <button
                    className="text-left p-3 w-full"
                    onClick={() => {
                      setQuery(item?.name)
                      // handleFilterData(item?.name, true)
                      handleState({
                        type: item?.type,
                        value: item?.id,
                        click: true,
                      })
                      setIsFilterList(false)
                    }}
                  >
                    {" "}
                    {item.name}
                  </button>
                </List>
              ))}
            </ul>
          )}
        </div>
      </Container>
      <Marginer margin={60} direction="vertical" />
      <Container>
        <Wrapper>
          <FaListCheck
            className="size-5 lg:hidden block text-sky-500"
            onClick={openFilter}
          />
          {filter && (
            <Filter className="filter lg:static absolute z-10 bg-white">
              <Accordion className="border-0">
                <Apanel>
                  <Accordion.Title>Sort By</Accordion.Title>
                  {
                    <Accordion.Content>
                      <div className="flex m-2 gap-8">
                        <select
                          onChange={(e) => handleSortChange(e)}
                          className="p-2 border rounded-md"
                        >
                          {["Low to high", "High to low"].map(
                            (option, index) => (
                              <option key={index}>{option}</option>
                            )
                          )}
                        </select>
                      </div>
                    </Accordion.Content>
                  }
                </Apanel>

                <Apanel>
                  <Accordion.Title>Gender</Accordion.Title>
                  <Accordion.Content>
                    {pathLocation?.includes("men") && !condition && (
                      <FilterButton
                        className={`${gender == 1 ? "active" : null}`}
                        onClick={() => {
                          return handleState({ type: "gender_id", value: 1 })
                        }}
                      >
                        Man
                      </FilterButton>
                    )}
                    {condition && (
                      <FilterButton
                        className={`${gender == 2 ? "active" : null}`}
                        onClick={() =>
                          handleState({ type: "gender_id", value: 2 })
                        }
                      >
                        Woman
                      </FilterButton>
                    )}
                  </Accordion.Content>
                </Apanel>
                <NewHr />
                <Apanel>
                  <Accordion.Title>Price</Accordion.Title>
                  <Accordion.Content>
                    <div className="flex m-2 gap-8">
                      <StyledInput
                        id="min_price"
                        label="Min Price"
                        type="number"
                        value={priceMin}
                        onChange={(e) => {
                          handleState({
                            type: "price_min",
                            value: e.target.value,
                          })
                        }}
                      />

                      <StyledInput
                        id="max_price"
                        label="Max Price"
                        type="number"
                        value={priceMax}
                        onChange={(e) => {
                          handleState({
                            type: "price_max",
                            value: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </Accordion.Content>
                </Apanel>
                <NewHr />
                <Apanel>
                  <Accordion.Title>Category</Accordion.Title>
                  <Accordion.Content className="h-64 overflow-y-auto ">
                    {categoriesData?.categoryData?.categories_list.map(
                      (item, index) => (
                        <FilterButton
                          key={index}
                          className={`${category && category.indexOf(item.category_id.toString()) > -1 ? "active" : null}`}
                          onClick={() => {
                            // console.log(item.category_id)
                            handleState({
                              type: "category",
                              value: item.category_id.toString(),
                            })
                          }}
                        >
                          {item?.["name"]}
                        </FilterButton>
                      )
                    )}

                    {hasCategorySelected && (
                      <FilterButton
                        className="active"
                        onClick={() => {
                          clearAllFilters()
                        }}
                      >
                        Clear All
                      </FilterButton>
                    )}
                  </Accordion.Content>
                </Apanel>
                <NewHr />
                <Apanel>
                  <Accordion.Title>Colors</Accordion.Title>
                  {hasCategorySelected && (
                    <Accordion.Content className="h-64 overflow-y-auto ">
                      {colorsData?.colorsListData?.colors_data?.map(
                        (item, index) => (
                          <FilterButton
                            key={index}
                            className={
                              color && color.includes(item.color_id)
                                ? "active"
                                : ""
                            }
                            onClick={() => {
                              const colors = color
                              const updatedColors = color.includes(
                                item?.color_id
                              )
                                ? color.filter(
                                    (colorId) => colorId !== item?.color_id
                                  )
                                : [...color, item.color_id]
                              // console.log(updatedColors, color.includes(item.color_id), [...color, item.color_id], "we are")
                              handleState({
                                type: "color",
                                value: updatedColors,
                              })
                            }}
                          >
                            {item.color_name}
                          </FilterButton>
                        )
                      )}
                    </Accordion.Content>
                  )}
                </Apanel>

                <NewHr />
                <Apanel>
                  <Accordion.Title>Sizes</Accordion.Title>
                  <Accordion.Content>
                    {sizeslist?.sizeListData.sizes_data?.map((item, index) => (
                      <FilterButton
                        key={index}
                        className={`${size && size.indexOf(item.size_id.toString()) > -1 ? "active" : null}`}
                        onClick={() => {
                          handleState({
                            type: "sizes",
                            value: item.size_id.toString(),
                          })
                        }}
                      >
                        {item.size_name}
                      </FilterButton>
                    ))}
                  </Accordion.Content>
                </Apanel>
              </Accordion>
            </Filter>
          )}
          <div className="w-3/4">
            <ProductsSection>
              {receiveData?.sui_data?.length > 0 ? (
                <ProductList>
                  {getSorted(receiveData?.sui_data, sortingType).map(
                    (proitem, index) => (
                      <Product
                        link={`/product-details/${proitem.SUI ?? "na"}`}
                        key={index}
                        image={proitem.image_url}
                        name={proitem.product_title}
                        price={proitem.display_final_price}
                        salePrice={
                          proitem.display_total_price -
                          proitem.platform_discount -
                          proitem.store_discount
                        }
                        item={proitem}
                        symbol={proitem.display_currency_symbol}
                        currency_code={proitem.display_currency_code}
                      />
                    )
                  )}
                </ProductList>
              ) : (
                <Skeleton />
              )}
            </ProductsSection>
            {receiveData?.sui_data?.length > 0 && (
              <div className="pagination-wrapper">
                <div className=" mb-16 flex mt-4 justify-center gap-2  ml-16">
                  <button
                    onClick={handlePrevClick}
                    disabled={preDisable}
                    className=" font-bold text-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => {
                    const page = i + 1
                    const isHidden = Math.abs(page - currentPage) > 2
                    return (
                      <div className="pagination block w-8 h-8 text-center border border-gray-100 rounded leading-8  dark:active:bg-sky-400 focus:bg-sky-400">
                        <button
                          key={i}
                          onClick={() =>
                            handleState({ type: "page", value: i })
                          }
                          disabled={i + 1 === currentPage}
                          className={`${isHidden ? "hidden" : ""} ${page === currentPage ? "active" : ""}`}
                        >
                          {i + 1}
                        </button>
                      </div>
                    )
                  })}
                  <button
                    onClick={handleNextClick}
                    disabled={nextDisable}
                    className="font-bold text-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
          </div>
        </Wrapper>
      </Container>
      <Marginer margin={60} direction="vertical" />
    </Main>
  )
}

export default Discover
