import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Marginer } from "../../../Components/marginer"
import axios from "axios"
import { API, API_TOKEN } from "../../../api/config"
import { toast } from "react-toastify"

const ContainerRight = styled.div`
  ${tw` 
    justify-start w-full text-sm lg:py-6 py-10 lg:p-6 p-20 pr-6 
    
`}
`
const ContainerDelete = styled.div`
  ${tw` 
   p-8 rounded-md border-2 border-gray-200 bg-transparent w-full
`}
`
const DeleteHead = styled.div`
  ${tw` 
    font-bold text-[20px]
`}
`
const DivBottom = styled.div`
  ${tw` 
    flex flex-col mt-[500px] gap-1 
`}
`
const Button = styled.button`
  ${tw` 
    text-white
    bg-sky-500  
    text-xl
    p-2
    rounded-full
`}
`
export default function DeleteAccount() {
  async function DeleteAccountUser() {
    try {
      let response = await axios.post(`${API}/user/disable_user`, {
        api_token: API_TOKEN,
      })
      console.log(response)
      localStorage.removeItem("userData")
      if (response.status === 200) {
        toast.success("Delete Account successfully")
      }
    } catch (error) {
      console.error(error, "Error")
    }
  }

  return (
    <ContainerRight>
      <ContainerDelete>
        <DeleteHead>Delete Account</DeleteHead>
        <Marginer margin={10} direction="vertical" />
        <div className="md:text-lg text-md font-normal">
          Deleting your account is permanent. When you delete your Seashels
          account,you
          <br />
          <span>
            won't be able to retrieve the content or information you've shared
            on Seashels.
          </span>
        </div>
      </ContainerDelete>
      <DivBottom>
        <Button onClick={DeleteAccountUser}>
          Continue to Account Deletion
        </Button>
        <Marginer margin={10} direction="vertical" />
        <button className="text-sm font-bold">Cancel</button>
      </DivBottom>
      <Marginer margin={50} direction="vertical" />
    </ContainerRight>
  )
}
