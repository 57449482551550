import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { store } from "./app/utils/store"
import { Provider } from "react-redux"
import { SbCallsProvider } from "./sendBirdCalls/lib/sendbird-calls"
import { ThemeProvider } from "styled-components"
import { ToastContainer } from "react-toastify"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <Provider store={store}>
    <SbCallsProvider appId="">
      <ThemeProvider theme={{ isWidget: false }}>
        <App />
      </ThemeProvider>
    </SbCallsProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
