import { Select } from "flowbite-react"
import styled from "styled-components"
import tw from "twin.macro"
import { IoIosCloseCircleOutline } from "react-icons/io"
import { SCREENS } from "../responsive"
import { useShoppingBagData } from "../../Pages/ShoppingBag/shoppingBag.Hooks"
import { useCartProducts } from "./cartProduct.hook"
import { Button } from "../button"
import { Link } from "react-router-dom"
const ProductHolder = styled.div`
  ${tw`
    flex 
    w-full
    h-72
    py-7
    relative
`}
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        flex-col
        items-center
        h-auto
    `}
  }
`

const ImageHolder = styled.div`
  ${tw`
    w-52
    h-52
    inline-block
    justify-center
    items-center
    overflow-hidden
    rounded-2xl
`}
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        mb-3
    `}
  }
`
const ContentHolder = styled.div`
  ${tw`
    flex-grow
    ml-6
`}
`
const Title = styled.h3`
  ${tw`
    text-lg
    font-bold
    max-w-[85%]
`}
`
const Small = styled.h4`
  ${tw`
text-xs
font-normal
text-[#777]
`}
`
const Price = styled.p`
  ${tw`
    absolute
    -bottom-4
    right-[15px]
    text-[16px]
    font-semibold mb-2
`}
`
const OriginalPrice = styled.p`
  ${tw`
    absolute
    -bottom-4
    right-[100px]
    text-[16px]
    font-semibold
    text-gray-400 line-through mb-2
`}
`
const Remove = styled.span`
  ${tw`
    absolute
    top-7
    right-4
`}
`
const DiscountPrice = styled.span`
  ${tw`
    text-sm
    flex mt-2
  
`}
`
const InputHolder = styled.span`
  ${tw`
    flex
    items-center
  mr-2
    bg-white
    border-[#EBEBEB]
    border
    py-0 px-2
    justify-center
    xl:mt-0 lg:mt-2 mt-2 
`}
  select {
    border: transparent;
    cursor: pointer;
  }
  select:focus {
    border: transparent;
    cursor: pointer;
  }
`
const Inputs = styled.div`
  ${tw`
    flex xl:flex-nowrap lg:flex-wrap flex-wrap
    flex-row md:justify-start justify-center
    items-center
    mt-3 
`}
`
const CartProduct = ({ title, subhead, image, ...props }) => {
  const { CartDetailsData } = useShoppingBagData()

  const {
    removeItem,
    options,
    increase,
    decrease,
    symbol,
    onChange,
    updateItem,
  } = useCartProducts(props)
  const propsItemTotal = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
  }).format(props.item.total)
  const propsItemOriginalTotal = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
  }).format(props.item.original_price)

  return (
    <ProductHolder key={props.key}>
      <ImageHolder>
        <Link to={"/product-details/" + props.item.SUI}>
          <img src={image} alt={"product - " + title} />
        </Link>
      </ImageHolder>

      <ContentHolder>
        <Title>
          <Link to={"/product-details/" + props.item.SUI}>{title}</Link>
        </Title>
        <Small>{subhead}</Small>
        <Inputs>
          <InputHolder>
            <label
              htmlFor="quantity-input"
              className=" text-xs font-light text-black inline-flex"
            >
              Qty
            </label>
            <div className="inline-flex ml-2">
              <button
                type="button"
                id="decrement-button"
                data-input-counter-decrement="quantity-input"
                className="px-2"
                onClick={decrease}
              >
                <svg
                  className="w-3 h-3 text-black "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 2"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h16"
                  />
                </svg>
              </button>
              <input
                type="text"
                id="quantity-input"
                data-input-counter
                aria-describedby="helper-text-explanation"
                className="rounded-lg border-0 focus:ring-0 w-10"
                placeholder={CartDetailsData.cart_items[0].quantity}
                value={options.quantinty}
                required
                onChange={() => {}}
              />
              <button
                type="button"
                id="increment-button"
                data-input-counter-increment="quantity-input"
                className="px-2"
                onClick={increase}
              >
                <svg
                  className="w-3 h-3 text-black"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </button>
            </div>
          </InputHolder>
          {props?.item?.available_sizes.length > 0 && (
            <InputHolder>
              <label
                htmlFor="size"
                className=" text-xs  font-normal text-black inline-flex mr-2"
              >
                Size
              </label>
              <Select
                name="size"
                id="size"
                className=" bg-white"
                color={"white"}
                key={"cart_size"}
                defaultValue={options.size_id}
                onChange={(e) => {
                  onChange(e, "size")
                }}
              >
                {props?.item?.available_sizes.map((size, si) => {
                  return (
                    <option key={"size-" + si} value={size.size_id}>
                      {size.size_name}
                    </option>
                  )
                })}
              </Select>
            </InputHolder>
          )}
          {props?.item?.available_colors.length > 0 && (
            <InputHolder>
              <label
                htmlFor="color"
                className=" text-xs font-light text-black inline-flex mr-2"
              >
                Color
              </label>
              <Select
                name="color"
                id="color"
                className=" bg-white"
                color={"white"}
                key={"cart_color"}
                defaultValue={options.color_id}
                onChange={(e) => {
                  onChange(e, "color")
                }}
              >
                {props?.item?.available_colors.map((color, ci) => {
                  return (
                    <option key={"color-" + ci} value={color.color_id}>
                      {color.color_name}
                    </option>
                  )
                })}
              </Select>
            </InputHolder>
          )}
        </Inputs>

        <div className="">
          <OriginalPrice>{symbol + " " + propsItemOriginalTotal}</OriginalPrice>
          <Price>{symbol + " " + propsItemTotal}</Price>
          <DiscountPrice>
            <h4 className="mr-2  font-semibold">Discount:</h4>
            {Math.ceil(
              ((props.item.original_price - props.item.total) /
                props.item.original_price) *
                100
            )}
            %
          </DiscountPrice>
        </div>
        {options.isUpdated && (
          <Button
            className="!w-1/2"
            click={async () => {
              updateItem({
                sui: props.item.SUI,
                final_price: props.item.final_price,
              })
              await props.remove()
              return
            }}
            theme={"normal"}
            text={"Update"}
          />
        )}
        <Remove
          onClick={async (e) => {
            let targ = e.target
            await removeItem(props.item.SUI)
            setTimeout(async () => {
              await props.remove()
            }, 500)
          }}
        >
          <IoIosCloseCircleOutline
            style={{
              fontSize: "24px",
              marginRight: "-15px",
              cursor: "pointer",
            }}
          />
        </Remove>
      </ContentHolder>
    </ProductHolder>
  )
}

export default CartProduct
