import React from "react"
import axios from "axios"
import { API, getToken, tokenPayloadLogin } from "../config"
import { useAppDispatch } from "../../utils/hooks"
import { setStoreGiftList } from "../../utils/slices/Gifts/getStoreGiftList"
import { setFavList } from "../../utils/slices/Gifts/userFavList"
import { setGiftLists } from "../../utils/slices/Gifts/getGiftList"

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers.common["Content-Type"] = "application/json"

const useGift = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()

  const removeItemGiftList = async ({ sui }) => {
    const payload = tokenPayloadLogin({ sui })
    const addItemGiftList = axios.post(
      `${API}/gift/add_item_to_gift_list`,
      payload
    )
    return (await addItemGiftList).data
  }

  const addItemGiftList = async ({
    sui,
    system_name,
    store_uuid,
    size_id,
    color_id,
    influencer_user_id,
  }) => {
    const payload = tokenPayloadLogin({
      sui,
      system_name,
      store_uuid,
      size_id,
      color_id,
      influencer_user_id,
    })
    const addItemGiftList = axios.post(
      `${API}/gift/add_item_to_gift_list`,
      payload
    )
    return (await addItemGiftList).data
  }

  const addFavorite = async ({
    type,
    sui,
    system_name,
    store_uuid,
    influencer_user_id,
  }) => {
    const payload = tokenPayloadLogin({
      reference_id: sui,
      system_name,
      store_uuid,
      influencer_user_id,
      type,
    })
    const addItemFav = axios.post(`${API}/favorite/set_favorite`, payload)
    return (await addItemFav).data
  }
  const removeFavorite = async ({ type, sui }) => {
    const payload = tokenPayloadLogin({ reference_id: sui, type })
    const addItemFav = axios.post(`${API}/favorite/remove_favorite`, payload)
    return (await addItemFav).data
  }

  function getGiftList() {
    const GiftList = axios
      .get(`${API}/gift/get_user_gift_list?api_token=` + API_TOKEN + `&page=0`)
      .then((res) => {
        dispatch(setGiftLists(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  function getUserFavouriteList() {
    const userFavouriteList = axios
      .get(`${API}/favorite/get_favorite?api_token=` + API_TOKEN)
      .then((res) => {
        console.log(res)
        dispatch(setFavList(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getStoreGiftList() {
    const storeGiftList = axios
      .get(
        `${API}/gift/get_store_gift_list?api_token=` +
          API_TOKEN +
          `&store_uuid=aad8e7c98c114bbc9635d160c07cf0fe&page=0`
      )
      .then((res) => {
        dispatch(setStoreGiftList(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return {
    getStoreGiftList,
    getUserFavouriteList,
    getGiftList,
    addItemGiftList,
    removeItemGiftList,
    addFavorite,
    removeFavorite,
  }
}
export default useGift
