import { useEffect } from "react"
import useFilters from "./app/api/hooks/useFliters"

export const useAppAuth = () => {
  const { getMenu } = useFilters()

  useEffect(() => {
    const getMenu = async () => {
      if (!localStorage.getItem("men")) {
        await getMenu()
      }
    }
    if (
      (!localStorage.getItem("token") ||
        localStorage.getItem("token") == "undefined" ||
        localStorage.getItem("token") == "") &&
      !(window.location.href.indexOf("Auth") > -1)
    ) {
      window.location.href = "/Auth"
    }
  }, [])
  return
}
