import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  ISearch,
  ISearchDict,
  ISearchDictionary,
} from "../../../../@types/dict"

const initialState: ISearchDict = {
  search_data: [],
  status: 0,
}

const categoriesListSlice = createSlice({
  name: "searchDict",
  initialState,
  reducers: {
    setDictList: (state, action: PayloadAction<ISearch>) => {
      state.search_data = action.payload.data
      state.status = action.payload.status
    },
  },
})
export const { setDictList } = categoriesListSlice.actions

export default categoriesListSlice.reducer
