import axios from "axios"
import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { API, getToken, default_gender, paramsTypes } from "../config"
import { setSuggestions } from "../../utils/slices/Sku/suggestSkuSlice"
import { setRelated } from "../../utils/slices/Sku/relatedSkuSlice"
import { setMultiple } from "../../utils/slices/Sku/multipleSkuSlice"
import { setStoreListMarket } from "../../utils/slices/Sku/storeListMarketSkuSlice"
import { setStoreSku } from "../../utils/slices/Sku/storeSkuSlice"
import { setProducts } from "../../utils/slices/Products/ProductsSlice"
import { setSkuList } from "../../utils/slices/Sku/skuListSlice"

const useSku = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()

  function getSuggest(params: paramsTypes) {
    dispatch(
      setProducts({
        data: [],
        count: 0,
        current_page: 0,
        total_count: 0,
        next_page: -1,
        prev_page: 0,
      })
    )
    dispatch(
      setSuggestions({
        count: 0,
        current_page: "0",
        price_band: [],
        segment_data: [],
        status: 200,
        sui_data: [],
        total_count: 0,
      })
    )

    const { gender_id, segment_id, page } = params

    const suggest = axios
      .get(
        `${API}/sku/suggest?api_token=${API_TOKEN}&page=${page}&segment_id=${segment_id}&gender_id=${gender_id}`
      )
      .then((res) => {
        // console.log(res);
        dispatch(setSuggestions(res.data))
        dispatch(
          setProducts({
            data: [...res.data.sui_data],
            count: res.data.count,
            current_page: parseInt(res.data.current_page),
            total_count: res.data.total_count,
            next_page: res.data.next_page,
            prev_page: res.data.prev_page,
          })
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getSkuDetails(sui: string) {
    dispatch(
      setRelated({
        current_index: 0,
        per_page: 0,
        sku_count: 0,
        sku_data: [],
        status: 200,
        store_details: { store_logo: "", store_name: "", store_region: "" },
        total_count: 0,
      })
    )
    dispatch(
      setStoreSku({
        shopper_details: "",
        status: 200,
        store_details: {
          base_country: "",
          city: "",
          store_logo: "",
          store_logo_thumb: "",
          store_name: "",
          store_owner: 0,
          store_username: "",
          store_uuid: "",
        },
        sui_data: [],
      })
    )
    const skuDetails = axios
      .get(
        `${API}/sku/get_sku_details?api_token=${API_TOKEN}&sui=${sui}&is_private=0`
      )
      .then((res) => {
        dispatch(setStoreSku(res.data))
        // console.log(sui,"sui")
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getRelatedSku(sui: string, store_uuid: string) {
    dispatch(
      setRelated({
        current_index: 0,
        per_page: 0,
        sku_count: 0,
        sku_data: [],
        status: 200,
        store_details: { store_logo: "", store_name: "", store_region: "" },
        total_count: 0,
      })
    )
    const relatedSku = axios
      .get(
        `${API}/sku/get_related_sku?api_token=${API_TOKEN}&sui=${sui}&store_uuid=${store_uuid}`
      )
      .then((res) => {
        dispatch(setRelated(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getSkuList = async (uuid: string) => {
    const API_TOKEN = getToken()

    try {
      const response = await axios.get(
        `${API}/sku/get_sku_list?api_token=${API_TOKEN}&store_uuid=${uuid}&is_private=0&page=0`
      )
      console.log(response)
      dispatch(setSkuList(response.data))

      return response.data
    } catch (error) {
      console.error("Error fetching SKU list:", error)
      throw error
    }
  }

  function getMultipleSku() {
    const suggest = axios
      .get(
        `${API}/sku/get_multiple_sku?api_token=${API_TOKEN}&sui_list=22082108012, 22082108011, 22082108010&store_uuid=a882de080f084fe28ba19300901f6a5d`
      )
      .then((res) => {
        dispatch(setMultiple(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getStoreSkuDetails() {
    const suggest = axios
      .get(
        `${API}/sku/get_store_sku_details?api_token=${API_TOKEN}&sui=23101523005&gift_id=0&favorite_id=5817&is_private=0`
      )
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function getStoreSkuListMarket() {
    const storeSku = axios
      .get(
        `${API}/sku/get_store_sku_list_for_markets?api_token=${API_TOKEN}&store_uuid=4d10e01de1cb43bba2723572954c796f&is_private=0&page=0&fashion_markets=1,2`
      )
      .then((res) => {
        dispatch(setStoreListMarket(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return {
    getSuggest,
    getSkuDetails,
    getRelatedSku,
    getSkuList,
    getMultipleSku,
    getStoreSkuDetails,
    getStoreSkuListMarket,
  }
}

export default useSku
