import { useEffect, useState } from "react"
import useGuestLogin from "../../api/hooks/useGuestLogin"
import useAuthApi from "../../api/hooks/useAuthApi"
import { useNavigate } from "react-router-dom"
import { Bounce, toast } from "react-toastify"
export const useGetGuest = () => {
  const {
    loginApi,
    loginOTP,
    sendOTP,
    confirmOTP,
    signupApi,
    forgotPassword,
    check,
    forgotSetPassword,
  } = useAuthApi()
  const navigate = useNavigate()
  const [state, setState] = useState({
    activePage: "",
    verifyFor: "",
    withPassword: false,
    isForget: false,
    passOtpConfirm: false,
    phoneCode: "",
    request: {
      phone: "",
      email: "",
      password: "",
      gender: "",
      name: "",
      country_code: "",
      referral_code: "",
      role: "shopper",
      user_uuid: "",
      otp: "",
      phone_code: "",
    },
  })

  const { generateGuest } = useGuestLogin()
  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/")
    }
    if (!localStorage.getItem("token")) {
      generateGuest()
    }
  }, [])

  const handleState = ({ type, value }) => {
    setState({ ...state, [type]: value })
  }

  const login = async () => {
    if (state.request.email.length == 0 && state.request.phone.length == 0) {
      toast.error("One should be filled from E-mail and Phone .", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
    } else {
      if (state.withPassword) {
        if (
          state.request.password.length == 0 ||
          state.request.password.length < 4
        ) {
          toast.error("A password should be at least 4 characters long.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          })
          return
        }
        const response = await loginApi(state)

        //@ts-ignore
        if (response.status == 200 || response.status == 201) {
          navigate("/")
        }
      } else {
        // console.log(state.request.phone_code.substring(1))
        const response = await loginOTP({
          email: state.request.email,
          phone:
            state.request.country_code +
            "-" +
            state.request.phone.substring(
              state.request.phone_code.substring(1).length
            ),
        })
        //@ts-ignore
        if (response.status == 200 || response.status == 201) {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          })
          setState({ ...state, activePage: "verify" })
        }
      }
    }
  }

  const signup = async () => {
    if (state.request.email.length == 0 && state.request.phone.length == 0) {
      toast.error("One should be filled from E-mail and Phone .", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
      return
    }
    if (state.request.name.length == 0) {
      toast.error("Profile name should be filled.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
      return
    }

    if (state.request.email.length > 0) {
      let check = await checkUser()

      if (check) {
        return false
      }
    }

    if (state.withPassword) {
      if (
        state.request.password.length == 0 ||
        state.request.password.length < 4
      ) {
        toast.error("A password should be at least 4 characters long.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
        return false
      }
    }
    const response = await signupApi(state)

    if (response.status == 200 || response.status == 201) {
      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
      setState({ ...state, activePage: "verify" })
    }
  }

  const verify = async () => {
    const response = await confirmOTP({
      otp: state.request.otp,
      user_uuid: localStorage.user,
    })
    if (response.status == 200 || response.status == 201) {
      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
      if (state.isForget) {
        setState({
          ...state,
          activePage: "forgotPassword",
          passOtpConfirm: true,
        })
      } else {
        navigate("/")
      }
    }
  }

  const checkUser = async () => {
    const response = await check({ username: state.request.email })
    if (response.status == 200 || response.status == 201) {
      toast.error("E-mail already registered.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
      return true
    }
    return false
  }

  const resendOTP = async () => {
    const response = await sendOTP({ user_uuid: localStorage.user })
    if (response.status == 200 || response.status == 201) {
      toast.success(response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      })
    }
  }

  const forgetPassword = async () => {
    if (state.passOtpConfirm) {
      const response = await forgotSetPassword({
        uuid: state.request.user_uuid,
        new_phone: state.request.password,
      })
      if (response.status == 200 || response.status == 201) {
        toast.success("OTP Sent.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
        navigate("/")
      }
    } else {
      const response = await forgotPassword({
        email: state.request.email,
        phone: state.request.phone,
      })
      if (response.status == 200 || response.status == 201) {
        toast.success("OTP Sent.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
        let data = response?.message
        localStorage.setItem("user", data?.user_uuid)
        //@ts-ignore
        setState({
          ...state,
          activePage: "verify",
          request: { ...state.request, user_uuid: data?.user_uuid },
        })
      }
    }
  }

  return {
    state,
    handleState,
    login,
    signup,
    verify,
    checkUser,
    resendOTP,
    forgetPassword,
  }
}
