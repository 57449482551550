import React, { useRef, useEffect, useContext } from "react"
import Banner from "../../Components/BannerSmall"
import Main from "../../layouts/Main"
import styled from "styled-components"
import tw from "twin.macro"
import LiveSocial from "../../Components/LiveSocial"
import ThumbSlide from "../../Components/LiveSocial/ThumbSlides"
import { Container } from "../../Components/Globals/Container"
import { Marginer } from "../../Components/marginer"
import { BlockHeading } from "../../Components/Globals/BlockHeading"
import { Hr, Product, ProductList } from "../Home/Styled.Comps"
import { useState } from "react"
import useStore from "../../api/hooks/useStore"
import { useAppDispatch } from "../../utils/hooks"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useStoreData } from "./Store.Hooks"
import { Accordion } from "flowbite-react"
import { SCREENS } from "../../Components/responsive"
import StyledInput from "../../Components/input"
import { Link } from "react-router-dom"
import { useSendbird } from "../Chats/Chats.Hooks"
import { setStorelistMain } from "../../utils/slices/Stores/getStore"
import FilterTrack from "../../Components/FilterTrack"
import useTalkToStore from "../../api/hooks/useTalkToStore"
import { FaImage, FaListCheck } from "react-icons/fa6"
import { NumericFormat } from "react-number-format"
import Skeleton from "react-loading-skeleton"
import SocialThumb from "../../Components/LiveSocial/SocialThumb"
const Wrapper = styled.div`
  ${tw`
            flex
            flex-row
        `}
`
const Filter = styled.div`
  ${tw` w-1/4`}
  h2 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`ml-6 w-[40%]`}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`ml-6 w-3/4`}
  }
`
const ProductsSection = styled.div`
  ${tw`
            
            w-3/4
        `}
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
                w-full
            `}
  }
`
const FilterButton = styled.button`
  ${tw`
            py-2
            px-6
            rounded-full
            mx-2
            mt-2 
            transition-all
            font-bold
            border-gray-400
            text-gray-400
            bg-transparent
            border
        `}
  font-size:14px;
  font-weight: 400;
  &:hover {
    ${tw`
                border-sky-400
                text-sky-400
            `}
  }
  &.active {
    ${tw`
                border-sky-400
                text-sky-400
            `}
  }
`
const NewHr = styled(Hr)`
  ${tw`
        my-4
        `}
`
const Apanel = styled(Accordion.Panel)`
  ${tw`
            border-0
			`}
`

const PageBanner = styled(Banner)``
const SectionHeading = styled(BlockHeading)`
  ${tw`
        text-black
        text-xl
    `}
`
const CardContent = styled.div`
  ${tw` 
    flex
    justify-between 
    flex-wrap
    mt-3
    
    `}
`
const CardContentDetails = styled.div`
  ${tw` 
    flex
    `}
`
const Button = styled.button`
  ${tw` 
    mt-2
    p-1
    pr-7
    pl-7
    rounded-lg
    border-solid 
    border-2 
    bg-gray-200
    text-[#038DCE] 
    font-semibold
    ml-8
    `}
`
const ButtonMain = styled.div`
  ${tw` 
    gap-6 
    inline-block
    self-center 
    `}
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
      mt-8
                `}
  }
`

export interface LinkData {
  link: any
}
export interface item {
  category_id: number | string
  name: string
} // i'm not able to use this so tell where i have to define the interface

const StorePage = ({ ...props }) => {
  const { uuid } = useParams<"uuid">()
  const channelRef = useRef()
  const { joinChannel, leaveChannel } = useSendbird(channelRef)
  const [searchParams, setSearchParams] = useSearchParams()
  const { followStore, unFollowStore, getStoreSeller } = useStore()
  const [filter, setOpenFilter] = useState(false)

  const openFilter = () => {
    setOpenFilter(!filter)
  }
  const dispatch = useAppDispatch()
  const {
    receiveData,
    storeData1,
    storeData2,
    handleState,
    gender,
    priceMin,
    priceMax,
    colorsData,
    categoriesData,
    sizeslist,
    color,
    size,
    category,
    handleTags,
    ProductsList,
  } = useStoreData({ searchParams, setSearchParams, uuid })
  const videoData = storeData1?.suggestions?.video_data
  console.log(storeData1, "storeData1")
  console.log(storeData2, "storeData2")
  const [tagid, setTagId] = useState("")
  const [selectTag, setSelectTag] = useState("")

  const navigate = useNavigate()
  const { addChannel } = useTalkToStore()

  const handleFollow = async (e) => {
    e.preventDefault()
    if (!storeData1.suggestions.is_following) {
      const resp = await followStore({
        uuid: storeData1.suggestions.store_user_uuid,
      })
      if (resp.status == 200 && storeData1.suggestions.store_chat_url) {
        storeData1.suggestions.store_chat_url &&
          joinChannel(storeData1.suggestions.store_chat_url)
      }
      dispatch(
        setStorelistMain({ ...storeData1.suggestions, is_following: true })
      )
    } else {
      const resp = await unFollowStore({
        uuid: storeData1.suggestions.store_user_uuid,
      })
      if (resp.status == 200 && storeData1.suggestions.store_chat_url) {
        storeData1.suggestions.store_chat_url &&
          leaveChannel(storeData1.suggestions.store_chat_url)
      }
      dispatch(
        setStorelistMain({ ...storeData1.suggestions, is_following: false })
      )
    }
  }

  const [categorygender, setCategoryGender] = useState([])
  const [userData, setUserData] = useState([])
  const [currentPage, setCurrentPage] = useState(
    Number(storeData2.skuListData.current_index)
  )
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    const categoryId = categorygender?.map((item) => item?.category_id)
    const filterData =
      categorygender?.length > 0
        ? storeData2.skuListData.sku_data?.filter((item) =>
            categoryId?.includes(item?.category_id)
          )
        : storeData2.skuListData.sku_data
    setUserData(filterData)
    console.log(filterData, "filterData")

    setTotalPages(Math.ceil(storeData2.skuListData.total_count / 2))
  }, [ProductsList.list.data, categorygender])

  const category_gender = (gender: any, storeData1: any) => {
    const { suggestions } = storeData1

    if (suggestions && suggestions.gender_list) {
      const genderList = Object.values(suggestions.gender_list).flat()

      if (!gender || gender === 3 || !genderList?.length) {
        return genderList
      } else {
        return genderList?.filter(
          (item) => item?.["gender_id"] === Number(gender)
        )
      }
    }

    return []
  }

  useEffect(() => {
    const genderData = category_gender(gender, storeData1)
    setCategoryGender(genderData)
    // console.log(genderData, storeData1, gender, "checking 1sth render")
  }, [gender, storeData1, storeData2])

  const handleNextClick = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages))
  }
  const handlePrevClick = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
  }

  const preDisable = currentPage === 1
  const nextDisable = currentPage === totalPages

  const itemsPerPage = storeData2.skuListData.sku_count
  const startIndex = currentPage
  const endIndex = itemsPerPage
  const itemsToDisplay = userData.slice(startIndex, endIndex)

  const handlePageChange = () => {
    setCurrentPage(itemsPerPage)
  }
  const [numericValue, setNumericValue] = useState(0)

  const handleValueChange = (newValue) => {
    if (!isNaN(newValue)) {
      setNumericValue(newValue)
    }
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])
  return (
    <Main>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "#f3f3f3",
            position: "relative",
          }}
        >
          <Skeleton height={500} count={1} />
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              left: "50%",
              top: "25%",
              color: "#000",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaImage className="mr-2 size-4" />
          </div>
        </div>
      ) : (
        <PageBanner
          img={storeData1.suggestions.store_logo}
          children={undefined}
        />
      )}

      <Marginer margin={60} direction="vertical" />
      <Container>
        {loading ? (
          <Skeleton height={100} />
        ) : (
          <LiveSocial
            link={`/storeVideos/${storeData1.suggestions.store_uuid}`}
            linkText={"View All"}
            heading={storeData1.suggestions.store_name}
            subheading={storeData1?.suggestions?.store_city}
          >
            {videoData && videoData.length === 1
              ? videoData?.map((data, index) => {
                  return (
                    <SocialThumb
                      key={index}
                      title={data.store_username}
                      subtitle={data.description}
                      image={data.thumbnail}
                      link={"/social/" + data.video_uuid}
                      children={undefined}
                    />
                  )
                })
              : videoData?.map((item, index) => {
                  return (
                    <ThumbSlide
                      key={index}
                      title={item.store_username}
                      subtitle={item.description}
                      image={item.thumbnail}
                      link={"/social/" + item.video_uuid}
                      children={undefined}
                      className=""
                    />
                  )
                })}
          </LiveSocial>
        )}
        <Marginer margin={60} direction="vertical" />
        <SectionHeading>
          <Link
            to={""}
            onClick={async (e) => {
              e.preventDefault()
              let resp = await addChannel({
                shopper_username_v1: storeData1.suggestions.store_username,
                store_uuid: storeData1.suggestions.store_uuid,
              })
              if (resp.status == 200) {
                navigate("/chats/")
              }
            }}
          >
            Talk to store
          </Link>
        </SectionHeading>
        <Marginer margin={40} direction="vertical" />
        <CardContent>
          <CardContentDetails>
            <div className="w-[120px] h-[120px] rounded-full overflow-hidden">
              <Link
                to={`/chats/${storeData1.suggestions.store_user_uuid}/${storeData1.suggestions.store_name + "_Channel"}/${storeData1.suggestions.store_uuid}`}
              >
                <img
                  className=" w-full h-auto"
                  src={storeData1.suggestions.store_sellers[0]?.profile_image}
                  alt="/"
                />
              </Link>
            </div>
            <div className="flex flex-col gap-6 justify-center pl-4">
              <h4 className=" font-semibold">
                {" "}
                {storeData1.suggestions.store_sellers[0]?.seller_name}
              </h4>
              <p>
                <Link
                  to={""}
                  onClick={async (e) => {
                    e.preventDefault()
                    let resp = await addChannel({
                      shopper_username_v1:
                        storeData1.suggestions.store_username,
                      store_uuid: storeData1.suggestions.store_uuid,
                    })
                    if (resp.status == 200) {
                      navigate("/chats")
                    }
                  }}
                >
                  {" "}
                  Talk to {storeData1.suggestions.store_name}
                </Link>{" "}
              </p>
            </div>
          </CardContentDetails>

          <ButtonMain>
            {localStorage.getItem("user") && (
              <>
                {!storeData1.suggestions.is_following && (
                  <Button onClick={handleFollow}>Follow</Button>
                )}
                {storeData1.suggestions.is_following && (
                  <Button onClick={handleFollow}>UnFollow</Button>
                )}
              </>
            )}

            <Button>Share</Button>
          </ButtonMain>
        </CardContent>
        <Marginer margin={30} direction="vertical" />
      </Container>
      <Hr />
      <Container>
        <SectionHeading>Store</SectionHeading>
        <Marginer margin={30} direction="vertical" />
        <FilterTrack
          filters={storeData1.suggestions?.tags_list}
          onClick={(e) => {
            setSelectTag(tagid)

            handleTags(e, { tag_id: tagid })
          }}
          setTagId={setTagId}
        />
        <Marginer margin={30} direction="vertical" />
        <Wrapper className="relative">
          <FaListCheck
            className="size-5 lg:hidden block text-sky-500"
            onClick={openFilter}
          />
          {filter && (
            <Filter className="lg:static absolute z-10 bg-white h-full">
              <Accordion className="border-0">
                <Apanel>
                  <Accordion.Title>Gender</Accordion.Title>
                  <Accordion.Content>
                    <FilterButton
                      className={`${gender == 1 ? "active" : null}`}
                      onClick={() => {
                        return handleState({ type: "gender_id", value: 1 })
                      }}
                    >
                      Man
                    </FilterButton>
                    <FilterButton
                      className={`${gender == 2 ? "active" : null}`}
                      onClick={() =>
                        handleState({ type: "gender_id", value: 2 })
                      }
                    >
                      Woman
                    </FilterButton>
                    {/* <FilterButton
                      className={`${gender == 3 ? "active" : null}`}
                      onClick={() =>
                        handleState({ type: "gender_id", value: 3 })
                      }
                    >
                      Unisex
                    </FilterButton> */}
                  </Accordion.Content>
                </Apanel>
                <NewHr />
                <Apanel>
                  <Accordion.Title>Price</Accordion.Title>
                  <Accordion.Content>
                    <div className="flex m-2 gap-8">
                      <StyledInput
                        id="min_price"
                        label="Min Price"
                        type="number"
                        value={priceMin}
                        onChange={(e) => {
                          handleState({
                            type: "price_min",
                            value: e.target.value,
                          })
                        }}
                      />

                      <StyledInput
                        id="max_price"
                        label="Max Price"
                        type="number"
                        value={priceMax}
                        onChange={(e) => {
                          handleState({
                            type: "price_max",
                            value: e.target.value,
                          })
                        }}
                      />
                    </div>
                  </Accordion.Content>
                </Apanel>
                <NewHr />
                <Apanel>
                  <Accordion.Title>Category</Accordion.Title>
                  <Accordion.Content>
                    {categorygender?.map((item, index) => (
                      <FilterButton
                        key={index}
                        className={`${category && category?.indexOf(item.category_id.toString()) > -1 ? "active" : null}`}
                        onClick={() => {
                          // console.log(category)

                          handleState({
                            type: "category",
                            value: item?.category_id?.toString(),
                          })
                        }}
                      >
                        {item?.name}
                      </FilterButton>
                    ))}
                  </Accordion.Content>
                </Apanel>
                <NewHr />
                <Apanel>
                  <Accordion.Title>Colors</Accordion.Title>
                  <Accordion.Content>
                    {colorsData?.colorsListData?.colors_data?.map(
                      (item, index) => (
                        <FilterButton
                          key={index}
                          className={`${color && color.indexOf(item.color_id.toString()) > -1 ? "active" : null}`}
                          onClick={() => {
                            // console.log(item.color_id)
                            handleState({
                              type: "colors",
                              value: item.color_id.toString(),
                            })
                          }}
                        >
                          {item.color_name}
                        </FilterButton>
                      )
                    )}
                  </Accordion.Content>
                </Apanel>
                <NewHr />
                <Apanel>
                  <Accordion.Title>Sizes</Accordion.Title>
                  <Accordion.Content>
                    {sizeslist?.sizeListData?.sizes_data?.map((item, index) => (
                      <FilterButton
                        key={index}
                        className={`${size && size.indexOf(item.size_id.toString()) > -1 ? "active" : null}`}
                        onClick={() => {
                          handleState({
                            type: "sizes",
                            value: item.size_id.toString(),
                          })
                        }}
                      >
                        {item.size_name}
                      </FilterButton>
                    ))}
                  </Accordion.Content>
                </Apanel>
              </Accordion>
            </Filter>
          )}

          <ProductsSection>
            {receiveData?.sui_data?.length > 0 ? (
              <ProductList>
                {receiveData?.sui_data?.map((item, index) => (
                  <Product
                    link={`/product-details/${item.SUI}`}
                    key={index}
                    image={item.image_url}
                    name={item.product_title}
                    price={item.display_final_price}
                    uuid={uuid}
                    salePrice={
                      item.display_total_price -
                      item.platform_discount -
                      item.store_discount
                    }
                    item={item}
                    symbol={item.display_currency_symbol}
                    currency_code={item.display_currency_code}
                  />
                ))}
              </ProductList>
            ) : (
              "No Product Found"
            )}

            <div className="pagination-wrapper">
              <div className=" mb-16 flex mt-4 justify-center gap-2  ml-16">
                <button
                  onClick={handlePrevClick}
                  disabled={preDisable}
                  className=" font-bold text-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                {Array.from({ length: totalPages }, (_, i) => {
                  const page = i + 1
                  const isHidden = Math.abs(page - currentPage) > 2
                  return (
                    <div className="pagination block w-8 h-8 text-center border border-gray-100 rounded leading-8  dark:active:bg-sky-400 focus:bg-sky-400">
                      <button
                        key={i}
                        onClick={() => handleState({ type: "page", value: i })}
                        disabled={i + 1 === currentPage}
                        className={`${isHidden ? "hidden" : ""} ${page === currentPage ? "active" : ""}`}
                      >
                        {i + 1}
                      </button>
                    </div>
                  )
                })}
                <button
                  onClick={handleNextClick}
                  disabled={nextDisable}
                  className="font-bold text-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 h-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </ProductsSection>
        </Wrapper>
      </Container>
      <Marginer margin={100} direction="vertical" />
    </Main>
  )
}

export default StorePage
