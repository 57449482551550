import { Link } from "react-router-dom"
import styled from "styled-components"
import tw from "twin.macro"
import logo from "../../../assets/logo.png"
const LogoHolder = styled(Link)`
  ${tw`
    text-white
    font-bold
    text-xl
    flex
    justify-center
    items-center
`}
`
const LogoImage = styled.img`
  ${tw`
    
`}
`
const Logo = () => {
  return (
    <LogoHolder to={"/"}>
      <LogoImage className="logoImage" src={logo} />
    </LogoHolder>
  )
}

export default Logo
