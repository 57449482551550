import { styled } from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../responsive"

export const BlockHeading = styled.h2`
  ${tw`
    float-left
    w-fit
    text-xl
    font-semibold
`}
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        px-4
    `}
  }
`
