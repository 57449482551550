import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IStoreSellerDetails, IStoreSeller } from "../../../../@types/store"

const initialState: IStoreSellerDetails = {
  suggestions: {
    seller_details: [],
    status: 0,
    alert_notice: 0,
    chat_url: 0,
    is_internal: 0,
    phone: "",
    profile_image: "",
    seller_email: "",
    seller_id: 0,
    seller_name: "",
    store_id: 0,
    store_name: "",
    user_uuid: "",
    username_v1: "",
  },
}

const getStoreSeller = createSlice({
  name: "storeSellerMain",
  initialState,
  reducers: {
    setStoreSellerMain: (state, action: PayloadAction<IStoreSeller>) => {
      state.suggestions = action.payload
    },
  },
})

export const { setStoreSellerMain } = getStoreSeller.actions

export default getStoreSeller.reducer
