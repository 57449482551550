import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IGetStoreList, IStoreListMain } from "../../../../@types/store"

const initialState: IGetStoreList = {
  suggestions: {
    status: 0,
    store_list: [],
  },
}

const getStoreList = createSlice({
  name: "storeList",
  initialState,
  reducers: {
    setStoreList: (state, action: PayloadAction<IStoreListMain>) => {
      state.suggestions = action.payload
    },
  },
})

export const { setStoreList } = getStoreList.actions

export default getStoreList.reducer
