import { styled } from "styled-components"
import tw from "twin.macro"
import { SCREENS } from "../../Components/responsive"
import { Button } from "../../Components/button"
import ProductThumb from "../../Components/ProductThumb"

export const Heading = styled.h2`
  ${tw`
      text-7xl
      font-thin
      text-white
      uppercase
    `}
  @media screen and (max-width:${SCREENS["2xl"]}) {
    ${tw`
        text-6xl
      `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    ${tw`
        text-5xl
      `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        text-4xl
      `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        text-lg
      `}
  }
`
export const HeadingBold = styled(Heading)`
  ${tw`
      font-bold
    `}
`

export const SmallHead = styled.h3`
  ${tw`
    text-xl
    text-white
    uppercase
  `}

  @media screen and (max-width:${SCREENS["2xl"]}) {
    ${tw`
        text-xl
      `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    ${tw`
        text-lg
      `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        text-lg
      `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        text-xs
      `}
  }
`

export const Category = styled.h3`
  ${tw`
      text-2xl
      text-white
      underline
      uppercase
    `}
  @media screen and (max-width:${SCREENS["2xl"]}) {
    ${tw`
        text-xl
      `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    ${tw`
        text-lg
      `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        text-lg
      `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        text-sm
      `}
  }
`
export const ShopButton = styled(Button)`
  ${tw`
      w-64
    `}
  @media screen and (max-width:${SCREENS["2xl"]}) {
    ${tw`
        w-64
      `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    ${tw`
        w-64
      `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        w-44
        text-lg
        font-semibold
      `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        w-28
        text-sm
        font-semibold
      `}
  }
`
export const Hr = styled.div`
  ${tw`
        flex-grow 
        border-t 
        border-slate-200
        my-8
    `}
`
export const ProductList = styled.div`
  ${tw`
    grid
    grid-cols-4
    gap-4
  `}
  @media screen and (max-width:${SCREENS["2xl"]}) {
    ${tw`
        grid-cols-4
        gap-3
      `}
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    ${tw`
        grid-cols-3
        gap-2
      `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        grid-cols-2
        w-full
      `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
        grid-cols-1
      `}
  }
`
export const Product = styled(ProductThumb)`
  ${tw`

  `}
`
