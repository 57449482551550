import { Container } from "../../Components/Globals/Container"
import Main from "../../layouts/Main"
import { Marginer } from "../../Components/marginer"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Grid4 } from "../../Components/Grid"
import FilterTrack from "../../Components/FilterTrack"
import { useEffect, useState } from "react"
import { ITagData } from "../../../@types/tags"
import { useSeasheEditData } from "./Seashel.Hook"
import { Product } from "../Home/Styled.Comps"

const SeashelEditPage = (props: any) => {
  const [tagId, setTagId] = useState("")
  const { listTags } = useSeasheEditData(tagId)

  const [listTagsData, setListTagsData] = useState(listTags.tags_data)
  const handleFilterClick = (tagId) => {
    let newData = []

    if (listTags && listTags.tags_data.length > 0) {
      newData = (listTags.tags_data as ITagData[]).filter(
        (item: ITagData) => item.tag_id === tagId
      )
    }
    setListTagsData(newData)
  }
  useEffect(() => {
    handleFilterClick(tagId)
  }, [tagId])

  const [userData, setUserData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  console.log(currentPage, "currentPage")
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setUserData(listTags.tags_data)
    setTotalPages(Math.ceil(listTags?.tags_data?.length / 2))
  }, [listTags.tags_data])

  const handleNextClick = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages))
  }
  const handlePrevClick = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
  }

  const preDisable = currentPage === 1
  const nextDisable = currentPage === totalPages

  const itemsPerPage = Math.ceil(listTags?.tags_data?.length / 99)
  console.log(itemsPerPage, "itemsPerPage")
  const startIndex = currentPage
  const endIndex = itemsPerPage
  const itemsToDisplay = userData.slice(startIndex, endIndex)
  const handlePageChange = () => {
    setCurrentPage(itemsPerPage)
  }

  return (
    <Main>
      <Container>
        <Marginer margin={40} direction="vertical" />

        <Grid4>
          {itemsToDisplay.length > 0 ? (
            itemsToDisplay.map((item, index) => (
              <Product
                link={"/product-details/" + item.sui}
                image={item.image_url}
                name={item.product_title}
                price={item.display_total_price}
                percentage={item.store_discount}
                salePrice={item.display_final_price}
                key={index}
                item={item}
                symbol={item.display_currency_symbol}
                currency_code={item.display_currency_code}
              />
            ))
          ) : (
            <p>No products Found</p>
          )}
        </Grid4>
        <div className="pagination-wrapper">
          <div className=" mb-16 flex mt-4 justify-center gap-2  ml-16">
            <button
              onClick={handlePrevClick}
              disabled={preDisable}
              className=" font-bold text-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            {Array.from({ length: totalPages }, (_, i) => {
              const page = i + 1
              const isHidden = Math.abs(page - currentPage) > 2
              return (
                <div className="pagination block w-8 h-8 text-center border border-gray-100 rounded leading-8  dark:active:bg-sky-400 focus:bg-sky-400">
                  <button
                    key={i}
                    onClick={() => handlePageChange()}
                    disabled={i + 1 === currentPage}
                    className={`${isHidden ? "hidden" : ""} ${page === currentPage ? "active" : ""}`}
                  >
                    {i + 1}
                  </button>
                </div>
              )
            })}
            <button
              onClick={handleNextClick}
              disabled={nextDisable}
              className="font-bold text-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        <Marginer margin={100} direction="vertical" />
      </Container>
    </Main>
  )
}

export default SeashelEditPage
