import axios from "axios"
import { useAppDispatch } from "../../utils/hooks"
import { API, getToken } from "../config"

const useGenerateDynamicLink = () => {
  const API_TOKEN = getToken()

  const dispatch = useAppDispatch()
  const generateLink = async (longUrl: string) => {
    try {
      const apiUrl = `${API}/utilities/create_dynamic_url`

      const response = await axios.post(apiUrl, {
        api_token: API_TOKEN,
        url: `https://seashels.com/?link=${longUrl}`,
      })
      console.log(response, "respDynamic")
      if (response.status === 200) {
        return response.data.shortLink
      } else {
        throw new Error("Error generating dynamic link")
      }
    } catch (error) {
      console.error("Error generating dynamic link:", error)
      return null
    }
  }

  return generateLink
}

export default useGenerateDynamicLink
