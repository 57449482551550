import { useEffect, useRef, useState } from "react"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6"
import Slider from "react-slick"
import { styled } from "styled-components"
import tw from "twin.macro"
import Skeleton from "react-loading-skeleton"

const ButtonHolder = styled.span`
  ${tw`
            px-1
        `}
`
const FilterButton = styled.button`
  ${tw`
            py-2
            px-3
            rounded-full
            transition-all
            font-semibold
            border-gray-400
            text-gray-400
            bg-transparent
            border-2
            w-fit
        `}
  &:hover {
    ${tw`
                border-[#038DCE]
                text-[#038DCE]
            `}
  }
  &.active {
    ${tw`
                border-[#038DCE]
                text-[#038DCE]
            `}
  }
`
const settings = {
  dots: false,
  infinite: false,
  autoplay: false,
  autoSlidesToShow: false,
  variableWidth: true,
  slidesToScroll: 3,
  prevArrow: <FaAngleLeft />,
  nextArrow: <FaAngleRight />,
}

const FilterTrack = styled(Slider)`
  &.slick-slide {
    ${tw`
                px-1
                flex
                
            `}
  }
  svg.slick-arrow {
    font-size: 18px;
    z-index: 1;
    color: #000;
  }
`

const FilterTrackComponent = ({ filters, onClick, setTagId, ...props }) => {
  console.log(filters, "filters")
  const [loading, setLoading] = useState(true)

  const [selectedIndex, setSelectedIndex] = useState(0)
  const defaultFilter = {
    tag_name: "All",
    tag_id: "",
  }
  const filterWithAll = [defaultFilter, ...filters]
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 2400)
    return () => clearTimeout(timer)
  }, [filterWithAll])
  return (
    <div>
      {loading ? (
        <Skeleton height={30} count={1} />
      ) : (
        <FilterTrack {...settings} {...props}>
          {filterWithAll?.map((item, index) => {
            return (
              <ButtonHolder key={index}>
                <FilterButton
                  onClick={(e) => {
                    setTagId(item.tag_id)
                    setSelectedIndex(index)
                    onClick(e)
                  }}
                  className={`btn ${selectedIndex === index ? "active" : ""}`}
                >
                  {item?.tag_name}
                </FilterButton>
              </ButtonHolder>
            )
          })}
        </FilterTrack>
      )}
    </div>
  )
}

export default FilterTrackComponent
