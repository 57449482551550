import styled from "styled-components"
import tw from "twin.macro"
import { Container } from "../Globals/Container"
import { SCREENS } from "../responsive"

const Slide = styled.div<{ img: string }>`
  ${tw`
    bg-cover 
    bg-top
    w-full
    bg-no-repeat
    
`}
  height:90vh;
  background-image: url(${(props) => props.img});
  @media screen and (max-width: ${SCREENS["2xl"]}) {
    height: 90vh;
  }
  @media screen and (max-width: ${SCREENS.xl}) {
    height: 70vh;
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
        bg-cover 
    `}
    height:40vh;
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    height: 30vh;
    ${tw`
        bg-cover 
    `}
  }
`

const SlideContain = styled.div`
  ${tw`
    grid
    grid-cols-2
    gap-4
    h-full
`}
`

const Banner = ({ children, img, ...props }) => {
  return (
    <Slide img={img} className="cursor-pointer">
      <Container className="h-full">
        <SlideContain>{children}</SlideContain>
      </Container>
    </Slide>
  )
}

export default Banner
