import { Link } from "react-router-dom"
import styled from "styled-components"
import tw from "twin.macro"
import { FaAngleRight } from "react-icons/fa6"
import { Marginer } from "../marginer"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { SCREEN, SCREENS } from "../responsive"
import { BlockHeading as Heading } from "../Globals/BlockHeading"

const Header = styled.div`
  ${tw`            
    w-full 
    block
    float-left
`}
`
const ViewAll = styled(Link)`
  ${tw`
    float-right
    flex
    justify-center
`}
`
const NextIcon = styled(FaAngleRight)`
  ${tw`
    inline
    w-16
`}
  margin-top:3px;
`
const SlideHolder = styled.div`
  ${tw`
    flex
    flex-col
`}
  .slick-dots li button:before {
    font-size: 20px;
  }
  .slick-dots {
    bottom: -60px;
  }

  @media screen and (max-width: ${SCREENS.sm}) {
    margin-bottom: 84px;
  }
`

const LiveSocial = ({ children, heading, linkText, link, ...props }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: SCREEN.xl,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: SCREEN.lg,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: SCREEN.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <SlideHolder>
      <Header>
        <Heading>{heading}</Heading>
        <ViewAll to={link}>
          {linkText} <NextIcon />
        </ViewAll>
      </Header>
      {props.subheading && <li>{props.subheading}</li>}
      <Marginer margin={30} direction="vertical" />
      <Slider {...settings}>{children}</Slider>
    </SlideHolder>
  )
}

export default LiveSocial
