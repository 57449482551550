import React, { useEffect, useState } from "react"
import { FaTruckFast } from "react-icons/fa6"
import StyledInput from "../../Components/input"
import tw from "twin.macro"
import styled from "styled-components"
import { Button } from "../../Components/button"
import Main from "../../layouts/Main"
import { Marginer } from "../../Components/marginer"
import { Container } from "../../Components/Globals/Container"
import { Grid2 } from "../../Components/Grid"
import { Link } from "react-router-dom"
import { useCheckout } from "./Checkout.Hooks"
import { API_TOKEN } from "../../api/config"
import axios from "axios"
import { API } from "../../api/config"
import { useShoppingBagData } from "../ShoppingBag/shoppingBag.Hooks"
import { loadStripe } from "@stripe/stripe-js"
import Countries from "../../utils/countryList.json"
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { PopTost } from "../../utils/common"
import Skeleton from "react-loading-skeleton"

const HeaderContainer = styled.div`
  ${tw`
  mt-4 
  w-screen 
  h-28 
  bg-[#EFEFEF] 
  flex 
  justify-center 
  items-center
    `}
`

const HeaderDeatils = styled.div`
  ${tw`
flex 
flex-col
  `}
`

const BoldText = styled.span`
  ${tw`
text-left 
font-bold
  `}
`
const NormalText = styled.span`
  ${tw`
text-justify
  `}
`

const ShippingContainer = styled.div`
  ${tw`
  pt-5 
  ml-28 
  flex 
  flex-col
    `}
`
const ShippingText = styled.span`
  ${tw`
  font-bold
    `}
`
const ShippingAddressText = styled.span`
  ${tw`
  border-b-2 
  border-[#038DCE] 
  w-fit
    `}
`
const HorizentalLine = styled.hr`
  ${tw`
  w-full 
  bg-gray-200
    `}
`
const OrderContainer = styled.div`
  ${tw`
  py-2.5
  px-6
    `}
`
const OrderDetail = styled.div`
  ${tw`
  border-2 
  py-4
    `}
`
const OrderTitle = styled.div`
  ${tw`
  ml-3 
  p-2 
  font-bold 
  border-b-2 
  border-[#038DCE] 
  w-fit
    `}
`
const CreditDetail = styled.div`
  ${tw`
  m-4
  `}
`
const ApplyCreditButton = styled.button`
  ${tw`
  bg-[#038DCE]
  h-[51px]
  p-2 
  text-white
  text-[16px]
  font-semibold
  border-2 
  border-[#038DCE] 
  hover:bg-blue-400
  `}
`
const CreditText = styled.div`
  ${tw`
  ml-3
  px-4
  `}
`
const CreditScore = styled.span`
  ${tw`
  text-[#038DCE]
  `}
`
const CreditEnterText = styled.div`
  ${tw`
  mt-3 
  mb-5
  text-sm
  font-normal
  `}
`
const PaymentDetail = styled.div`
  ${tw`
  flex 
  justify-between
  px-5
  `}
`
const SubtotalPaymentDetail = styled.div`
  ${tw`
  flex 
  flex-col 
  mt-6 
  ml-2
  `}
`
const TotalPaymentDetail = styled.div`
  ${tw`
  flex 
  flex-col 
  mt-6 
  ml-2
  `}
`
const TotalAmount = styled.div`
  ${tw`
  font-bold
  `}
`

const Checkout = () => {
  const { CalculateCredits, AddressListData } = useCheckout()
  const { CartDetailsData } = useShoppingBagData()
  const [credits, setCredits] = useState(0)

  function applyCredit(e) {
    console.log(e.target.value)
    setCredits(e.target.value)
  }

  const [total, setTotal] = useState(CalculateCredits?.total_cart_value)

  useEffect(() => {
    setTotal(total)
  }, [total])

  function CreditsApply() {
    if (
      CalculateCredits.credits != null &&
      CalculateCredits.credits > credits &&
      totalValue > credits
    ) {
      setTotal(totalValue - credits)
    }
  }

  const [userName, setUserName] = useState("")
  const [addressLine, setAddressLine] = useState("")
  const [addressSecoundLine, setAddressSecoundLine] = useState("")
  const [postal, setPostal] = useState("")
  const [destination, setDestination] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNum, setPhoneNum] = useState("")
  const [countryCode, setCountryCode] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(true)
  const [stripeKeys, setStripeKeys] = useState({
    public: "",
    private: "",
  })
  const [options, setOptions] = useState()
  const handleFirstNameChange = (e: any) => {
    setUserName(e.target.value)
  }

  const handleAddressLine = (event) => {
    const value = event.target.value
    if (value.length <= 100) {
      setAddressLine(value)
    }
  }

  const handleAddressSecoundLine = (event) => {
    const value = event.target.value
    if (value.length <= 100) {
      setAddressSecoundLine(value)
    }
  }
  const handlePostal = (e: any) => {
    setPostal(e.target.value)
  }
  const handleDestination = (e: any) => {
    setDestination(e.target.value)
  }
  const handlesetState = (e: any) => {
    setState(e.target.value)
  }
  const handleCity = (e: any) => {
    setCity(e.target.value)
  }
  const handlesetCountry = (e: any) => {
    setCountry(e.target.value)
  }

  const handlePhoneNumberChange = (e, code) => {
    let rep = code.replace("+", "")
    setPhoneNumber(e)

    setCountryCode(Countries[code])
    setPhoneNum(e.replace(rep, Countries[code] + "-"))
  }
  const handleEmail = (e: any) => {
    setEmail(e.target.value)
  }

  let userDataDetail = JSON.parse(localStorage.getItem("userData"))
  const [addresResult, setAddresResult] = useState("")
  const [isOrderCreated, setIsOrderCreated] = useState(false)
  const [totalValue, setTotalValue] = useState(
    CartDetailsData?.shopping_cart_data?.total_cart_discounted_value
  )
  const updateAddress = async () => {
    let response = await axios.post(`${API}/user/add_address`, {
      api_token: API_TOKEN,
      user_id: userDataDetail.user_uid,
      name: userName,
      address_1: addressLine,
      address_2: addressSecoundLine,
      city: city,
      state: state,
      country: country,
      zipcode: postal,
      type: "home",
      phone: phoneNum,
      country_code: countryCode,
      email: email,
    })
    setAddresResult(response.data.result)
    PopTost({ message: "Address updated successfully.", type: "success" })
    console.log(response.data, "Response")
  }

  useEffect(() => {
    if (AddressListData[AddressListData?.length - 1]?.phone) {
      let phone = AddressListData[AddressListData?.length - 1]?.phone.split("-")
      // console.log(phone)
      let code = "" //Countries[phone[0]]

      Object.keys(Countries).map((key) => {
        if (Countries[key] == phone[0]) {
          code = key
        }
      })
      setPhoneNumber(code + phone[1])
      setCountryCode(phone[0])
    }

    setUserName(AddressListData[AddressListData?.length - 1]?.name)
    setAddressLine(AddressListData[AddressListData?.length - 1]?.address_1)
    setAddressSecoundLine(
      AddressListData[AddressListData?.length - 1]?.address_2
    )
    setPostal(AddressListData[AddressListData?.length - 1]?.zipcode)
    setDestination(AddressListData[AddressListData?.length - 1]?.address_2)
    setState(AddressListData[AddressListData?.length - 1]?.state)
    setCity(AddressListData[AddressListData?.length - 1]?.city)
    setCountry(AddressListData[AddressListData?.length - 1]?.country)
    setEmail(AddressListData[AddressListData?.length - 1]?.email)
    setAddresResult(
      AddressListData[AddressListData?.length - 1]?.address_id + ""
    )

    console.log(AddressListData[0], "userName")
  }, [AddressListData])

  useEffect(() => {
    setTotalValue(
      CartDetailsData?.shopping_cart_data?.total_cart_discounted_value
    )
  }, [CartDetailsData])

  let orDerNumber = localStorage.getItem("order_num")

  const createCart = async () => {
    let creatNewCart = await axios.post(`${API}/payment/create_order`, {
      api_token: API_TOKEN,
      address_id: addresResult,
      apply_credits: credits ?? 0,
      coupon_id: CartDetailsData?.cart_items[0].coupon_id,
      order_num: orDerNumber,
    })
    if (creatNewCart.data.status == 200) {
      setIsOrderCreated(true)
      //@ts-ignore
      console.log(creatNewCart.data.order_data)
      //@ts-ignore
      setStripeKeys({
        public: creatNewCart?.data.order_data?.publishableKey,
        private: creatNewCart?.data.order_data?.paymentIntent,
      })
    }
    console.log(creatNewCart.data, "creatNewCart")
  }

  const ConfirmPayment = async () => {
    let confirmPtm = await axios.post(`${API}/payment/confirm_payment`, {
      api_token: API_TOKEN,
      gateway: "RazorPay",
      order_num: "",
      payment_id: "pay_DUM1DWOVUnlAdR",
    })
    console.log(confirmPtm.data, "confirmPtm")
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [])
  return (
    <Main>
      <Marginer margin={10} direction="vertical" />
      <HeaderContainer>
        <FaTruckFast className="h-10 w-10 m-5 fill-slate-400" />

        <HeaderDeatils>
          <BoldText>We are here for you</BoldText>
          <NormalText>Delivering safely to your door</NormalText>
        </HeaderDeatils>
      </HeaderContainer>
      <Marginer margin={50} direction="vertical" />
      <Container>
        {!isOrderCreated && (
          <Grid2>
            <ShippingContainer>
              <ShippingText>Shipping Address</ShippingText>
              <ShippingAddressText> Add a shipping address</ShippingAddressText>
              <HorizentalLine />
              <div className="mt-10 ">
                <Skeleton
                  height={30}
                  width="100%"
                  duration={2}
                  count={loading ? 1 : 0}
                />
                {!loading && (
                  <StyledInput
                    id={"userName"}
                    type={"userName"}
                    label="Profile Name"
                    value={userName}
                    onChange={handleFirstNameChange}
                    maxLength={100}
                  />
                )}
              </div>
              <div className="mt-10">
                <Skeleton
                  height={30}
                  width="100%"
                  duration={2}
                  count={loading ? 1 : 0}
                />
                {!loading && (
                  <StyledInput
                    id={"Addressline1"}
                    type={""}
                    label="Address line 1*"
                    value={addressLine}
                    onChange={handleAddressLine}
                    maxLength={100}
                  />
                )}
              </div>
              <div className="mt-10">
                <Skeleton
                  height={30}
                  width="100%"
                  duration={2}
                  count={loading ? 1 : 0}
                />
                {!loading && (
                  <StyledInput
                    id={"Addressline2"}
                    type={""}
                    label="Address line 2"
                    value={addressSecoundLine}
                    onChange={handleAddressSecoundLine}
                  />
                )}
              </div>
              <div className="mt-10">
                <Skeleton
                  height={30}
                  width="100%"
                  duration={2}
                  count={loading ? 1 : 0}
                />
                {!loading && (
                  <StyledInput
                    id={"PostalCode"}
                    type={""}
                    label="Postal Code"
                    value={postal}
                    onChange={handlePostal}
                  />
                )}
              </div>

              <div className="mt-10">
                <Skeleton
                  height={30}
                  width="100%"
                  duration={2}
                  count={loading ? 1 : 0}
                />
                {!loading && (
                  <StyledInput
                    id={"City"}
                    type={""}
                    label="City"
                    value={city}
                    onChange={handleCity}
                  />
                )}
              </div>
              <div className="mt-10">
                <Skeleton
                  height={30}
                  width="100%"
                  duration={2}
                  count={loading ? 1 : 0}
                />
                {!loading && (
                  <StyledInput
                    id={"State"}
                    type={""}
                    label="State"
                    value={state}
                    onChange={handlesetState}
                  />
                )}
              </div>
              <div className="mt-10">
                <Skeleton
                  height={30}
                  width="100%"
                  duration={2}
                  count={loading ? 1 : 0}
                />
                {!loading && (
                  <StyledInput
                    id={"Country"}
                    type={""}
                    label="Country"
                    value={country}
                    onChange={handlesetCountry}
                  />
                )}
              </div>
              <div className="mt-10">
                <Skeleton
                  height={30}
                  width="100%"
                  duration={2}
                  count={loading ? 1 : 0}
                />
                {!loading && (
                  <StyledInput
                    id={"Phone Number"}
                    type={"phone"}
                    label="PhoneNumber"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                )}
              </div>
              <div className="mt-10">
                <Skeleton
                  height={30}
                  width="100%"
                  duration={2}
                  count={loading ? 1 : 0}
                />
                {!loading && (
                  <StyledInput
                    id={"Email"}
                    type={""}
                    label="Email"
                    value={email}
                    onChange={handleEmail}
                  />
                )}
              </div>
              <p className="text-sm font-normal mt-4">
                We will only contact you by phone if there is a problem with
                your order.
              </p>

              <Button
                click={updateAddress}
                theme="outlined"
                className="w-full"
                text={"Update Address"}
              />
            </ShippingContainer>

            <OrderContainer>
              <OrderDetail>
                <OrderTitle>Your order</OrderTitle>
                <HorizentalLine />
                <CreditDetail>
                  <input
                    type="number"
                    placeholder="Credits to apply"
                    className="h-[50px]"
                    value={credits == 0 ? "" : credits}
                    onChange={applyCredit}
                  />
                  <ApplyCreditButton onClick={CreditsApply}>
                    Apply Credits
                  </ApplyCreditButton>
                </CreditDetail>
                <Skeleton
                  height={30}
                  width="100%"
                  duration={2}
                  count={loading ? 1 : 0}
                />
                {!loading && (
                  <CreditText>
                    <div className="text-sm font-normal">
                      You can use upto{" "}
                      <CreditScore>
                        {CalculateCredits.credits > 0
                          ? CalculateCredits.credits
                          : "0"}{" "}
                        credit
                      </CreditScore>{" "}
                      for this purchase.
                    </div>
                    <CreditEnterText>
                      Please enter in above box the amount you would like to use
                      and press "Apply Credits"
                    </CreditEnterText>
                  </CreditText>
                )}
                <HorizentalLine />
                <PaymentDetail>
                  <SubtotalPaymentDetail className="">
                    <Skeleton
                      height={30}
                      width="100%"
                      duration={2}
                      count={loading ? 1 : 0}
                    />
                    {!loading && (
                      <div className="text-md font-semibold">
                        Subtotal:{" "}
                        {CartDetailsData?.shopping_cart_data?.currency_symbol}{" "}
                        {CartDetailsData.shopping_cart_data.total_cart_value}
                      </div>
                    )}
                    <div className="text-md font-normal">Free Shipping</div>
                  </SubtotalPaymentDetail>

                  <TotalPaymentDetail>
                    <div className="text-md font-semibold"> Total </div>
                    <Skeleton
                      height={30}
                      width="100%"
                      duration={2}
                      count={loading ? 1 : 0}
                    />
                    {!loading && (
                      <TotalAmount className="text-md font-bold">
                        {CartDetailsData?.shopping_cart_data?.currency_symbol}{" "}
                        {totalValue ??
                          CartDetailsData?.shopping_cart_data
                            ?.total_cart_discounted_value}
                        {/* { credits && CalculateCredits.total_cart_value  ? CalculateCredits.total_cart_value - credits
                      : CalculateCredits.total_cart_value} */}
                      </TotalAmount>
                    )}
                    <p className="text-[#848686] text-sm">
                      (import duties included.)
                    </p>
                  </TotalPaymentDetail>
                </PaymentDetail>
              </OrderDetail>
              <Marginer margin={40} direction="vertical" />
              <Button
                theme="outlined"
                click={createCart}
                className="w-full"
                text={"Place Order"}
              />
            </OrderContainer>
          </Grid2>
        )}

        {isOrderCreated && stripeKeys?.public?.length > 0 && (
          <Elements
            stripe={loadStripe(stripeKeys.public)}
            options={{ clientSecret: stripeKeys.private }}
          >
            <form>
              <PaymentElement />
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mt-2">
                Submit
              </button>
            </form>
          </Elements>
        )}
      </Container>
      <Marginer margin={100} direction="vertical" />
    </Main>
  )
}

export default Checkout
