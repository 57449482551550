import React from "react"
import axios from "axios"
import { API, getToken } from "../config"
import { setProfileCaption } from "../../utils/slices/Caption/getProfileCaption"
import { useAppDispatch } from "../../utils/hooks"
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers.common["Content-Type"] = "application/json"

const useCaption = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()
  function getCaption() {
    const profileCaption = axios
      .get(`${API}/user/get_user_caption?api_token=${API_TOKEN}`)
      .then((res) => {
        console.log(res)
        dispatch(setProfileCaption(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return { getCaption }
}
export default useCaption
