import React, { useEffect, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Link, useNavigate, useParams } from "react-router-dom"
import { FaRocketchat } from "react-icons/fa"
import Main from "../../layouts/Main"
import { Container } from "../../Components/Globals/Container"
import { Marginer } from "../../Components/marginer"
import { Hr } from "../Home/Styled.Comps"
import { useGiftReceipts } from "../MyGiftsLists/giftReceipts.Hooks"
import { useSearchParams } from "react-router-dom"
import { useOrderdetail } from "../Orders/Orders.Hooks"
import useTalkToStore from "../../api/hooks/useTalkToStore"
import { useGetCurrency } from "../../Components/Header/Header.Hooks"
import { Label, Textarea } from "flowbite-react"
import Skeleton from "react-loading-skeleton"

const Heading = styled.div`
  ${tw` 
      md:text-2xl 
      text-lg
      font-bold
  `}
`
const ButtonComp = styled.div`
  ${tw` 
        rounded-3xl  
        text-white 
        bg-black 
        px-8 py-4
        text-center
    `}
`

const Button = styled.div`
  ${tw` 
        rounded-3xl  
        text-white 
        bg-black 
        py-4
        px-8
        inline-block
        text-center
        text-base ml-8
    `}
`
const ButtonBlue = styled.button`
  ${tw` 

mt-4
rounded-3xl 
font-semibold 
text-lg 
bg-sky-500 
w-full
pt-2 
pb-2
px-5
`}
`
const BgSection = {
  background: "#F7F9FA",
}
const SquareImage = {
  height: "200px",
  width: "200px",
  overflow: "hidden",
  "border-radius": "10px",
  "box-shadow": "3px 3px 10px #555",
}
const OrderDetails = () => {
  const param = useParams()
  const [searchParams] = useSearchParams()
  const isGift = searchParams.get("isGift")
  const { order_id } = useParams<"order_id">()
  const { OrderCards } = useOrderdetail(order_id)
  const { giftReceiptsList } = useGiftReceipts(order_id)
  const navigate = useNavigate()
  const { addChannel } = useTalkToStore()
  const data = isGift === "1" ? giftReceiptsList : OrderCards
  console.log(data, "data1")
  const tackOrder = () => {
    navigate("/")
  }
  const formattedDate = new Date(
    OrderCards.receipt_data[0]?.order_date
  ).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
  const ShippingDate = new Date(
    OrderCards.receipt_data[0]?.item_list[0]?.ship_eta
  ).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
  const newPrice = new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
  }).format(OrderCards.receipt_data[0]?.item_list[0].total)
  const UnitPrice = new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
  }).format(OrderCards.receipt_data[0]?.item_list[0].unit_price)
  const newSalePrice = new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
  }).format(
    Math.ceil(
      OrderCards.receipt_data[0]?.item_list[0].total +
        OrderCards.receipt_data[0]?.duties +
        OrderCards.receipt_data[0]?.shipping -
        OrderCards.receipt_data[0]?.total_credits_applied
    )
  )
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 4000)
    return () => clearTimeout(timer)
  }, [])
  return (
    <Main>
      <div style={BgSection}>
        <Marginer margin={40} direction="vertical" />
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "#f3f3f3",
              position: "relative",
            }}
          >
            <Skeleton height={100} count={1} />
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                left: "50%",
                top: "25%",
                color: "#000",
                display: "flex",
                alignItems: "center",
              }}
            ></div>
          </div>
        ) : (
          <Container>
            <Heading>Order Details</Heading>
            <h5 className="text-[16px]">Order Date: {formattedDate}</h5>
            <h5 className="text-[16px]">
              Order Number: {OrderCards.receipt_data[0]?.order_id}
            </h5>
          </Container>
        )}
      </div>
      {OrderCards.receipt_data[0]?.item_list.map((item, index) => {
        return (
          <>
            <Marginer margin={40} direction="vertical" />
            <Link
              to={`/store/${item.store_uuid}`}
              className="text-center font-bold md:text-[26px] text-xl py-4 bg-white flex justify-center"
            >
              Store :
              <span className="font-normal md:text-[20px] ml-2 text-lg">
                {item.store_name}
              </span>
            </Link>
            <Marginer margin={40} direction="vertical" />
            <div className="flex" style={BgSection}>
              <Container>
                <Link to="" className="cursor-default">
                  <div className="flex items-center ">
                    {item.product_title === "Gift Voucher" ? (
                      <Link
                        to=""
                        style={SquareImage}
                        className="cursor-default"
                      >
                        <img src={item.product_url} alt="" />
                      </Link>
                    ) : (
                      <Link
                        to={`/product-details/${item.SUI}`}
                        style={SquareImage}
                      >
                        <img src={item.product_url} alt="" />
                      </Link>
                    )}
                    <div className="mx-5">
                      <Heading>{item.product_title}</Heading>
                      <h5 className="font-bold text-md my-2">
                        Size:{" "}
                        <span className="font-normal">{item.size_name}</span>
                      </h5>
                      <h5 className="font-bold text-md my-2">
                        Color:{" "}
                        <span className="font-normal">{item.color_name}</span>
                      </h5>
                      <h5 className="font-bold text-md my-2">
                        Unit Price:{" "}
                        <span className="font-normal">
                          {item.currency_symbol} {UnitPrice}
                        </span>
                      </h5>
                      <h5 className="font-bold text-md my-2">
                        Discounted Price:
                        {Number(item.store_discount) !== 0 ? (
                          <span className="font-normal text-md my-2">
                            {" "}
                            ${item.store_discount}
                          </span>
                        ) : null}
                        <span className="font-normal"> {param?.price}</span>
                      </h5>
                    </div>
                  </div>
                </Link>
                <Marginer margin={30} direction="vertical" />
                <div className="flex justify-between items-start">
                  <div>
                    <h5 className="text-md my-2">
                      Status: <span>{item.item_status}</span>
                    </h5>
                    {item.item_status !== "canceled" ? (
                      <h5 className="text-md my-2">
                        Est. Shipping Date: <span>{ShippingDate}</span>
                      </h5>
                    ) : (
                      <ButtonBlue className="text-white" onClick={() => {}}>
                        Check Reason
                      </ButtonBlue>
                    )}
                  </div>
                  <ButtonComp
                    className="flex items-center !w-auto !py-3 px-8 cursor-pointer"
                    onClick={async () => {
                      let resp = await addChannel({
                        shopper_username_v1:
                          OrderCards.receipt_data[0].item_list[0]
                            .store_username,
                        store_uuid:
                          OrderCards.receipt_data[0].item_list[0].store_uuid,
                      })
                      if (resp.status == 200) {
                        navigate("/chats")
                      }
                    }}
                  >
                    {" "}
                    <FaRocketchat className="size-5 mr-2" /> Talk to store
                  </ButtonComp>
                </div>
              </Container>
            </div>
            <div style={BgSection}>
              <Hr />

              <Container>
                <ul key={index}>
                  <li className="flex items-center justify-between my-4">
                    Item Total Price (excluding tax){" "}
                    <span>
                      {item.currency_symbol}
                      {UnitPrice}
                    </span>
                  </li>
                  <li className="flex items-center justify-between my-4">
                    Taxes <span>{item.tax}%</span>
                  </li>
                  <li className="flex items-center justify-between my-4">
                    Total (including tax)
                    <span>
                      {item.currency_symbol}
                      {newPrice}
                    </span>
                  </li>
                  <li className="flex items-center justify-between my-4">
                    Duties{" "}
                    <span>
                      {item.currency_symbol}
                      {OrderCards.receipt_data[0].duties}
                    </span>
                  </li>
                  <li className="flex items-center justify-between my-4">
                    Shipping{" "}
                    <span>
                      {item.currency_symbol}
                      {OrderCards.receipt_data[0]?.shipping}
                    </span>
                  </li>
                  <li className="flex items-center justify-between my-4">
                    Credits Applied{" "}
                    <span>
                      {item.currency_symbol}
                      {OrderCards.receipt_data[0]?.total_credits_applied}
                    </span>
                  </li>
                </ul>

                <h3 className="flex items-center justify-between my-4 font-bold md:text-3xl text-xl">
                  Total Paid{" "}
                  <span>
                    {item.currency_symbol}
                    {newSalePrice}
                  </span>
                </h3>
                <Marginer margin={1} direction="vertical" />
                {item.item_status !== "canceled" ? (
                  <Button onClick={tackOrder} className="my-2 !ml-0">
                    Track order
                  </Button>
                ) : null}
                <Hr />
              </Container>
            </div>
          </>
        )
      })}
    </Main>
  )
}

export default OrderDetails
