import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { DynamicCard } from "../../Components/Card"
import { Link } from "react-router-dom"
import { SCREENS } from "../../Components/responsive"
import Main from "../../layouts/Main"
import { Container } from "../../Components/Globals/Container"
import { useRequestData } from "../Request/Request.Hooks"
import axios from "axios"
import { API } from "../../api/config"
import { API_TOKEN } from "../../api/config"

const MainContainer = styled.div`
  ${tw`
  lg:flex
  `}
  @media screen and (max-width: ${SCREENS.md}) {
    ${tw`
   flex-wrap 
    `}
  }
`
const FollowText = styled.div`
  ${tw`
  text-lg py-5 px-14 rounded-xl bg-white font-semibold w-full
  `}
`
const StyledLink = styled(Link)`
  ${tw`
   hover:bg-[#038DCE] hover:text-white mb-4 flex w-full
  `}
`
const ButtonContainer = styled.div`
  ${tw`
  left-0
  top-0 
  h-screen 
  px-4 
  pt-10
    text-center
  m-1
  mt-4
  lg:block flex lg:justify-start justify-center
  `}
  @media screen and (max-width: ${SCREENS.md}) {
    ${tw`
    w-full
    flex
    gap-2
    ml-0
    `}
  }
  @media screen and (max-width: ${SCREENS.lg}) {
    ${tw`
 
    h-auto
    pl-0
    pr-0
    `}
  }
  @media screen and (max-width: ${SCREENS.sm}) {
    ${tw`
    flex-wrap
    w-auto
    ml-12
    mr-12
    mt-4
    `}
  }
`

const CardDetails = styled.div`
  ${tw`
  grid 
  sm:grid-cols-1
  sm:grid-cols-2
  xl:grid-cols-2
  gap-6
  lg:p-10 p-4
  `}
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  @media screen and (max-width: ${SCREENS.md}) {
    ${tw`
      grid-cols-1
    pr-10
    pl-10
    pt-10
    `}
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`

const Request = () => {
  const { follow } = useRequestData()
  const [isRemoved, setIsRemoved] = useState(false)

  const handleDataForAll = async (user_follower_uuid, status) => {
    try {
      const BlockUser = await axios.post(`${API}/follow/set_status`, {
        api_token: API_TOKEN,
        status: status,
        user_follower_uuid: user_follower_uuid,
      })
      console.log(BlockUser.data)
      setIsRemoved(true)
    } catch (error) {
      console.error("Error:", error)
    }
  }

  return (
    <>
      <Main>
        <Container>
          <MainContainer>
            <ButtonContainer>
              <StyledLink to="/followers">
                <FollowText>Followers</FollowText>
              </StyledLink>
              <StyledLink to="/following">
                <FollowText>Following</FollowText>
              </StyledLink>
              <StyledLink to="/request">
                <FollowText className="!bg-sky-500 text-white">
                  Request
                </FollowText>
              </StyledLink>
            </ButtonContainer>
            <CardDetails>
              {follow.follower_request_pending.map((card) => {
                return (
                  <DynamicCard
                    imageSrc={card.image_url}
                    userName={card.following_name}
                    brandLevel={card.following_role}
                    onAccept={() =>
                      handleDataForAll(card.user_following_uuid, "accept")
                    }
                    onDecline={() =>
                      handleDataForAll(card.user_following_uuid, "reject")
                    }
                    onBlock={() =>
                      handleDataForAll(card.user_following_uuid, "block")
                    }
                    user_uid={card.user_following_uuid}
                  />
                )
              })}
            </CardDetails>
          </MainContainer>
        </Container>
      </Main>
    </>
  )
}

export default Request
