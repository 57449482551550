import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IMultipleData, IMultipleSku } from "../../../../@types/sku"

const initialState: IMultipleData = {
  multiple: {
    data: {
      data: [],
      status: 0,
    },
  },
}

const multipleSlice = createSlice({
  name: "multipleSku",
  initialState,
  reducers: {
    setMultiple: (state, action: PayloadAction<IMultipleSku>) => {
      state.multiple = action.payload
    },
  },
})

export const { setMultiple } = multipleSlice.actions

export default multipleSlice.reducer
