import { useState } from "react"
import useCart from "../../api/hooks/useCart"
import { useAppSelector } from "../../utils/hooks"

export const useCartProducts = (props) => {
  const { removeFromCart, updateCartItem } = useCart()
  const { cartDetail } = useAppSelector((state) => state)
  const [options, setOptions] = useState({
    color_id: props.item.color_id,
    size_id: props.item.size_id,
    quantinty: props.item.quantity,
    isUpdated: false,
  })

  const removeItem = async (sui) => {
    await removeFromCart({ sui })
    return
  }

  const increase = () => {
    setOptions({
      ...options,
      quantinty: parseInt(options.quantinty) + 1,
      isUpdated: true,
    })
  }
  const decrease = () => {
    if (options.quantinty <= 1) {
      return
    }
    setOptions({
      ...options,
      quantinty: parseInt(options.quantinty) - 1,
      isUpdated: true,
    })
  }

  const onChange = (e, type) => {
    switch (type) {
      case "color": {
        setOptions({ ...options, color_id: e.target.value, isUpdated: true })
        break
      }
      case "size": {
        setOptions({ ...options, size_id: e.target.value, isUpdated: true })
        break
      }
    }
  }

  const updateItem = async ({ sui, final_price }) => {
    let itemResp = await updateCartItem({
      sui,
      final_price,
      size_id: options.size_id,
      quantity: options.quantinty,
      color_id: options.color_id,
    })

    setOptions({ ...options, isUpdated: false })
  }

  return {
    removeItem,
    options,
    setOptions,
    increase,
    decrease,
    symbol: cartDetail?.cartdetail?.shopping_cart_data?.currency_symbol,
    onChange,
    updateItem,
  }
}
