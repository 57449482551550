import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  ICategoriesListData,
  ICategoriesList,
} from "../../../../@types/filters"

const initialState: ICategoriesListData = {
  categoryData: {
    categories_list: [],
    segment_list: [],
    status: 0,
  },
}

const categoriesListSlice = createSlice({
  name: "categoriesData",
  initialState,
  reducers: {
    setcatogoryList: (state, action: PayloadAction<ICategoriesList>) => {
      state.categoryData = action.payload
    },
  },
})

export const { setcatogoryList } = categoriesListSlice.actions

export default categoriesListSlice.reducer
