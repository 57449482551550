import axios from "axios"
import React, { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { API, default_gender, getToken, paramsTypes } from "../config"
import { setGiftsDetails } from "../../utils/slices/giftsReceipts/getGifts"
const giftOrderInitial = {
  data: "",
  gift_receipt: [],
  status: 0,
}
const useGiftsReceipts = () => {
  const API_TOKEN = getToken()
  const dispatch = useAppDispatch()

  function getGiftReceipts(order_id) {
    dispatch(setGiftsDetails(giftOrderInitial))
    const giftsReceipts = axios
      .get(
        `${API}/commerce/get_user_gift_receipts?&api_token=${API_TOKEN}&order_id=${order_id}`
      )
      .then((res) => {
        dispatch(setGiftsDetails(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return { getGiftReceipts }
}
export default useGiftsReceipts
