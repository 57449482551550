import Header from "../../Components/Header"
import { BaseContainer } from "../../Components/Globals/BaseContainer"
import Footer from "../../Components/Footer"
import styled from "styled-components"
import { useEffect } from "react"
import tw from "twin.macro"
import useGift from "../../api/hooks/useGift"

const Wrapper = styled.div`
  ${tw`            
   bg-[#F7F9FA]
`}
`

const Main = ({ children, ...props }: any) => {
  const { getStoreGiftList } = useGift()
  useEffect(() => {
    getStoreGiftList()
  }, [])

  return (
    <BaseContainer className="baseContainer mt-20">
      <Header />
      <Wrapper className="bodyContainer">{children}</Wrapper>
      <Footer />
    </BaseContainer>
  )
}

export default Main
