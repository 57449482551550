import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Link, useNavigate, useParams } from "react-router-dom"
import { FaRocketchat } from "react-icons/fa"
import Main from "../../layouts/Main"
import { Container } from "../../Components/Globals/Container"
import { Marginer } from "../../Components/marginer"
import { Hr } from "../Home/Styled.Comps"
import { useGiftReceipts } from "../MyGiftsLists/giftReceipts.Hooks"
import useTalkToStore from "../../api/hooks/useTalkToStore"

const Heading = styled.div`
  ${tw` 
      md:text-2xl 
      text-lg
      font-bold
  `}
`
const ButtonComp = styled.div`
  ${tw` 
        rounded-3xl  
        text-white 
        bg-black 
        px-8 py-4
        text-center
    `}
`

const BgSection = {
  background: "#F7F9FA",
}
const SquareImage = {
  height: "200px",
  width: "200px",
  overflow: "hidden",
  "border-radius": "10px",
  "box-shadow": "3px 3px 10px #555",
}
const GiftOrderDetailScreen = () => {
  const { order_id } = useParams<"order_id">()
  const { giftReceiptsList } = useGiftReceipts(order_id)
  const navigate = useNavigate()
  const { addChannel } = useTalkToStore()
  console.log(giftReceiptsList, "giftScreen")

  return (
    <Main>
      {giftReceiptsList.map((item) => {
        const formattedDate = new Date(item.order_date).toLocaleDateString(
          "en-GB",
          {
            year: "numeric",
            month: "short",
            day: "numeric",
          }
        )
        const ShippingDate = new Date(item.ship_eta).toLocaleDateString(
          "en-GB",
          {
            year: "numeric",
            month: "short",
            day: "numeric",
          }
        )

        return (
          <>
            <div style={BgSection}>
              <Marginer margin={40} direction="vertical" />
              <Container>
                <Heading>Order Details</Heading>
                <h5 className="text-[16px]">Order Date: {formattedDate}</h5>
                <h5 className="text-[16px]">Order Number: {item.order_id} </h5>
              </Container>
            </div>
            <Marginer margin={40} direction="vertical" />
            <h4 className="text-center font-bold md:text-[26px] text-xl py-4 bg-white">
              Store:{" "}
              <span className="font-normal md:text-[20px] text-lg">
                {item.store_name}
              </span>{" "}
            </h4>
            <Marginer margin={40} direction="vertical" />
            <div className="flex" style={BgSection}>
              <Container>
                <div className="flex items-center">
                  {item.product_title === "Gift Voucher" ? (
                    <Link to="" style={SquareImage} className="cursor-default">
                      <img src={item.product_url} alt="" />
                    </Link>
                  ) : (
                    <Link
                      to={`/product-details/${item.SUI}`}
                      style={SquareImage}
                    >
                      <img src={item.product_url} alt="" />
                    </Link>
                  )}

                  <div className="mx-5">
                    <Heading>{item.product_title}</Heading>
                    <h5 className="font-normal text-md my-2 ">
                      Sender Name
                      <span className="font-bold ml-4">
                        {item.store_username}
                      </span>
                    </h5>
                    {giftReceiptsList[0].message && (
                      <h5 className="font-normal text-md my-2">
                        Message
                        <span className="font-bold ml-4">{item.message}</span>
                      </h5>
                    )}
                  </div>
                </div>

                <Marginer margin={30} direction="vertical" />
                <div className="flex justify-between items-start">
                  <div>
                    <h5 className="text-md my-2">
                      Status: <span>{giftReceiptsList[0].item_status}</span>
                    </h5>
                    <h5 className="text-md my-2">
                      Est. Shipping Date: <span>{ShippingDate}</span>
                    </h5>
                  </div>
                  <Link to="/chats">
                    <ButtonComp
                      className="flex items-center !w-auto !py-3 px-8"
                      onClick={async () => {
                        let resp = await addChannel({
                          shopper_username_v1:
                            giftReceiptsList[0].store_username,
                          store_uuid: giftReceiptsList[0].store_uuid,
                        })
                        if (resp.status == 200) {
                          navigate("/chats")
                        }
                      }}
                    >
                      {" "}
                      <FaRocketchat className="size-5 mr-2" /> Talk to store
                    </ButtonComp>
                  </Link>
                </div>
              </Container>
            </div>
            <div style={BgSection}>
              <Hr />

              <Container>
                <Link to={`/product-details/${item.SUI}`}></Link>
              </Container>

              <Hr />
              <Marginer margin={30} direction="vertical" />
            </div>
          </>
        )
      })}
    </Main>
  )
}

export default GiftOrderDetailScreen
